/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { Box, Text, Button } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUpdatePasswordMutation} from "services/system/users";
import {ResetPassword} from "models/query/system";
import {useAppSelector} from "hooks/appHooks";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";

type Props = {} & WizardChildRef;

const Security: React.FC<Props> = (props) => {
  const { goToStep } = props;

  const { user } = useAppSelector((state) => state.auth);

  const passwordValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    password: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string()
        .required("Password Confirmation is required")
        .oneOf([Yup.ref("password"), null], "Passwords do not match"),

  });

  const defaultValues: FieldValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(passwordValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
  };

  const { mutate: updatePassword, isLoading: updating } =
      useUpdatePasswordMutation(onSubmitFinish);
  const onSubmit = (data : ResetPassword) => {
    delete data.confirmPassword
    updatePassword({
      variables: {
        data,
        id: Number(user.id)
      },
    });
  };

  return (
    <div className={"root px-3 lg:px-0 h-fit"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            " flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between mb-[2.5rem]"
          }
        >
          <Box col className={"w-full"}>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              Security
            </Heading>
            <Text className={cn(s.subtitle, "max-w-[29.0625rem] text-left")}>
              You can change your password settings
            </Text>
          </Box>
          <Box row>
            <Button
              variant="icon"
              className="lg:btn-md mr-6"
              type={'button'}
              onClick={() => {
                goToStep && goToStep(0);
              }}
            >
              Cancel
            </Button>
            <Button
                variant="fill"
                pill
                className="btn-md"
                type={'submit'}
                disabled={!isValid || updating}
            >
              {updating ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>


        <Box col className={"section no-border !pb-0 gi-inputs"}>
          <TextWrapper
              name={'oldPassword'}
              label={"Old Password"}
              placeholder={"Enter old password"}
              className={'mb-4'}
              type={'password'}
          />
          <TextWrapper
              name={'password'}
              label={"New Password"}
              placeholder={"Enter New Password"}
              className={'mb-4'}
              type={'password'}
          />
          <TextWrapper
              name={'confirmPassword'}
              label={"Confirm New Password"}
              placeholder={"Confirm New Password"}
              className={'mb-4'}
              type={'password'}
          />
        </Box>

      </Box>
      </FormProvider>
    </div>
  );
};
export default Security;
