/**
 * Project: tracebility-dashboard
 * File: auth
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */

import apiClient from './requests';
import ApiConfig from './endpoints';

export function loginService(username: string, password: string) {
    return apiClient.post(ApiConfig.AUTH.LOGIN, { username, password });
}
