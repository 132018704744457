/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { DeleteDialog } from "@features/Utils";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {CreateOrEditProductionRegion, ProductionRegion} from "@models/query/system";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
  useDeleteRegionMutation,
  useUpdateRegionMutation
} from "services/system/regions";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import s from "@pages/app/system/organizations/general-information/index.module.css";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import {toast} from "react-toastify";

type Props = {
  data: ProductionRegion | undefined;
  error: any;
  isLoading: boolean;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);

  const { generateModal } = useModalContext();

  const navigate = useNavigate();


  const { data, isLoading, error } = props;

  const regionValidationSchema = Yup.object().shape({
    name: Yup.string().required('Production region name is required'),
  });

  const defaultValues: FieldValues = {
    name: '',
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(regionValidationSchema),
    defaultValues,
  });

  const onSubmitFinish = () => {
    reset(defaultValues);

  }

  const { handleSubmit, reset, formState: { isValid } } = methods;

  const { mutate: updateRegion, isLoading: updating } =
      useUpdateRegionMutation({
        setEdit: onSubmitFinish
        });

        const goBack = () => {
          navigate(-1);

        }

  const { mutate: deleteRegion, isLoading: deleting, error: DeleteError } =
      useDeleteRegionMutation(goBack);


  const onSubmit = (region: CreateOrEditProductionRegion) => {


    if (data) {
      updateRegion({
        variables: {
          input: region,
          id: data.id
        }
      });
      setEdit(false);
    }
  }

  useEffect(() => {
    if (data) reset(data)
    return () =>{};
  }, [data]);

  useEffect(() => {
   if (DeleteError) {
     let handler = DeleteError as AxiosError

        if (handler.response?.data) {
          // @ts-ignore
          toast.error(handler.response?.data.message);

        }
   }
    return () =>{};
  }, [DeleteError]);

  return (
    <div className={"root px-3 lg:px-0"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            " flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can change the details of the production region.
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <a
                  className="btn btn-md mt-5"
                  onClick={() => setEdit(false)}
                  type={'button'}
                  role={'button'}
                >Cancel</a>
                <Button
                    disabled={!isValid || updating}
                  variant="fill"
                  pill
                  className="btn-md"
                  label={updating ? 'Saving Changes...' : 'Save'}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                  label={"Edit"}
                  type={'button'}
                />
                <Button
                  variant="fill"
                  pill
                  className="px-6"
                  label={"Delete Production Region"}
                  type={'button'}
                  onClick={() =>
                    generateModal(
                      "Delete Production Region",
                      <DeleteDialog
                          prompt=" Are you sure you want to delete this Production Region?"
                          callback={() => data ? deleteRegion({
                            variables: {
                              id: data.id
                            }
                          }) : null}
                          loading={deleting}
                      />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"gi-section"}>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={""}>
              Name of Production <br /> Region
            </Text>
            {edit ? (
                <TextWrapper
                    name={"name"}
                    color="primary"
                    placeholder="Northern Savannah Belt"
                    className={s.input}
                />
            ) : (
              <Text className={"gi-subtitle"}>{isLoading ? 'Fetching' : data?.name}</Text>
            )}
          </Box>
        </Box>
      </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
