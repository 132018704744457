import {
  Dashboard,
  RegionalCommodities,
  Users,
  ProductionRegion,
  Batches,
  Warehouse,
  Vehicles,
  Clients,
  TraceAnalytics,
  ReportAnalytics,
  Settings,
} from "@pages/app/buyer";
import AvailableGoods from "pages/app/buyer/available-goods";

import { MenuPaths } from "layout/app/context";
import { ComponentType } from "react";
import ResetPassword from "@forms/buyer/auth/reset-password";
import Login from "@forms/buyer/auth/Login";
import SignUp from "@forms/buyer/auth/SignUp";
import ActiveUsers from "@pages/app/buyer/users/ActiveUsers";
import Invitations from "@pages/app/buyer/users/Invitations";
import Blacklist from "@pages/app/buyer/users/blacklist";
// import Login from "@forms/buyer/Login";
// import SignUp from "@forms/buyer/SignUp";

export const buyerAuthRoutes: Array<{
  path: string;
  component: React.ElementType;
  subRoutes?: Array<{ path: string; component: React.ElementType }>;
}> = [
  { path: "login", component: Login },
  { path: "sign-up", component: SignUp },
  {
    path: "reset-password",
    component: ResetPassword,
    subRoutes: [
      { path: "", component: ResetPassword.Default },
      { path: "verification", component: ResetPassword.Verification },
      { path: "new-password", component: ResetPassword.NewPassword },
      { path: "blacklist", component: Blacklist },
    ],
  },
];
export const buyerAppRoutes: Array<{
  path: string;
  component: React.ElementType;
  subRoutes?: Array<{ path: string; component: React.ElementType }>;
}> = [
  {
    path: "dashboard",
    component: Dashboard as ComponentType,
    subRoutes: [
      { path: "", component: Dashboard.Default },
      { path: "move-goods", component: Dashboard.MoveGoods },
      { path: "dispose-goods", component: Dashboard.DisposeGoods },
      { path: "batch", component: Dashboard.Batch },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.USERS,
    component: Users,
    subRoutes: [
      { path: "", component: Users.Default },
      { path: ":id", component: Users.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.PRODUCTION_REGION,
    component: ProductionRegion,
    subRoutes: [
      { path: "", component: ProductionRegion.Default },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.REGIONAL_COMMODITIES,
    component: RegionalCommodities,
    subRoutes: [
      { path: "", component: RegionalCommodities.Default },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.GOODS,
    component: AvailableGoods,
    subRoutes: [
      { path: "", component: AvailableGoods.Default },
      { path: ":id", component: AvailableGoods.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.BATCH,
    component: Batches,
    subRoutes: [
      { path: "", component: Batches.Default },
      { path: ":id", component: Batches.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.WAREHOUSE,
    component: Warehouse,
    subRoutes: [
      { path: "", component: Warehouse.Default },
      { path: ":id", component: Warehouse.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.VEHICLES,
    component: Vehicles,
    subRoutes: [
      { path: "", component: Vehicles.Default },
      { path: ":id", component: Vehicles.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.CLIENTS,
    component: Clients,
    subRoutes: [
      { path: "", component: Clients.Default },
      { path: ":id", component: Clients.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.TRACE_ANALYTICS,
    component: TraceAnalytics,
  },
  {
    path: MenuPaths.ANALYTICS,
    component: ReportAnalytics,
  },
  {
    path: MenuPaths.SETTINGS,
    component: Settings,
  },

  {
    path: MenuPaths.ACTIVE_USERS,
    component: Users,
    subRoutes: [
      { path: "", component: ActiveUsers },
      { path: ":id", component: Users.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.INVITED_USERS,
    component: Users,
    subRoutes: [
      { path: "", component: Invitations },
      { path: ":id", component: Users.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
];
