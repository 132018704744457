/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import DetailsWizard from "@features/detail-wizard";
import React from "react";

import GeneralInformation from "./GeneralInformation";
import Organizations from "./organizations";
import {useParams} from "react-router-dom";
import {useProductionRegion} from "services/system/regions";
import AppGuard from "guards/AppGuard";
import Commodities from "@pages/app/system/production-region/commodities";

type Props = {};

const Details: React.FC<Props> = (props) => {

  const { id } =  useParams();

  const {
    region,
    isLoading,
    error,
  } = useProductionRegion(id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    {
      label: "Organizations",
      name: "organizations",
    },
    {
      label: "Commodities",
      name: "commodities",
    },
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard
      id={"production-region-details"}
      views={items}
      title={region?.name}
    >
      <GeneralInformation
          data={region}
          isLoading={isLoading}
          error={error}
          name="general-information"
      />
      <Organizations name="organizations"  id={id as string} />
      <Commodities  name="commodities" id={id as string} />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
