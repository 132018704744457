/**
 *Project:Traceability Platform
 *File:top-bar
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Textfield } from "@components/common";
import Heading from "@components/heading";
import { useModalContext } from "@layout/app/context";
import React, { ReactNode } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import s from "./index.module.css";
import AppGuard from "guards/AppGuard";
import cn from "classnames";

type Props = {
  title?: string;
  addButtonLabel?: string;
  renderForm?: ReactNode;
  optionalButtonLabel?: string;
  optionalAction?: () => void;
  onOptionClick?: string;
  onSearch?: Function;
  modalTitle?: string;
  renderAddButtonContent?: ReactNode;
  className?: string;
};

const TopBar: React.FC<Props> = (props) => {
  const {
    title = "default",
    addButtonLabel,
    optionalButtonLabel,
      optionalAction,
    onSearch,
    renderAddButtonContent,
    renderForm: renderModalChildren,
    modalTitle,
    className,
  } = props;
  const { generateModal } = useModalContext();
  // const openModal = () => {
  //   console.log("");
  //   setModalType && setModalType("center");
  //   renderContent(renderForm);
  //   toggleModal && toggleModal();
  //   _modalTitle && _modalTitle(modalTitle);
  // };

  return (
    <AppGuard
      showMessage={false}
      accessibleRoles={["systemAdministrator", 'buyerWarehouse', 'corporateAdministrator']}
    >
      <div className={cn(s.root, className)}>
        <Box row className={"flex flex-row items-center justify-between mb-5"}>
          <Heading
            Component={"h2"}
            className={"!text-[1.4rem] lufga-bold  lg:!text-[1.5rem] mr-auto"}
          >
            {title}
          </Heading>
          {optionalButtonLabel && (
            <Button
              color="secondary"
              variant="icon"
              onClick={optionalAction}
              className="h-[3rem] !text-[10px] lg:!text-base px-4 lg:w-[12.9375rem]"
            >
              {optionalButtonLabel}
            </Button>
          )}
          {addButtonLabel && (
            <Button
              color="primary"
              pill
              onClick={() => {
                generateModal(modalTitle, renderModalChildren);
              }}
              className="h-[3rem] !text-[10px] lg:!text-base  px-4 lg:px-5"
            >
              {addButtonLabel}
            </Button>
          )}

          {renderAddButtonContent && renderAddButtonContent}
        </Box>
        {onSearch && (
          <Box row alignItems={"center"} className={s.search}>
            <Textfield
              className={s.input}
              placeholder="Search"
              variant="borderless"
            />
            <AiOutlineSearch />
          </Box>
        )}
      </div>
    </AppGuard>
  );
};

export default TopBar;
