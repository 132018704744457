/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useState } from "react";
import cn from "classnames";
import { Box, Textfield, Text, Button } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
import { IAvailableGoods, ICreateAvailableGoods } from "@models/query/buyer";
import { useCountry, useDeleteGoodMutation, useGetCooperatives, useProductionRegions, useProviderType, useUpdateAvailableGoodMutation } from "services/buyer/goods";
import Select from 'react-select'
import { useUsers } from "services/buyer/users";
import auth from "@models/query/auth";
import TextWrapper from "@components/forms/TextWrapper";
import { UnitsOfQuantity } from "@components/forms/buyer/AddRecordGoodPurchase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type Props = { defaultData: IAvailableGoods } & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState<{ default: IAvailableGoods, editable: IAvailableGoods }>({ default: props.defaultData, editable: props.defaultData });
  const [providers, setProviders] = React.useState<{ mode: string, data: auth.RootObject[] }>({ mode: 'FARMER', data: [] });

  const { generateModal } = useModalContext();

  const { regions, isLoading } = useProductionRegions({
    page: 0,
    pageSize: 100,
    search: "",
  });

  const { users: farmers, isLoading: isLoadingFarmers } = useUsers({
    page: 0,
    size: 999,
    query: '',
    //@ts-ignore
    role: 'FARMER'
  });

  const { data: cooperatives, isLoading: isLoadingCooperatives } = useGetCooperatives({
    page: 0,
    size: 999,
    query: '',
    type: 'COOPERATIVE'
  });
  
  const { data: providerTypes, isLoading: isProviderTypesLoading } = useProviderType({
    page: 0,
    pageSize: 100,
    search: "",
  });

  const { data: countries, isLoading: isCountriesLoading } =
    useCountry({
      page: 0,
      pageSize: 999,
      search: "",
    });

  const handleOnChange = (param0: any, param1: any) => {

    if (param1) {
      const name = param1.name;
      const value = param0.value;

      if (name === 'country' && value) {
        const findCountry = countries.find(x => x.code = value);
        if (findCountry) {
          setData((prev) => ({ ...prev, editable: { ...prev.editable, [name]: findCountry } }))
        }
        return
      }

      setData((prev) => ({ ...prev, editable: { ...prev.editable, [name]: value } }))

      return
    }

    const name = param0.target.name;
    const value = param0.target.value;

    if (name && value) {
      setData((prev) => ({ ...prev, editable: { ...prev.editable, [name]: value } }))
    }

  }

  const onSubmitFinish = () => {
    setEdit(false)
  };

  const { mutate: updateGoods, isLoading: updating } =
    useUpdateAvailableGoodMutation(props.defaultData.id, onSubmitFinish);

    const { mutate: deleteGood, isLoading: deleting } =
    useDeleteGoodMutation(() => navigate && navigate(-1));

  const handleOnSubmit = () => {

    const payload = {
      askingPrice: Number(data.editable.askingPrice),
      countryCode: data.editable.country.code,
      digitalAddress: data.editable.digitalAddress,
      location: data.editable.location,
      providerId: Number.isInteger(data.editable.provider) ? Number(data.editable.provider) : 0,
      providerType: data.editable.providerType,
      quantity: data.editable.quantity,
      quantityUnit: data.editable.quantityUnit,
      regionalCommodityId: data.editable.regionalCommodity.id,
    } as ICreateAvailableGoods;

    if(!payload.providerId){
      toast.info('Select a provider');
      return;
    }

    updateGoods({
      variables: { input: payload }
    });
    
  }

  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can change the details of the goods available for purchase.
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => {
                    setEdit(false);
                    setData((prev) => ({ ...prev, editable: { ...prev.default } }))
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleOnSubmit}
                  variant="fill"
                  pill
                  className="btn-md"
                  disabled={updating}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  onClick={() =>
                    generateModal(
                      "Delete Goods",
                      <DeleteDialog
                        prompt={
                          "Are you sure you want to delete this goods?"
                        }
                        callback={() =>
                          deleteGood({
                            variables: {
                              id: data.default.id
                            },
                          })
                        }
                        loading={deleting}
                      />,
                      "center"
                    )
                  }
                >
                  Delete Record
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box className={"section no-border flex flex-col"}>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Regional Commodity</Text>
            {edit ? (
              <Select
                name='regionalCommodity'
                placeholder={data.editable.regionalCommodity.name}
                className="input"
                onChange={handleOnChange}
                options={regions.map(x => ({ label: x.name, value: x.id, ...x }))} />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.regionalCommodity.name}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Provider Type</Text>
            {edit ? (
              <Select
                name='providerType'
                placeholder={data.editable.providerType}
                className="input"
                options={providerTypes.map(x => ({ label: x.name, value: x.name, ...x }))}
                onChange={(newVal: any, actionMeta: any) => {
                  setProviders((x) => ({ ...x, mode: newVal.value, data: newVal.value === 'FARMER' ? farmers : cooperatives }))
                  handleOnChange(newVal, actionMeta);
                }}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.providerType}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Provider</Text>
            {edit ? (
              <Select name='provider' placeholder={data.editable.provider}
                className="input"
                onChange={handleOnChange}
                options={
                  (providers.mode === 'FARMER' ? farmers : cooperatives)
                  .map(x => x.firstName ? ({...x, name: `${x.firstName} ${x.lastName}`}): ({...x}))
                    .map(x => ({ label: x.name, value: x.id, ...x }))
                }
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.provider}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Quantity</Text>
            {edit ? (
              <Textfield
                color="primary"
                name={"quantity"}
                placeholder="1"
                onChange={handleOnChange as any}
                className="input"
                value={data.editable.quantity}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.quantity}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Units Of Quantity</Text>
            {edit ? (
              <Select name='providerId' placeholder={data.editable.quantityUnit}
                className="input"
                onChange={handleOnChange}
                options={UnitsOfQuantity.map(x => ({ label: x.name, value: x.id }))}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.quantityUnit}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Asking Price</Text>
            {edit ? (
              <Textfield
                color="primary"
                name={"askingPrice"}
                placeholder="1"
                className="input"
                onChange={handleOnChange as any}
                value={data.editable.askingPrice}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.askingPrice}</Text>
            )}
          </Box>

        </Box>

        <Box className={"section flex flex-col"}>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Country</Text>
            {edit ? (
              <Select
                name='country'
                placeholder={data.editable.country.name}
                className="input"
                options={countries.map(x => ({ label: x.name, value: x.code, ...x }))}
                onChange={handleOnChange}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.country.name}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Location</Text>
            {edit ? (
              <Textfield
                color="primary"
                name='location'
                placeholder="Location"
                className="input"
                onChange={handleOnChange as any}
                value={data.editable.location}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.location}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"}>Digital Address</Text>
            {edit ? (
              <Textfield
                color="primary"
                name='digitalAddress'
                placeholder="Digital address"
                className="input"
                onChange={handleOnChange as any}
                value={data.editable.digitalAddress}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.digitalAddress}</Text>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default GeneralInformation;
