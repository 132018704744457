/**
 * Project: tracebility-dashboard
 * File: jwt
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import jwtDecode from 'jwt-decode';
import { set, remove, get } from './ls';
import axios from 'services/requests';

const isValidToken = (accessToken: string) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    // @ts-ignore
    return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
    let expiredTimer;
    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime;
    console.log(timeLeft);
    expiredTimer = window.setTimeout(() => {
        console.log('expired');
    }, timeLeft);
    window.clearTimeout(expiredTimer);
};

const setSession = (accessToken: string) => {
    if (accessToken) {
        set('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        //@ts-ignore
        const { exp } = jwtDecode(accessToken);

        handleTokenExpired(exp);
    } else {
        remove('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const getAuthToken = () => {

   try {
       const accessToken = get('accessToken');
       if (!accessToken) {
           return null;
       }
       if (isValidToken(accessToken)) {
           return accessToken;
       }
       removeAuthToken();
       return null;
   }
   catch (_e) {
       return null;
   }

}

const removeAuthToken = () => {
    remove('accessToken');
    delete axios.defaults.headers.common.Authorization;
}
export { isValidToken, setSession, getAuthToken, removeAuthToken };
