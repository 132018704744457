/**
 * Project: tracebility-dashboard
 * File: FormProvider
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */

import { FormProvider as Form } from 'react-hook-form';


interface FormProviderProps {
    children: React.ReactNode,
    methods: any,
    onSubmit?: any
}

export default function FormProvider({ children, onSubmit, methods }: FormProviderProps) {
    return (
        <Form {...methods}>
            <form onSubmit={onSubmit}>{children}</form>
        </Form>
    );
}
