/**
 * Project: tracebility-dashboard
 * File: types
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
// COMMON
export const ENABLE_LOADER = 'ENABLE_LOADER';
export const DISABLE_LOADER = 'DISABLE_LOADER';
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const COMMON_RESPONSE = 'COMMON_RESPONSE';
export const COMMON_FAILED = 'COMMON_FAILED';
export const INITIALIZE = 'INITIALIZE';
export const RESET_STATUS = 'RESET_STATUS';
export const INACTIVE_ACCOUNT = 'INACTIVE_ACCOUNT';

// auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_LOADING_ENDED = 'LOGIN_LOADING_ENDED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOG_OUT = 'LOG_OUT';
