 import React, { useEffect, useState } from "react";
 import TopBar from "@layout/app/partials/top-bar";
 import SetProductionRegion from "@forms/buyer/SetProductionRegion";
 import AppGuard from "guards/AppGuard";
 import FormProvider from "@forms/FormProvider";
 import { Box, Button } from "@components/common";
 import cn from "classnames";
 import s from "@layout/app/partials/top-bar/index.module.css";
 import TextWrapper from "@forms/TextWrapper";
 import { AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
 import Table from "@components/table/Table";
 import { useNavigate } from "react-router-dom";
 import { useModalContext } from "@layout/app/context";
 import { FieldValues, useForm } from "react-hook-form";
 import {useDeleteRegionMutation,} from "services/buyer/goods";
 import { TableColumn, TablePagination } from "@models/store/table";
 import { IAvailableGoods, ProductionRegion } from "@models/query/buyer";
 import u from "@features/tables/system/users-table/index.module.css";
import AddNewWarehouse from "@forms/buyer/AddNewWarehouse";
import WarehouseTable from "@features/tables/buyer/Warehouse";
import { DeleteDialog } from "@features/Utils";
import { useOrgWarehouses, useDeleteWarehouseMutation } from "services/buyer/warehouse";
import { useAppSelector } from "hooks/appHooks";
import { Warehouse } from "@models/query/system";

type Props = {};

const Default: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const { generateModal } = useModalContext();

  const defaultValues: FieldValues = {
    search: "",
  };
  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;

  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const { data: warehouses, paginatorInfo, isLoading, error, loadNext, loadPrevious } =
  useOrgWarehouses(organisationId, {
      page,
      pageSize,
      search: searchTerm,
    });

  const { mutate: deleteWarehouse, isLoading: deleting } =
  useDeleteWarehouseMutation();

  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  const handleClick = (id: number, data: Warehouse | string = '') => {
    navigate(`${id}`, { state: data });
    // console.log(id)
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      handleSearch(value.search);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const Pagination: TablePagination | false = paginatorInfo
    ? {
      count: paginatorInfo.totalCount,
      pageSize: paginatorInfo.pageSize,
      page: paginatorInfo.page,
    }
    : false;

  const Columns: Array<TableColumn> = [
    {
      key: "name",
      title: "Name",
      onClick: (reg: Warehouse) => handleClick(reg.id, reg),
      render: (text: string, record: Warehouse) => (
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{text}</div>
          </div>
        </div>
      ),
    },
    {
      key: "address",
      title: "Location",
      onClick: (reg: Warehouse) => handleClick(reg.id, reg),
      render: (text: {id: number, streetAddress?: string}, record: Warehouse) => (
        <div className="text-sm text-gray-500">{text?.streetAddress || 'No Location'}</div>
      ),
    },
    {
      key: "id",
      title: "",
      className: "text-end",
      render: (text: string, record: Warehouse) => (
        <Box row className={cn(u.box, u.actions)}>
          <Button
            variant="icon"
            className="!hidden lg:!flex"
            onClick={() =>
              generateModal(
                "Delete Warehouse",
                <DeleteDialog
                  prompt={
                    "Are you sure you want to delete this warehouse?"
                  }
                  callback={() =>
                    deleteWarehouse({
                      variables: {
                        warehouseId: record.id,
                        organisationId
                      },
                    })
                  }
                  loading={deleting}
                />,
                "center"
              )
            }
          >
            <AiOutlineDelete />
          </Button>
        </Box>
      ),
    },
  ];
  
  return (
    <>
      <div className={""}>
        <TopBar
          title="Warehouse"
          modalTitle={"Add New Warehouse"}
          renderForm={<AddNewWarehouse />}
          addButtonLabel={"New Warehouse"}
          // onSearch={() => {}}
          // optionalButtonLabel={"Export CSV"}
        />
        {/* <WarehouseTable /> */}
        <FormProvider methods={methods} onSubmit={() => { }}>
          <Box row alignItems={"center"} className={cn(s.search, "mb-4")}>
            <TextWrapper
              name={"search"}
              className={s.input}
              variant="borderless"
              placeholder={"Search"}
            />
            <AiOutlineSearch />
          </Box>
        </FormProvider>
        <Table
            tableClassName={"table-hover"}
            loading={isLoading}
            columns={Columns}
            dataSource={warehouses}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={"No warehouses available"}
        />
      </div>
    </>
  );
};

export default Default;
