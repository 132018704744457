/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

import Default from "./Default";

type Props = {};

const ProductionRegion: FunctionComponent<Props> & {
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
ProductionRegion.Default = Default;

export default ProductionRegion;
