/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import Commodities from "./commodity";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import {useLocation} from "react-router-dom";
import {useBasicCommodity} from "services/system/commodities";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = (props) => {

  const { pathname } = useLocation();

  const id = pathname.split("/").pop();

  const {
    commodity,
    isLoading,
    error,
  } = useBasicCommodity(id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    {
      label: "Regional Commodities",
      name: "basic-commodities",
    },
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard
      id={"basic-commodities-details"}
      views={items}
      title={commodity?.name}
    >
      <GeneralInformation
          data={commodity}
          isLoading={isLoading}
          error={error}
          name="general-information" />
      <Commodities
          id={id as string}
          title={commodity?.name}
          name="basic-commodities" />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
