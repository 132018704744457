/**
 *Project:Traceability Platform
 *File:blacklist
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

import Default from "./Default";
import Details from "./Details";

type Props = {};
// export const WarehouseDetails = Details;
// export const WarehouseDefault = Default;
const Country: FunctionComponent<Props> & {
  Default: FunctionComponent<Props>;
  Details: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
Country.Default = Default;
Country.Details = Details;

export default Country;
