/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useEffect, useState} from "react";
import cn from "classnames";
import { Box, Text, Button } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
import {Client} from "@models/query/buyer";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "hooks/appHooks";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {CreateOrEditClient} from "@models/query/buyer";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {useUpdateClientMutation, useDeleteClientMutation} from "services/buyer/clients";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import s from "@pages/app/system/organizations/general-information/index.module.css";
type Props = {
  data: Client | undefined;
  error: any;
  isLoading: boolean;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props, context) => {
  const [edit, setEdit] = useState(false);

  const { generateModal } = useModalContext();

  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.auth);

  const { data, isLoading, error } = props;

  const clientValidationSchema = Yup.object().shape({
    name: Yup.string().required('Client\'s full name is required'),
    contactEmail: Yup.string().required('Contact email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
  });

  const defaultValues: FieldValues = {
    name: "",
    contactEmail: "",
    phoneNumber: "",
  };


  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(clientValidationSchema),
    defaultValues,
  });

  const onSubmitFinish = () => {
    reset(defaultValues);

  }
  const { handleSubmit, reset, formState: { isValid } } = methods;

  const { mutate: updateClient, isLoading: updating } =
      useUpdateClientMutation({
        setEdit: onSubmitFinish
      });

  const goBack = () => {
    navigate(-1);

  }

  const { mutate: deleteClient, isLoading: deleting, error: DeleteError } =
      useDeleteClientMutation(goBack);


  const onSubmit = (region: CreateOrEditClient) => {


    if (data) {
      updateClient({
        variables: {
          input: region,
          id: data.id.toString(),
          org: user.organisation.id
        }
      });
      setEdit(false);
    }
  }

  useEffect(() => {
    if (data) reset(data)
    return () =>{};
  }, [data]);

  useEffect(() => {
    if (DeleteError) {
      let handler = DeleteError as AxiosError
      if (handler.response?.data) {
        // @ts-ignore
        toast.error(handler.response?.data.message);

      }
    }
    return () =>{};
  }, [DeleteError]);


  return (
    <div className={"root px-3 lg:px-3"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between header"
          }
        >
          <Box col>
            <Heading size="lg100" bold="bold600" className={"text-left"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle max-w-[29.0625rem] text-left")}>
              You can change the details of the client
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <a
                  className="btn btn-md mt-5"
                  onClick={() => setEdit(false)}
                  type={'button'}
                  role={'button'}
                >Cancel</a>

                <Button
                    disabled={!isValid || updating}
                  variant="fill"
                  pill
                  className="btn-md"
                  label={updating ? 'Saving Changes...' : 'Save'}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  label={"Edit"}
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                  type={'button'}
                />

                <Button
                  variant="fill"
                  pill
                  label={"Delete Client"}
                  className="lg:btn-lg btn-md"
                  onClick={() =>
                    generateModal(
                      "Delete Client",
                      <DeleteDialog
                        prompt="Are you sure you want to delete "
                        boldText={data ? data.name : ''}
                        callback={() => data ? deleteClient({
                          variables: {
                            id: data.id.toString(),
                            org: user.organisation.id
                          }
                        }) : null}
                        loading={deleting}
                      />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section no-border"}>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Organization Name
            </Text>
            {edit ? (
                <TextWrapper
                    name={"name"}
                    color="primary"
                    required
                    placeholder="Ghana Nuts"
                    className={s.input}
                />
            ) : (
              <Text className={"gi-subtitle"}>{data ? data.name : 'Fetching record...'}</Text>
            )}
          </Box>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Email
            </Text>
            {edit ? (
                <TextWrapper
                    name={"contactEmail"}
                    color="primary"
                    required
                    placeholder="Ghana@Nuts.com"
                    className={s.input}
                />
            ) : (
              <Text className={"gi-subtitle"}>{data ? data.contactEmail : 'Fetching record...'}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Phone Number
            </Text>
            {edit ? (
                <TextWrapper
                    name={"phoneNumber"}
                    color="primary"
                    required
                    placeholder="020000000000"
                    className={s.input}
                />
            ) : (
              <Text className={"gi-subtitle"}>{data ? data.phoneNumber : 'Fetching record...'}</Text>
            )}
          </Box>
        </Box>
      </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;


/*
https://trace.lucidarray.dev/products_logistics/api/v1/organisation/26/clients/12 [GET]

{"data":[],"message":"Request validation failed","requestId":"0C41E4113F3319B12E021ED0A991835F","status":400,"totalCount":0,"page":1,"pageSize":0,"errors":[{"field":"clientId","message":"client with id 12 does not belong to organisation 26"},{"field":"organisationId","message":"organisation with id 26 does not have a client with 12registered as it's client"}]}

*/