/**
 * Project: tracebility-dashboard
 * File: authActions
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import { ICommonResponse } from 'models/query/common';
import * as types from './types';
import auth from 'models/query/auth';

export function requestLogin(username: string, password:string) {
    return {
        type: types.LOGIN_REQUEST,
        username,
        password
    };
}
export function initializeApp(isAuthenticated: boolean, user: any) {
    return {
        type: types.INITIALIZE,
        isAuthenticated,
        user,
    };
}
export function loginFailed(body: ICommonResponse) {
    return {
        type: types.LOGIN_FAILED,
        ...body,
    };
}

export function onLoginResponse(response: auth.RootObject) {
    return {
        type: types.LOGIN_RESPONSE,
        ...response,
    };
}
export function loginSuccessful() {
    return {
        type: types.LOGIN_LOADING_ENDED,
    };
}

export function logOut() {
    return {
        type: types.LOG_OUT,
    };
}
