/**
 *Project:Traceability Platform
 *File:box
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {
  CSSProperties,
  JSXElementConstructor,
  ReactElement,
  Ref,
} from "react";
import s from "./index.module.css";
import cn from "classnames";
import RefObject from "react";

type Props = {
  Component?: string | JSXElementConstructor<any>;
  id?: string;
  className?: String;
  row?: boolean;
  col?: boolean;
  form?: boolean;
  authForm?: boolean;
  children?: React.ReactNode;
  collapse?: string;
  ref?: Ref<HTMLElement>;
} & CSSProperties;

const Box: React.FC<Props> = (props) => {
  const {
    Component = "div",
    collapse,
    className,
    row,
    form,
    ref,
    id,
    authForm,
    col,
    children,
    ...rest
  } = props;
  return (
    <Component
      id={id}
      ref={ref}
      style={rest}
      data-collapse={collapse}
      className={cn(s.root as any, className as any, {
        [s.row]: row,
        [s.column]: col,
        [s.form]: form,
        [s.authForm]: authForm,
      } as any)}
    >
      {children}
    </Component>
  );
};
export default Box;
