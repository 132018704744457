import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import Table from "@components/table/Table";
import TopBar from "@layout/app/partials/top-bar";
import React from "react";
import cn from "classnames";
import AddNewVehicles from "@forms/buyer/AddNewVehicle";
import { useAppSelector } from "hooks/appHooks";
import { IVehicle } from "models/query/buyer";
import { TableColumn, TablePagination } from "@models/store/table";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "@layout/app/context";
import { FieldValues, useForm } from "react-hook-form";
import { useDeleteVehicleMutation, useVehicles } from "services/buyer/vehicle";
import { Box, Button } from "@components/common";
import u from "@features/tables/system/users-table/index.module.css";
import { DeleteDialog } from "@features/Utils";
import { AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";

type Props = {};

const Default: React.FC<Props> = (props) => {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(12);
  const [searchTerm, setSearchTerm] = React.useState("");
  const navigate = useNavigate();
  const { generateModal } = useModalContext();

  const defaultValues: FieldValues = {
    search: "",
  };
  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;

  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const { data: vehicles, paginatorInfo, isLoading, error, loadNext, loadPrevious } =
    useVehicles(organisationId, {
      page,
      pageSize,
      search: searchTerm,
    });

  const { mutate: deleteVehicle, isLoading: deleting } =
    useDeleteVehicleMutation();

  const Pagination: TablePagination | false = paginatorInfo ? {
    count: paginatorInfo.totalCount,
    pageSize: paginatorInfo.pageSize,
    page: paginatorInfo.page,

  } : false;

  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  React.useEffect(() => {
    const subscription = watch((value: any) => {
      handleSearch(value.search);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleClick = (id: number, data: any | string = '') => {
    navigate(`${id}`, { state: data });
  };

  const Columns: Array<TableColumn> = [
    {
      key: 'registrationNumber',
      title: 'Registration Number',
      onClick: (reg: IVehicle) => handleClick(reg.id, reg),
      render: (text: string, record: IVehicle) => (
        <div className="flex items-center">
          <div className="ml-4">
            {/* <div className="text-sm font-medium text-gray-900">
              {text}
            </div> */}
            <Box className={"flex flex-row"}>
              {text} &nbsp; &nbsp;

              {
                record.isUnknown ?
                  <div className="flex items-center justify-center rounded-[100rem] bg-[#F5HFB4] !text-[#8FA600] !text-[0.625rem] !w-[6.5625rem] !h-[1.1875rem]">
                    Known Vehicle
                  </div>
                  :
                  <div className="flex items-center justify-center rounded-[100rem] bg-[#F5FFB4] !text-[#8FA600] !text-[0.625rem] !w-[6.5625rem] !h-[1.1875rem]">
                    Unknown Vehicle
                  </div>
              }
            </Box>
          </div>
        </div>
      )
    },
    {
      key: 'capacity',
      title: 'Capacity',
      onClick: (reg: IVehicle) => handleClick(reg.id, reg),
      render: (text: string, record: IVehicle) => (
        <div className="text-sm text-gray-500">
          {record?.capacity?.value || 'Unknown'}
        </div>
      )
    },
    {
      key: 'axleCount',
      title: 'No. of Axles',
      onClick: (reg: IVehicle) => handleClick(reg.id, reg),
      render: (text: string) => (
        <div className="text-sm text-gray-500">
          {text}
        </div>
      )
    },

    {
      key: 'id',
      title: '',
      className: 'text-end',
      render: (text: string, record: IVehicle) => (
        <Box row className={cn(u.box, u.actions)}>
          <Button
            variant="icon"
            className="!hidden lg:!flex"
            onClick={() =>
              generateModal(
                "Delete Vehicle",
                <DeleteDialog
                  prompt={"Are you sure you want to delete vehicle "}
                  boldText={record.registrationNumber}
                  callback={() => deleteVehicle({
                    variables: {
                      id: record.id.toString(),
                      org: user.organisation.id
                    }
                  })}
                  loading={deleting}
                />,
                "center"
              )
            }
          >
            <AiOutlineDelete />
          </Button>
        </Box>
      )
    }
  ];

  return (
    <AppGuard accessibleRoles={["buyerWarehouse", 'corporateAdministrator']}>
      <div className={""}>
        <TopBar
          title="Vehicle"
          modalTitle={"Add New Vehicle"}
          renderForm={<AddNewVehicles />}
          addButtonLabel={"New Vehicle"}
        />
        <FormProvider methods={methods} onSubmit={() => { }} >

          <Box row alignItems={"center"} className={cn(s.search, 'mb-4')}>
            <TextWrapper
              name={'search'}
              className={s.input}
              variant="borderless"
              placeholder={"Search"}
            />
            <AiOutlineSearch />
          </Box>
        </FormProvider>
        <Table
          tableClassName={'table-hover'}
          loading={isLoading}
          columns={Columns}
          dataSource={vehicles}
          pagination={Pagination}
          pageSize={pageSize}
          loadNext={loadNext}
          loadPrevious={loadPrevious}
          setPage={setPage}
          page={page}
          error={error}
          emptyText={'No vehicle available'}
        />
      </div>
    </AppGuard>
  );

  // return (
  //   <>
  //     <div className={""}>
  //       <TopBar
  //         title="Vehicle"
  //         modalTitle={"Add New Vehicle"}
  //         renderForm={<AddNewVehicles />}
  //         addButtonLabel={"New Vehicle"}
  //         onSearch={() => {}}
  //         // optionalButtonLabel={"Export CSV"}
  //       />
  //       <VehicleTable />
  //     </div>
  //   </>
  // );
};

export default Default;
