/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
 import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
 import s from "./index.module.css";
 import cn from "classnames";
 import { Box, Text, Button } from "@components/common";
 import Avatar from "@components/avatar";
 import Heading from "@components/heading";
 import { fData, generateInitials, toBase64, validateEmail, validatePhoneNumber } from "@utils/helper";
 import { WizardChildRef } from "@components/common/wizard";
 import "@styles/forms.css";
 import { useAppSelector } from "hooks/appHooks";
 import countryList from "react-select-country-list";
 import * as Yup from "yup";
 import { FieldValues, useForm } from "react-hook-form";
 import { yupResolver } from "@hookform/resolvers/yup";
 import { useUpdateUserMutation } from "services/system/users";
 import { Contact, UpdateUser } from "models/query/system";
 import FormProvider from "@forms/FormProvider";
 import { Image, Shimmer } from "react-shimmer";
 import TextWrapper from "@forms/TextWrapper";
 import SelectWrapper from "@forms/SelectWrapper";
 type Props = {} & WizardChildRef;
 
 const MAX_FILE_SIZE = 3 * 1000 * 1000; // 3 Mb
 const FILE_FORMATS = ["image/jpg", "image/jpeg", "image/webp", "image/png"];
 
 const Profile: React.FC<Props> = () => {
   const ref = useRef();
 
   const [IDImage, setIDImage] = useState("https://imgs.search.brave.com/wDPLXXccb8aSrsq8KBP51VZu9d4415W8MaiEQoihKzc/rs:fit:474:225:1/g:ce/aHR0cHM6Ly90c2U0/Lm1tLmJpbmcubmV0/L3RoP2lkPU9JUC5S/NGdFanpHZHhJRUJa/NDJlYWZVSGZnSGFI/YSZwaWQ9QXBp");
   const [edit, setEdit] = useState(false);
   const { user: currentUser } = useAppSelector((state) => state.auth);
   const countryOptions = useMemo(() => countryList().getData(), []);
 
   const userValidationSchema = Yup.object().shape({
     firstName: Yup.string().required('First Name is Required'),
     lastName: Yup.string().required('Last Name is Required'),
     countryCode: Yup.string().required('Country Code is Required'),
     email: Yup.string()
       .required('Email Address is Required')
       .test('validate-email', 'Enter a valid email address', (value: any) => {
         if (value) {
           return validateEmail(value)
         }
         return true;
       }),
     phone: Yup.string()
       .required('Phone Number is Required')
       .test('validate-phone', 'Enter a valid phone number', (value: any) => {
         if (value) {
 
           return validatePhoneNumber(value)
         }
         return true;
       }),
     city: Yup.string().required('City is Required'),
     image: Yup.mixed()
       .notRequired()
       .test("fileFormat", "Unsupported Format", (value) => {
         if (value && value.length > 0) {
           return value && FILE_FORMATS.includes(value[0].type);
         }
         return true;
       })
       .test(
         "fileSize",
         `File must be less than or equal to ${fData(MAX_FILE_SIZE)}`,
         (value) => {
           if (value && value.length > 0) {
             return value && value[0].size <= MAX_FILE_SIZE;
           }
           return true;
         }
       ),
 
   });
 
 
   const defaultValues: FieldValues = {
     firstName: '',
     lastName: '',
     countryCode: '',
     email: '',
     phone: '',
     city: '',
     image: ''
   };
 
   const methods = useForm({
     mode: "onChange",
     reValidateMode: "onChange",
     resolver: yupResolver(userValidationSchema),
     defaultValues,
   });
 
   const onSubmitFinish = () => {
     reset(defaultValues);
 
   }
 
   const { handleSubmit, setValue, reset } = methods;
 
   const { mutate: updateUser, isLoading: updating } =
     useUpdateUserMutation(onSubmitFinish);
 
   const onSubmit = (user: typeof defaultValues) => {
     let contacts: Contact[] = []
     if (user.phone) {
       contacts.push({
         isPrimary: true,
         type: 'mobile',
         value: user.phone
       })
     }
     if (user.email) {
       contacts.push({
         isPrimary: true,
         type: 'email',
         value: user.email
       })
     }
     let payload: UpdateUser = {
       firstName: user.firstName,
       lastName: user.lastName,
       countryCode: user.countryCode,
       city: user.city,
       identificationMedia: {
         contentType: "application/json",
         fileName: "Any",
         url: IDImage,
       },
       contacts
     }
 
 
     if (user) {
       updateUser({
         variables: {
           data: payload,
           id: parseInt(currentUser.id)
         }
       });
     }
   }
 
 
   useEffect(() => {
     if (currentUser) {
 
       reset({
         firstName: currentUser.firstName,
         lastName: currentUser.lastName,
         countryCode: currentUser.country?.code || '',
         phone: currentUser.contacts.find((e: Contact) => e.type === 'mobile')?.value || '',
         email: currentUser.contacts.find((e: Contact) => e.type !== 'mobile')?.value || '',
         city: currentUser.city,
       });
 
       setIDImage(currentUser?.profileImage || '')
     }
     return () => { };
   }, [currentUser]);
 
   const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
     console.log(e.target.files)
     if (e.target.files) {
       // setIDImage(e.target.files[0]);
       toBase64(e.target.files[0])
         .then((image: unknown) => setIDImage(image as string))
         .catch(console.log);
     }
   };
 
 
   return (
     <div className={"root px-3 lg:px-0"}>
       {/*@ts-ignore*/}
       <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
         <Box col className={cn("w-full h-full pb-10")}>
           <Box
             className={
               "lg:items-center justify-between mb-[1rem] gap-4 lg:gap-0 flex lg:flex-row flex-col"
             }
           >
             <Box col>
               <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
                 Profile
               </Heading>
               <Text className={cn(s.subtitle, "max-w-[29.0625rem] text-left")}>
                 You can change your profile settings
               </Text>
             </Box>
             <Box row>
               {edit ? (
                 <>
                   <a
                     className="btn btn-md mt-5"
                     onClick={() => setEdit(false)}
                     type={'button'}
                     role={'button'}
                   >Cancel</a>
                   <Button
                     variant="fill"
                     pill
                     disabled={updating}
                     type={'submit'}
                     className="btn-md"
                     label={updating ? 'Saving...' : 'Save'}
                   />
                 </>
               ) : (
                 <>
                   <Button
                     variant="icon"
                     color="primary"
                     className="btn-md ml-auto lg:ml-0"
                     style={{ color: "var(--clr-primary-100)" }}
                     onClick={() => setEdit((current) => !current)}
                     type={'button'}
                   >
                     Edit
                   </Button>
                 </>
               )}
             </Box>
           </Box>
           {/* personal Information */}
 
           <Box className={"section !py-0"}>
             <Box className={s.personalInfo}>
               {/* @ts-ignore */}
               <img src={IDImage} height={250} width={250} style={{ borderRadius: 30 }} alt={currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : 'Error'} />
               {/* <Image
                  src={IDImage}
                  fallback={<Shimmer height={30} width={30} />}
                  
                  errorFallback={(props) => <Avatar
                    className={cn("w-[6.25rem]", s.avatar)}
                    initials={generateInitials(`${user ? `${user.firstName} ${user.lastName}` : 'Error'}`)}
                  />}
                  NativeImgProps={
                    {
                      className: 'img-sm img-avatar',
                      alt: user ? `${user.firstName} ${user.lastName}` : 'Error'
                    }} /> */}
             </Box>
           </Box>
 
           <Box row>
             <Box col className={"lg:pl-[6.875rem] w-full section no-border"}>
 
               <input hidden type='file' ref={ref as any} onChange={handleFileChange} />
               {edit ? <Button
                 variant="icon"
                 color="primary"
                 className="btn-md ml-auto lg:ml-0"
                 style={{ color: "var(--clr-primary-100)" }}
                 //@ts-ignore
                 onClick={() => ref.current.click()}
                 type={'button'}
               >
                 Change profile image
               </Button> : null}
 
               <Box row className={cn("gi-field")}>
                 <Text Component={"label"} className={s.label}>
                   First Name
                 </Text>
                 {edit ? (
                   <TextWrapper
                     name={"firstName"}
                     color="primary"
                     placeholder="Reggie"
                     className={"mb-4"}
                   />
 
                 ) : (
                   <Text className={s.subtitle}> {currentUser ? currentUser.firstName : 'Error'}</Text>
                 )}
               </Box>
               <Box row className={"gi-field"}>
                 <Text Component={"label"} className={s.label}>
                   Last Name
                 </Text>
                 {edit ? (
                   <TextWrapper
                     name={"lastName"}
                     color="primary"
                     placeholder="Okti"
                     className={"mb-4"}
                   />
                 ) : (
                   <Text className={s.subtitle}> {currentUser ? currentUser.lastName : 'Error'}</Text>
                 )}
               </Box>
             </Box>
           </Box>
 
           <Box row>
             <Box col className={"lg:pl-[6.875rem] w-full section no-border"}>
               <Box row className={cn("gi-field")}>
                 <Text Component={"label"} className={s.label}>
                   Email
                 </Text>
                 {edit ? (
                   <TextWrapper
                     name={"email"}
                     type={"email"}
                     color="primary"
                     placeholder="chelseaarday@gmail.com"
                     className={"mb-4"}
                   />
                 ) : (
                   <Text className={s.subtitle}>{currentUser ? `${currentUser.contacts.find((e: Contact) => e.type !== 'mobile')?.value || 'No Email Address'}` : 'Error'}</Text>
                 )}
               </Box>
               <Box row className={"gi-field"}>
                 <Text Component={"label"} className={s.label}>
                   Phone Number
                 </Text>
                 {edit ? (
                   <TextWrapper
                     name={"phone"}
                     color="primary"
                     placeholder="024xxxxxxxx"
                     className={"mb-4"}
                   />
                 ) : (
                   <Text className={s.subtitle}> {currentUser ? `${currentUser.contacts.find((e: Contact) => e.type === 'mobile')?.value || 'No Phone Number'}` : 'Error'}</Text>
                 )}
               </Box>
               <Box row className={"gi-field"}>
                 <Text Component={"label"} className={s.label}>
                   City
                 </Text>
                 {edit ? (
                   <TextWrapper
                     name={"city"}
                     color="primary"
                     placeholder="Accra"
                     className={s.input}
                   />
                 ) : (
                   <Text className={s.subtitle}> {currentUser ? `${currentUser.city || 'N/A'}` : 'Error'}</Text>
                 )}
               </Box>
               <Box row className={"gi-field"}>
                 <Text Component={"label"} className={s.label}>
                   Country
                 </Text>
                 {edit ? (
                   <SelectWrapper
                     name={"countryCode"}
                     className={'mb-4 input '}
                     placeholder={"GH"}
                     options={countryOptions}
                     value={countryOptions.find((i) => i.value === methods.getValues("countryCode"))}
                     onChange={(newValue: unknown, actionMeta) => {
                       let value = newValue as any;
                       if (currentUser) {
                         setValue("countryCode", value.value);
                       }
 
                     }}
                   />
                 ) : (
                   <Text className={s.subtitle}>{currentUser ? `${currentUser.country?.name || 'No Country'}` : 'Error'}</Text>
                 )}
               </Box>
             </Box>
           </Box>
         </Box>
       </FormProvider>
     </div>
   );
 };
 
 export default Profile;
 