/**
 * Project: tracebility-dashboard
 * File: endpoints
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */

const ApiConfig = {
    AUTH: {
        LOGIN: 'users/v1/login',
        FORGOT_PASSWORD: 'users/v1/forgot-password',
    },
    USERS: {
        DETAILS: 'users/v1/users/{id}',
        UPDATE: 'users/v1/users/{id}',
        CREATE: 'users/v1/users',
        REPORTS: 'users/v1/reports/users-and-organisations',
        ALL: 'users/v1/users',
        DELETE: 'users/v1/users/{id}',
        BLACKLIST: 'users/v1/blacklist',
        SUPPORTED_ROLES: 'users/v1/roles',
        ROLE: 'users/v1/users/{id}/roles',
        PASSWORD: 'users/v1/users/{id}/password',
        ENABLE: 'users/v1/users/{id}/enable',
        DISABLE: 'users/v1/users/{id}/disable',
        ADD_BLACKLIST: 'users/v1/users/{id}/blacklist',
        REMOVE_BLACKLIST: 'users/v1/users/{id}/blacklist',
        ORGANISATIONS: {
            ALL: 'users/v1/users/{id}/organisations',
            CREATE: 'users/v1/users/{id}/organisations',
            DELETE: 'users/v1/users/{id}/organisations'
        },
    },
    INVITATIONS: {
        CREATE: 'users/v1/invitations',
        ALL: 'users/v1/invitations',
        DETAILS: 'users/v1/invitations/{id}',
        DELETE: 'users/v1/invitations/{id}',
        RESEND: 'users/v1/invitations/{id}/resend',
        ACCEPT: 'users/v1/users?invitationId={id}',
        REPORTS: 'users/v1/reports/invitations',
    },
    ORGANISATIONS: {
        DETAILS: 'users/v1/organisations/{id}',
        ALL: 'users/v1/organisations',
        SUPPORTED: 'users/v1/supported-organisations',
        DELETE: 'users/v1/organisations/{id}',
        UPDATE: 'users/v1/organisations/{id}',
        DISABLE: 'users/v1/organisations/{id}/disable',
        ENABLE: 'users/v1/organisations/{id}/enable',
        CREATE: 'users/v1/organisations',
        COOPERATIVES: 'users/v1/organisations'
    },
    PRODUCTION_REGIONS: {
        ALL: 'products_logistics/api/v1/production/regions',
        CREATE: 'products_logistics/api/v1/production/regions',
        UPDATE: 'products_logistics/api/v1/production/regions/{id}',
        DELETE: 'products_logistics/api/v1/production/regions/{id}',
        DETAILS: 'products_logistics/api/v1/production/regions/{id}',
        ORGANISATIONS: 'products_logistics/api/v1/production/regions/{id}/organisations',
        COMMODITIES: 'products_logistics/api/v1/production/regions/{id}/regional/commodities',
        SET_REGION: 'products_logistics/api/v1/production/regions/assign/organisation',
        FETCH_SET_REGION: 'products_logistics/api/v1/production/regions',
        DELETE_SET_REGION: 'products_logistics/api/v1/production/regions/{id}/organisations',
        ORGAN: {
            ALL: 'products_logistics/api/v1/organisation/{id}/production/regions',
            DELETE: 'products_logistics/api/v1/organisation/{organisationId}/production/regions/{productionRegionId}/remove',
            SET_REGION: 'products_logistics/api/v1/production/regions'
        }
    },
    PRODUCTION_ZONES: {
        ALL: 'products_logistics/api/v1/production/zones',
        CREATE: 'products_logistics/api/v1/production/zones',
        UPDATE: 'products_logistics/api/v1/production/zones/{id}',
        DELETE: 'products_logistics/api/v1/production/zones/{id}',
        DETAILS: 'products_logistics/api/v1/production/zones/{id}',
        GET_BY_REGION: 'products_logistics/api/v1/production/regions/{regionId}/zones'
    },
    COMMODITIES: {
        BASIC: {
            ALL: 'products_logistics/api/v1/commodities/basic',
            CREATE: 'products_logistics/api/v1/commodities/basic',
            UPDATE: 'products_logistics/api/v1/commodities/basic/{id}',
            DELETE: 'products_logistics/api/v1/commodities/basic/{id}',
            DETAILS: 'products_logistics/api/v1/commodities/basic/{id}',
            REGIONAL: 'products_logistics/api/v1/commodities/basic/{id}/regional/commodities',
            ORGANIZATION: 'products_logistics/api/v1/organisation/{id}/commodities/regional',
            REMOVE_ORG: 'products_logistics/api/v1/commodities/regional/{id}/organisation/{organisationId}/remove',
            SET: 'products_logistics/api/v1/commodities/regional/{id}/organisation/{organisationId}/assign'
        },
        REGIONAL: {
            ALL: 'products_logistics/api/v1/commodities/regional',
            CREATE: 'products_logistics/api/v1/commodities/regional',
            UPDATE: 'products_logistics/api/v1/commodities/regional/{id}',
            DELETE: 'products_logistics/api/v1/commodities/regional/{id}',
            DETAILS: 'products_logistics/api/v1/commodities/regional/{id}',
            COUNT: 'reporting/api/v1/commodities/regional/count',
        }

    },
    BLACKLIST: {
        CATEGORIES: {
            ALL: 'users/v1/blacklist-categories',
            CREATE: 'users/v1/blacklist-categories',
            DELETE: 'users/v1/blacklist-categories/{id}',
        }
    },
    WAREHOUSES: {
        ALL: 'products_logistics/v1/organisation/{organisationId}/warehouses',
        CREATE: 'products_logistics/v1/organisation/{organisationId}/warehouses',
        UPDATE: 'products_logistics/v1/organisation/{organisationId}/warehouses/{id}',
        DELETE: 'products_logistics/v1/organisation/{organisationId}/warehouses/{id}',
        DETAILS: 'products_logistics/v1/organisation/{organisationId}/warehouses/{id}',
        STATUS: 'products_logistics/v1/organisation/{organisationId}/warehouses/{id}/enabled/{enabled}',
        COUNT: 'reporting/api/v1/warehouses/count',
        COUNT_BY_ORG: 'reporting/api/v1/organisation/{organisationId}/warehouses/count',
        RECEIVED_GOODS: 'reporting/api/v1/warehouse/{warehouseId}/goods/received',
        DISPATCHED_GOODS: 'reporting/api/v1/warehouse/{warehouseId}/goods/dispatched',
    },
    EDUCATION_LEVELS: 'users/v1/education-levels',
    COUNTRIES: {
        ALL: 'users/v1/organisation/{organisationId}/operating/countries',
        CREATE: 'users/v1/organisation/{organisationId}/operating/countries',
        DETAILS: 'users/v1/organisation/{organisationId}/operating/countries/{id}',
        UPDATE: 'users/v1/organisation/{organisationId}/operating/countries/{id}',
        DELETE: 'users/v1/organisation/{organisationId}/operating/countries/{id}',
    },
    CLIENTS: {
        ALL: 'products_logistics/api/v1/organisation/{organisationId}/clients',
        CREATE: 'products_logistics/api/v1/organisation/{organisationId}/clients',
        UPDATE: 'products_logistics/api/v1/organisation/{organisationId}/clients/{id}',
        DELETE: 'products_logistics/api/v1/organisation/{organisationId}/clients/{id}',
        DETAILS: 'products_logistics/api/v1/organisation/{organisationId}/clients/{id}'
    },
    GOODS:{
        REGION: {},
        COMMODITIES:{},
        ALL: 'products_logistics/api/v1/goods',
        CREATE: 'products_logistics/api/v1/goods',
        UPDATE: 'products_logistics/api/v1/goods/{id}',
        DELETE: 'products_logistics/api/v1/goods/{id}',
        DETAILS: 'products_logistics/api/v1/goods/{id}',
        GET_PROVIDERS: 'products_logistics/api/v1/providers',
        GET_PROVIDER_TYPES: 'products_logistics/api/v1/supported-provider-types',
        GET_COUNTRY: 'users/v1/supported/countries'
    },
    VEHICLE: {
        ALL: 'products_logistics/v1/organisation/{organisationId}/vehicles',
        CREATE: 'products_logistics/v1/organisation/{organisationId}/vehicles',
        UPDATE: 'products_logistics/v1/organisation/{organisationId}/vehicles/{id}',
        DELETE: 'products_logistics/v1/organisation/{organisationId}/vehicles/{id}',
        DETAILS: 'products_logistics/v1/organisation/{organisationId}/vehicles/{id}',
        STATUS: 'products_logistics/v1/organisation/{organisationId}/vehicles/{id}/active/{enabled}',
        COUNT: 'reporting/api/v1/vehicles/count',
    },
    BATCH: {
        ALL: 'products_logistics/v1.1/regional/commodity/batch',
        CREATE: 'products_logistics/v1.1/regional/commodity/batch',
        UPDATE: 'products_logistics/v1.1/regional/commodity/batch/{id}',
        DELETE: 'products_logistics/v1.1/regional/commodity/batch/{id}',
        DETAILS: 'products_logistics/v1.1/regional/commodity/batch/{id}',
    },
    VENDOR: {
        ALL: 'products_logistics/api/v1/vendors',
        CREATE: 'products_logistics/api/v1/vendors',
        UPDATE: 'products_logistics/api/v1/vendors/{id}',
        DELETE: 'products_logistics/api/v1/vendors/{id}',
        DETAILS: 'products_logisticsapi/v1/vendors/{id}',
    },
    REPORTING:{
        TRACE: {
            SEARCH: 'reporting/api/v1/organisation/{organisationId}/trace/batch/item/{tagId}',
            SEARCH_NO_ORG: 'reporting/api/v1/trace/batch/item/{tagId}'
        },
        QUERY: 'reporting/api/v1',
        RECEIVED_GOODS_COUNT: 'reporting/api/v1/goods/count/received',
        MOVED_GOODS_COUNT: 'reporting/api/v1/goods/count/moved',
        AVAILABLE_GOODS_COUNT: 'reporting/api/v1/goods/count/available',
        WAREHOUSE_COUNT: 'reporting/api/v1/warehouses/count',
    },
    TRANSACTION: {
        CLIENT: {
            ALL: 'reporting/api/v1/client/{clientId}/transactions'
        }
    }
}

export default ApiConfig;
