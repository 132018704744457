/**
 * Project: tracebility-dashboard
 * File: dashboard
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */

import { useQuery } from 'react-query';
import client from './index';
import {CommoditiesCount, CountQuery, DashboardCount, WarehousesCount} from "models/query/system";
import ApiConfig from "services/endpoints";

export const useDashboardCount = () => {
    const { data, isLoading, error } = useQuery<DashboardCount, Error>(
        [ApiConfig.USERS.REPORTS],
        client.users.count
    );

    return {
        users: data?.data[0].users,
        organisations: data?.data[0].organisations,
        isLoading,
        error,
    };
};

export const useWarehouseCount = (query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<WarehousesCount, Error>(
        [ApiConfig.WAREHOUSES.COUNT, query],
        ({ queryKey, pageParam }) =>
            client.warehouses.count(Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};

export const useWarehouseCountByOrg = (orgId: string, query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<WarehousesCount, Error>(
        [ApiConfig.WAREHOUSES.COUNT_BY_ORG, query],
        ({ queryKey, pageParam }) =>
            client.warehouses.countByOrgId(orgId, Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};

export const useCommoditiesCount = (query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<CommoditiesCount, Error>(
        [ApiConfig.COMMODITIES.REGIONAL.COUNT, query],
        ({ queryKey, pageParam }) =>
            client.regionalCommodities.count(Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};

export const useVehicleCount = (query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<WarehousesCount, Error>(
        [ApiConfig.VEHICLE.COUNT, query],
        ({ queryKey, pageParam }) =>
            client.dashboard.vehicleCount(Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};

export const useReceivedGoodsCount = (query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<WarehousesCount, Error>(
        [ApiConfig.REPORTING.RECEIVED_GOODS_COUNT, query],
        ({ queryKey, pageParam }) =>
            client.dashboard.receivedGoodsCount(Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};

export const useMovedGoodsCount = (query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<WarehousesCount, Error>(
        [ApiConfig.REPORTING.MOVED_GOODS_COUNT, query],
        ({ queryKey, pageParam }) =>
            client.dashboard.movedGoodsCount(Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};

export const useAvailableGoodsCount = (query?: CountQuery) => {
    const { data, isLoading, error } = useQuery<WarehousesCount, Error>(
        [ApiConfig.REPORTING.AVAILABLE_GOODS_COUNT, query],
        ({ queryKey, pageParam }) =>
            client.dashboard.availableGoodsCount(Object.assign({}, queryKey[1], pageParam))
    );

    return {
        count: data?.data[0].count,
        isLoading,
        error,
    };
};
