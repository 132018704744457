/**
 *Project:Traceability Platform
 *File:move-batch
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Select, Textfield, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { KgNoBatches } from "@components/Icons";
import AddMovedBatch from "@forms/buyer/AddMovedBatch";

import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";

type Props = {} & WizardChildRef;

const MoveBatch: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();
  const [isEmpty, setIsEmpty] = useState(true);
  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full h-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box row alignItems={"center"}>
            <Button
              onClick={() => props.goToStep && props.goToStep(1)}
              variant="icon"
              pill
              className="!text-2xl mr-5"
            >
              <AiOutlineArrowLeft />
            </Button>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              Move Batch
            </Heading>
          </Box>
          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setEdit(false)}
                  variant="fill"
                  pill
                  className="btn-md"
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  onClick={() => {
                    setIsEmpty(false);
                    generateModal(
                      "Move Batch",
                      <>
                        <AddMovedBatch />
                      </>,
                      "side"
                    );
                  }}
                >
                  Move Batch
                </Button>
              </>
            )}
          </Box>
        </Box>
        {isEmpty ? (
          <>
            <Box col className={"h-fit w-fit m-auto items-center"}>
              <KgNoBatches />
              <span>You have not moved batch yet</span>
            </Box>
          </>
        ) : (
          <>
            <Box col className={cn("section no-border")}>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Quantity of goods moved</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Coastal Shea Produce"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>30</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Waybill Number</Text>
                {edit ? (
                  <Textfield
                    color="primary"
                    placeholder="89893SD"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Shea</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Units of Quantity</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Kilogram"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Kilogram</Text>
                )}
              </Box>

              <Box row className={"gi-field"}>
                <Text Component={"label"}>Origin Warehouse</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Awodome Center"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Awodome Center</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Destination Type</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Awodome Center"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Buyer Warehouse</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Destination</Text>
                {edit ? (
                  <Textfield
                    color="primary"
                    placeholder="DSC Logistics"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>DSC Logistics</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Vehicle Type</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Non Platform Registered"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Non Platform Registered</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>
                  Vehicle Registration <br /> Number
                </Text>
                {edit ? (
                  <Textfield
                    color="primary"
                    placeholder="Box Truck"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>GN 8654 - 24</Text>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default MoveBatch;
