/**
 *Project:Traceability Platform
 *File:add-new-warehouse
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from 'react';
import { Box, Button, Textfield } from '@components/common';
import { useModalContext } from '@layout/app/context';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import SelectWrapper from "@forms/SelectWrapper";
import FormProvider from "@forms/FormProvider";
import { useAppSelector } from "hooks/appHooks";
import { useCreateVehicleMutation } from 'services/buyer/vehicle';
import TextWrapper from "../TextWrapper";
import { ICreateVehicle, IVehicle } from '@models/query/buyer';

type Props = {};

const AddNewVehicles: React.FC<Props> = (props) => {

  const { closeModal } = useModalContext();

  const defaultValues: FieldValues = {
    make: "",
    model: '',
    registrationNumber: '',
    capacity: 0,
    axleCount: 0
  };

  const regionValidationSchema = Yup.object().shape({
    registrationNumber: Yup.string().required("Registration Number is required"),
    make: Yup.string().required("Make is required"),
    model: Yup.string().required("Model is required"),
    capacity: Yup.number().required("Capacity is required"),
    axleCount: Yup.number().required("Axle Count is required"),
  });

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(regionValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    closeModal();
  };

  const { mutate: createVehicle, isLoading: creating } =
    useCreateVehicleMutation(onSubmitFinish);

  const { user } = useAppSelector((state) => state.auth);

  const onSubmit = (data: ICreateVehicle) => {

    const ownedBy = {
      id: null,
      name: user?.organisation?.name,
      contacts: [{ id: null, value: "+23324410010000", type: "mobile" }],
      isOwnerRegisteredOrganisation: true,
      isIndependentOwner: false,
      organisation: null
    };

    const size = {
      length: 0,
      width: 0,
      height: 0,
      unit: "ft",
      vehicle: null
    }

    const payload = {
      make: data.make,
      model: data.model,
      registrationNumber: data.registrationNumber,
      color: "",
      isActive: true,
      axleCount: data.axleCount,
      capacity: {
        value: data.capacity,
        unit: "ton"
      },
      size,
      ownedBy,
      transporterFor: { id: user?.organisation?.id }
    }


    createVehicle({
      variables: { input: payload as unknown as ICreateVehicle, org: user?.organisation?.id }
    });

  }


  return (
    <div className={"pb-5 w-full"}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit as any)}>
        <Box form className={"w-full"}>
          <TextWrapper
            required
            label={"Registration Number"}
            // className={cn(s.input, s.text)}
            placeholder="Enter registration number"
            name={"registrationNumber"}
          />

          <TextWrapper
            required
            label={"Make"}
            name={"make"}
            placeholder="Enter vehicle's make"
          />

          <TextWrapper
            required
            label={"Model"}
            name={"model"}
            placeholder="Enter vehicle's model"
          />
          <TextWrapper
            required
            label={"Capacity (Tonnes)"}
            name={"capacity"}
            placeholder="Enter capacity in tonnes"
          />
          <TextWrapper
            required
            label={"Number of axles"}
            name={"axleCount"}
            placeholder="Enter number of axles"
          />
        </Box>
        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
          className="mt-[5rem]"
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid || creating}
            pill
            style={{ width: "13.75rem" }}
          >
            {creating ? "Processing..." : "Add Vehicle"}
          </Button>
        </Box>

      </FormProvider>
    </div>
  );
};

export default AddNewVehicles;
