import {
  resolvePath,
  useLocation,
} from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import {KgEducation} from "@components/Icons";
import numeral from 'numeral'

export const uuid = () => {
  return uuidV4();
};
export const generateInitials = (value: string) => {

  // @ts-ignore
    return value.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase();
};
export const useRelativePath = () => {
  const location = useLocation();
  // console.log(
  //   "location",
  //   location.pathname,
  //   resolvePath("..", location.pathname)
  // );
  return resolvePath("..", location.pathname).pathname;
};

export const crypt = (text: string, salt = 'pennycodes@traceability') => {
  const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
  const byteHex = (n:string) => ('0' + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

export const decrypt = (encoded: string, salt = 'pennycodes@traceability') => {
  const textToChars = (text: string) => text.split('').map((c) => c.charCodeAt(0));
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);
  // @ts-ignore
  return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join('');
};

export const randomizeColorSet = () => {
  const colorSet = [
    {
      icon:  KgEducation({ color: "#71B9B2" }),
      color: "#C2DDD5"
    },
    {
        icon:  KgEducation({ color: "#8BAD50" }),
        color: "#CDD7B6"
    },
    {
        icon: KgEducation({ color: "#8BAD50" }),
        color: "#E7C2B0"
    },
    {
        icon: KgEducation({ color: "#E59034" }),
        color: "#E5CBB0"
    },
    {
        icon: KgEducation({ color: "#C13734" }),
        color: "rgba(193, 55, 52, 0.3)"
    },
    {
        icon: KgEducation({ color: "#EAB74A" }),
        color: "rgba(234, 183, 74, 0.3)"
    }
  ];

    const randomIndex = Math.floor(Math.random() * colorSet.length);
    return colorSet[randomIndex];
}

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "August",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

const days = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
];

export const formatDate = (date: Date) => {
    return  `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
}

export function fData(number: number) {
    return numeral(number).format('0.0 b');
}

export const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
}

export const validatePhoneNumber = (phoneNumber:string) => {
    const phoneNumberRegex = /^\d{10,15}$/;
    return phoneNumberRegex.test(phoneNumber);
}


export  const fetchValidRoleName = (role: string) => {
            switch (role) {
                case 'farmer':
                    return 'farmer';
                case 'fieldAgent':
                    return 'fieldAgent';
                case 'buyerWarehouse':
                    return 'buyerWarehouseOperator';
                case 'buyerOperations':
                    return 'buyerOperations';
                    case 'corporateAdministrator':
                        return 'corporateAdministrator'
                default:
                    return  'farmer';
            }
}
