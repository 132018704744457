/**
 * Project: tracebility-dashboard
 * File: TextWrapper
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import React, {forwardRef} from "react";


import { useFormContext, Controller } from 'react-hook-form';
import ErrorWrapper from "./ErrorWrapper";
import TextField, {TextFieldProps} from "components/common/textfield";


interface TextWrapperProps extends TextFieldProps {
    name: string;
}
const TextWrapper = forwardRef<HTMLInputElement, TextWrapperProps>(
    (props, ref) => {
    const { control } = useFormContext();
    const { name, ...rest } = props


    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <>
                    {/* @ts-ignore */}
                    <TextField {...field}  {...rest} ref={ref}  />
                    <ErrorWrapper state={!!error} message={error?.message}  />
                </>
            )}
        />
    );
})

export default TextWrapper
