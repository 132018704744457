/**
 * Project: tracebility-dashboard
 * File: blacklist
 * Created by pennycodes on 30/11/2022.
 * Copyright tracebility-dashboard
 */
import { useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";
import {QueryOptions,
    BlackListCategoryPaginator,
    CreatBlackListCategory
} from "@models/query/system";

export function useBlacklistCategories(options?: QueryOptions) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<BlackListCategoryPaginator, Error>(
        [ApiConfig.BLACKLIST.CATEGORIES.ALL, options],
        ({ queryKey, pageParam }) =>
            client.blacklistCategories.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        categories: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export interface IBlacklistIDVariable {
    variables: {
        id: number;
    }
}

export const useDeleteBlacklistMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IBlacklistIDVariable) =>
            client.blacklistCategories.delete(id),
        {
            onSuccess: () => {
                toast.success('Blacklist category deleted successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.BLACKLIST.CATEGORIES.ALL);
            },
        }
    );
}

export interface IBlacklistCreateVariable {
    variables: {
        input: CreatBlackListCategory;
    }
}

export const useCreateBlacklistMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }: IBlacklistCreateVariable) =>
            client.blacklistCategories.create(input),
        {
            onSuccess: () => {
                toast.success('Blacklist category added successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.BLACKLIST.CATEGORIES.ALL);
            },
        }
    );
}
