/**
 * Project: damirifa
 * File: TextField
 * Created by Pennycodes on 4/27/2022.
 * Copyright damirifa
 */

// import { useFormContext, Controller } from "react-hook-form";
// import styled, { css } from 'styled-components'
import { v4 as uuid } from "uuid";
import cn from "classnames";
import React, { JSXElementConstructor, useState } from "react";
// import { AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";
import s from "./index.module.css";
import { KgUpload } from "@components/Icons";

// import LoadingDots from '../loading-dots'

export interface Props {
  className?: string;
  color?: "primary" | "secondary";
  variant?: "borderless" | "outline";
  Component?: string | JSXElementConstructor<any>;
  disabled?: boolean;
  name?: string;
  value?: string;
  icon?: React.ReactNode;
  placeholder?: string;
  error?: boolean;
  onChange?: Function;
  label?: string;
  ref?: any;
}
const FileSelect: React.FC<Props> = (props) => {
  const {
    className,
    variant = "borderless",
    color = "primary",
    disabled = false,
    error = false,
    label,
      value,
    Component = "input",
      ref,
      onChange,
      ...rest
  } = props;

  const rootClassName = cn(
    s.root,
    {
      [s.secondary]: color === "secondary",
      [s.borderless]: variant === "borderless",
      [s.outline]: variant === "outline",
      [s.disabled]: disabled,
      [s.error]: error,
    },
    className
  );

  const [file, setFile] = useState<Object>();
  const [_label, setLabel] = useState<string>(label || "");
  const id = uuid();
  const handleFileUpload = (param: File) => {
    setFile(param);
    setLabel(param.name);
  };
  return (
    <fieldset
      data-selected={`${typeof file !== "undefined"}`}
      className={cn(s.fieldset, rootClassName)}
    >
      <label className={s.fileInput}>
        <Component
            ref={ref}
          id={id}
          disabled={disabled}
            type={"file"}
            value={value}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const target = event.target as HTMLInputElement;
              const fileList: FileList | null = target?.files;
              if (fileList) {
                const file: File = fileList[0] as File;
                handleFileUpload(file);
                if (onChange) {
                  return onChange(fileList)
                }
              }

            }}
            {...rest}
        />
        {variant === "outline" && <KgUpload />}
        {_label}
      </label>
    </fieldset>
  );
};

export default FileSelect;
