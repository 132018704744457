import Login from "@forms/system/Login";
import SignUp from "@forms/system/SignUp";
import { MenuPaths } from "layout/app/context";
import {
  Dashboard,
  Warehouse,
  BlacklistCategory,
  TraceAnalytics,
  ReportAnalytics,
  Country,
  EducationLevel,
  Settings,
  BasicCommodities,
  RegionalCommodities,
  Organizations,
  Blacklist,
  ProductionRegion,
  ProductionZone,
  Users,
  ActiveUsers,
  Invitations,
} from "pages/app/system";

export const systemAuthRoutes: Array<{
  path: string;
  component: React.ElementType;
  subRoutes?: Array<{ path: string; component: React.ElementType }>;
}> = [
  { path: "login", component: Login },
  { path: "sign-up", component: SignUp },
];
export const systemAppRoutes: Array<{
  path: string;
  component: React.ElementType;
  subRoutes?: Array<{ path: string; component: React.ElementType }>;
}> = [
  { path: "dashboard", component: Dashboard },
  {
    path: "warehouse",
    component: Warehouse,
    subRoutes: [
      { path: "", component: Warehouse.Default },
      { path: ":id", component: Warehouse.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.BLACKLIST_CATEGORY,
    component: BlacklistCategory,
    subRoutes: [
      { path: "", component: BlacklistCategory.Default },

      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.TRACE_ANALYTICS,
    component: TraceAnalytics,
  },
  {
    path: MenuPaths.ANALYTICS,
    component: ReportAnalytics,
  },
  {
    path: "country",
    component: Country,
    subRoutes: [
      { path: "", component: Country.Default },
      { path: ":id", component: Country.Details },

      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: "education-level",
    component: EducationLevel,
    subRoutes: [
      { path: "", component: EducationLevel.Default },
      { path: ":id", component: EducationLevel.Details },

      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.SETTINGS,
    component: Settings,
    subRoutes: [
      // { path: "", component: WarehouseDefault },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.BASIC_COMMODITIES,
    component: BasicCommodities,
    subRoutes: [
      {
        path: "",
        component: BasicCommodities.Default,
      },
      {
        path: ":id",
        component: BasicCommodities.Details,
      },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.REGIONAL_COMMODITIES,
    component: RegionalCommodities,
    subRoutes: [
      {
        path: "",
        component: RegionalCommodities.Default,
      },
      {
        path: ":id",
        component: RegionalCommodities.Details,
      },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: "organization",
    component: Organizations,
    subRoutes: [
      { path: "", component: Organizations.Default },
      { path: ":id", component: Organizations.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.BLACKLIST_CATEGORY,
    component: Blacklist,
    subRoutes: [
      { path: "", component: Organizations.Default },
      { path: ":id", component: Organizations.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: "production-region",
    component: ProductionRegion,
    subRoutes: [
      { path: "", component: ProductionRegion.Default },
      { path: ":id", component: ProductionRegion.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: "production-zone",
    component: ProductionZone,
    subRoutes: [
      { path: "", component: ProductionZone.Default },
      { path: ":id", component: ProductionZone.Details },
      // { path: "blacklist", component: Blacklist },
    ],
  },
  // {
  //   path: "users",
  //   component: Users,
  //   subRoutes: [
  //     { path: "", component: Users.Default },
  //     { path: ":id", component: Users.Details },
  //     { path: "blacklist", component: Blacklist },
  //   ],
  // },
  {
    path: MenuPaths.ACTIVE_USERS,
    component: Users,
    subRoutes: [
      { path: "", component: ActiveUsers },
      { path: ":id", component: Users.Details },
      { path: "blacklist", component: Blacklist },
    ],
  },
  {
    path: MenuPaths.INVITED_USERS,
    component: Users,
    subRoutes: [
      { path: "", component: Invitations },
      { path: ":id", component: Users.Details },
      { path: "blacklist", component: Blacklist },
    ],
  },
];
