/**
 * Project: tracebility-dashboard
 * File: clients
 * Created by pennycodes on 22/01/2023.
 * Copyright tracebility-dashboard
 */

import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import client from './index';
import {
    ClientsPaginator,
    ClientTransactionPaginator,
    CreateOrEditClient,
    QueryOptionsTwo
} from "models/query/buyer";
import ApiConfig from "services/endpoints";
import { toast } from "react-toastify";


//?page=0&pageSize=10&range=custom

export function useClients(org: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ClientsPaginator, Error>(
        [ApiConfig.CLIENTS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.clients.all(org, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        clients: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useClientTransaction(clientId: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ClientTransactionPaginator, Error>(
        [ApiConfig.TRANSACTION.CLIENT.ALL.replace('{clientId}', clientId), options],
        ({ queryKey, pageParam }) =>
            client.clients.transaction(clientId, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    };

    const response = data?.pages.flatMap((page) => page.data) ?? [];
    const temp = [];
    if (response && response.length) {
        for (const item of response) {
            const payload = { batchMovementId: 0, tagId: '', regionalCommodity: '', date: '' };
            payload.batchMovementId = item.batchMovementId
            payload.date = item.timestamp;
            for (const commodity of item.commodities) {
                payload.regionalCommodity = commodity.regionalCommodity.name;
                payload.tagId = commodity.tagId;
                if (!temp.map(x => x.tagId).includes(commodity.tagId))
                    temp.push(payload);
            }
        }
    }

    return {
        data: temp,
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}


export function useClient(org: string, id: string) {
    const { data, isLoading, error } = useQuery<ClientsPaginator, Error>(
        [ApiConfig.CLIENTS.DETAILS, id],
        () => client.clients.details(org, id),
        {
            enabled: !!id,
        }
    );
    return {
        client: data?.data[0],
        isLoading,
        error,
    };
}

export interface IClientIDVariable {
    variables: {
        id: string;
        org: string;
    }
}

export const useDeleteClientMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, org } }: IClientIDVariable) =>
            client.clients.delete(org, id),
        {
            onSuccess: () => {
                toast.success('Client removed successfully');
                reset && reset();
            },
            onError: () => {
                toast.error('An error occured while removing client');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.CLIENTS.ALL);
            },
        }
    );
}

export interface IClientUpdateVariables {
    variables: {
        id: string;
        org: string;
        input: CreateOrEditClient;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateClientMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit } = props
    return useMutation(
        ({ variables: { id, input, org } }: IClientUpdateVariables) =>
            client.clients.update(org, id, input),
        {
            onSuccess: () => {
                toast.success('Client details updated successfully');
                setEdit(false)
            },
            onError: () => {
                toast.error('An error occured while updating client info');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.CLIENTS.ALL);
                queryClient.invalidateQueries(ApiConfig.CLIENTS.DETAILS);
            },
        }
    );
};

export interface IClientCreateVariables {
    variables: { input: CreateOrEditClient, org: string };
}

export const useCreateClientMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input, org } }: IClientCreateVariables) =>
            client.clients.create(org, input),
        {
            onSuccess: () => {
                toast.success('Client created successfully');
                reset()
            },
            onError: () => {
                toast.error('An error occured while creating client');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.CLIENTS.ALL);
            },
        }
    );
};
