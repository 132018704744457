/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import {
    Box,
    Button,
    Checkbox,
    Select,
    Text,
    Textfield,
} from "@components/common";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useModalContext } from "@layout/app/context";
import React, { useState } from "react";

type Props = {};

const AddMoveBatch: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();
    // const ref = useAutoAnimate({
    //     duration: 0.3,
    // });
    const ref = React.useRef(null);

    const [isAdHoc, setIsAdhoc] = useState(false);
    return (
        <div
            className={"flex flex-col gap-[2.1875rem] pb-5 w-full mt-[-1rem]"}
            ref={ref as any}
        >
            <Textfield label={"Batch Number"} placeholder="847401" />
            <Textfield
                label={"Waybill Number"}
                required
                placeholder="Enter waybill Number"
            />
            <Textfield
                label={"Quantity of Goods To Be Moved"}
                required
                placeholder="Enter waybill Number"
            />
            <Select
                required
                label={"Units of Quantity"}
                placeholder="Select unit of quantity"
            />
            <Select
                required
                label={"Origin Warehouse"}
                placeholder="Select a warehouse"
            />
            <Box col height={"48px"}>
                <Text className={"mb-2"}>Destination Type</Text>
                <Box className={"flex lg:flex-row gap-4"}>
                    <Box row gap={"0.5rem"}>
                        <Checkbox />
                        Buyer Warehouse
                    </Box>
                    <Box row gap={"0.5rem"}>
                        <Checkbox />
                        Client
                    </Box>
                </Box>
            </Box>
            <Select
                required
                label={"Destination"}
                placeholder="Select client"
            />
            <Box col height={"48px"}>
                <Text className={"mb-2"}>Vehicle(s) Type</Text>
                <Box className={"flex lg:flex-row gap-4"}>
                    <Box row gap={"0.5rem"}>
                        <Checkbox />
                        Buyer Vehicle
                    </Box>
                    <Box row gap={"0.5rem"}>
                        <Checkbox />
                        Non platform registered
                    </Box>
                </Box>
            </Box>
            <Textfield
                label={"Vehicle(s)"}
                required
                placeholder="Enter delivery Vehicle registration number"
            />
            <Box
                row
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={"1.875rem"}
                className="mt-[rem]"
            >
                <Button
                    variant="icon"
                    style={{ color: "#20201F" }}
                    onClick={() => closeModal()}
                >
                    Cancel
                </Button>
                <Button
                    onClick={(_) => {}}
                    color="primary"
                    pill
                    style={{ width: "13.75rem" }}
                >
                    Move Batch
                </Button>
            </Box>
        </div>
    );
};

export default AddMoveBatch;
