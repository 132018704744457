/**
 *Project:Traceability Platform
 *File:card
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { CSSProperties, ReactNode } from "react";
import s from "./index.module.css";
import cn from "classnames";
export type Props = {
  className?: String;
  children?: ReactNode;
  fillColor?:
    | "blue"
    | "pink"
    | "green"
    | "orange"
    | "grey"
    | "yellow"
    | "red"
    | "";
  variant?: "fill" | "outline";
  onClick?: Function;
} & CSSProperties;

const getColors = (variant: string = 'fill', color: string = 'blue') => {
  return {
    [s.fill]: variant === "fill",
    [s.outline]: variant === "outline",
    [s.blue]: color === "blue",
    [s.pink]: color === "pink",
    [s.green]: color === "green",
    [s.orange]: color === "orange",
    [s.grey]: color === "grey",
    [s.yellow]: color === "yellow",
    [s.red]: color === "red",
  } as any
}

const Card: React.FC<Props> = (props) => {
  const {
    children,
    className,
    onClick,
    variant = "fill",
    fillColor: color = "blue",
    ...rest
  } = props;
  return (
    <article
      style={rest}
      onClick={() => onClick && onClick()}
      className={cn(s.root as any, className as any, getColors(variant, color))}
    >
      {children}
    </article>
  );
};

export default Card;
