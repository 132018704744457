/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
 import React from "react";
 import Profile from "./profile";
 import DetailsWizard from "@features/detail-wizard";
 import Security from "./security";
 import { useAppSelector } from "hooks/appHooks";
 
 type Props = {};
 
 const Settings: React.FC<Props> = () => {
   const { user } = useAppSelector((state) => state.auth);
   
   const items: Array<{ label: string; name: string }> = [
     {
       label: "Profile",
       name: "profile",
     },
     {
       label: "Security",
       name: "security",
     },
   ];
   return (
     <DetailsWizard id={"settings-details"} views={items} title={user ? `${user.firstName} ${user.lastName}` : 'Error'}>
       <Profile name="profile" />
       <Security name="security" />
     </DetailsWizard>
   );
 };
 
 export default Settings;
 