import { IVendorPaginator, QueryOptionsTwo } from "models/query/buyer";
import ApiConfig from "services/endpoints";
import client from './index';
import { useInfiniteQuery } from "react-query";

export function useVendors(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<IVendorPaginator, Error>(
        [ApiConfig.VENDOR.ALL, options],
        ({ queryKey, pageParam }) =>
            client.vendor.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}