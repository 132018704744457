/**
 *Project:Traceability Platform
 *File:auth
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import AuthLayout from "layout/auth";
import { Outlet } from "react-router-dom";
interface Props {}
const Auth: React.FC<Props> = (props) => {
  // let match = useMatch("/auth");

  // const routes: Array<{
  //   path: string;
  //   component: React.ElementType;
  // }> = [{ path: "system/login", component: SystemAdminLogin }];

  // console.log("auth.match", match?.pathname, routes);
  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
};

export default Auth;
