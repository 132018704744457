/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/

import DetailsWizard from "@features/detail-wizard";
import React from "react";
import GeneralInformation from "./general-information";
import Users from "./users";
import { useParams} from "react-router-dom";
import {useOrganisation} from "services/system/organizations";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = (props) => {
  const { id } =  useParams();

  const {
    organisation,
    isLoading,
    error,
  } = useOrganisation(id as string);


  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    {
      label: "Users",
      name: "users",
    },
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard id={"organization-details"} title={organisation?.name} views={items}>
      <GeneralInformation
          data={organisation}
          error={error}
            isLoading={isLoading}
          name="general-information" />
      <Users name="users" id={id as string} />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
