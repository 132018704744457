/**
 *Project:Traceability Platform
 *File:users-table
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Badge, Box } from '@components/common';
import Table from '@components/table';
import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import s from './index.module.css';

type Props = {};
const TableItem = (props: { isAdhoc?: boolean }) => {
  const { isAdhoc } = props;
  const navigate = useNavigate();
  const handleClick = () => {};
  return (
    <>
      <Table.Row className={cn("row rowLayout")}>
        <Table.Cell className={"cell"} onClick={() => handleClick()}>
          <Box row className={"box"}>
            847401
            {isAdhoc ? (
              <Badge className="!bg-[#EEFFFA]  !text-[#73CAB1] !w-[4.75rem] !h-[1.1875rem]">
                Ad-hoc
              </Badge>
            ) : (
              <></>
            )}
          </Box>
        </Table.Cell>
        <Table.Cell className={"cell"} onClick={() => handleClick()}>
          Costal Shea Produce
        </Table.Cell>
        <Table.Cell className={"cell"} onClick={() => handleClick()}>
          GN 8654 - 24, GN 1384 - 22
        </Table.Cell>
      </Table.Row>
    </>
  );
};
const GoodsBatchTable: React.FC<Props> = (props) => {
  return (
    <div className={"table-root"}>
      {/* </Box> */}
      <div className={"tableContainer"}>
        <Table className={"table"}>
          <Table.Header className={"rowLayout"}>
            <Table.Head className={"heading"}>Batch</Table.Head>
            <Table.Head className={"heading"}>Regional Commodity</Table.Head>
            <Table.Head className={"heading"}>Vehicle(s)</Table.Head>
          </Table.Header>
          <TableItem />
          <TableItem isAdhoc={true} />
          <TableItem isAdhoc />
        </Table>
      </div>
    </div>
  );
};

export default GoodsBatchTable;
