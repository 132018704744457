/**
 *Project:Traceability Platform
 *File:login
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { Box, Button, Link, Text, Textfield } from "@components/common";
import { useNavigate } from "react-router-dom";
import { useRelativePath } from "@utils/helper";
// import { useMatch } from "react-router-dom";
// import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { loginSuccessful, requestLogin } from "store/actions/authActions";
import FormProvider from "components/forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import { toast } from "react-toastify";
import Loader from "@components/loader";

interface Props {}

const Login: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const base = useRelativePath();

  const isProcess = useRef(false);
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isLoading } = useAppSelector((state) => state.loading);
  const { status, isAuthenticated, user } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate("/app/dashboard");
    }
    setIsLoaded(true);
    return () => {};
  }, [isAuthenticated, user]);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Email or Phone Number is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    username: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = methods;

  interface FormValues {
    username: string;
    password: string;
  }
  const onSubmit = (data: FormValues) => {
    isProcess.current = true;
    dispatch(requestLogin(data.username, data.password));
  };

  useEffect(() => {
    if (!isProcess.current || status === 100) return;

    if (status === 200) {
      dispatch(loginSuccessful());
      navigate("/app/dashboard"); //navigate if successful
      return;
    } else if (status === 401) {
      toast.error("Invalid Credentials");
    } else {
      reset();
      toast.error("An unknown error occurred");
    }

    // isProcess.current = false;
  }, [status]);

  return !isLoaded ? (
    <Loader />
  ) : (
    <Box className={"authForm mt-auto"}>
      <Text Component="h2" className={"header !text-center"}>
        Login
      </Text>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col  gap-[1rem] lg:gap-[2.5rem] w-full">
          <TextWrapper
            className={cn("input", "email")}
            label={"Email or Phone"}
            placeholder={"Enter email address or phone number"}
            name={"username"}
          />
          <TextWrapper
            className={cn("input", "password")}
            type={"password"}
            label={"Password"}
            placeholder={"Enter your password"}
            name={"password"}
          />
        </div>
        <Box
          row
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          className={"otherOption"}
        >
          <div className="flex items-center ">
            <input
              id="remember-me"
              type="radio"
              name="remember"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember Me
            </label>
          </div>
          <Link color="secondary" to={`${base}/reset-password`}>
            Forgot Password ?
          </Link>
        </Box>

        <Button
          label={isLoading ? "Processing..." : "Login"}
          pill
          className={"button"}
          type={"submit"}
          disabled={!isValid}
        />
        <Text className={"link !text-center !mt-[80px]"}>
          Don't have an account?&nbsp;
          <Link color="primary" to={`${base}/sign-up`}>
            Sign Up
          </Link>
        </Text>
      </FormProvider>
    </Box>
  );
};

export default Login;
