import { Box, Button, Select, Text, TextArea } from "components/common";
import { useModalContext } from "layout/app/context";
import cn from "classnames";
import React, {MouseEventHandler, useEffect} from "react";
import TextField from "@components/common/textfield";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";
import {
    useBlacklistUserMutation,
    useSupportRoles,
    useUpdatePasswordMutation,
    useUpdateRoleMutation
} from "services/system/users";
import s from "@forms/system/add-user/add-new-user/index.module.css";
import {AddBlacklist, BlackListCategory, CreateInvitation, ResetPassword, SupportedRole} from "@models/query/system";
import {toast} from "react-toastify";
import {useBlacklistCategories} from "services/system/blacklist";
import blacklist from "@pages/app/system/users/blacklist";
import TextAreaWrapper from "@forms/TextAreaWrapper";

const BlacklistDialog = (props: { id: number }) => {
  const { closeModal } = useModalContext();

    const {
        categories,
        paginatorInfo,
        isLoading,
        error,
    } = useBlacklistCategories({
        size: 999,
    });

    const blacklistValidationSchema = Yup.object().shape({
        categoryId: Yup.number().required("Blacklist category is required"),
        reason: Yup.string().required("Reason is required"),
    });
    const defaultValues: FieldValues = {
        categoryId: 0,
        reason: "",
    };

    const methods = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(blacklistValidationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { isValid },
    } = methods;

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();
    };

    const { mutate: blacklistUser, isLoading: updating } =
        useBlacklistUserMutation(onSubmitFinish);
    const onSubmit = (data: AddBlacklist) => {
        blacklistUser({
            variables: {
                data,
                id: props.id
            },
        });
    };

  return (
    <div className={"grid grid-cols-1 gap-[40px] w-full"}>
      <Text className={"text-base text-[#99A1A3]"}>
        Are you sure you want to blacklist this user? Once this account is
        blacklisted, no purchases shall be made from this account. To reverse
        this action, simply turn off the blacklist switch.
      </Text>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SelectWrapper
                name={"categoryId"}
                className={'mb-4'}
                required
                placeholder={"Select blacklist category"}
                options={categories}
                isLoading={isLoading}
                loadingMessage={() => "Fetching blacklist categories..."}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.name}
                value={categories.find(
                    (category: BlackListCategory) => category.id === methods.getValues("categoryId")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as BlackListCategory;

                    setValue("categoryId", value.id);
                }}
            />
      <TextAreaWrapper
          name={'reason'}
        className={"mb-4"}
        placeholder="Enter reason here..."
        label={"Reason for the blacklisting"}
      />
      <Box row className={"items-center justify-end mb-[1.25rem]"}>
        <Button
          variant="icon"
          className="mr-6"
          onClick={() => {
            closeModal();
          }}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || updating}
            className="w-[10.1875rem] h-[3rem]" pill>
            {updating ? "Blacklisting..." : "Blacklist User"}
        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};
const ChangeRolesDialog = (props: { id: number }) => {
  const { closeModal } = useModalContext();

    const { roles, isLoading } = useSupportRoles({
        size: 100,
    });
    const roleValidationSchema = Yup.object().shape({
        role: Yup.string().required("User role is required"),
    });
    const defaultValues: FieldValues = {
        role: "",
    };

    const methods = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(roleValidationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { isValid },
    } = methods;

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();
    };

    const { mutate: updateRole, isLoading: updating } =
        useUpdateRoleMutation(onSubmitFinish);
    const onSubmit = ({ role } : { role: string}) => {
        updateRole({
            variables: {
                role,
                id: props.id
            },
        });
    };
  return (
    <div className={"grid grid-cols-1 gap-[40px]  w-full"}>
      <Text className={"text-base text-[#99A1A3]"}>
        Are you sure you want to change the role of this user?
      </Text>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SelectWrapper
            name={"role"}
            className={'mb-4'}
            required
            placeholder={"Select user role"}
            options={roles}
            isLoading={isLoading}
            loadingMessage={() => "Fetching supported roles..."}
            getOptionValue={(option: any) => option.name}
            getOptionLabel={(option: any) => option.humanizedName}
            value={roles.find(
                (role: any) => role.name === methods.getValues("role")
            )}
            onChange={(newValue: unknown, actionMeta) => {
                let value = newValue as SupportedRole;

                setValue("role", value.name);
            }}
        />
      <Box row className={"items-center justify-end mt-4 mb-[1.25rem]"}>
        <Button
          variant="icon"
          className="mr-6"
          onClick={() => {
            closeModal();
          }}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || updating}
            className="w-[10.1875rem] h-[3rem]" pill>
            {updating ? "Updating..." : "Update Role"}
        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};
const ChangePasswordDialog = (props: { id: number }) => {
  const { closeModal } = useModalContext();

    const passwordValidationSchema = Yup.object().shape({
        password: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string()
            .required("Password Confirmation is required")
            .oneOf([Yup.ref("password"), null], "Passwords do not match"),

    });
    const defaultValues: FieldValues = {
        password: "",
        confirmPassword: "",
    };

    const methods = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(passwordValidationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isValid },
    } = methods;

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();
    };

    const { mutate: updatePassword, isLoading: updating } =
        useUpdatePasswordMutation(onSubmitFinish);
    const onSubmit = (data : ResetPassword) => {
        updatePassword({
            variables: {
                data,
                id: props.id
            },
        });
    };

  return (
    <div className={"grid grid-cols-1 gap-[40px]  w-full"}>
      <Text className={"text-base text-[#99A1A3]"}>
        Are you sure you want change your password this user?
      </Text>

        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

      <TextWrapper
          name={'password'}
          label={"New Password"}
          placeholder={"Enter New Password"}
          className={'mb-4'}
          type={'password'}
      />
      <TextWrapper
          name={'confirmPassword'}
        label={"Confirm New Password"}
        placeholder={"Confirm New Password"}
        className={'mb-4'}
          type={'password'}
      />

      <Box row className={"items-center justify-end mb-[1.25rem]"}>
        <Button
          variant="icon"
          className="mr-6"
          onClick={() => {
            closeModal();
          }}
          type={'button'}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || updating}
            className="w-[10.1875rem] h-[3rem]" pill>
            {updating ? "Updating..." : "Update Password"}
        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

interface Props {
  prompt: string;
  boldText?: string;
  callback?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  text?: string;
}
const DeleteDialog = (props: Props) => {
  const { closeModal } = useModalContext();
  return (
    <div className={cn("w-full")}>
      <Text
        className={"mt-[1.5625rem] mb-[2.3125rem] text-base text-[#99A1A3]"}
      >
        {`${props.prompt}`}&nbsp;
        {props.boldText && (
          <>
            <b className="text-black2A">{`${props.boldText}`}</b>&nbsp;?
          </>
        )}
      </Text>
      <Box
        row
        className={
          "items-center justify-end gap-[0.5rem] lg:gap-[2rem] mb-[1.25rem]"
        }
      >
        <Button
          variant="icon"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            props.callback && props.callback(e);
            closeModal();
          }}
          className="w-[10.1875rem] h-[3rem]"
          disabled={props.loading}
          pill
        >
          {props.loading ? "Processing..." : props.text || "Delete"}
        </Button>
      </Box>
    </div>
  );
};
export {
  BlacklistDialog,
  DeleteDialog,
  ChangePasswordDialog,
  ChangeRolesDialog,
};
