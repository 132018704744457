/**
 *Project:Traceability Platform
 *File:education-overview-cards
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import Card from "@components/card";
import { Box } from "@components/common";
import Heading from "@components/heading";
import cn from "classnames";
import React, { ReactNode } from "react";

import s from "./index.module.css";

type Props = {
  Icon: ReactNode;
  label: string;
  color: string;
  children?: ReactNode;
  className?: string;
  onClick?: Function;
};

const EducationOverviewCards: React.FC<Props> = (props) => {
  const { children, Icon, label, className, color, onClick, ...rest } = props;
  return (
    <Card
      className={cn(className, s.root)}
      color={color}
      background={color}
      {...rest}
      onClick={onClick}
    >
      <Box className={s.inner} row gap={30} margin={"auto"}>
        {Icon}
        <Box col gap={5}>
          <Heading
            textTransform="capitalize"
            color={"#000000"}
            className={"lg:text-[1.5rem] !text-[1.3rem] lufga-semi-bold fs-16"}
          >
            {label}
          </Heading>
        </Box>
      </Box>
    </Card>
  );
};

export default EducationOverviewCards;
