import { Box, Button } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import { useModalContext } from "@layout/app/context";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";
import {
  fData,
  toBase64,
  validateEmail,
  validatePhoneNumber,
} from "@utils/helper";

import s from "./index.module.css";
import { useInviteUserMutation, useSupportRoles } from "services/system/users";
import { SupportedRole } from "@models/query/system";
import DropZoneWrapper from "@components/forms/DropZoneWrapper";
import { useAppSelector } from "hooks/appHooks";
import { useProductionZones } from "services/system/zones";
import { AiOutlineClose } from "react-icons/ai";
// import { useAutoAnimate } from "@formkit/auto-animate/react";
import { CreateInvitation } from "@models/query/buyer";
import { useCreateOrgUserMutation } from "services/buyer/users";
import { useOrgWarehouses } from "services/buyer/warehouse";
import FileInput from "@components/forms/FileUpload";
type Props = {
  id?: string;
} & WizardChildRef;

const MAX_FILE_SIZE = 3 * 1000 * 1000; // 3 Mb
const FILE_FORMATS = ["image/jpg", "image/jpeg", "image/webp", "image/png"];

const AddNewUser: React.FC<Props> = React.forwardRef((props, ref) => {
  const [IDImage, setIDImage] = useState("/image/image-2.png");
  const [isRendered, setIsRendered] = useState(true);
  const { closeModal } = useModalContext();

  const [selectedRegions, setSelectedRegions] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [emailStatus, setEmailStatus] = useState(false);
  const [phoneStatus, setPhoneStatus] = useState(false);
  const [showStation, setShowStation] = useState(false);
  const [showProductionZones, setShowProductionZones] = useState(false);
  const forceUpdate = React.useCallback(() => setIDImage("/image/image-2.png"), []);

  const remove = (key: string) => {
    setSelectedRegions((r) => r.filter((r) => r.id !== key));
  };

  const add = (key: { id: string; name: string }) => {
    if (!selectedRegions.find((x) => x.id === key.id))
      setSelectedRegions((r) => [key, ...r]);
  };

  const userValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    identificationType: Yup.string().required("National ID type required"),
    identificationDocumentId: Yup.string().required(
      "National ID type required"
    ),
    // warehouseId: Yup.string().required("Station required"),
    //productionZones: Yup.string().required("Station required"),
    lastName: Yup.string().required("Last name required"),
    role: Yup.string().required("User role is required"),
    // password: Yup.string().required("New Password is required"),
    // confirmPassword: Yup.string().required("Password Confirmation is required").oneOf([Yup.ref("password"), null], "Passwords do not match"),
    email: Yup.string()
      .optional()
      .test("validate-email", "Enter a valid email address", (value: any) => {
        if (value) {
          return validateEmail(value);
        }
        return true;
      }),
    phone: Yup.string()
      .required("Phone Number is Required")
      .test("validate-phone", "Enter a valid phone number", (value: any) => {
        if (value) {
          return validatePhoneNumber(value);
        }
        return true;
      }),
    identificationMedia: Yup.mixed()
      .notRequired()
      .test("fileFormat", "Unsupported Format", (value) => {
        if (value && value.length > 0) {
          return value && FILE_FORMATS.includes(value[0].type);
        }
        return true;
      })
      .test(
        "fileSize",
        `File must be less than or equal to ${fData(MAX_FILE_SIZE)}`,
        (value) => {
          if (value && value.length > 0) {
            return value && value[0].size <= MAX_FILE_SIZE;
          }
          return true;
        }
      ),
  });

  const defaultValues: FieldValues = {
    username: "",
    role: "",
    firstName: "",
    lastName: "",
    identificationType: "",
    identificationDocumentId: "",
    warehouseId: "",
    productionZones: "",
    // password: "",
    // confirmPassword: "",
    email: "",
    phone: "",
  };

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(userValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    props.goToStep && props.goToStep(1);
  };

  const { roles, isLoading } = useSupportRoles({
    size: 100,
  });

  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const { zones, isLoading: isLoadingZones } = useProductionZones({
    page: 0,
    pageSize: 999,
    search: "",
  });
  const { data: warehouses, isLoading: isLoadingWarehouses } = useOrgWarehouses(
    organisationId,
    {
      page: 0,
      pageSize: 999,
      search: "",
    }
  );

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.identificationMedia) {
        if (value.identificationMedia[0]) {
          toBase64(value.identificationMedia[0])
            .then((image: unknown) => setIDImage(image as string))
            .catch(console.log);
        }
      }

      if (validateEmail(value.email)) {
        setEmailStatus(true);
      }

      if (validatePhoneNumber(value.phone)) {
        setPhoneStatus(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const { mutate: inviteUser, isLoading: creating } =
    useCreateOrgUserMutation(onSubmitFinish);

  const onSubmit = (user: CreateInvitation) => {
    const payload = {
      ...user,
      productionZoneIds: selectedRegions.map((x) => Number(x.id)),
      contacts: [] as { isPrimary: boolean, type: string, value: string }[],
      warehouseId: user.warehouseId
    };

    payload.identificationMedia = {
      contentType: "application/json",
      fileName: "Any",
      url: IDImage,
    };

    if (validateEmail(payload.email)) {
      payload.contacts.push({
        isPrimary: true,
        type: "email",
        value: payload.email,
      });
    }

    if (validatePhoneNumber(user.phone)) {
      payload.contacts.push({
        isPrimary: true,
        type: "mobile",
        value: user.phone,
      });
    }

    //@ts-ignore
    delete payload.username

    console.log(payload);

    inviteUser({
      variables: {
        input: payload,
      },
    });
  };

  const idTypes = [
    { label: "Voter's ID", value: "Voters" },
    { label: "National ID", value: "National" },
    { label: "Passport", value: "Passport" },
    { label: "NHIS", value: "NHIS" },
    { label: "Drivers License", value: "Drivers" },
  ];

  const genders = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];

  return (
    <div className={s.root}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box form className={"pb-[min(5rem,10vh)]"}>
          <TextWrapper
            name={"firstName"}
            required
            label={"First name"}
            placeholder="Enter full name"
          />

          <TextWrapper
            name={"lastName"}
            required
            label={"Last name"}
            placeholder="Enter Last name"
          />

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Photo of ID
            </label>
            <FileInput
                        accept="image/png, image/jpg, image/jpeg, image/gif"
                        name="identificationMedia"
                        label="File Upload"
                        setFile={setIDImage}
                        selectedImage={IDImage}
                    />
            {/* <DropZoneWrapper
              name={"identificationMedia"}
              render={() => <img alt={"user image"} src={IDImage} />}
            />
            {
              IDImage !== "/image/image-2.png" ?
                <span
                  style={{ fontSize: 11, color: 'red', fontWeight: 'bolder' }}
                  onClick={removeImage}>
                  Remove
                </span> :
                null
            } */}
          </div>

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              National ID Type <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              name={"identificationType"}
              className={"mb-4 input "}
              placeholder={"Select ID Type"}
              options={idTypes}
              value={idTypes.find(
                (i) => i.value === methods.getValues("identificationType")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                let value = newValue as any;
                setValue("identificationType", value.value);
              }}
            />
          </div>

          <TextWrapper
            name={"identificationDocumentId"}
            required
            label={"ID Number"}
            placeholder="Enter ID Number"
          />

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Gender
            </label>
            <SelectWrapper
              name={"gender"}
              className={"mb-4 input "}
              placeholder={"Select Gender"}
              options={genders}
              value={genders.find(
                (i) => i.value === methods.getValues("gender")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                let value = newValue as any;
                setValue("gender", value.value);
              }}
            />
          </div>

          {/* <TextWrapper
            name={"username"}
            required
            label={"Contact"}
            placeholder="Enter email address or phone number"
          /> */}

          <TextWrapper
            label={"Email"}
            name={"email"}
            type={"email"}
            color="primary"
            placeholder="chelseaarday@gmail.com"
            // readOnly={emailStatus}
            className={"mb-4"}
            required
          />
          <TextWrapper
            label={"Phone"}
            name={"phone"}
            color="primary"
            // readOnly={phoneStatus}
            placeholder="024xxxxxxxx"
            className={"mb-4"}
            required
          />

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Role <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              name={"role"}
              className={s.input}
              required
              placeholder={"Select user role"}
              options={roles.filter((x) => x.name !== "systemAdministrator")}
              isLoading={isLoading}
              loadingMessage={() => "Fetching supported roles..."}
              getOptionValue={(option: any) => option.name}
              getOptionLabel={(option: any) => option.humanizedName}
              value={roles.find(
                (role: any) => role.name === methods.getValues("role")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                let value = newValue as SupportedRole;
                setValue("role", value.name);

                if (value.name === 'buyerOperations' || value.name === 'buyerWarehouse') {
                  setShowStation(true)
                } else {
                  setShowStation(false)
                }

                if (value.name === 'fieldAgent' || value.name === 'buyerOperations' || value.name === 'buyerWarehouse' || value.name == 'farmer') {
                  setShowProductionZones(true)
                } else {
                  setShowProductionZones(false)
                }

              }}
            />
          </div>

          {
            showStation ? <div className="flex flex-col gap-[8px]">
              <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
                Station
              </label>
              <SelectWrapper
                name={"warehouseId"}
                className={"mb-4 input "}
                placeholder={"Select Station"}
                options={warehouses.map((x) => ({
                  ...x,
                  label: x.name,
                  value: x.id,
                }))}
                value={warehouses
                  .map((x) => ({ ...x, label: x.name, value: x.id }))
                  .find((i) => i.value === methods.getValues("warehouseId"))}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as any;
                  console.log(value);
                  setValue("warehouseId", value.value);
                }}
              />
            </div>
              : null
          }

          {
            showProductionZones ?
              <div className="flex flex-col gap-[8px]">
                <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
                  Production Zone <span style={{ color: "red" }}>*</span>
                </label>
                <SelectWrapper
                  name={"productionZones"}
                  required
                  placeholder={"Select zones"}
                  options={zones}
                  isLoading={isLoadingZones}
                  loadingMessage={() => "Fetching supported regions..."}
                  getOptionValue={(option: any) => option.id}
                  getOptionLabel={(option: any) => option.name}
                  value={zones.find(
                    (role: any) =>
                      role.name === methods.getValues("productionZones")
                  )}
                  onChange={(newValue: unknown, actionMeta) => {
                    const value = newValue as { id: string; name: string };

                    if (value) {
                      setValue("productionZones", value.id);
                      add(value);
                      console.log(selectedRegions);
                    }
                  }}
                />
                <Box
                  ref={ref as any}
                  className={"flex flex-row mt-[0.5rem] gap-[0.625rem] flex-wrap"}
                >
                  {selectedRegions.map((region, index) => (
                    <Box
                      display={"flex"}
                      key={index}
                      className={
                        " flex-row !h-[1.1875rem] gap-[0.7937rem] px-[0.625rem] rounded-[0.1875rem] items-center bg-[#FFDBCA]"
                      }
                    >
                      <span className="text-[#ED7136] text-[0.625rem]">
                        {region.name}
                      </span>
                      <AiOutlineClose
                        className="text-[10px]"
                        onClick={() => remove(region.id)}
                      />
                    </Box>
                  ))}
                </Box>
              </div>
              : null
          }


          {/* <TextWrapper
            name={"password"}
            label={"New Password"}
            placeholder={"Enter New Password"}
            type={"password"}
            className={"mb-4"}
          />

          <TextWrapper
            name={"confirmPassword"}
            label={"Confirm New Password"}
            placeholder={"Confirm New Password"}
            type={"password"}
            className={"mb-4"}
          /> */}
        </Box>
        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
            type={"button"}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid || creating}
            pill
            style={{ width: "9rem" }}
          >
            {creating ? "Processing..." : "Create User"}
          </Button>
        </Box>
      </FormProvider>
    </div>
  );
});

export default AddNewUser;
