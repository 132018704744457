/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect, useState } from "react";
import { Box, Button, Select, Textfield } from "@components/common";
import Wizard from "@components/common/wizard";
import Details from "./Details";
import UsersTable from "@features/tables/system/users-table";
import TopBar from "@layout/app/partials/top-bar";
import UsersOverviewCards from "@features/cards/users-overview-cards";
import AddNewUser from "@forms/system/add-user/add-new-user";
import InvitationSuccess from "@forms/system/add-user/invitation-success";
import cn from "classnames";
import {
  KgFilter,
  KgExchange,
  KgInvitationSent,
  KgInvitationAccepted,
  KgInvitationPending,
} from "@components/Icons";
import { AiOutlineSearch } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import AppGuard from "guards/AppGuard";
import { useUsers, useUsersInvitationCount } from "services/system/users";

type Props = {};
export const UserDetails = Details;
const Default: React.FC<Props> = () => {
  const [collapse, toggleCollapse] = useState<boolean>(true);
  const { accepted, pending, sent } = useUsersInvitationCount();
  const { users, paginatorInfo, hasNextPage } = useUsers();

  useEffect(() => {
    if (users) {
      console.log("my users", users);
      console.log("my paginatorInfo", paginatorInfo);
      console.log("my hasNextPage", hasNextPage);
    }
    return () => {};
  }, [users]);

  const AddUserModal = () => (
    <Wizard id={"user-add-side-modal"}>
      <AddNewUser />
      <InvitationSuccess />
    </Wizard>
  );
  return (
    <AppGuard accessibleRoles={["systemAdministrator"]}>
      <main className={""}>
        <TopBar
          title="Users"
          modalTitle={"Add New User"}
          renderForm={<AddUserModal />}
          addButtonLabel={"Add New User"}
          optionalButtonLabel={"Export CSV"}
        />
        <Box
          className={
            "gap-[0.6456rem] xl:gap-[3.125rem] grid md:grid-cols-2 lg:grid-cols-3"
          }
        >
          <UsersOverviewCards
            Icon={KgInvitationSent}
            label={"Invitation sent"}
            value={sent ?? "..."}
            fillColor={"blue"}
          />
          <UsersOverviewCards
            Icon={KgInvitationAccepted}
            label={"Invitation Accepted"}
            value={accepted ?? "..."}
            fillColor={"green"}
          />
          <UsersOverviewCards
            Icon={KgInvitationPending}
            label={"Invitation pending"}
            value={pending ?? "..."}
            fillColor={"pink"}
          />
        </Box>
        <Button
          variant="icon"
          className="mt-[1.75rem] mb-[0.75rem] px-5 gap-4 text-primary"
          onClick={(_) => toggleCollapse((r) => !r)}
        >
          <KgFilter />
          <span>Filter</span>
          <BsChevronDown
            style={{ transform: `rotateZ(${collapse ? "0deg" : "180deg"})` }}
          />
        </Button>
        {/* <Collapse isOpen={true}> */}
        {!collapse ? (
          <Box
            className={cn(
              "mb-4 gap-4 flex flex-col lg:flex-row lg:items-center",
              {}
            )}
          >
            <Box
              row
              alignItems={"center"}
              className={
                "lg:w-[16.9375rem] border-solid border-[0.0625rem] !h-[2.6875rem]  border-[#DADADA] rounded pr-4"
              }
            >
              <Textfield
                placeholder="Search"
                className="!border-none !h-[100%] "
                fill
              />
              <AiOutlineSearch className="text-[#878787] text-lg" />
            </Box>
            <Select
              icon="caret"
              label="Roles"
              color="secondary"
              className={"lg:!w-[11.8125rem]"}
            />
            <Select
              icon="caret"
              label="Users"
              color="secondary"
              className={"lg:!w-[8.4375rem]"}
            />
            <Select
              icon="caret"
              label="Roles"
              color="secondary"
              className={"lg:!w-[8.4375rem]"}
            />
            <Button
              variant="outline"
              className="px-4 rounded-[5px] !max-w-[3.375rem] mx-auto lg:mx-0 !h-[54px] aspect-square"
              color="secondary"
              pill={false}
            >
              <KgExchange />
            </Button>
          </Box>
        ) : (
          <></>
        )}
        <UsersTable />
      </main>
    </AppGuard>
  );
};

export default Default;
