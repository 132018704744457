/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";

import { Box, Button, Link, Text, Textfield } from "@components/common";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useRelativePath } from "@utils/helper";
interface Props {}

const Verification: React.FC<Props> = (props) => {
  const base = useRelativePath();
  const navigate = useNavigate();
  const handleVerify = () => {
    navigate(`${base}/new-password`);
  };

  return (
    <Box className={"authForm reset-password"}>
      <Text Component="h2" className={"header"}>
        Enter Verification Code
      </Text>
      <Box col className={"content"}>
        <Text Component="h2" className={"subtitle"}>
          We’ve sent a code to your preferred contact provided. Please enter
          code here.
        </Text>

        <OtpInput
          value={""}
          onChange={() => {}}
          numInputs={6}
          inputStyle={"otp"}
          containerStyle={
            "flex flex-row w-full justify-between items-center max-w-[22rem] mx-auto"
          }
          separator={<span className="mx-1" />}
        />
        <Text className={"resetLink"}>
          Already have an account?&nbsp;
          <Link
            color="secondary"
            className="font-bold"
            to={`${base}/forgot-password`}
          >
            Try Again
          </Link>
        </Text>
        <Button
          label={"Verify"}
          pill
          className={"button mt-5"}
          onClick={() => handleVerify()}
        />
      </Box>
    </Box>
  );
};

export default Verification;
