/**
 * Project: tracebility-dashboard
 * File: clients
 * Created by pennycodes on 22/01/2023.
 * Copyright tracebility-dashboard
 */

import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    ClientsPaginator,
    CreateOrEditClient,
    ICreateVehicle,
    IVehiclePaginator,
    QueryOptionsTwo
} from "models/query/buyer";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";



export function useVehicles(org: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ClientsPaginator, Error>(
        [ApiConfig.VEHICLE.ALL, options],
        ({ queryKey, pageParam }) =>
            client.vehicle.all(org, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useVehicle(org: string, id: string) {
    const { data, isLoading, error } = useQuery<IVehiclePaginator, Error>(
        [ApiConfig.VEHICLE.DETAILS, id],
        () => client.vehicle.details(org,id),
        {
            enabled: !!id,
        }
    );
    
    return {
        data: data?.data[0],
        isLoading,
        error,
    };
}

export interface IClientIDVariable {
    variables: {
        id: string;
        org: string;
    }
}

export const useDeleteVehicleMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, org } }: IClientIDVariable) =>
            client.vehicle.delete(org,id),
        {
            onSuccess: () => {
                toast.success('Vehicle removed successfully');
                reset && reset();
            },
            onError: () => {
                toast.error('An error occured while removing vehicle');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.VEHICLE.ALL);
            },
        }
    );
}

export interface IVehicleUpdateVariables {
    variables: {
        id: string;
        org: string;
        input: ICreateVehicle;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateVehicleMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit } = props
    return useMutation(
        ({ variables: { id, input, org } }: IVehicleUpdateVariables) =>
            client.vehicle.update(org, id, input),
        {
            onSuccess: () => {
                toast.success('Vehicle updated successfully');
                setEdit(false)
            },
             onError: () => {
                toast.error('An error occured while updating vehicle info');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.VEHICLE.ALL);
                queryClient.invalidateQueries(ApiConfig.VEHICLE.DETAILS);
            },
        }
    );
};

export interface IVehicleCreateVariables {
    variables: { input: ICreateVehicle, org: string };
}

export const useCreateVehicleMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input, org } }: IVehicleCreateVariables) =>
            client.vehicle.create(org, input),
        {
            onSuccess: () => {
                toast.success('Vehicle created successfully');
                reset()
            },
            onError: () => {
                toast.error('An error occured while creating vehicle');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.VEHICLE.ALL);
            },
        }
    );
};
