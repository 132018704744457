/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import {Box, Button, Dropdown} from "@components/common";
import { KgExchange, KgFilter } from "@components/Icons";
import AddNewUser from "@forms/buyer/add-user/add-new-user";

import TopBar from "@layout/app/partials/top-bar";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {AiOutlineDelete, AiOutlineSearch} from "react-icons/ai";
import {BsChevronDown, BsThreeDotsVertical} from "react-icons/bs";

import Details from "./Details";
import {ROLES, USER_STATUS} from "models/query/system";
import {Link, useNavigate} from "react-router-dom";
import {useModalContext} from "@layout/app/context";
import {
  useDeleteUserMutation,
  useDisableUserMutation,
  useEnableUserMutation,
  useRemoveUserMutation,
  useUsers
} from "services/system/users";
import {BlacklistDialog, ChangePasswordDialog, ChangeRolesDialog, DeleteDialog} from "@features/Utils";
import auth from "models/query/auth";
import {FieldValues, useForm} from "react-hook-form";
import {TableColumn, TablePagination} from "@models/store/table";
import Avatar from "@components/avatar";
import Badge from "@features/tables/system/users-table/badge";
import s from "@features/tables/system/users-table/index.module.css";
import {fetchValidRoleName, uuid} from "@utils/helper";
import client from "services/system";
import Wizard from "@components/common/wizard";
import InvitationSuccess from "@forms/system/add-user/invitation-success";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";
import Table from "@components/table/Table";

type Props = {};
export const UserDetails = Details;

const roles = [
  { label: "All", value: ROLES.ALL },
  { label: "System Administrator", value: ROLES.SYSTEM },
  { label: "Corporate Administrator", value: ROLES.ADMIN },
  { label: "Buyer Operations", value: ROLES.BUYER },
  { label: "Buyer Warehouse", value: ROLES.WAREHOUSE },
  { label: "Corporate Warehouse Manager", value: ROLES.MANAGER },
  { label: "Farmer", value: ROLES.FARMER },
  { label: "Field Agent", value: ROLES.FIELDAGENT },
];

const Default: React.FC<Props> = () => {
  const [collapse, toggleCollapse] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [exporting, setExporting] = useState("Export CSV");
  const [role, setRole] = useState<ROLES>(ROLES.ALL);
  const [status, setStatus] = useState<USER_STATUS>(USER_STATUS.ALL);
  let navigate = useNavigate();

  const { generateModal } = useModalContext();

  const { mutate: disableUser, isLoading: disabling } = useDisableUserMutation();
  const { mutate: enableUser, isLoading: enabling } = useEnableUserMutation();
  const { mutate: removeBlacklist, isLoading: blacklist } = useRemoveUserMutation();
  
  const handleUserStatus = (id: number, action: string) => {
    if (action === 'enable') {
      enableUser({
        variables: {
          id
        }
      })
    }
    if (action === 'disable') {
      disableUser({
        variables: {
          id
        }
      })
    }


  }

  const actions: Array<{ label: string; onClick: Function, dynamic?: string }> = [
    {
      label: "Edit User",
      onClick: (id: number) => {
        navigate(`${id}`, { state: '' });
      },
    },
    {
      label: "Change User Role",
      onClick: (id: number) => {
        // generateModal(
        //     "Change User Role",
        //     <ChangeRolesDialog id={id} />,
        //     "center"
        // );
      },
    },
    {
      label: "Reset Password",
      onClick: (id: number) => {
        // generateModal(
        //     "Change Password",
        //     <ChangePasswordDialog id={id} />,
        //     "center"
        // );
      },
    },
    {
      label: "Blacklist User",
      dynamic: 'blacklist',
      onClick: (data: auth.RootObject) => {
        if (data.isBlacklisted) {
          generateModal(
              "Unblacklist User",
              <DeleteDialog
                  prompt={"Are you sure you want to unblacklist this user?"}
                  callback={() =>
                      removeBlacklist({
                        variables: {
                          id: data.id,
                        },
                      })
                  }
                  loading={blacklist}
                  text={"Remove Blacklist"}
              />,
              "center"
          )
        }
        else {
          generateModal("Blacklist User", <BlacklistDialog id={data.id} />, "center");
        }
      },
    },
    { label: "Disable User",
      dynamic: 'status',
      onClick: (data: auth.RootObject) => {
        if (data.status === "DISABLED") {
          handleUserStatus(data.id, 'enable')
        }
        if (data.status === "ACTIVE") {
          handleUserStatus(data.id, 'disable')
        }
      } },
  ];

  const defaultValues: FieldValues = {
    role: ROLES.ALL,
    status: USER_STATUS.ALL,
    search: "",
  };
  const methods = useForm({
    defaultValues,
  });

  const { reset, setValue, watch } = methods;

  const { users, paginatorInfo, isLoading, error, loadNext, loadPrevious } =
      useUsers({
        page,
        size: pageSize,
        query: searchTerm,
        role,
        status,
      });
  const { mutate: deleteUser, isLoading: deleting } = useDeleteUserMutation();
  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  const handleResetFilter = () => {
    reset(defaultValues);
  };

  const onRoleFilter = (value: ROLES) => {
    setRole(value);
    setPage(0);
  };

  const onStatusFilter = (value: USER_STATUS) => {
    setStatus(value);
    setPage(0);
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      handleSearch(value.search);
      onRoleFilter(value.role);
      onStatusFilter(value.status);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const Pagination: TablePagination | false = paginatorInfo
      ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,
      }
      : false;

  const Columns: Array<TableColumn> = [
    {
      key: "firstName",
      title: "Name",
      render: (text: string, record: auth.RootObject) => (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {record.profileImage ? (
                  <img
                      className="h-10 w-10 rounded-full"
                      src={record.profileImage}
                      alt=""
                  />
              ) : (
                  <Avatar
                      initials={record.firstName[0] + record.lastName[0]}
                      color={"green"}
                  />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                <Link to={record.id.toString()} className={'nav-link font-bold'}>
                  {record.firstName} {record.lastName}
                </Link>
              </div>
            </div>
          </div>
      ),
    },
    {
      key: "role",
      title: "Role",
      render: (text: auth.Role, record: auth.RootObject) => (
          <div className="text-sm text-gray-500">{text.role}</div>
      ),
    },
    {
      key: "organisation",
      title: "Production Zone",
      render: (text: auth.Organization, record: auth.RootObject) => {
        console.log({text, record})
        return <div className="text-sm text-gray-500">{text?.name}</div>
      },
    },
    {
      key: "organisation",
      title: "Station",
      render: (text: auth.Organization, record: any) => {
          // <Badge status={text?.name} />
          const name = record[fetchValidRoleName(record.role.role)]?.warehouse?.name || 'Unavailable';
          return <div className="text-sm text-gray-500">{name}</div>
      },
    },

    {
      key: "id",
      title: "",
      className: "text-end",
      render: (text: number, record: auth.RootObject) => (
          <Box row className={cn(s.box, s.actions)}>
            <Dropdown>
              <Dropdown.Toggle>
                <Button variant="icon">
                  <BsThreeDotsVertical />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu x="left" variant="default" className={s.dropdown}>
                {actions.map((i) => (
                    <Dropdown.Item className={s.item} key={uuid()}>
                  <span className={s.item} onClick={() => i.onClick(i.dynamic ? record : text)}>
                    {i.dynamic ? (i.dynamic === 'status' ? (record.status === 'ACTIVE' ? 'Disable User' : 'Enable User') : (record.isBlacklisted ? 'Remove Blacklist' : 'Blacklist User') ) : i.label}

                  </span>
                    </Dropdown.Item>
                ))}
                <Dropdown.Item className={cn("lg:hidden", s.item)} key={uuid()}>
                <span
                    className={s.item}
                    onClick={() =>
                        generateModal(
                            "Delete User",
                            <DeleteDialog
                                prompt={"Are you sure you want to delete User"}
                                callback={() =>
                                    deleteUser({
                                      variables: {
                                        id: record.id,
                                      },
                                    })
                                }
                                loading={deleting}
                            />,
                            "center"
                        )
                    }
                >
                  Delete User
                </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
                variant="icon"
                className="!hidden lg:!flex"
                onClick={() =>
                    generateModal(
                        "Delete User",
                        <DeleteDialog
                            prompt={"Are you sure you want to delete User"}
                            callback={() =>
                                deleteUser({
                                  variables: {
                                    id: record.id,
                                  },
                                })
                            }
                            loading={deleting}
                        />,
                        "center"
                    )
                }
            >
              <AiOutlineDelete />
            </Button>
          </Box>
      ),
    },
  ];

  const exportACSV = () => {
    setExporting("Exporting users...");

    client.users
        .all({
          size: 9999,
          exportAsCsv: true,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
              new Blob([response as unknown as BlobPart])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "users.csv");
          document.body.appendChild(link);
          link.click();
          setExporting("Export Complete");
        })
        .catch((error) => {
          setExporting("Export Error. Retry");
        })
        .finally(() => {
          setTimeout(() => {
            setExporting("Export CSV");
          }, 2000);
        });
  };

  const AddUserModal = () => (
      <Wizard id={"user-add-side-modal"}>
        <AddNewUser />
        <InvitationSuccess />
      </Wizard>
  );

  return (
      <AppGuard accessibleRoles={["buyerWarehouse", 'corporateAdministrator']}>
      <main className={"pb-4"}>
        <TopBar
          title="Users"
          modalTitle={"Add New User"}
          renderForm={<AddUserModal />}
          addButtonLabel={"Add New User"}
          optionalButtonLabel={exporting}
          optionalAction={exportACSV}
        />

        <Button
          variant="icon"
          className="mt-[1.75rem] mb-[0.75rem] px-5 gap-4 text-primary"
          onClick={(_) => toggleCollapse((r) => !r)}
        >
          <KgFilter />
          <span>Filter</span>
          <BsChevronDown
            style={{ transform: `rotateZ(${collapse ? "0deg" : "180deg"})` }}
          />
        </Button>
        {/* <Collapse isOpen={true}> */}
        {!collapse ? (
            <FormProvider methods={methods} onSubmit={() => {}}>
              <Box
                  className={cn(
                      "mb-4 gap-4 flex flex-col lg:flex-row lg:items-center",
                      {}
                  )}
              >
                <Box
                    row
                    alignItems={"center"}
                    className={
                      "lg:w-[16.9375rem] border-solid border-[0.0625rem] !h-[2.6875rem]  border-[#DADADA] rounded pr-4"
                    }
                >
                  <TextWrapper
                      name={"search"}
                      className="!border-none !h-[100%] "
                      fill
                      placeholder={"Search"}
                  />
                  <AiOutlineSearch className="text-[#878787] text-lg" />
                </Box>
                <SelectWrapper
                    name={"role"}
                    className={"lg:w-[16.9375rem]"}
                    options={roles}
                    placeholder={"Select Role"}
                    value={roles.find((i) => i.value === role)}
                    onChange={(newValue: unknown, actionMeta) => {
                      let value = newValue as any;
                      setValue("role", value.value);
                    }}
                />

                <Button
                    variant="outline"
                    className="px-4 rounded-[5px] !max-w-[3.375rem] mx-auto lg:mx-0 !h-[54px] aspect-square"
                    color="secondary"
                    onClick={handleResetFilter}
                    type={"button"}
                    pill={false}
                >
                  <KgExchange />
                </Button>
              </Box>
            </FormProvider>
        ) : (
          <></>
        )}
        <Table
            tableClassName={"table-hover"}
            loading={isLoading}
            columns={Columns}
            dataSource={users}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={"No users available"}
        />
      </main>
      </AppGuard>
  );
};

export default Default;
