/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import "@styles/forms.css";

import { Box, Button, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { DeleteDialog } from "@features/Utils";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import {
  BasicCommodity,
  CreateOrEditRegionalCommodity,
  ProductionRegion,
  RegionalCommodity,
} from "@models/query/system";
import { useProductionRegions } from "services/system/regions";
import {
  useBasicCommodities,
  useDeleteRegionalCommodityMutation,
  useUpdateRegionalCommodityMutation,
} from "services/system/commodities";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";

type Props = {
  data: RegionalCommodity | undefined;
  error: any;
  isLoading: boolean;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);
  // const navigate = useNavigate();
  const { generateModal } = useModalContext();

  const { data, isLoading, error } = props;

  const navigate = useNavigate();

  const { regions } = useProductionRegions({
    pageSize: 999,
  });
  const { commodities } = useBasicCommodities({
    pageSize: 999,
  });
  const regionalCommodityValidationSchema = Yup.object().shape({
    name: Yup.string().required("Commodity name is required"),
    basicCommodity: Yup.number().required("Basic Commodity is required"),
    productionRegion: Yup.number().required("Production Region is required"),
  });
  const defaultValues: FieldValues = {
    name: "",
    basicCommodity: undefined,
    productionRegion: undefined,
  };

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(regionalCommodityValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    setEdit(false);
  };

  const {
    mutate: updateRegionalCommodity,
    isLoading: updating,
    error: updateError,
  } = useUpdateRegionalCommodityMutation({
    setEdit: onSubmitFinish,
  });

  const goBack = () => {
    navigate(-1);
  };

  const {
    mutate: deleteRegionalCommodity,
    isLoading: deleting,
    error: DeleteError,
  } = useDeleteRegionalCommodityMutation(goBack);

  const onSubmit = (commodity: CreateOrEditRegionalCommodity) => {
    if (data) {
      updateRegionalCommodity({
        variables: {
          input: commodity,
          id: data.id,
        },
      });
      setEdit(false);
    }
  };

  useEffect(() => {
    if (data)
      reset({
        name: data.name,
        productionRegion: data.productionRegion.id,
        basicCommodity: data.basicCommodity.id,
      });
    return () => {};
  }, [data]);

  useEffect(() => {
    if (DeleteError) {
      let handler = DeleteError as AxiosError;
      console.log("handler", handler);
      if (handler.response?.data) {
        // @ts-ignore
        toast.error(handler.response?.data.message);
      }
    }
    return () => {};
  }, [DeleteError]);

  useEffect(() => {
    if (updateError) {
      let handler = updateError as AxiosError;
      if (handler.response?.data) {
        // @ts-ignore
        if ("errors" in handler.response?.data) {
          // @ts-ignore
          toast.error(handler.response?.data.errors[0].message);
        }
      }
    }
    return () => {};
  }, [updateError]);

  return (
    <div className={"root px-3 lg:px-0"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box col className={cn("w-full")}>
          <Box
            className={
              "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header"
            }
          >
            <Box col>
              <Heading className={"text-left fs-30 lufga-semi-bold"}>
                General Information
              </Heading>
              <Text
                className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}
              >
                You can change the details of the regional commodity
              </Text>
            </Box>

            <Box row>
              {edit ? (
                <>
                  <a
                    className="btn btn-md mt-5"
                    onClick={() => setEdit(false)}
                    type={"button"}
                    role={"button"}
                  >
                    Cancel
                  </a>
                  <Button
                    disabled={!isValid || updating}
                    variant="fill"
                    pill
                    className="btn-md"
                    label={updating ? "Saving Changes..." : "Save"}
                  />
                </>
              ) : (
                <>
                  <Button
                    variant="icon"
                    label={"Edit"}
                    className="btn-md"
                    onClick={() => setEdit((current) => !current)}
                    type={"button"}
                  />
                  <Button
                    variant="fill"
                    pill
                    label={"Delete Regional Commodity"}
                    className="px-6"
                    type={"button"}
                    onClick={() =>
                      generateModal(
                        "Delete Regional Commodity",
                        <DeleteDialog
                          prompt=" Are you sure you want to delete"
                          boldText={data?.name}
                          callback={() =>
                            data
                              ? deleteRegionalCommodity({
                                  variables: {
                                    id: data.id,
                                  },
                                })
                              : null
                          }
                          loading={deleting}
                        />,
                        "center"
                      )
                    }
                  />
                </>
              )}
            </Box>
          </Box>
          <Box col className={"section no-border"}>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={"gi-label"}>
                Name of Regional <br /> Commodity
              </Text>
              {edit ? (
                <TextWrapper
                  name={"name"}
                  required
                  label={"Name of Regional Commodity"}
                  placeholder="Shea"
                  className={"mb-4"}
                />
              ) : (
                <Text className={"subtitle"}>
                  {isLoading ? "Fetching" : data?.name}
                </Text>
              )}
            </Box>
            <Box row className={"gi-field"}>
              <Text Component={"label"} className={"label"}>
                Production Region
              </Text>
              {edit ? (
                <SelectWrapper
                  name={"productionRegion"}
                  className={"mb-4"}
                  required
                  placeholder={"Select production region"}
                  options={regions}
                  isLoading={isLoading}
                  loadingMessage={() => "Fetching production regions..."}
                  getOptionValue={(option: any) => option.id}
                  getOptionLabel={(option: any) => option.name}
                  value={regions.find(
                    (region: any) =>
                      region.id === methods.getValues("productionRegion")
                  )}
                  onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as ProductionRegion;

                    setValue("productionRegion", value.id);
                  }}
                />
              ) : (
                <Text className={"gi-subtitle"}>
                  {isLoading ? "Fetching" : data?.productionRegion.name}
                </Text>
              )}
              <Text Component={"label"} className={"label"}>
                Basic Commodity
              </Text>
              {edit ? (
                <SelectWrapper
                  name={"basicCommodity"}
                  className={"mb-4"}
                  required
                  placeholder={"Select basic commodity"}
                  options={commodities}
                  isLoading={isLoading}
                  loadingMessage={() => "Fetching basic commodities..."}
                  getOptionValue={(option: any) => option.id}
                  getOptionLabel={(option: any) => option.name}
                  value={commodities.find(
                    (commodity: any) =>
                      commodity.id === methods.getValues("basicCommodity")
                  )}
                  onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as BasicCommodity;
                    console.log("value", value);

                    setValue("basicCommodity", value.id);
                  }}
                />
              ) : (
                <Text className={"gi-subtitle"}>
                  {isLoading ? "Fetching" : data?.basicCommodity.name}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
