/**
 * Project: tracebility-dashboard
 * File: regions
 * Created by pennycodes on 22/11/2022.
 * Copyright tracebility-dashboard
 */
import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import client from './index';
import {
    ProductionRegionPaginator,
    CreateOrEditProductionRegion,
    ProductionRegionOrganisationPaginator,
    QueryOptionsTwo,
    ISetProductionRegion,
    ICreateAvailableGoods,
    QueryOptions
} from "models/query/buyer";
import ApiConfig from "services/endpoints";
import { toast } from "react-toastify";


export interface IRegionIDVariable {
    variables: {
        orgId: number;
        productionRegionId: number
    }
}

export interface IOrgCommodity {
    variables: {
        orgId: number;
        coomodityId: number
    }
}

export interface IRegionUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditProductionRegion;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export interface IRegionCreateVariables {
    variables: { input: CreateOrEditProductionRegion };
}

export interface ISetProductionRegionVariables {
    variables: { input: ISetProductionRegion };
}

export interface ICreateAvailableGoodsVariables {
    variables: { input: ICreateAvailableGoods };
}

export function useTaceBatch(props: { setFirst: React.Dispatch<React.SetStateAction<boolean>>, setData: any }) {
    const { setFirst, setData } = props;
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: {tagId } }: { variables: {tagId: string } }) =>
            client.reporting.search(tagId),
        {
            onSuccess: (data) => {
                setData(data.data);
                if (data.data && data.data.length) {
                    setFirst(true);
                } else {
                    setFirst(false);
                    toast.info('No results found');
                }

            },
        }
    );
}

export function useReportQuery(props: { setFirst: React.Dispatch<React.SetStateAction<boolean>>, setData: any }) {
    const { setFirst, setData } = props;
    return useMutation(
        ({ variables: { param } }: { variables: { param: string } }) =>
            client.reporting.queryReport(param),
        {
            onSuccess: (data) => {
                setData(data.data);
                if (data.data && data.data.length) {
                    setFirst(true);
                } else {
                    setFirst(false);
                    toast.info('No results found');
                }

            },
            onError: (error) => {
                toast.error('An error occured whiles loading report');
            },
        }
    );
}

/*
#FDF6EF
*/