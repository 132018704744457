/**
 *Project:Traceability Platform
 *File:detail-wizard
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Button } from "@components/common";
import Wizard, { WizardRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { uuid } from "@utils/helper";
import cn from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineCloseCircle,
  AiOutlineOrderedList,
} from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";

import s from "./index.module.css";

type Props = {
  children?: ReactNode;
  title?: string;
  id: string;
  variant?: "standard" | "simple";
  views?: Array<{ label: string; name: string }>;
};

const DetailsWizard: React.FC<Props> = (props) => {
  const ref = useRef<WizardRef>(null);
  const {
    id,
    children,
    variant = "standard",
    title = "Default",
    views = [
      {
        label: "Personal Information",
        name: "security",
      },
      {
        label: "Organization",
        name: "security",
      },
      {
        label: "Security",
        name: "security",
      },
    ],
  } = props;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(views[0].name);
  const [showOptions, setOptions] = useState(false);
  const params = useSearchParams();
  useEffect(() => {
    if (params[0].get("action") && params[0].get("action") !== null) {
      ref.current?.goToNamedStep(params[0].get("action"));
    }
  }, [params, views]);

  useEffect(() => {
    const res = views.some((r) => r.name === activeTab);
    // console.log("res", res, activeTab, views);
    if (!res) {
      setActiveTab(views[0].name);
    } else {
      setActiveTab(activeTab);
    }
  }, [activeTab, views]);

  useEffect(() => {
    const container = document.getElementById("children") as HTMLElement;
    container.style.paddingLeft = "0";
    container.style.paddingRight = "0";
    container.style.paddingBottom = "0";
    container.style.paddingTop = "0.5rem";
    container.classList.remove("3xl:container");
    // container.style.overflowY = "hidden";

    return () => {
      container.style.paddingLeft = "min(2.75rem, 4%)";
      container.style.paddingRight = "min(4%, 3.125rem)";
      container.style.paddingBottom = "2rem";
      container.style.paddingBottom = "4rem";
      container.style.overflowY = "auto";
      container.classList.add("3xl:container");
    };
  }, []);
  return (
    <div className={s.root}>
      {variant === "standard" ? (
        <>
          {" "}
          <div className="flex flex-row lg:ml-10 ml-3 items-center gap-7 lg:py-6 lg:!h-[4.9375rem] !h-[2.6875rem]">
            <Button
              className={s.backButton}
              variant={"icon"}
              onClick={() => navigate(-1)}
            >
              <AiOutlineArrowLeft />
            </Button>

            <Heading className={"lufga-semi-bold"}>{title}</Heading>
            <Button
              className={cn(s.backButton, " lg:!hidden ml-auto mr-3")}
              variant={"icon"}
              onClick={() => {
                setOptions((r) => !r);
              }}
            >
              {showOptions ? (
                <AiOutlineCloseCircle />
              ) : (
                <AiOutlineOrderedList />
              )}
            </Button>
          </div>
          <div className={cn("flex flex-row w-full", s.content)}>
            <div
              className={cn(
                { "hidden lg:flex": !showOptions },
                "flex-col lg:h-full min-w-full max-w-full px-3 lg:px-0 lg:min-w-[15.0625rem] lg:max-w-[15.0625rem] bg-[#F3F5F7]"
              )}
            >
              {views.map((item, index: number) => (
                <div
                  onClick={() => {
                    ref.current?.goToNamedStep(item.name);
                    setOptions(false);
                  }}
                  key={uuid()}
                  className={cn(
                    s.base,
                    "flex flex-row items-center pl-[10px]  w-full hover:cursor-pointer h-[3.25rem]",
                    {
                      "bg-[#ED7136]": activeTab === item.name,
                      [s.textWhite]: activeTab === item.name,
                      [s.secondaryHover]: activeTab !== item.name,
                    }
                  )}
                >
                  {item.label}
                </div>
              ))}
            </div>
            <div
              className={cn(
                { "hidden lg:block": showOptions },
                "flex lg:pr-4 w-full pt-[1.8125rem] overflow-y-auto lg:pb-0 lg:pl-[2.625rem]"
              )}
            >
              <Wizard
                id={id}
                onViewChange={(active: number) => {
                  const tabName = views[active] && views[active].name;
                  tabName && setActiveTab(tabName);
                }}
                ref={ref}
              >
                {children}
              </Wizard>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DetailsWizard;
