/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import {useProductionRegions} from "services/system/regions";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {useBasicCommodities, useCreateRegionalCommodityMutation} from "services/system/commodities";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {BasicCommodity, CreateOrEditRegionalCommodity, ProductionRegion} from "@models/query/system";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";

type Props = {};

const AddRegionalCommodity: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();

    const { regions, isLoading } = useProductionRegions({
        pageSize: 999,
    });
    const { commodities } = useBasicCommodities({
        pageSize: 999,
    });
    const regionalCommodityValidationSchema = Yup.object().shape({
        name: Yup.string().required('Commodity name is required'),
        basicCommodity: Yup.number().required('Basic Commodity is required'),
        productionRegion: Yup.number().required('Production Region is required'),
    });
    const defaultValues: FieldValues = {
        name: "",
        basicCommodity: undefined,
        productionRegion: undefined,
    };

    const methods = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(regionalCommodityValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, setValue, formState: { isValid } } = methods;

    const { mutate: createRegionalCommodity, isLoading: creating } =
        useCreateRegionalCommodityMutation(onSubmitFinish);

    const onSubmit = (commodity: CreateOrEditRegionalCommodity) => {


        createRegionalCommodity({
            variables: {
                input: commodity
            }
        });
    }


    return (
    <div className={s.root}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <TextWrapper
                name={"name"}
                required
                label={"Name of Regional Commodity"}
                placeholder="Enter name"
                className={'mb-4'}
            />

            <SelectWrapper
                name={"productionRegion"}
                className={'mb-4'}
                required
                placeholder={"Select production region"}
                options={regions}
                isLoading={isLoading}
                loadingMessage={() => "Fetching production regions..."}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.name}
                value={regions.find(
                    (region: any) => region.id === methods.getValues("productionRegion")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as ProductionRegion;

                    setValue("productionRegion", value.id);
                }}
            />

            <SelectWrapper
                name={"basicCommodity"}
                className={'mb-4'}
                required
                placeholder={"Select basic commodity"}
                options={commodities}
                isLoading={isLoading}
                loadingMessage={() => "Fetching basic commodities..."}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.name}
                value={commodities.find(
                    (commodity: any) => commodity.id === methods.getValues("basicCommodity")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                    let value = newValue as BasicCommodity;

                    setValue("basicCommodity", value.id);
                }}
            />

      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || creating}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
            {creating ? "Creating..." : "Add Regional Commodity"}

        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddRegionalCommodity;
