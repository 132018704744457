/**
 * Project: tracebility-dashboard
 * File: regions
 * Created by pennycodes on 22/11/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    ProductionRegionPaginator,
    CreateOrEditProductionRegion,
    ProductionRegionOrganisationPaginator,
    QueryOptionsTwo,
    ProductionRegionCommoditiesPaginator
} from "models/query/system";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";



export function useProductionRegions(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.productionRegions.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        regions: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useProductionRegion(id: string) {
    const { data, isLoading, error } = useQuery<ProductionRegionPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.DETAILS, id],
        () => client.productionRegions.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        region: data?.data[0],
        isLoading,
        error,
    };
}

export interface IRegionIDVariable {
    variables: {
        id: number;
    }
}

export const useDeleteRegionMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IRegionIDVariable) =>
            client.productionRegions.delete(id),
        {
            onSuccess: () => {
                toast.success('Production Region removed successfully');
                reset && reset();
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ALL);
            },
        }
    );
}

export interface IRegionUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditProductionRegion;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateRegionMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit} = props
    return useMutation(
        ({ variables: { id, input } }: IRegionUpdateVariables) =>
            client.productionRegions.update(id, input),
        {
            onSuccess: () => {
                toast.success('Production Region updated successfully');
                setEdit(false)
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ALL);
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.DETAILS);
            },
        }
    );
};

export interface IRegionCreateVariables {
    variables: { input: CreateOrEditProductionRegion };
}

export const useCreateRegionMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IRegionCreateVariables) =>
            client.productionRegions.create(input),
        {
            onSuccess: () => {
                toast.success('Production Region created successfully');
                reset()
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ALL);
            },
        }
    );
};

export function useRegionOrganisations(id: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionOrganisationPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.ORGANISATIONS, options],
        ({ queryKey, pageParam }) =>
            client.productionRegions.organisations(id,Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        organisations: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useRegionCommodities(id: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionCommoditiesPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.COMMODITIES, options],
        ({ queryKey, pageParam }) =>
            client.productionRegions.commodities(id,Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        commodities: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}
