/**
 * Project: tracebility-dashboard
 * File: requests
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import axios from "axios";
import { getAuthToken, removeAuthToken } from "utils/jwt";
import { signOut} from "@utils/CustomRouter";

const axiosInstance = axios.create({
  baseURL: process.env.HOST_API || "https://trace.lucidarray.dev/",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
     //@ts-ignore
    config.headers = {
      ...config.headers,
    };
    //@ts-ignore
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403) ||
      (error.response && error.response.data.message === "Unauthorized")
    ) {
      removeAuthToken();
        signOut()

    }
    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await axiosInstance.get<T>(url, { params });
    return response.data;
  }

  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await axiosInstance.post<T>(url, data, options);
    return response.data;
  }

  static async put<T>(url: string, data: unknown) {
    const response = await axiosInstance.put<T>(url, data);
    return response.data;
  }

    static async patch<T>(url: string, data: unknown) {
        const response = await axiosInstance.patch<T>(url, data);
        return response.data;
    }

  static async delete<T>(url: string) {
    const response = await axiosInstance.delete<T>(url);
    return response.data;
  }
}
export default axiosInstance;
