import React from "react";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import InventoryNotification from "./InventoryNotification";
import { useLocation, useParams } from "react-router-dom";
import { useOrgWarehouse } from "services/buyer/warehouse";
import { useAppSelector } from "hooks/appHooks";
import ReceivedGoods from "@pages/app/buyer/warehouse/ReceivedGoods";
import DispatchedGoods from "@pages/app/buyer/warehouse/DispatchedGoods";

type Props = {};

const Details: React.FC<Props> = (_props) => {
  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const warehouse = useLocation()?.state as any;
  const { id } =  useParams();

  // const {
  //   data: warehouse,
  //   isLoading,
  //   error,
  // } = useOrgWarehouse(organisationId, id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    {
      label: 'Received Goods',
      name: 'received-goods'
    },
    {
      label: 'Dispatched Goods',
      name: 'dispatched-goods'
    },
    // {
    //   label: "Inventory Notification",
    //   name: "inventory-notification",
    // },
  ];

  return (
    <DetailsWizard
      id={"warehouse-details"}
      views={items}
      title={warehouse?.name}
    >
      <GeneralInformation name="general-information" data={warehouse as any} />
      {/*<InventoryNotification name="inventory-notification" />*/}
      <ReceivedGoods name={'received-goods'} data={warehouse as any}  />
      <DispatchedGoods name={'dispatched-goods'} payload={ warehouse as any} />
    </DetailsWizard>
  );
};

export default Details;
