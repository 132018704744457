/**
 *Project:Traceability Platform
 *File:commodities
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { FunctionComponent, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import Default from './Default';
import Details from './Details';

type Props = {};

const Batches: FunctionComponent<Props> & {
  Details: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
Batches.Default = Default;
Batches.Details = Details;
export default Batches;
