/**
 * Project: tracebility-dashboard
 * File: users
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    BlackListPaginator,
    InvitationCount,
    UserPaginator,
    UserSearchQuery,
    SupportedRolePaginator,
    QueryOptions,
    InvitationSearchQuery,
    InvitationPaginator,
    ResetPassword,
    AddBlacklist,
    UserOrganisationsPaginator,
    CreateOrEditOrganization,
    AddUserOrganisation,
    UpdateUser,
    AcceptInvitation
} from "models/query/system";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";
import { CreateInvitation } from 'models/query/buyer';

export const useUsersInvitationCount = () => {
    const { data, isLoading, error } = useQuery<InvitationCount, Error>(
        [ApiConfig.INVITATIONS.REPORTS],
        client.users.invitationCount
    );

    return {
        accepted: data?.data[0].accepted,
        sent: data?.data[0].sent,
        pending: data?.data[0].pending,
        isLoading,
        error,
    };
};

export function useUsers(options?: UserSearchQuery) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<UserPaginator, Error>(
        [ApiConfig.USERS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.users.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        users: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useUser(id: string) {
    const { data, isLoading, error } = useQuery<UserPaginator, Error>(
        [ApiConfig.USERS.DETAILS, id],
        () => client.users.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        user: data?.data[0],
        isLoading,
        error,
    };
}

export function useInvitations(options?: InvitationSearchQuery) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<InvitationPaginator, Error>(
        [ApiConfig.INVITATIONS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.invitations.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        invitations: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useInvitation(id: string) {
    const { data, isLoading, error } = useQuery<InvitationPaginator, Error>(
        [ApiConfig.INVITATIONS.DETAILS, id],
        () => client.invitations.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        invitation: data?.data[0],
        isLoading,
        error,
    };
}


export interface IUserIDVariable {
    variables: {
        id: number;
    }
}
export interface IInvitationIDVariable {
    variables: {
        id: string;
    }
}

export const useDeleteUserMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IUserIDVariable) =>
            client.users.delete(id),
        {
            onSuccess: () => {
                toast.success('User deleted successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
            },
        }
    );
}

export const useDeleteInvitationMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IInvitationIDVariable) =>
            client.invitations.delete(id),
        {
            onSuccess: () => {
                toast.success('Invitation deleted successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.INVITATIONS.ALL);
            },
        }
    );
}

export const useResendInvitationMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IInvitationIDVariable) =>
            client.invitations.resend(id),
        {
            onSuccess: () => {
                toast.success('Invitation email resent successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.INVITATIONS.ALL);
            },
        }
    );
}

export interface IUserUpdateVariable {
    variables: {
        id: number;
        data: UpdateUser
    }
}
export const useUpdateUserMutation = (reset: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id, data } }: IUserUpdateVariable) =>
            client.users.update(id, data),
        {
            onSuccess: () => {
                toast.success('User Info updated successfully');
                reset()
            },
            onError: (error) => {
                toast.error('An error occured whiles updating the user info');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
                queryClient.invalidateQueries(ApiConfig.USERS.DETAILS);
            },
        }
    );
};

export interface IAcceptInvitationVariable {
    variables: {
        id: string;
        data: AcceptInvitation
    }
}

export const useAcceptInvitationMutation = (reset: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id, data } }: IAcceptInvitationVariable) =>
            client.invitations.accept(id, data),
        {
            onSuccess: () => {
                toast.success('Invitation accepted successfully');
                reset()
            }
        }
    );
};
export interface IInvitationCreateVariables {
    variables: { input: CreateInvitation };
}

export const useInviteUserMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IInvitationCreateVariables) =>
            client.invitations.create(input),
        {
            onSuccess: () => {
             //   toast.success('User Invitation created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
            },
        }
    );
};


export function useBlacklists() {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<BlackListPaginator, Error>(
        [ApiConfig.USERS.BLACKLIST],
        client.users.blacklist,
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        blacklists: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useSupportRoles(options?: QueryOptions) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<SupportedRolePaginator, Error>(
        [ApiConfig.USERS.SUPPORTED_ROLES, options],
        ({ queryKey, pageParam }) =>
            client.users.supportedRoles(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        roles: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export interface IRoleUpdateVariables {
    variables: {
        id: number
        role: string
    };
}
export const useUpdateRoleMutation = (reset: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id, role } }: IRoleUpdateVariables) =>
            client.users.updateRole(id, role),
        {
            onSuccess: () => {
                  toast.success('User Role update successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
                queryClient.invalidateQueries(ApiConfig.USERS.DETAILS);
            },
        }
    );
};

export interface IPasswordUpdateVariables {
    variables: {
        id: number
        data: ResetPassword
    };
}
export const useUpdatePasswordMutation = (reset: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id, data } }: IPasswordUpdateVariables) =>
            client.users.updatePassword(id, data),
        {
            onSuccess: () => {
                toast.success('User Password updated successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
            },
        }
    );
};

export interface IBlacklistVariables {
    variables: {
        id: number
        data: AddBlacklist
    };
}

export const useBlacklistUserMutation = (reset: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id, data } }: IBlacklistVariables) =>
            client.users.add_blacklist(id, data),
        {
            onSuccess: () => {
                toast.success('User Account blacklisted successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
                queryClient.invalidateQueries(ApiConfig.USERS.DETAILS);
            },
        }
    );
};


export const useEnableUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id } }: IUserIDVariable) =>
            client.users.enable(id),
        {
            onSuccess: () => {
                toast.success('User account enabled successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
                queryClient.invalidateQueries(ApiConfig.USERS.DETAILS);
            },
        }
    );
};


export const useDisableUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id } }: IUserIDVariable) =>
            client.users.disable(id),
        {
            onSuccess: () => {
                toast.success('User account disabled successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
                queryClient.invalidateQueries(ApiConfig.USERS.DETAILS);
            },
        }
    );
};


export const useRemoveUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id } }: IUserIDVariable) =>
            client.users.remove_blacklist(id),
        {
            onSuccess: () => {
                toast.success('User account blacklist removed successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
                queryClient.invalidateQueries(ApiConfig.USERS.DETAILS);

            },
        }
    );
};

export function useUserOrganisations(id: number, options?: QueryOptions) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<UserOrganisationsPaginator, Error>(
        [ApiConfig.USERS.ORGANISATIONS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.users.organisations(id,Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        organisations: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export interface IUserOrganisationCreateVariables {
    variables: {
        id: number,
        input: AddUserOrganisation
    };
}

export const useAddUserOrganisationMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input, id } }: IUserOrganisationCreateVariables) =>
            client.users.addOrganisation(id,input),
        {
            onSuccess: () => {
                toast.success('User added to organisation group successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ORGANISATIONS.ALL);
            },
        }
    );
};

export const useDeleteUserOrganisationMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { id } }: IUserIDVariable) =>
            client.users.removeOrganisation(id),
        {
            onSuccess: () => {
                toast.success('User removed from organisation group successfully');
            },
            onError: (error) => {
                toast.error('An error occured whiles removing the user');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.USERS.ORGANISATIONS.ALL);

            },
        }
    );
};

export interface ICreateOrgUserVariables {
    variables: {
        input: CreateInvitation
    };
}
export const useCreateOrgUserMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: ICreateOrgUserVariables) =>
            client.users.create(input),
        {
            onSuccess: () => {
                toast.success('User invited successfully');
                reset()
            },
            onError: (error) => {
                console.log({error});
                toast.error('An error occured whiles creating the user');
            },
            // Always refetch after error or success:
            onSettled: (data, err, params) => {
                console.log(err)
                queryClient.invalidateQueries(ApiConfig.USERS.ALL);
            },
        }
    );
};
