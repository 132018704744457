/**
 *Project:Traceability Platform
 *File:add-production-zone
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import React from "react";
import * as Yup from "yup";
import {useCreateZoneMutation} from "services/system/zones";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {CreateOrEditProductionZone, ProductionRegion} from "@models/query/system";
import {useProductionRegions} from "services/system/regions";
import FormProvider from "@forms/FormProvider";
import SelectWrapper from "@forms/SelectWrapper";
import TextWrapper from "@forms/TextWrapper";
import TextAreaWrapper from "@forms/TextAreaWrapper";

type Props = {};

const AddProductionZone: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();

    const { regions, isLoading } = useProductionRegions({
        pageSize: 999,
    });
    const zoneValidationSchema = Yup.object().shape({
        name: Yup.string().required('Production zone name is required'),
        description: Yup.string().required('Description is required'),
        productionRegionId: Yup.number().required('Production zone is required'),
    });
    const defaultValues: FieldValues = {
        name: "",
        description: "",
        productionRegionId: 0,
    };


    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(zoneValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, setValue, formState: { isValid } } = methods;

    const { mutate: createZone, isLoading: creating } =
        useCreateZoneMutation(onSubmitFinish);

    const onSubmit = (zone: CreateOrEditProductionZone) => {


        createZone({
            variables: {
                input: zone
            }
        });
    }



    return (
    <div className={"w-full pb-5"}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
          <SelectWrapper
              name={"productionRegionId"}
              className={'mb-4'}
              required
              placeholder={"Select production region"}
              options={regions}
              isLoading={isLoading}
              loadingMessage={() => "Fetching production regions..."}
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) => option.name}
              value={regions.find(
                  (region: any) => region.id === methods.getValues("productionRegionId")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as ProductionRegion;

                  setValue("productionRegionId", value.id);
              }}
          />
        <TextWrapper
            name={"name"}
          required
          label={"Name of Production Zone"}
          placeholder="Enter name"
            className={'mb-4'}
        />
        <TextAreaWrapper
            name={'description'}
          required
          label={"Production Zone Description"}
          placeholder="Enter Description"
            rows={3}
        />
      </Box>

      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || creating}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
            {creating ? "Creating..." : "    Add Production Zone"}
        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddProductionZone;
