import React, { useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { useFormContext } from "react-hook-form"
import s from "./index.module.css";
import cn from "classnames";
import { toBase64 } from "@utils/helper";

const Dropzone: React.FC<any> = (props) => {
    const { name, label = name, className, setFile, selectedImage } = props
    const { register, unregister, setValue, watch } = useFormContext()
    const files = watch(name)
    const onDrop = useCallback(
        (droppedFiles: any) => {
            setValue(name, droppedFiles, { shouldValidate: true });
            toBase64(droppedFiles[0])
            .then((image: unknown) => setFile(image as string))
            .catch(console.log);
        },
        [setValue, name]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.accept,
    });

    useEffect(() => {
        register(name);
        console.log({ name })
        return () => {
            unregister(name)
        }
    }, [register, unregister, name]);

    return (
        <>
            <div className={cn(s.root, className)} >
                <div
                    {...getRootProps()}
                    //@ts-ignore
                    type="file"
                    role="button"
                    aria-label="File Upload"
                    id={name}
                >
                    <input {...props} {...getInputProps()} />
                    <div className={" " + (isDragActive ? " " : " ")} >

                        {
                            files?.length ? <img
                                src={selectedImage}
                                alt={files[0].name}
                            /> :
                                <p className={s.paragraph}>
                                    Drag the banner image here or&nbsp;
                                    <i className={s.alternate}>browse</i>&nbsp;for the image to upload
                                </p>
                        }

                    </div>
                </div>
            </div>

            {
                files?.length ?
                    <span
                        style={{ fontSize: 11, color: 'red', fontWeight: 'bolder' }}
                        onClick={() => {
                            setValue(name, [], { shouldValidate: true });
                            setFile('');
                        }}>
                        Remove Image
                    </span>
                    :
                    null
            }
        </>
    )
}

export default Dropzone