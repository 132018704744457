/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Select, Text, Textfield } from "@components/common";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useState } from "react";

type Props = {};

const AddDisposedBatch: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();
    // const ref = useAutoAnimate({
    //     duration: 0.3,
    // });
    const ref = React.useRef(null);

    const [isAdHoc, setIsAdhoc] = useState(false);
    return (
        <div
            className={"flex flex-col gap-[2.1875rem] pb-5 w-full mt-[-1rem]"}
            ref={ref as any}
        >
            <Textfield label={"Batch Number"} placeholder="847401" />

            <Select
                required
                label={"Warehouse"}
                placeholder="Select a warehouse"
            />
            <Box col className={"w-full"}>
                <Text
                    Component={"label"}
                    className={"font-bold  !text-[0.875rem] mb-2"}
                >
                    Date of Disposal&nbsp;
                </Text>
                <input
                    type={"date"}
                    style={{ paddingLeft: "min(5%, 1.875rem);" }}
                    placeholder="Enter date of disposal"
                    className={cn(
                        "rounded-[3px] w-full h-[2.6875rem]  border-solid border-[#5C6970] border-[0.0625rem]"
                    )}
                />
            </Box>
            <Select
                required
                label={"Recipient"}
                placeholder="Select recipient"
            />

            <Box
                row
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={"1.875rem"}
                className="mt-[5rem]"
            >
                <Button
                    variant="icon"
                    style={{ color: "#20201F" }}
                    onClick={() => closeModal()}
                >
                    Cancel
                </Button>
                <Button
                    onClick={(_) => {}}
                    color="primary"
                    pill
                    style={{ width: "13.75rem" }}
                >
                    Dispose Batch
                </Button>
            </Box>
        </div>
    );
};

export default AddDisposedBatch;
