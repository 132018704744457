/**
 * Project: tracebility-dashboard
 * File: warehouses
 * Created by pennycodes on 04/12/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    WarehousePaginator,
    QueryOptions,
    CreateOrEditWarehouse
} from "models/query/system";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";



export function useWarehouses(org: string, options?: QueryOptions) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<WarehousePaginator, Error>(
        [ApiConfig.WAREHOUSES.ALL, options],
        ({ queryKey, pageParam }) =>
            client.warehouses.all(org, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        warehouses: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useWarehouse(org: string, id: string) {
    const { data, isLoading, error } = useQuery<WarehousePaginator, Error>(
        [ApiConfig.WAREHOUSES.DETAILS, id],
        () => client.warehouses.details(org,id),
        {
            enabled: !!id,
        }
    );
    return {
        warehouse: data?.data[0],
        isLoading,
        error,
    };
}

export interface IWarehouseIDVariable {
    variables: {
        id: number;
        org: string
    }
}

export const useDeleteWarehouseMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, org } }: IWarehouseIDVariable) =>
            client.warehouses.delete(org,id),
        {
            onSuccess: () => {
                toast.success('Warehouse removed successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL);
            },
        }
    );
}

export interface IWarehouseUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditWarehouse;
        org: string
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateWarehouseMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit} = props
    return useMutation(
        ({ variables: { id, input, org } }: IWarehouseUpdateVariables) =>
            client.warehouses.update(org, id, input),
        {
            onSuccess: () => {
                toast.success('Warehouse updated successfully');
                setEdit(false)
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL);
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.DETAILS);
            },
        }
    );
};

export interface IWarehouseCreateVariables {
    variables: { input: CreateOrEditWarehouse; org: string };
}

export const useCreateWarehouseMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input, org } }: IWarehouseCreateVariables) =>
            client.warehouses.create(org, input),
        {
            onSuccess: () => {
                toast.success('Warehouse created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL);
            },
        }
    );
};

export const useEnableWarehouseMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, org } }: IWarehouseIDVariable) => client.warehouses.status(org,String(id), true),
        {
            onSuccess: () => {
                toast.success('Warehouse enabled successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL);
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.DETAILS);
            },
        }
    );
}

export const useDisableWarehouseMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, org } }: IWarehouseIDVariable) => client.warehouses.status(org,String(id), false),
        {
            onSuccess: () => {
                toast.success('Warehouse disabled successfully');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL);
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.DETAILS);
            },
        }
    );
}
