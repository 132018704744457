/**
 * Project: tracebility-dashboard
 * File: TextAreaWrapper
 * Created by pennycodes on 21/11/2022.
 * Copyright tracebility-dashboard
 */

import React, {forwardRef} from "react";


import { useFormContext, Controller } from 'react-hook-form';
import ErrorWrapper from "./ErrorWrapper";
import TextArea, {TextFieldProps} from "components/common/textarea";


interface TextWrapperProps extends TextFieldProps {
    name: string;
}
const TextAreaWrapper =  forwardRef<HTMLTextAreaElement, TextWrapperProps>(
    (props, ref) => {
    const { control } = useFormContext();
    const { name, ...rest } = props


    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <>

                    <TextArea {...field}  {...rest} ref={ref} />
                    <ErrorWrapper state={!!error} message={error?.message}  />
                </>
            )}
        />
    );
})

export default TextAreaWrapper
