/**
 *Project:Traceability Platform
 *File:link
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { LinkProps, Link as RouterLink } from "react-router-dom";

type Props = {
  color?: "primary" | "secondary";
  variant?: "underlined" | "default";
  to: string;
} & LinkProps;

const Link: React.FC<Props> = (props) => {
  const {
    className,
    variant = "default",
    color = "primary",
    to,
    children,
  } = props;

  return (
    <RouterLink
      className={cn(s.root, className, {
        [s.primary]: color === "primary",
        [s.secondary]: color === "secondary",
      })}
      to={to}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
