/**
 *Project:Traceability Platform
 *File:invitation-success
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { WizardChildRef } from "@components/common/wizard";
import { KgCelebrate } from "@components/Icons";
import { Button, Link } from "@components/common";
import Heading from "@components/heading";
import { useNavigate } from "react-router-dom";

type Props = {} & WizardChildRef;

const InvitationSuccess: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  return (
    <div className={s.root}>
      <KgCelebrate />
      <Heading size="lg100" margin={"10px 0 20px"}>
        Invitation sent successfully
      </Heading>
      {/* <Button
        pill
        color="primary"
        onClick={(r) => navigate("/app/invited-users")}
        style={{ width: "9rem", height: "3rem" }}
      >
        View User
      </Button> */}

      <Button
        variant="icon"
        style={{ color: "var(--clr-primary-100)" }}
        onClick={(r) => props.goToStep && props.goToStep(0)}
      >
        Add Another User
      </Button>
    </div>
  );
};

export default InvitationSuccess;
