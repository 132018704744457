/**
 *Project:Traceability Platform
 *File:users-overview-cards
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { ComponentType } from "react";
import s from "./index.module.css";
import cn from "classnames";
import Card, { Props as CardProps } from "@components/card";
import { Box } from "@components/common";
import Heading from "@components/heading";

type Props = {
  Icon: ComponentType;
  label: string;
  value: string | number;
} & CardProps;

const UsersOverviewCards: React.FC<Props> = (props) => {
  const { children, Icon, label, className, value, ...rest } = props;
  return (
    <Card className={cn(className, s.root)} {...rest}>
      <Box className={s.inner} row gap={30} margin={"auto"}>
        <Icon />
        <Box col gap={5}>
          <Heading
            textTransform="capitalize"
            className={"lufga-semi-bold fs-16"}
          >
            {label}
          </Heading>
          <Heading className={"lufga-semi-bold fs-30"}>{value}</Heading>
        </Box>
      </Box>
    </Card>
  );
};

export default UsersOverviewCards;
