/**
 * Project: tracebility-dashboard
 * File: FileWrapper
 * Created by pennycodes on 30/01/2023.
 * Copyright tracebility-dashboard
 */
import React, {forwardRef} from "react";


import { useFormContext, Controller } from 'react-hook-form';
import ErrorWrapper from "./ErrorWrapper";
import FileInput, {Props} from "components/common/file-select";


interface FileWrapperProps extends Props {
    name: string;
}
const FileWrapper = forwardRef<HTMLInputElement, FileWrapperProps>(
    (props, ref) => {
        const { control } = useFormContext();
        const { name, ...rest } = props


        return (
            <Controller
                name={name}
                control={control}
                defaultValue={[]}
                render={({ field:{onChange, value, ...fields}, fieldState: { error } }) => (
                    <>
                        {/* @ts-ignore */}
                        <FileInput
                            {...fields}
                            {...rest}
                            value={value.filename}
                            onChange={onChange}
                            ref={ref}  />
                        <ErrorWrapper state={!!error} message={error?.message}  />
                    </>
                )}
            />
        );
    })

export default FileWrapper
