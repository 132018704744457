/**
 *Project:Traceability Platform
 *File:blacklist
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { FunctionComponent, useRef } from "react";
import { Outlet } from "react-router-dom";
import Default from "./Default";

type Props = {};
// export const WarehouseDetails = Details;
const BlacklistCategory: FunctionComponent<Props> & {
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
BlacklistCategory.Default = Default;
export default BlacklistCategory;
