/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Checkbox, Select } from "@components/common";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useModalContext } from "@layout/app/context";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import SelectWrapper from "@forms/SelectWrapper";
import FormProvider from "@forms/FormProvider";
import { useCreateBatchMutation } from "services/buyer/batch";
import { useOrganizationRegionCommodities } from "services/buyer/goods";
import { useAppSelector } from "hooks/appHooks";
import { useVendors } from "services/buyer/vendor";
import { IVendor } from "models/query/buyer";

type Props = {};

const AddBatch: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();
  // const ref = useAutoAnimate(

  //   {
  //     duration: 0.3,

  //   }

  // );
  const ref = React.useRef(null);

  const [isAdHoc, setIsAdhoc] = useState(false);

  const defaultValues: FieldValues = {
    regionalCommodity: 0,
    vendor: 0,
    isAdHoc: false,

  };

  const addNameToVendors = (vendors: IVendor[]) => {
    return vendors.map(x => ({ ...x, name: `${x.firstName} ${x.lastName}` }))
  }

  const validationSchema = Yup.object().shape({
    regionalCommodity: Yup.number().required("Regional Commodity is required"),
    isAdHoc: Yup.boolean(),
    vendor: Yup.number().when("isAdHoc", {
      is: true,
      then: Yup.number().required("Vendor is required")
    })
  });

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    closeModal();
  };

  const { user } = useAppSelector((state) => state.auth);
  const organizationId = user?.organisation?.id

  const { commodities, isLoading: isRegionalCommoditiesLoading } =
    useOrganizationRegionCommodities(organizationId, {
      page: 0,
      pageSize: 999,
      search: '',
    });

  const { data: vendors, isLoading: isVendorsLoading } = useVendors({
    page: 0,
    pageSize: 999,
    search: '',
  })

  const { mutate: createBatch, isLoading: creating } =
    useCreateBatchMutation(onSubmitFinish);

  const onSubmit = (incoming: { regionalCommodity: number, isAdHoc: boolean, vendor: number }) => {

    const payload = {
      id: null,
      referenceCode: null,
      vendor: {
        id: incoming?.vendor || 0
      },
      quantity: null,
      regionalCommodity: {
        id: incoming.regionalCommodity
      },
      commodities: null,
      isExpired: false,
      isAdHoc: incoming.isAdHoc,
      createdBy: null,
      updatedBy: null,
      createdOn: null,
      updatedOn: null
    }

    console.log({ payload, incoming })

    createBatch({ variables: { input: payload } })

  }

  return (
    <div
      className={"flex flex-col gap-[2.1875rem] pb-5 w-full"}
      ref={ref as any}
    >

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit as any)}>
        <div className="flex flex-col gap-[8px]">
          <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
            Regional Commodity <span style={{ color: "red" }}>*</span>
          </label>
          <SelectWrapper
            name={"regionalCommodity"}
            required
            placeholder={"Select Regional Commodity"}
            options={commodities}
            isLoading={isRegionalCommoditiesLoading}
            loadingMessage={() => "Fetching supported regions..."}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            value={commodities.find(
              (role: any) => role.id === methods.getValues("regionalCommodity")
            )}
            onChange={(newValue: unknown, actionMeta) => {
              const value = newValue as { id: string; name: string };
              if (value) {
                setValue("regionalCommodity", value.id);
              }

            }}
          />
        </div>

        <div style={{ marginTop: 20 }}>
          <Box row gap={"0.5rem"} >
            <Checkbox
              checked={isAdHoc}
              onChange={(event) => {
                setIsAdhoc(event.target.checked);
                setValue("isAdHoc", event.target.checked);
              }}
            />
            Is this an ad - hoc batch?
          </Box>
        </div>


        {isAdHoc && (
          <div className="flex flex-col gap-[8px]" style={{ marginTop: 20 }}>
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Vendors <span style={{ color: "red" }}>*</span>
            </label>
            <Box col className={"w-full"}>
              {/* <Select required label={"Vendor"} placeholder="Select vendor" /> */}
              <SelectWrapper
                name={"vendor"}
                required
                placeholder={"Select Vendor"}
                options={addNameToVendors(vendors)}
                isLoading={isVendorsLoading}
                loadingMessage={() => "Fetching supported vendors..."}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.name}
                value={addNameToVendors(vendors).find(
                  (role: any) => role.id === methods.getValues("vendor")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                  const value = newValue as { id: string; name: string };
                  if (value) {
                    setValue("vendor", value.id);
                  }

                }}
              />
              <Button
                variant="icon"
                className="!text-[#ED7136] !p-0 !w-fit !h-fit ml-auto mt-[0.5rem]"
              >
                Add New Vendor
              </Button>
            </Box>
          </div>
        )}

        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
          className="mt-[4rem]"
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid || creating}
            pill
            style={{ width: "13.75rem" }}
          >
            {creating ? "Processing..." : "Add Batch"}
          </Button>
        </Box>
      </FormProvider>
    </div>
  );
};

export default AddBatch;
