/**
 * Project: tracebility-dashboard
 * File: appHooks
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
