/**
 *Project:Traceability Platform
 *File:overview-cards
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { ComponentType } from "react";
import s from "./index.module.css";
import cn from "classnames";
import Card, { Props as CardProps } from "@components/card";
import Heading from "@components/heading";
import { Box } from "@components/common";

type Props = {
  Icon: ComponentType;
  label: string;
  value: string | number;
} & CardProps;

const DashboardOverviewCard: React.FC<Props> = (props) => {
  const { children, Icon, label, className, value, ...rest } = props;
  return (
    <Card className={cn(className, s.root)} {...rest}>

      <div className={s.container}>
        <Icon />
      </div>

      <div className={s.container}>
        <Heading
          textTransform="capitalize"
          className={
            "lufga-semi-bold !text-center lg:!text-left !text-black"
          }
        >
          <span style={{ fontSize: '1rem' }}>{label}</span>
        </Heading>
      </div>

      <div className={s.container}>
        <Heading className={"lufga-semi-bold fs-30"}>{value}</Heading>
      </div>

    </Card>
  );
};

export default DashboardOverviewCard;
