/**
 *Project:Traceability Platform
 *File:organizations
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import AddNewClient from "@forms/buyer/AddNewClient";
import TopBar from "@layout/app/partials/top-bar";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useModalContext} from "@layout/app/context";
import {FieldValues, useForm} from "react-hook-form";
import {useClients, useDeleteClientMutation} from "services/buyer/clients";
import {useAppSelector} from "hooks/appHooks";
import {TableColumn, TablePagination} from "@models/store/table";
import {Box, Button} from "@components/common";
import cn from "classnames";
import u from "@features/tables/system/users-table/index.module.css";
import {DeleteDialog} from "@features/Utils";
import {AiOutlineDelete, AiOutlineSearch} from "react-icons/ai";
import {Client} from "@models/query/buyer";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import Table from "@components/table/Table";

type Props = {};

const Default: React.FC<Props> = (props) => {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const { generateModal } = useModalContext();

    const defaultValues: FieldValues = {
        search: '',
    };
    const methods = useForm({
        defaultValues,
    });

    const { watch } = methods;
    const { user } = useAppSelector((state) => state.auth);
    const {
        clients,
        paginatorInfo,
        isLoading,
        error,
        loadNext,
        loadPrevious
    } = useClients(user.organisation.id,{
        page,
        pageSize,
        search: searchTerm,
    });

    const { mutate: deleteClient, isLoading: deleting } =
        useDeleteClientMutation();


    function handleSearch(searchText: string) {
        setSearchTerm(searchText);
        setPage(0);
    }

    const handleClick = (id: number) => {
        navigate(`${id}`);

    }

    useEffect(() => {
        const subscription = watch((value:any) => {
            handleSearch(value.search);
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const Pagination: TablePagination | false  = paginatorInfo ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,
    } : false;

    const Columns: Array<TableColumn> = [
        {
            key: 'name',
            title: 'Client\'s Name',
            onClick: (reg: Client) => handleClick(reg.id),
            render: (text: string) => (
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {text}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'contactEmail',
            title: 'Email',
            onClick: (reg: Client) => handleClick(reg.id),
            render: (text: string) => (
                <div className="text-sm text-gray-500">
                    {text}
                </div>
            )
        },
        {
            key: 'phoneNumber',
            title: 'Phone Number',
            onClick: (reg: Client) => handleClick(reg.id),
            render: (text: string) => (
                <div className="text-sm text-gray-500">
                    {text}
                </div>
            )
        },

        {
            key: 'id',
            title: '',
            className: 'text-end',
            render: (text: string, record: Client) => (
                <Box row className={cn(u.box, u.actions)}>
                    <Button
                        variant="icon"
                        className="!hidden lg:!flex"
                        onClick={() =>
                            generateModal(
                                "Delete Client",
                                <DeleteDialog
                                    prompt={"Are you sure you want to delete client "}
                                    boldText={record.name}
                                    callback={() => deleteClient({
                                        variables: {
                                            id: record.id.toString(),
                                            org: user.organisation.id
                                        }
                                    })}
                                    loading={deleting}
                                />,
                                "center"
                            )
                        }
                    >
                        <AiOutlineDelete />
                    </Button>
                </Box>
            )
        }
    ]
    return (
        <AppGuard  accessibleRoles={["buyerWarehouse", 'corporateAdministrator']}>
      <div className={""}>
        <TopBar
          title="Clients"
          modalTitle={"Add New Client"}
          renderForm={<AddNewClient />}
          addButtonLabel={"New Client"}
        />
          <FormProvider methods={methods} onSubmit={()=>{}} >

              <Box row alignItems={"center"} className={cn(s.search, 'mb-4')}>
                  <TextWrapper
                      name={'search'}
                      className={s.input}
                      variant="borderless"
                      placeholder={"Search"}
                  />
                  <AiOutlineSearch />

              </Box>
          </FormProvider>
          <Table
              tableClassName={'table-hover'}
              loading={isLoading}
              columns={Columns}
              dataSource={clients}
              pagination={Pagination}
              pageSize={pageSize}
              loadNext={loadNext}
              loadPrevious={loadPrevious}
              setPage={setPage}
              page={page}
              error={error}
              emptyText={'No clients available'}
          />
      </div>
        </AppGuard>
  );
};

export default Default;
