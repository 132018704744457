/**
 * Project: damirifa
 * File: TextField
 * Created by Pennycodes on 4/27/2022.
 * Copyright damirifa
 */

// import { useFormContext, Controller } from "react-hook-form";
// import styled, { css } from 'styled-components'
import cn from "classnames";
import React, {
  JSXElementConstructor,
  InputHTMLAttributes,
  useEffect,
} from "react";
// import { AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";
import s from "./index.module.css";
// import LoadingDots from '../loading-dots'

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  color?: "primary" | "secondary" | "dark";
  variant?: "borderless" | "outline";
  Component?: string | JSXElementConstructor<any>;
  disabled?: boolean;
  name?: string;
  disableCopyAndPaste?: boolean;
  placeholder?: string;
  type?: "email" | "text" | "tel" | "currency" | "password";
  label?: String;
  required?: boolean;
  error?: boolean;
  fill?: boolean;
  ref?: any;
}

//Just pass register as props and name to be used

const TextField: React.FC<TextFieldProps> = (props) => {
  const {
    className,
    variant = "outline",
    color = "primary",
    disabled = false,
    error = false,
    placeholder,
    fill,
    style = {},
    label,
    Component = "input",
    name = "default",
    disableCopyAndPaste = false,
    required = false,
    type,
    ...rest
  } = props;

  const rootClassName = cn(s.root, {
    [s.secondary]: color === "secondary",
    [s.dark]: color === "dark",
    [s.outline]: variant === "outline",
    [s.borderless]: variant === "borderless",
    [s.disabled]: disabled,
    [s.tel]: type === "tel",
    [s.error]: error,
  });
  let otherProps = { ...rest };
  //  update props with null copy and paste functions
  if (disableCopyAndPaste) {
    otherProps = {
      onCopy: (e: any) => {
        e.preventDefault();
        return false;
      },
      onPaste: (e: any) => {
        e.preventDefault();
        return false;
      },
      ...rest,
    };
  }

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <fieldset className={cn(s.fieldset, rootClassName, { "!w-full": fill })}>
      {label && (
        <label>
          {label}
          &nbsp;
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <Component
          ref={props.ref}
        data-variant={variant}
        disabled={disabled}
        type={type}
        name={name}
        placeholder={placeholder}
        className={cn(s.input, className)}
        style={{
          ...style,
        }}
        {...otherProps}
      />
    </fieldset>
  );
};

export default TextField;
