import { Box, Button } from "@components/common";
import Heading from "@components/heading";
import GoodsBatchTable from "@features/tables/buyer/GoodsBatchTable";
// import GoodsBatchTable from "@features/tables/buyer/goods-batch-table";
import autoAnimate from "@formkit/auto-animate";
import React, { Ref, useEffect, useRef, useState } from "react";
import RefObject from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface Props {}

const Batch = (props: Props) => {
  const [view, setView] = useState(0);
  const parent = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 250000 });
  }, [parent]);
  return (
    <Box col ref={parent as any}>
      <Box className={"flex flex-row mb-[3.125rem] gap-4 lg:gap-0 items-center"}>
        <Button
          className={"!text-[1.2rem] mr-4 text-[#99a1a3]"}
          variant={"icon"}
          onClick={() => navigate(-1)}
        >
          <AiOutlineArrowLeft />
        </Button>
        <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
          Batch
        </Heading>
      </Box>
      <GoodsBatchTable />
    </Box>
  );
};

export default Batch;
