/**
 * Project: tracebility-dashboard
 * File: AuthGuard
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import React from "react";
// next
import { Navigate, useLocation } from "react-router-dom";

// components
import { useAppSelector } from "hooks/appHooks";
import Loader from "@components/loader";

// ----------------------------------------------------------------------

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { pathname } = useLocation();
  const { isAuthenticated, isInitialized } = useAppSelector(
    (state) => state.auth
  );

  // if (!isInitialized) {
  //   return <Loader />;
  // }

  if (!isAuthenticated) {
    if (pathname !== "/auth/login") {
      return (
        <Navigate to="/auth/login" replace={true} state={{ from: pathname }} />
      );
    }
  }

  return <>{children}</>;
}
