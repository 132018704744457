/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Text, Textfield } from "@components/common";
import { useRelativePath } from "@utils/helper";
import cn from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const Default: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate(`verification`);
  };
  return (
    <Box className={"authForm reset-password"}>
      <Text Component="h2" className={"header"}>
        Forgot Your Password?
      </Text>
      <Box col className={"content"}>
        <Text Component="h2" className={"subtitle"}>
          Enter your primary contact and we’ll send you instructions on how to
          reset your password.
        </Text>
        <Textfield
          className={cn("input")}
          type={"email"}
          label={"Email or Phone"}
          placeholder={"Enter email address or phone number"}
        />
        <Button
          label={"Continue"}
          pill
          className={"button mt-5 lg:mt-16"}
          onClick={() => handleContinue()}
        />
      </Box>
    </Box>
  );
};

export default Default;
