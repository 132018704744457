/**
 *Project:Traceability Platform
 *File:checkbox
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { InputHTMLAttributes } from "react";
import s from "./index.module.css";
import cn from "classnames";

type Props = {} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox: React.FC<Props> = (props) => {
  return <input type={"checkbox"} {...props} className={s.root} />;
};

export default Checkbox;
