/**
 *Project:Traceability Platform
 *File:education level
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box } from "@components/common";
import EducationOverviewCards from "@features/cards/education-overview-cards";
import TopBar from "@layout/app/partials/top-bar";
import {randomizeColorSet, uuid} from "@utils/helper";
import React from "react";
import { useNavigate } from "react-router-dom";
import {useEducationLevels} from "services/system/levels";
import AppGuard from "guards/AppGuard";

type Props = {};

const Default: React.FC<Props> = () => {
  const navigate = useNavigate();

  const {
      educationLevels,
        isLoading,
        error,
  } = useEducationLevels();

    const handleClick = (name: string) => {
        navigate(`${name}`);
    }
  return (
      <AppGuard  accessibleRoles={["systemAdministrator"]}>
      <div className={""}>
        <TopBar title="Education" />
        <Box row className={"gap-[1.5625rem] flex-wrap"}>
          {educationLevels && educationLevels.map(level => {
              let colorSet = randomizeColorSet();
            return (
                <EducationOverviewCards
                    key={uuid()}
                    Icon={colorSet.icon}
                    label={level.humanizedName}
                    color={colorSet.color}
                    onClick={handleClick.bind(null, level.name)}
                />
            );
          })}
            {isLoading && <div>Loading...</div>}
            {error && !isLoading && <div>Error</div>}
        </Box>
      </div>
      </AppGuard>
  );
};

export default Default;
