/**
 *Project:Traceability Platform
 *File:radio
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";

interface Props {}

const Radio: React.FC<Props> = (props) => {
  return <input type={"radio"} className={s.root} />;
};

export default Radio;
