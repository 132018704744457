/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";

import { Box, Button, Link, Text, Textfield } from "@components/common";
import { useNavigate } from "react-router-dom";
interface Props {}

const NewPassword: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  function handleSubmit(): void {
    navigate("/auth/login");
    throw new Error("Function not implemented.");
  }

  return (
    <Box className={"authForm reset-password"}>
      <Text Component="h2" className={"header"}>
        Enter New Password
      </Text>
      <Box col className={"content w-full"}>
        <Text Component="h2" className={"subtitle"}>
          Kindly input your new password.
        </Text>
        <Textfield
          className={cn("input mb-8")}
          type={"password"}
          label={"New Password"}
          placeholder={"Enter your password"}
        />
        <Textfield
          className={cn("input")}
          type={"password"}
          label={"Confirm Password"}
          placeholder={"Re enter your password"}
        />
        <Button
          label={"Submit"}
          pill
          className={"button mt-5 w-full"}
          onClick={() => handleSubmit()}
        />
      </Box>
    </Box>
  );
};

export default NewPassword;
