import { Box, Button, Dropdown } from "@components/common";
import DashboardOverviewCard from "@features/cards/dashboard-overview-cards";
import Doughnut from "@features/doughnut";
import Graph from "@features/graph";
import TopBar from "@layout/app/partials/top-bar";
import { uuid } from "@utils/helper";
import cn from "classnames";
import React, { Ref, useState } from "react";
import RefObject from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import s from "./styles.module.css";
import { actions, cards } from "../constants";
import { useAvailableGoodsCount, useDashboardCount, useMovedGoodsCount, useReceivedGoodsCount, useVehicleCount, useWarehouseCountByOrg } from "services/system/dashboard";
import {
  KgGoodsAvailable,
  KgGoodsMoved,
  KgGoodsReceived,
  KgOverviewUser, KgOverviewVehicle,
  KgOverviewWarehousesGreen
} from "@components/Icons";
import { useAppSelector } from "hooks/appHooks";

interface Props { }

const Default = (props: Props) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const { users } = useDashboardCount();

  // const { count: vehicles } = useVehicleCount({
  //   range: 'custom'
  // });

  const { count: movedGoodsCount } = useMovedGoodsCount({
    range: 'custom'
  });

  const { count: receivedGoodsCount } = useReceivedGoodsCount({
    range: 'custom'
  });

  // const { count: availableGoodsCount } = useAvailableGoodsCount({
  //   range: 'custom'
  // });

  const { count: warehouses } = useWarehouseCountByOrg(organisationId, {
    range: 'custom'
  });

  return (
    <main className={s.root}>
      <TopBar
        title="Dashboard"
        renderAddButtonContent={
          <></>
          // <Dropdown
          //   show={showDropdown}
          //   toggle={setShowDropdown}
          //   className={s.notificationButton}
          // >
          //   <Dropdown.Toggle>
          //     <Button className="btn-md">
          //       New Action&nbsp;
          //       <BsFillCaretDownFill />
          //     </Button>
          //   </Dropdown.Toggle>
          //   <Dropdown.Menu variant="default" x="left-half" className={s.menu}>
          //     <ul className={s.options}>
          //       {actions.map((n) => (
          //         <Dropdown.Item
          //           className={cn(s.item)}
          //           onClick={() => navigate(n.path)}
          //         >
          //           {n.label}
          //         </Dropdown.Item>
          //       ))}
          //     </ul>
          //   </Dropdown.Menu>
          // </Dropdown>
        }
      />

{/* <div className={s.row}>
          <div className={s.column}>
            <div className={s.card}>
              <h3>Card 1</h3>
              <p>Some text</p>
              <p>Some text</p>
            </div>
          </div>

          <div className={s.column}>
            <div className={s.card}>
              <h3>Card 1</h3>
              <p>Some text</p>
              <p>Some text</p>
            </div>
          </div>

          <div className={s.column}>
            <div className={s.card}>
              <h3>Card 1</h3>
              <p>Some text</p>
              <p>Some text</p>
            </div>
          </div>

          <div className={s.column}>
            <div className={s.card}>
              <h3>Card 1</h3>
              <p>Some text</p>
              <p>Some text</p>
            </div>
          </div>
          
        </div> */}

      <Box
        className={
          "grid xl:grid-cols-3 sm:grid-cols-1 gap-8  lg:gap-[1.625rem] grid-cols-2 lg:grid-cols-2 w-full"
        }
      >
        

        <DashboardOverviewCard
          Icon={KgOverviewUser}
          label={'users'}
          value={users ?? '...'}
          padding={"10px"}
          fillColor={'blue'}
        />
        <DashboardOverviewCard
          Icon={KgOverviewWarehousesGreen}
          label={'warehouses'}
          value={warehouses || 0}
          fillColor={'green'}
          padding={"10px"}
          backgroundColor="#CDD7B6"
        />
        {/* <DashboardOverviewCard
          Icon={KgGoodsAvailable}
          label={'Available Goods'}
          value={availableGoodsCount || 0}
          padding={"10px"}
          backgroundColor="#E7C2B0"
        /> */}
        <DashboardOverviewCard
          Icon={KgGoodsReceived}
          label={'received goods'}
          value={receivedGoodsCount || 0}
          fillColor={'pink'}
          padding={"10px"}
          backgroundColor="#E5CBB0"
        />
        <DashboardOverviewCard
          Icon={KgGoodsMoved}
          label={'moved goods'}
          value={movedGoodsCount || 0}
          fillColor={'pink'}
          padding={"10px"}
          backgroundColor="#ECC3C2"
        />
        {/* <DashboardOverviewCard
          Icon={KgOverviewVehicle}
          label={'vehicles'}
          value={vehicles || 0}
          fillColor={'pink'}
          padding={"10px"}
          backgroundColor="#F9E9C9"
        /> */}


      </Box>
      <Box
        // width={'70%'}
        className={
          "grid grid-cols-6 md:grid-cols-6 gap-6 md:gap-6 sm:gap-0 my-5 xl:grid-cols-3 lg:grid-cols-3"
        }
      >
        {/* <Doughnut className={"w-full"} /> */}
        {/* <Graph className={"lg:col-span-2 xl:col-span-2 w-full"} /> */}
      </Box>
    </main>
  );
};

export default Default;
