/**
 *Project:Traceability Platform
 *File:badge
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { ReactNode } from "react";
import s from "./index.module.css";
import cn from "classnames";

interface Props {
  children?: ReactNode;
  color?: "primary" | "green" | "yellow" | "red";
  className?: string;
}

const Badge: React.FC<Props> = (props) => {
  const { children, className, color = "green" } = props;
  return (
    <div
      className={cn(s.root, className, {
        [s.green]: color === "green",
        [s.yellow]: color === "yellow",
        [s.red]: color === "red",
      })}
    >
      {children}
    </div>
  );
};

export default Badge;
