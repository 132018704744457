/**
 * Project: tracebility-dashboard
 * File: zones
 * Created by pennycodes on 02/12/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    ProductionZonePaginator,
    CreateOrEditProductionZone, QueryOptionsTwo
} from "models/query/system";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";



export function useProductionZones(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionZonePaginator, Error>(
        [ApiConfig.PRODUCTION_ZONES.ALL, options],
        ({ queryKey, pageParam }) =>
            client.productionZones.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        zones: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useProductionZone(id: string) {
    const { data, isLoading, error } = useQuery<ProductionZonePaginator, Error>(
        [ApiConfig.PRODUCTION_ZONES.DETAILS, id],
        () => client.productionZones.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        zone: data?.data[0],
        isLoading,
        error,
    };
}

export interface IZoneIDVariable {
    variables: {
        id: number;
    }
}

export const useDeleteZoneMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IZoneIDVariable) =>
            client.productionZones.delete(id),
        {
            onSuccess: () => {
                toast.success('Production Zone removed successfully');
                reset && reset();
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_ZONES.ALL);
            },
        }
    );
}

export interface IZoneUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditProductionZone;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateZoneMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit} = props
    return useMutation(
        ({ variables: { id, input } }: IZoneUpdateVariables) =>
            client.productionZones.update(id, input),
        {
            onSuccess: () => {
                toast.success('Production Zone updated successfully');
                setEdit(false)
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_ZONES.ALL);
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_ZONES.DETAILS);
            },
        }
    );
};

export interface IZoneCreateVariables {
    variables: { input: CreateOrEditProductionZone };
}

export const useCreateZoneMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IZoneCreateVariables) =>
            client.productionZones.create(input),
        {
            onSuccess: () => {
                toast.success('Production Zone created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_ZONES.ALL);
            },
        }
    );
};
export function useProductionRegionZones(props: { setZones: React.Dispatch<React.SetStateAction<any>> }) {
    const { setZones } = props;
    return useMutation(
        ({ variables: { regionId } }: { variables: { regionId: string } }) =>
            client.productionRegions.getZonesByRegion(regionId),
        {
            onSuccess: (data) => {
                console.log(data)
                setZones(data.data);
            }
        }
    );
}

// export function useProductionRegionZones(regionId: string) {
//     const { data, isLoading, error } = useQuery<ProductionZonePaginator, Error>(
//         [ApiConfig.PRODUCTION_ZONES.GET_BY_REGION.replace('{regionId}', regionId)],
//         () => client.productionRegions.getZonesByRegion(regionId),
//         {
//             enabled: !!regionId,
//         }
//     );

//     console.log()
//     return {
//         zone: data?.data[0],
//         isLoading,
//         error,
//     };
// }

