/**
 *Project:Traceability Platform
 *File:organization
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import {Box, Button, Text} from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import Table from "@components/table/Table";
import cn from "classnames";
import React, {useState} from "react";

import s from "./index.module.css";
import {useModalContext} from "@layout/app/context";
import {useRegionCommodities} from "services/system/regions";
import {useDeleteRegionalCommodityMutation} from "services/system/commodities";
import {TableColumn, TablePagination} from "@models/store/table";
import {DeleteDialog} from "@features/Utils";
import {KgBin} from "@components/Icons";
import {BasicCommodity} from "models/query/system";

type Props = {
  id: string;
} & WizardChildRef;
const Commodities: React.FC<Props> = (props) => {

  const { id } = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const { generateModal } = useModalContext();
  const {
    commodities,
    paginatorInfo,
    isLoading,
    error,
    loadNext,
    loadPrevious
  } = useRegionCommodities(id,{
    page,
    pageSize
  });

  const { mutate: deleteRegionalCommodity, isLoading: deleting } =
      useDeleteRegionalCommodityMutation();

  const Pagination: TablePagination | false  = paginatorInfo ? {
    count: paginatorInfo.totalCount,
    pageSize: paginatorInfo.pageSize,
    page: paginatorInfo.page,

  } : false;

  const Columns: Array<TableColumn> = [
    {
      key: 'name',
      title: 'Name',
      render: (text: string) => (
          <div className="text-sm font-medium text-gray-900">
            {text}
          </div>
      )
    },
    {
      key: 'basicCommodity',
      title: 'Basic Commodity',
      render: (text: BasicCommodity) => (
          <div className="text-sm font-normal text-gray-900">
            {text.name}
          </div>
      )
    },

    {
      key: 'id',
      title: '',
      className: 'text-end',
      render: (text: number) => (
          <Button
              variant="icon"
              className="!hidden lg:!flex"
              type={'button'}
              onClick={() =>
                  generateModal(
                      "Delete Regional Commodity",
                      <DeleteDialog
                          prompt={"Are you sure you want to delete this Regional Commodity?"}
                          callback={() => deleteRegionalCommodity({
                            variables: {
                              id: text
                            }
                          })}
                          loading={deleting}
                      />,
                      "center"
                  )
              }
          >
            <KgBin />
          </Button>
      )
    }
  ]


  return (
    <>
      <div className={cn(s.root, "lg:px-0 px-3")}>
        <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
          Commodities
        </Heading>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 w-full justify-between mb-[2rem]"
          }
        >
          <Text
            className={
              "text-[#99A1A3] text-base text-left max-w-[29.0625rem] mb-[1.25rem]"
            }
          >
            You can view the commodities in this production zone
          </Text>
        </Box>
        <Table
            tableClassName={'table-hover'}
            loading={isLoading}
            columns={Columns}
            dataSource={commodities}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={'No regional commodities available'}
        />
      </div>
    </>
  );
};

export default Commodities;
