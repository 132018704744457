/**
 *Project:Traceability Platform
 *File:add-blacklist-category
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {FieldValues, useForm} from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import {useCreateBlacklistMutation} from "services/system/blacklist";
import {CreatBlackListCategory} from "@models/query/system";
import TextAreaWrapper from "@forms/TextAreaWrapper";

type Props = {};

const AddBlacklistCategory: React.FC<Props> = () => {
  const { closeModal } = useModalContext();

    const blacklistValidationSchema = Yup.object().shape({
        name: Yup.string().required('Category name is required'),
        description: Yup.string(),
    });

    const defaultValues: FieldValues = {
        name: "",
        description: ""
    };

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(blacklistValidationSchema),
        defaultValues,
    });
    const { handleSubmit, reset, formState: { isValid } } = methods;

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { mutate: createBlacklist, isLoading: creating } =
        useCreateBlacklistMutation(onSubmitFinish);

    const onSubmit = (blacklist: CreatBlackListCategory) => {
        createBlacklist({
            variables: {
                input: blacklist
            }
        });
    }


  return (
    <div className={s.root}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <TextWrapper
            name={"name"}
          required
          label={"Name of Blacklist Category"}
          placeholder="Enter  name"
            className={'mb-4'}
        />
        <TextAreaWrapper
            name={"description"}
            rows={4}
          label={"Blacklist Category Description"}
          placeholder="Enter Description"
        />
      </Box>
      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || creating}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
            {creating ? "Creating..." : "    Add Blacklist Category"}
        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddBlacklistCategory;
