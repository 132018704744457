/**
 *Project:Traceability Platform
 *File:trace-analytics
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect, useState } from "react";
import s from "./index.module.css";
import cn from "classnames";
import TopBar from "@layout/app/partials/top-bar";
import { Box, Button, Text } from "@components/common";
import TextField from "@components/common/textfield";
import ActivityStepper from "./activity-stepper";
import { useAppSelector } from "hooks/appHooks";
import { useTaceBatch } from "services/system/reporting";
import { IBatchTrace } from "models/query/buyer";
import Select from 'react-select'

type Props = {};

const TraceAnalytics: React.FC<Props> = (props) => {
  const [first, setFirst] = useState(false);
  const [data, setData] = useState<IBatchTrace[]>([]);
  const [tagId, setTagId] = useState('')

  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const { mutate: searchBatchTag, isLoading, isError } = useTaceBatch({ setFirst, setData });

  return (
    <div className={s.root}>
      <TopBar title="Trace" />
      <Box className={"gap-[3.625rem] flex lg:flex-row flex-col"}>
        <Box col className={cn("gap-[3rem]", s.card)}>
          <Box col className={"gap-[0.25rem] "}>
            <Text className="text-black2A text-[1.125rem] font-bold">
              Search tag records
            </Text>
            <Text className="text-[0.75rem]text-grey5B">
            Search for an RFID Tag number to see a trace
            </Text>
          </Box>
          <TextField
            label={"RFID Tag number"}
            value={tagId}
            onChange={(evnt) => setTagId(evnt.target.value)}
          />
          <Button
            label={isLoading ? 'Tracing...' : 'Trace'}
            disabled={isLoading ? isLoading : !tagId}
            className={"w-[12.9375rem] mx-auto"}
            onClick={() => searchBatchTag({ variables: { tagId } })}
          />
        </Box>
        <ActivityStepper activity={first} trace={data} />
      </Box>
    </div>
  );
};

export default TraceAnalytics;
