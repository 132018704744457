/**
 * Project: tracebility-dashboard
 * File: levels
 * Created by pennycodes on 04/12/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery} from 'react-query';
import client from './index';
import {
    QueryOptions,
     EducationLevelPaginator
} from "models/query/system";
import ApiConfig from "services/endpoints";

export function useEducationLevels(options?: QueryOptions) {
    const { data, isLoading, error } = useQuery<EducationLevelPaginator, Error>(
        [ApiConfig.EDUCATION_LEVELS, options],
        () => client.educationLevels.all(options),

    );
    return {
        educationLevels: data?.data,
        isLoading,
        error,
    };
}
