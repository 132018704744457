/**
 *Project:Traceability Platform
 *File:add-new-user
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import { useModalContext } from "@layout/app/context";
import React  from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";

import s from "./index.module.css";
import {useAddUserOrganisationMutation, useInviteUserMutation, useSupportRoles} from "services/system/users";
import {
  AddUserOrganisation,
  CreateInvitation,
  Organisation,
  SupportedRole,
} from "@models/query/system";
import { useOrganisations } from "services/system/organizations";
type Props = {
  id?: string;
} & WizardChildRef;

const roles = [
  {
    name: 'systemAdministrator',
    humanizedName: 'System Admin',
    description: 'System Administrators'
  },
  {
    name: 'corporateAdministrator',
    humanizedName: 'Corporate Admin',
    description: 'Corporate Administrators'
  }
]

const AddNewUser: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();

  const userValidationSchema = Yup.object().shape({
    username: Yup.string().required("Contact Phone / Email is required"),
    role: Yup.string().required("User role is required"),
    organisationId: Yup.number(),
  });

  const defaultValues: FieldValues = {
    username: "",
    role: "",
    organisationId: props.id ? parseInt(props.id) : 0,
  };

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(userValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    props.goToStep && props.goToStep(1);
  };

  // const { roles, isLoading } = useSupportRoles({
  //   size: 100,
  // });

  const { organisations, isLoading: orgLoading } = useOrganisations({
    size: 999,
  });

  const { mutate: inviteUser, isLoading: creating } =
    useInviteUserMutation(onSubmitFinish);

  const onSubmit = (user: CreateInvitation) => {
    inviteUser({
      variables: {
        input: user,
      },
    });
  };

  return (
    <div className={s.root}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box form className={"pb-[min(5rem,10vh)]"}>
          <TextWrapper
            name={"username"}
            required
            label={"Contact"}
            placeholder="Enter email address or phone number"
          />
          <SelectWrapper
            name={"role"}
            className={s.input}
            required
            placeholder={"Select user role"}
            options={roles}
            isLoading={false}
            loadingMessage={() => "Fetching supported roles..."}
            getOptionValue={(option: any) => option.name}
            getOptionLabel={(option: any) => option.humanizedName}
            value={roles.find(
              (role: any) => role.name === methods.getValues("role")
            )}
            onChange={(newValue: unknown, actionMeta) => {
              let value = newValue as SupportedRole;

              setValue("role", value.name);
            }}
          />
          {!props.id && (
            <SelectWrapper
              name={"organisationId"}
              className={s.input}
              required
              placeholder={"Select user organisation"}
              options={organisations}
              isLoading={orgLoading}
              loadingMessage={() => "Fetching organisations..."}
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) => option.name}
              value={organisations.find(
                (org: any) => org.id === methods.getValues("organisationId")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                let value = newValue as Organisation;
                setValue("organisationId", value.id);
              }}
            />
          )}
        </Box>
        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
            type={"button"}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid || creating}
            pill
            style={{ width: "9rem" }}
          >
            {creating ? "Processing..." : "Send Invite"}
          </Button>
        </Box>
      </FormProvider>
    </div>
  );
};

const AddUserToOrganisation: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();

  const userOrganisationValidationSchema = Yup.object().shape({
    organisationId: Yup.number().required("Select Organisation"),
    role: Yup.string().required("User role is required"),
  });

  const defaultValues: FieldValues = {
    organisationId: null,
    role: ''
  };

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(userOrganisationValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
   closeModal();
  };

  // const { roles, isLoading } = useSupportRoles({
  //   size: 100,
  // });

  const { organisations, isLoading: orgLoading } = useOrganisations({
    size: 999,
  });

  const { mutate: addUserOrganisation, isLoading: creating } =
      useAddUserOrganisationMutation(onSubmitFinish);

  const onSubmit = (user: AddUserOrganisation) => {
    addUserOrganisation({
      variables: {
        input: user,
        id: Number(props.id)
      },
    });
  };

  return (
      <div className={s.root}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Box form className={"pb-[min(5rem,10vh)]"}>
            <SelectWrapper
                name={"organisationId"}
                className={s.input}
                required
                placeholder={"Select user organisation"}
                options={organisations}
                isLoading={orgLoading}
                loadingMessage={() => "Fetching organisations..."}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.name}
                value={organisations.find(
                    (org: any) => org.id === methods.getValues("organisationId")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as Organisation;
                  setValue("organisationId", value.id);
                }}
            />
            <SelectWrapper
                name={"role"}
                className={s.input}
                required
                placeholder={"Select user role"}
                options={roles}
                isLoading={false}
                loadingMessage={() => "Fetching supported roles..."}
                getOptionValue={(option: any) => option.name}
                getOptionLabel={(option: any) => option.humanizedName}
                value={roles.find(
                    (role: any) => role.name === methods.getValues("role")
                )}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as SupportedRole;

                  setValue("role", value.name);
                }}
            />
          </Box>
          <Box
              row
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={"1.875rem"}
          >
            <Button
                variant="icon"
                style={{ color: "#20201F" }}
                onClick={() => closeModal()}
                type={"button"}
            >
              Cancel
            </Button>
            <Button
                color="primary"
                disabled={!isValid || creating}
                pill
                style={{ width: "9rem" }}
            >
              {creating ? "Processing..." : "Add User"}
            </Button>
          </Box>
        </FormProvider>
      </div>
  );
};
export { AddUserToOrganisation }

export default AddNewUser;
