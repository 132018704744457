/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";

type Props = {};

const Details: React.FC<Props> = (_props) => {
  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
  ];
  return (
    <DetailsWizard
      id={"warehouse-details"}
      views={items}
      title={"Awodome Supply Chain"}
    >
      <GeneralInformation name="general-information" />
    </DetailsWizard>
  );
};

export default Details;
