/**
 *Project:Traceability Platform
 *File:app
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import AuthGuard from "guards/AuthGuard";
import React from "react";
// import s from "./index.module.css";
// import cn from "classnames";
// import AppLayout from "@layout/app";
import { Outlet } from "react-router-dom";
interface Props {}
const AppLayout = React.lazy(() => import("layout/app"));
const App: React.FC<Props> = (props) => {
  return (
    <AuthGuard>
      <AppLayout>
        <Outlet />
      </AppLayout>
    </AuthGuard>
  );
};

export default App;
