/**
 *Project:Traceability Platform
 *File:dashboard
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box } from "@components/common";
import Doughnut from "@features/doughnut";
import Histogram from "@features/histogram";
import React from "react";
import DashboardOverviewCard from "@features/cards/dashboard-overview-cards";
import s from "./index.module.css";
import TopBar from "@layout/app/partials/top-bar";
import AppGuard from "guards/AppGuard";
import {useCommoditiesCount, useDashboardCount, useWarehouseCount} from "services/system/dashboard";
import {KgOverviewCommodities, KgOverviewOrganization, KgOverviewUser, KgOverviewWarehouses} from "components/Icons";

interface Props {}

const Dashboard: React.FC<Props> = (props) => {
    const { users, organisations } = useDashboardCount();
    const { count: warehouses } = useWarehouseCount({
        range: 'custom'
    });

    const { count: commodities } = useCommoditiesCount({
        range: 'custom'
    });


  return (
   <AppGuard accessibleRoles={['systemAdministrator']}>
       <main className={s.root}>
           <TopBar title="Dashboard" />
           <Box
               className={
                   "grid xl:grid-cols-4 sm:grid-cols-1 gap-8 grid-cols-2 lg:grid-cols-2 w-full"
               }
           >
               <DashboardOverviewCard
                   Icon={KgOverviewUser}
                   label={'users'}
                   value={users ?? '...'}
                   fillColor={'blue'}
               />
               <DashboardOverviewCard
                   Icon={KgOverviewOrganization}
                   label={'organizations'}
                   value={organisations ?? '...'}
                   fillColor={'green'}
               />
               <DashboardOverviewCard
                   Icon={KgOverviewCommodities}
                   label={'commodities'}
                   value={commodities ?? '...'}
                   fillColor={'pink'}
               />
               <DashboardOverviewCard
                   Icon={KgOverviewWarehouses}
                   label={'warehouses'}
                   value={warehouses ?? '...'}
                   fillColor={'orange'}
               />
           </Box>
           <Box
               className={
                   "grid grid-cols-1 md:grid-cols-1 gap-6 md:gap-6 sm:gap-0 mt-5 xl:grid-cols-3 lg:grid-cols-3"
               }
           >
               {/* <Histogram className={"lg:col-span-2 xl:col-span-2 w-full"} /> */}
               {/* <Doughnut className={"w-full"} /> */}
           </Box>
       </main>
   </AppGuard>
  );
};

export default Dashboard;
