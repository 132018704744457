/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useEffect, useState} from "react";

import cn from "classnames";
import { Box, Text, Button } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
import {AddOrEditCountry, CountryPage} from "@models/query/system";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {useDeleteCountryMutation, useUpdateCountryMutation} from "services/system/country";
import {useAppSelector} from "hooks/appHooks";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import s from "@pages/app/system/organizations/general-information/index.module.css";

type Props = {
  data: CountryPage | undefined;
  error: any;
  isLoading: boolean;

} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);

  const { data, isLoading, error } = props;

  const { user } = useAppSelector((state) => state.auth);

  const countryValidationSchema = Yup.object().shape({
    name: Yup.string().required('Country name is required'),
    code: Yup.string().required('Country code is required'),
  });

  const defaultValues: FieldValues = {
    name: '',
    code: '',
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(countryValidationSchema),
    defaultValues,
  });

  const onSubmitFinish = () => {
    reset(defaultValues);

  }

  const { handleSubmit, reset, formState: { isValid } } = methods;

  const { mutate: updateCountry, isLoading: creating } =
      useUpdateCountryMutation({
        setEdit: onSubmitFinish
      });

  const onSubmit = (country: AddOrEditCountry) => {


    if (data) {
      updateCountry({
        variables: {
          input: country,
          id: data.id,
          org: user.organisation.id
        }
      });
    }
  }

  useEffect(() => {
    if (data) reset({
      name: data.supportedCountry.name,
      code: data.supportedCountry.code,
    })
    return () =>{};
  }, [data]);


  const { mutate: deleteCountry, isLoading: deleting } =
      useDeleteCountryMutation();

  const { generateModal } = useModalContext();

  return (
    <div className={"root px-3 lg:px-0"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between header"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left"}>
              General Information
            </Heading>
            <Text
              className={cn(
                "gi-subtitle",
                "max-w-[29.0625rem] text-left fs-30 lufga-semi-bold"
              )}
            >
              You can change the details of this country
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <a
                    className="btn btn-md mt-5"
                    onClick={() => setEdit(false)}
                    type={'button'}
                    role={'button'}
                >Cancel</a>
                <Button
                  onClick={() => setEdit(false)}
                  variant="fill"
                  pill
                  className="btn-md"
                  label={"Save"}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                  label={"Edit"}
                  type={'button'}
                />
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  label={"Delete Country"}
                  onClick={() =>
                    generateModal(
                      "Delete Country",
                      <DeleteDialog
                        prompt=" Are you sure you want to delete"
                        boldText={data ? data.supportedCountry.name : '...'}
                        callback={() => data ? deleteCountry({
                          variables: {
                            id: data.id,
                            org: user.organisation.id
                          }
                        }) : null}
                        loading={deleting}
                      />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section"}>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={""}>
              Name
            </Text>
            {edit ? (
                <TextWrapper
                    name={"name"}
                    color="primary"
                    placeholder="Ghana"
                    className={s.input}
                />
            ) : (
              <Text className={"subtitle"}>{isLoading ? 'Fetching' : data?.supportedCountry.name}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              International Code <br />
              or Abbreviation
            </Text>
            {edit ? (
                <TextWrapper
                    name={"code"}
                    color="primary"
                    placeholder="GH"
                    className={s.input}
                />
            ) : (
              <Text className={"subtitle"}>{isLoading ? 'Fetching' : data?.supportedCountry.code}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Currency
            </Text>
            <Text className={"subtitle"}>{isLoading ? 'Fetching' : data?.supportedCountry.currency.toUpperCase()}</Text>
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
            Flag
            </Text>
            <Box row className={"items-center"}>
              <Text className={"subtitle"}>  {isLoading ? 'Fetching' : data?.supportedCountry.flag}</Text>


            </Box>
          </Box>
        </Box>
      </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
