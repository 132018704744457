/**
 *Project:Traceability Platform
 *File:trace-analytics
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect, useState, useReducer } from "react";
import { DateRangeInput } from '@datepicker-react/styled'
import s from "./index.module.css";
import cn from "classnames";
import TopBar from "@layout/app/partials/top-bar";
import { Box, Button, Text } from "@components/common";
import TextField from "@components/common/textfield";
import ActivityStepper from "./activity-stepper";
import { useAppSelector } from "hooks/appHooks";
import { useReportQuery } from "services/buyer/reporting";
import { IBatchTrace } from "models/query/buyer";
import Select from 'react-select';
import { useProductionRegionZones, useProductionZones } from "services/system/zones";
import { useProductionRegions } from "services/buyer/goods";
type Props = {};

const REPORTS = [
  {
    value: 1,
    label: 'Field Agents',
    param: '/production/regions/{regionId}/field/agents/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/production/regions/{regionId}/field/agents/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/production/zones/{zoneId}/field/agents/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
  {
    value: 2,
    label: 'Warehouses',
    param: '/warehouses/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/production/regions/{regionId}/warehouses/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/production/zones/{zoneId}/warehouses/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
  {
    value: 3,
    label: 'Farmers / Collectors',
    param: '/production/zones/{regionId}/farmers/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/production/regions/{regionId}/farmers/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/production/zones/{zoneId}/farmers/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
  {
    value: 4,
    label: 'Cooperative',
    param: '/production/regions/{regionId}/cooperatives/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/production/regions/{regionId}/field/agents/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/production/zones/{zoneId}/field/agents/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
  {
    value: 5,
    label: 'Goods',
    param: '/goods/count/available?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/goods/count/available?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/goods/count/available?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
  {
    value: 6,
    label: 'Commodity',
    param: '/commodities/regional/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/commodities/regional/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/commodities/regional/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
  {
    value: 7,
    label: 'Farmers / Collectors Distribution',
    param: '/production/zones/{regionId}/farmers/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
    params: {
      region: '/production/regions/{regionId}/farmers/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}',
      zone: '/production/zones/{zoneId}/farmers/count?range=custom&rangeStart={rangeStart}&rangeEnd={rangeEnd}'
    }
  },
];

const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: null,
}

const isValidated = (data: {
  reportData: string;
  productionRegion: string;
  startDate: any;
  endDate: any;
  isValid: boolean;
}) => (data.endDate && data.startDate && data.productionRegion && data.reportData)

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'focusChange':
      return { ...state, focusedInput: action.payload }
    case 'dateChange':
      return action.payload
    default:
      throw new Error()
  }
};

const ReportAnalytics: React.FC<Props> = (props) => {
  const [first, setFirst] = useState(false);
  const [data, setData] = useState<IBatchTrace[]>([]);
  const [zones, setZones] = useState<any[]>([]);

  const [searchParams, setSearchParams] = useState<{
    reportData: string;
    productionZone: string;
    productionRegion: string;
    startDate: any;
    endDate: any;
    isValid: boolean;
  }>({ reportData: '', productionRegion: '', productionZone: '', startDate: null, endDate: null, isValid: false });

  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const { mutate: queryReport, isLoading, isError } = useReportQuery({ setFirst, setData });

  const { regions, isLoading: isLoadingRegions } = useProductionRegions({
    page: 0,
    pageSize: 999,
    search: "",
  });

  const { isLoading: isLoadingZones, mutate: getZonesByRegion } = useProductionRegionZones({ setZones });


  const [state, dispatch] = useReducer(reducer, initialState);


  const handleOnChange = (data: { value: any, label: string }, name: string) => {

    if (name === 'productionRegion') {
      getZonesByRegion({ variables: { regionId: data.value } });
    }

    setSearchParams(param => ({ ...param, [name]: data.value }));

    if (isValidated(searchParams)) {
      setSearchParams(param => ({ ...param, isValid: true }));
    }
  }


  const constructReport = () => {
    const data = isValidated(searchParams) ? REPORTS.find(x => x.value === Number(searchParams.reportData)) : null;

    const hasRegion = data?.param && (searchParams.productionZone == '' || Number(searchParams.productionZone) === 0) || false
    const hasZone  = (data?.param && Number(searchParams.productionZone) !== 0) || false;

    if (data) {
      const start = new Date(searchParams?.startDate).toISOString();
      const end = new Date(searchParams?.endDate).toISOString();
      const regionId = searchParams?.productionRegion
      const zoneId = searchParams?.productionZone;

      if(hasZone){
        return data.params.zone.replace('{rangeStart}', start).replace('{rangeEnd}', end).replace('{zoneId}', zoneId);
      }

      if(hasRegion){
        return data.params.region.replace('{rangeStart}', start).replace('{rangeEnd}', end).replace('{regionId}', regionId);
      }
    }

    return '';
  }

  const reset = () => {
    setFirst(false);
    setData([]);
    //setSearchParams(x => ({ ...x, ...{ reportData: '', productionRegion: '', productionZone: '', startDate: null, endDate: null, isValid: false } }))
  };

  return (
    <div className={s.root}>
      <TopBar title="Analytics" />
      <Box className={"gap-[3.625rem] flex lg:flex-row flex-col"}>
        <Box col className={cn("gap-[3rem]", s.card)} backgroundColor='#FDF6EF' padding={20}>
          <Box col className={"gap-[0.25rem] "}>
            <Text className="text-black2A text-[1.125rem] font-bold">
              Report Filtering
            </Text>
            <Text className="text-[0.75rem]text-grey5B">
              Choose specific data report you want generated
            </Text>
          </Box>

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              {/* Regional Commodity <span style={{ color: "red" }}>*</span> */}
              Report Data  <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              name='reportData'
              placeholder={'Choose your preferred data'}
              className="input"
              onChange={(data: any) => {
                handleOnChange(data, 'reportData');
                reset()
              }}
              options={REPORTS}
            />
          </div>

          <div className="flex flex-col gap-[4px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              {/* Regional Commodity <span style={{ color: "red" }}>*</span> */}
              Production Region  <span style={{ color: "red" }}>*</span>
            </label>
            <Select
              isLoading={isLoadingRegions}
              name='productionRegion'
              placeholder={'Choose your preferred production region'}
              className="input"
              onChange={(data: any) => handleOnChange(data, 'productionRegion')}
              options={regions.map((x) => ({ ...x, label: x.name, value: x.id }))}
            // options={REPORTS}
            />
          </div>

          <div className="flex flex-col gap-[4px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              {/* Regional Commodity <span style={{ color: "red" }}>*</span> */}
              Production Zone
            </label>
            <Select
              isLoading={isLoadingZones}
              name='productionZone'
              placeholder={'Choose your preferred production zone'}
              className="input"
              onChange={(data: any) => handleOnChange(data, 'productionZone')}
              options={[{ id: 0, name: 'None' }, ...zones].map((x) => ({ ...x, label: x.name, value: x.id }))}
            // options={REPORTS}
            />
          </div>

          <div>
            <div style={{ padding: 20 }}>
              <DateRangeInput
                onDatesChange={data => {
                  if (data?.startDate) {
                    setSearchParams(x => ({ ...x, startDate: data.startDate }));
                  }

                  if (data?.endDate) {
                    setSearchParams(x => ({ ...x, endDate: data.endDate }));
                  }

                  if (isValidated({ ...searchParams, startDate: data.startDate, endDate: data.endDate })) {
                    setSearchParams(param => ({ ...param, startDate: data.startDate, endDate: data.endDate, isValid: true }));
                  }

                  dispatch({ type: 'dateChange', payload: data });
                }}
                placement='top'
                onFocusChange={data => {
                  console.log({ data })
                  dispatch({ type: 'focusChange', payload: data });
                }}
                startDate={state.startDate} // Date or null
                endDate={state.endDate} // Date or null
                focusedInput={state.focusedInput} // START_DATE, END_DATE or null
              />
            </div>
          </div>

          <Button
            label={isLoading ? 'Generating...' : 'Generate'}
            disabled={isLoading ? isLoading : !searchParams.isValid}
            className={"w-[12.9375rem] mx-auto"}
            onClick={() => queryReport({ variables: { param: constructReport() } })}
          />
        </Box>
        <ActivityStepper
          activity={first}
          trace={{
            params: {
              ...searchParams,
              report: REPORTS.find(x => x.value === Number(searchParams.reportData)),
              prodRegion: regions.find(x => x.id === Number(searchParams.productionRegion || 0)),
              prodZone: zones.find(x => x.id === Number(searchParams.productionZone || 0))
            },
            data
          }} />
      </Box>
    </div>
  );
};

export default ReportAnalytics;
