/**
 *Project:Traceability Platform
 *File:sign-up
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";

import cn from "classnames";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Button, Link, Text, Textfield } from "@components/common";
import { useNavigate } from "react-router-dom";
import { useRelativePath } from "@utils/helper";
interface Props {}

const SignUp: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const base = useRelativePath();
  const handleSignUp = () => {
    navigate("/auth/system/login");
  };
  return (
    <Box className={"authForm pb-[4rem] lg:pb-[1rem] mt-auto"}>
      <Text Component="h2" className={"header !h-[7.875rem] !text-center"}>
        Welcome, let’s get you started
      </Text>
      <Box
        justifyContent={"space-between"}
        className={"lg:flex lg:flex-row lg:gap-0"}
        width="100%"
        alignItems="baseline"
      >
        <Textfield
          className={cn("input", "firstName", "lg:mb-0 mb-[1rem]")}
          label={"First Name"}
          placeholder={"Enter your first name"}
        />
        <Textfield
          className={cn("input", "lastName")}
          type={"text"}
          label={"Last Name"}
          placeholder={"Enter your last name"}
        />
      </Box>
      <Textfield
        className={cn("input")}
        type={"email"}
        label={"Email"}
        placeholder={"Enter your email"}
      />
      <Textfield
        className={cn("input ")}
        type={"password"}
        label={"Password"}
        placeholder={"Enter your password"}
      />
      <fieldset className="flex flex-col">
        <label className="text-left mb-[8px] lufga-medium fs-14">Phone</label>
        <PhoneInput
          country={"gh"}
          buttonClass={"!border-none !bg-none"}
          inputClass="flex flex-1 !border-none"
          containerClass={cn(
            "input flex flex-row items-center border-solid  border-[0.1875rem] border-[0.0625rem] !h-[2.6875rem]"
          )}
          containerStyle={{
            borderColor: "rgb(153, 161, 163)",
          }}
          // value={this.state.phone}
          // onChange={(phone) => this.setState({ phone })}
        />
      </fieldset>

      <Button
        label={"Sign Up"}
        pill
        className={"button"}
        onClick={() => handleSignUp()}
      />
      <Text className={"link !text-center !mb-[0]"}>
        Already have an account?&nbsp;
        <Link color="primary" to={`${base}/login`}>
          Login In
        </Link>
      </Text>
    </Box>
  );
};

export default SignUp;
