/**
 * Project: tracebility-dashboard
 * File: country
 * Created by pennycodes on 17/12/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    CountriesPaginator,
    QueryOptions,
    AddOrEditCountry
} from "models/query/system";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";
import {appNavigate} from "@utils/CustomRouter";



export function useCountries(org: string, options?: QueryOptions) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<CountriesPaginator, Error>(
        [ApiConfig.COUNTRIES.ALL, options],
        ({ queryKey, pageParam }) =>
            client.countries.all(org, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        countries: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useCountry(org: string, id: string) {
    const { data, isLoading, error } = useQuery<CountriesPaginator, Error>(
        [ApiConfig.COUNTRIES.DETAILS, id],
        () => client.countries.details(org,id),
        {
            enabled: !!id,
        }
    );
    return {
        country: data?.data[0],
        isLoading,
        error,
    };
}

export interface ICountryIDVariable {
    variables: {
        id: number;
        org: string
    }
}

export const useDeleteCountryMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, org } }: ICountryIDVariable) =>
            client.countries.delete(org,id),
        {
            onSuccess: () => {
                toast.success('Country removed successfully');
                appNavigate('/app/country')
            },
            onError: (error) => {
                toast.error('An error occurred. Please retry later');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COUNTRIES.ALL);

            },
        }
    );
}

export interface ICountryUpdateVariables {
    variables: {
        id: number;
        input: AddOrEditCountry;
        org: string
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateCountryMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit} = props
    return useMutation(
        ({ variables: { id, input, org } }: ICountryUpdateVariables) =>
            client.countries.update(org, id, input),
        {
            onSuccess: () => {
                toast.success('Country updated successfully');
                setEdit(false)
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COUNTRIES.ALL);
                queryClient.invalidateQueries(ApiConfig.COUNTRIES.DETAILS);
            },
        }
    );
};

export interface ICountryCreateVariables {
    variables: { input: AddOrEditCountry; org: string };
}

export const useCreateCountryMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input, org } }: ICountryCreateVariables) =>
            client.countries.create(org, input),
        {
            onSuccess: () => {
                toast.success('Country created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COUNTRIES.ALL);
            },
        }
    );
};
