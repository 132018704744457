/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import DetailsWizard from "@features/detail-wizard";
import React from "react";

import GeneralInformation from "./GeneralInformation";
import { useParams} from "react-router-dom";
import {useProductionZone} from "services/system/zones";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = (props) => {

  const { id } =  useParams();

  const {
    zone,
    isLoading,
    error,
  } = useProductionZone(id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    }
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard
      id={"production-zone-details"}
      views={items}
      title={zone?.name}
    >
      <GeneralInformation
          name="general-information"
          data={zone}
          isLoading={isLoading}
          error={error}
      />

    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
