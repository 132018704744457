/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useState } from "react";
import cn from "classnames";
import { Box, Textfield, Text, Button, TextArea } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
type Props = {} & WizardChildRef;

const GeneralInformation: React.FC<Props> = () => {
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();
  return (
    <div className={"root px-3 lg:px-3"}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between header"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle max-w-[29.0625rem] text-left")}>
              You can change the details of the basic commodity
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit(false)}
                  label="Cancel"
                />

                <Button
                  onClick={() => setEdit(false)}
                  variant="fill"
                  pill
                  className="btn-md"
                  label={"Save"}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  label={"Edit"}
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                />

                <Button
                  variant="fill"
                  pill
                  label={"Delete Warehouse"}
                  className="lg:btn-lg btn-md"
                  onClick={() =>
                    generateModal(
                      "Delete Warehouse",
                      <DeleteDialog prompt=" Are you sure you want to delete Warehouse ?" />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section no-border"}>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Name of Warehouse
            </Text>
            {edit ? (
              <Textfield color="primary" placeholder="Awodome Supply Chain" />
            ) : (
              <Text className={"gi-subtitle"}>Awodome Supply Chain</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Warehouse Description
            </Text>
            {edit ? (
              <TextArea
                color="primary"
                placeholder=" Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
              />
            ) : (
              <Text className={"gi-subtitle"}>
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat
              </Text>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default GeneralInformation;
