/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import Farmers from "./Farmers";
import {useLocation} from "react-router-dom";
import {EducationLevel} from "@models/query/system";
import {useEducationLevels} from "services/system/levels";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = () => {

  const { pathname } = useLocation();
  const [data, setData] = React.useState<EducationLevel | undefined>(undefined);
  const id = pathname.split("/").pop();

    const {
        educationLevels,
    } = useEducationLevels();

    React.useEffect(() => {
        if (educationLevels) {
            const level = educationLevels.find((item) => item.name === id);
            setData(level);
        }
    }, [educationLevels]);



  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    {
      label: "Farmers",
      name: "farmers",
    },
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard
        id={"education-level-details"}
        views={items}
        title={data?.humanizedName || 'Fetching...'}
    >
      <GeneralInformation
          name="general-information"
          data={data}

      />
      <Farmers
          name="farmers"
          id={id as string}
      />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
