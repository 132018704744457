/**
 * Project: tracebility-dashboard
 * File: clients
 * Created by pennycodes on 22/01/2023.
 * Copyright tracebility-dashboard
 */

import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import client from './index';
import {
    ClientsPaginator,
    CreateOrEditBatch,
    IBatchPaginator,
    QueryOptionsTwo
} from "models/query/buyer";
import ApiConfig from "services/endpoints";
import { toast } from "react-toastify";


export function useBatches(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ClientsPaginator, Error>(
        [ApiConfig.BATCH.ALL, options],
        ({ queryKey, pageParam }) =>
            client.batch.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useBatch(id: string) {
    const { data, isLoading, error } = useQuery<IBatchPaginator, Error>(
        [ApiConfig.BATCH.DETAILS, id],
        () => client.batch.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        client: data?.data[0],
        isLoading,
        error,
    };
}

export interface IBatchDelete {
    variables: {
        id: string;
    }
}

export const useDeleteBatchMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IBatchDelete) =>
            client.batch.delete(Number(id)),
        {
            onSuccess: () => {
                toast.success('Batch removed successfully');
                reset && reset();
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.BATCH.ALL);
            },
        }
    );
}

export interface IBatchUpdateVariables {
    variables: {
        id: string;
        input: CreateOrEditBatch;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateBatchMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit } = props
    return useMutation(
        ({ variables: { id, input } }: IBatchUpdateVariables) =>
            client.batch.update(Number(id), input),
        {
            onSuccess: () => {
                toast.success('Batch updated successfully');
                setEdit(false)
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.BATCH.ALL);
                queryClient.invalidateQueries(ApiConfig.BATCH.DETAILS);
            },
        }
    );
};

export interface IBatchCreateVariables {
    variables: { input: CreateOrEditBatch};
}

export const useCreateBatchMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IBatchCreateVariables) =>
            client.batch.create( input),
        {
            onSuccess: () => {
                toast.success('Batch created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.BATCH.ALL);
            },
        }
    );
};
