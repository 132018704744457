/**
 *Project:Traceability Platform
 *File:add-production-region
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Select } from "@components/common";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useModalContext } from "@layout/app/context";
import {
    useSetProductionRegionMutation,
    useProductionRegions,
} from "services/buyer/goods";
import cn from "classnames";
import React, { Ref, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import SelectWrapper from "@forms/SelectWrapper";
import FormProvider from "@forms/FormProvider";
import { useAppSelector } from "hooks/appHooks";

type Props = {};

const SetProductionRegion: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();
    // const ref = useAutoAnimate({
    //     duration: 0.3,
    // });
    const ref = React.useRef(null);

    const [selectedRegions, setSelectedRegions] = useState<
        Array<{ id: string; name: string }>
    >([]);

    const { regions, paginatorInfo, isLoading, error, loadNext, loadPrevious } =
        useProductionRegions({ page: 0, pageSize: 100, search: "" });

    const remove = (key: string) => {
        setSelectedRegions((r) => r.filter((r) => r.id !== key));
    };

    const add = (key: { id: string; name: string }) => {
        if (!selectedRegions.find((x) => x.id === key.id))
            setSelectedRegions((r) => [key, ...r]);
    };

    const defaultValues: FieldValues = {
        regionId: "",
        // organizationId: 0,
    };

    const regionValidationSchema = Yup.object().shape({
        regionId: Yup.string().required("Production region is required"),
    });

    const methods = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(regionValidationSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { isValid },
    } = methods;

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();
    };

    const { mutate: setProdRegions, isLoading: creating } =
        useSetProductionRegionMutation(onSubmitFinish);

    const { user } = useAppSelector((state) => state.auth);

    const onSubmit = () => {
        const organisationId = user?.organisation?.id;

        if (selectedRegions.map((x) => x.id).length && organisationId) {
            const payload = {
                organisation: organisationId,
                productionRegions: selectedRegions.map((x) => Number(x.id)),
            };

            setProdRegions({
                variables: {
                    input: payload,
                },
            });
        }
    };

    return (
        <div className={"w-full pb-5"}>
            <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit as any)}
            >
                {/* <Select
          required
          label={"Select Production Region (s)"}
          className={cn("w-full")}
          placeholder="Enter  name"
        /> */}
                <SelectWrapper
                    name={"regionId"}
                    required
                    placeholder={"Select region"}
                    options={regions}
                    isLoading={isLoading}
                    loadingMessage={() => "Fetching supported regions..."}
                    getOptionValue={(option: any) => option.id}
                    getOptionLabel={(option: any) => option.name}
                    value={regions.find(
                        (role: any) =>
                            role.name === methods.getValues("regionId")
                    )}
                    onChange={(newValue: unknown, actionMeta) => {
                        const value = newValue as { id: string; name: string };

                        if (value) {
                            setValue("regionId", value.id);
                            add(value);
                            console.log(selectedRegions);
                        }

                        // setValue("role", value.name);
                    }}
                />

                <Box
                    ref={ref as any}
                    className={
                        "flex flex-row mt-[0.5rem] gap-[0.625rem] flex-wrap"
                    }
                >
                    {selectedRegions.map((region, index) => (
                        <Box
                            display={"flex"}
                            key={index}
                            className={
                                " flex-row !h-[1.1875rem] gap-[0.7937rem] px-[0.625rem] rounded-[0.1875rem] items-center bg-[#FFDBCA]"
                            }
                        >
                            <span className="text-[#ED7136] text-[0.625rem]">
                                {region.name}
                            </span>
                            <AiOutlineClose
                                className="text-[10px]"
                                onClick={() => remove(region.id)}
                            />
                        </Box>
                    ))}
                </Box>
                <Box
                    row
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    gap={"1.875rem"}
                    className="mt-[5rem]"
                >
                    <Button
                        variant="icon"
                        style={{ color: "#20201F" }}
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={!isValid || creating}
                        pill
                        style={{ width: "13.75rem" }}
                    >
                        {creating ? "Processing..." : "Set Production Region"}
                    </Button>
                    {/* <Button
          onClick={(_) => {}}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
          Set Production Region
        </Button> */}
                </Box>
            </FormProvider>
        </div>
    );
};

export default SetProductionRegion;
