import cn from "classnames";
import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import s from "./index.module.css";

export interface Props {
  children?: ReactNode;
  isOpen?: boolean;
  closeToFraction?: number;
}

const Collapse: FC<Props> = ({ children, isOpen, closeToFraction = 0 }) => {
  const rootClassName = cn(s.root, {
    [s.open]: isOpen,
  });
  const ref = useRef<HTMLDivElement>(null);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  useLayoutEffect(() => {
    if (ref.current !== null) {
      // console.log("ref", ref.current.scrollHeight);
      setScrollHeight(ref.current.scrollHeight);
    }
  }, [isOpen]);
  return (
    <div
      className={rootClassName}
      style={{
        maxHeight: `${
          isOpen ? scrollHeight : closeToFraction * scrollHeight
        }px`,
      }}
      ref={ref}
      aria-expanded={isOpen}
    >
      {children}
    </div>
  );
};

export default Collapse;
