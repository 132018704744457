/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import {
  Box,
  Button,
  Text
} from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { DeleteDialog } from "@features/Utils";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {
  useEnableOrganisationMutation,
  useDisableOrganisationMutation,
  useDeleteOrganisationMutation,
  useUpdateOrganisationMutation
} from "services/system/organizations";
import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {FieldValues, useForm} from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";
import s from "./index.module.css";
import {CreateOrEditOrganization, Organisation} from "@models/query/system";
import TextAreaWrapper from "@forms/TextAreaWrapper";

type Props = {
  data: Organisation | undefined;
  error: any;
    isLoading: boolean;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);

  const { data, isLoading, error } = props;

  const organisationValidationSchema = Yup.object().shape({
    name: Yup.string().required('Organisation name is required'),
    contact: Yup.string().required('Organisation contact email/phone is required'),
    description: Yup.string().nullable()
  });

  const defaultValues: FieldValues = {
    name: '',
    contact: '',
    description: ''
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(organisationValidationSchema),
    defaultValues,
  });

  const onSubmitFinish = () => {
    reset(defaultValues);

  }

  const { handleSubmit, reset, formState: { isValid } } = methods;

  const { mutate: updateOrganisation, isLoading: creating } =
      useUpdateOrganisationMutation({
        setEdit: onSubmitFinish
      });

  const { mutate: enableOrganisation, isLoading: enabling } =
      useEnableOrganisationMutation();

  const { mutate: disableOrganisation, isLoading: disabling } =
      useDisableOrganisationMutation();

  const onSubmit = (organization: CreateOrEditOrganization) => {


  if (data) {
    updateOrganisation({
      variables: {
        input: organization,
        id: data.id
      }
    });
  }
  }

  useEffect(() => {
    if (data) reset({
        name: data.name,
        contact: data.contacts[0].value,
        description: data.description
    })
    return () =>{};
  }, [data]);


  const { mutate: deleteOrganisation, isLoading: deleting } =
      useDeleteOrganisationMutation();

  const { generateModal } = useModalContext();
  return (
    <div className={"root px-3 lg:px-0"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col items-center gap-3 lg:gap-0 header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can change the details of the organization or contact details.
            </Text>
          </Box>
          <Box row>
            {edit ? (
              <>
                <a
                  className="btn btn-md mt-5"
                  onClick={() => setEdit(false)}
                  type={'button'}
                  role={'button'}
                >Cancel</a>
                <Button
                  onClick={() => setEdit(false)}
                  variant="fill"
                  pill
                  type={'submit'}
                  className="btn-md"
                  label={"Save"}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                  label={"Edit"}
                  type={'button'}
                />
                <Button
                  variant="fill"
                  pill
                  className="!px-6"
                  type={'button'}
                  label={"Delete Organisation"}
                  onClick={() =>
                    generateModal(
                      "Delete Organization",
                      <DeleteDialog
                          prompt=" Are you sure you want to delete this organization?"
                          callback={() => data ? deleteOrganisation({
                            variables: {
                              id: data.id
                            }
                          }) : null}
                          loading={deleting}
                      />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section mb-[1.875rem]"}>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Name of Organization</Text>
            {edit ? (
              <TextWrapper
                name={"name"}
                color="primary"
                placeholder="Farmall Corp"
                className={s.input}
              />
            ) : (
              <Text className={"gi-subtitle"}>{isLoading ? 'Fetching' : data?.name}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Contact</Text>
            {edit ? (
              <TextWrapper
                  name={'contact'}
                color="primary"
                placeholder="wendytamakloe@gmail.com"
                className={s.input}
              />
            ) : (
              <Text className={"gi-subtitle"}>
                {isLoading ? 'Fetching' : (
                    <ul className="space-y-1 max-w-md list-disc list-inside text-gray-500 dark:text-gray-400">
                      {data?.contacts.map((contact) => {
                        return <li>{contact.value} {contact.isPrimary && '(primary)'}</li>
                      })}
                    </ul>
                )}
              </Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Description</Text>
            {edit ? (
              <TextAreaWrapper
                  name={'description'}
                  rows={3}
                color="primary"
                placeholder="Add description"
                className={cn(s.input, s.textArea, "bg-white")}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data?.description}</Text>
            )}
          </Box>
        </Box>
      </Box>
      <Box row className={"section no-border"}>
        <Box col className={cn("w-full", s.textBox)}>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Organization Access</Text>
            <SelectWrapper
                name={'status'}
                className={"block py-2.5 px-0 border-0 text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"}
                options={[
                  {label: 'Enabled', value: 'enable'},
                  {label: 'Disabled', value: 'disable'},
                ]}
                placeholder={"Select Status"}
                value={data?.isEnabled ? {label: 'Enabled', value: 'enable'} :  {label: 'Disabled', value: 'disable'}}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as any;
                if (data) {
                  if (value.value === 'enable') {
                    enableOrganisation({
                      variables: {
                        id: data.id
                      }
                    })
                  } else {
                    disableOrganisation({
                        variables: {
                            id: data.id
                        }
                    })
                  }
                }
                }
                }
            />

          </Box>
        </Box>
      </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
