/**
 *Project:Traceability Platform
 *File:users-table
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box } from "@components/common";
import Table from "@components/table";
import { generateInitials } from "@utils/helper";
import cn from "classnames";
import React, { useState } from "react";

import s from "./index.module.css";
import Avatar from "./avatar";

type Props = {};
const TableItem = (props: { status?: "active" | "pending" | "disabled" }) => {
  return (
    <Table.Row className={cn(s.row, s.rowLayout)}>
      <Table.Cell className={s.cell}>
        <Box row className={s.box}>
          <Avatar initials={generateInitials("Chelsea Arday")} />
          &nbsp;&nbsp;Chelsea Arday
        </Box>
      </Table.Cell>
      <Table.Cell className={s.cell}>Illegal farming methods</Table.Cell>
      <Table.Cell className={s.cell}>Illegal farming methods</Table.Cell>
    </Table.Row>
  );
};
const BlacklistTable: React.FC<Props> = () => {
  const [collapse, toggleCollapse] = useState<boolean>(true);
  return (
    <div className={s.root}>
      {/* </Box> */}
      <div className={s.tableContainer}>
        <Table className={s.table}>
          <Table.Header className={s.rowLayout}>
            <Table.Head className={s.heading}>Name</Table.Head>
            <Table.Head className={s.heading}>Category</Table.Head>
            <Table.Head className={s.heading}>Reason</Table.Head>
          </Table.Header>
          <TableItem />
          <TableItem status="disabled" />
          <TableItem status="pending" />
          <TableItem status="disabled" />
          <TableItem status="pending" />
        </Table>
      </div>
    </div>
  );
};

export default BlacklistTable;
