/**
 *Project:Traceability Platform
 *File:heading
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { CSSProperties, JSXElementConstructor, ReactNode } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { Text } from "@components/common";
type Props = {
  size?: "lg100" | "lg200" | "md100" | "md200" | "sm100" | "sm200";
  bold?: "bold600" | "bold400";
  Component?: string | JSXElementConstructor<any>;
  className?: String;
  children?: ReactNode;
} & CSSProperties;
const Heading: React.FC<Props> = (props) => {
  const {
    className,
    children,
    Component = "p",
    size = "lg100",
    bold = "bold600",
    ...rest
  } = props;
  return (
    <Text
      className={cn(className, s[bold], s[size])}
      Component={Component}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Heading;
