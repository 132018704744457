/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import Transactions from "./Transactions";
import {useParams} from "react-router-dom";
import {useClient} from "services/buyer/clients";
import {useAppSelector} from "hooks/appHooks";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = (_props) => {

  const { user } = useAppSelector((state) => state.auth);

  const { id } =  useParams();

  const {
    client,
    isLoading,
    error,
  } = useClient(user.organisation.id, id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    {
      label: "Transactions",
      name: "transactions",
    },
  ];
  return (
      <AppGuard accessibleRoles={['buyerWarehouse', 'corporateAdministrator']}>
    <DetailsWizard
      id={"client-details"}
      views={items}
      title={client?.name}
    >
      <GeneralInformation
          name="general-information"
          data={client}
          isLoading={isLoading}
          error={error}
      />
      <Transactions name="transactions" client={client} />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
