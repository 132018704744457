/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { FunctionComponent, useRef } from "react";
import { Outlet } from "react-router-dom";
import Default from "./Default";

import Details from "./Details";

type Props = {};
export const OrganizationDetails = Details;
const EducationLevel: FunctionComponent<Props> & {
  Details: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
EducationLevel.Details = Details;
EducationLevel.Default = Default;
export default EducationLevel;
