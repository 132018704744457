/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useEffect, useMemo, useState } from "react";
import s from "./index.module.css";
import { Image, Shimmer } from "react-shimmer";
import cn from "classnames";
import { Box, Text, Button, Switch } from "@components/common";
import Avatar from "@components/avatar";
import Heading from "@components/heading";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import {
    fData,
    fetchValidRoleName,
    formatDate,
    generateInitials,
    toBase64,
    validateEmail,
    validatePhoneNumber,
} from "@utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { BlacklistDialog, DeleteDialog } from "@features/Utils";
import auth from "@models/query/auth";
import FormProvider from "@forms/FormProvider";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Contact, SupportedRole, UpdateUser } from "models/query/system";
import TextWrapper from "@forms/TextWrapper";
import countryList from "react-select-country-list";
import {
    useDeleteUserMutation,
    useDisableUserMutation,
    useEnableUserMutation,
    useRemoveUserMutation,
    useSupportRoles,
    useUpdateRoleMutation,
    useUpdateUserMutation,
} from "services/system/users";
import SelectWrapper from "@forms/SelectWrapper";
import FileWrapper from "@forms/FileWrapper";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useProductionZones } from "services/buyer/goods";
import Select from "react-select";
import { useOrgWarehouses } from "services/buyer/warehouse";
import { useAppSelector } from "hooks/appHooks";
import DropZoneWrapper from "@components/forms/DropZoneWrapper";

type Props = {
    data: auth.RootObject | undefined;
    error: any;
    isLoading: boolean;
} & WizardChildRef;

const statusOptions = [
    { label: "Enabled", value: true },
    { label: "Disabled", value: false },
];

const idTypes = [
    { label: "Voter's ID", value: "Voters" },
    { label: "National ID", value: "National" },
    { label: "Passport", value: "Passport" },
    { label: "NHIS", value: "NHIS" },
    { label: "Drivers License", value: "Drivers" },
];

const MAX_FILE_SIZE = 3 * 1000 * 1000; // 3 Mb
const FILE_FORMATS = ["image/jpg", "image/jpeg", "image/webp", "image/png"];

const Information: React.FC<Props> = (props) => {
    const [edit, setEdit] = useState(false);
    // const [IDImage, setIDImage] = useState("");
    const [IDImage, setIDImage] = useState("../../image/image-2.png");
    const navigate = useNavigate();
    // const ref = useAutoAnimate({
    //     duration: 0.3,
    // });
    const ref = React.useRef(null);

    const { generateModal } = useModalContext();
    const { data, error, isLoading } = props as any;
    let { id } = useParams();

    const [selectedRegions, setSelectedRegions] = useState<
        Array<{ id: string; name: string }>
    >([]);

    const remove = (key: string) => {
        setSelectedRegions((r) => r.filter((r) => r.id !== key));
    };

    const add = (key: { id: string; name: string }) => {
        if (!selectedRegions.find((x) => x.id === key.id))
            setSelectedRegions((r) => [key, ...r]);
    };

    const userValidationSchema = Yup.object().shape({
        // firstName: Yup.string().required("First Name is Required"),
        //lastName: Yup.string().required("Last Name is Required"),
        //countryCode: Yup.string().required("Country Code is Required"),
        identificationDocumentId: Yup.string().required(
            "ID Number is Required"
        ),
        identificationType: Yup.string().required("ID Type is Required"),
        //productionZones: Yup.string().required("Production Zones is Required"),
        email: Yup.string()
            .required("Email Address is Required")
            .test(
                "validate-email",
                "Enter a valid email address",
                (value: any) => {
                    if (value) {
                        return validateEmail(value);
                    }
                    return true;
                }
            ),
        phone: Yup.string()
            .required("Phone Number is Required")
            .test(
                "validate-phone",
                "Enter a valid phone number",
                (value: any) => {
                    if (value) {
                        return validatePhoneNumber(value);
                    }
                    return true;
                }
            ),
        identificationMedia: Yup.mixed()
            .notRequired()
            .test("fileFormat", "Unsupported Format", (value) => {
                if (value && value.length > 0) {
                    return value && FILE_FORMATS.includes(value[0].type);
                }
                return true;
            })
            .test(
                "fileSize",
                `File must be less than or equal to ${fData(MAX_FILE_SIZE)}`,
                (value) => {
                    if (value && value.length > 0) {
                        return value && value[0].size <= MAX_FILE_SIZE;
                    }
                    return true;
                }
            ),
    });

    const defaultValues: FieldValues = {
        firstName: "",
        lastName: "",
        countryCode: "",
        identificationDocumentId: "",
        identificationType: "",
        email: "",
        phone: "",
        role: "",
        isEnabled: false,
        city: "",
        identificationMedia: "",
        isBlacklisted: false,
    };

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(userValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
    };

    const {
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { isValid },
    } = methods;

    const { user } = useAppSelector((state) => state.auth);
    const organisationId = user?.organisation?.id;

    const { roles, isLoading: roleLoading } = useSupportRoles({
        size: 100,
    });

    const { data: warehouses, isLoading: isLoadingWarehouses } =
        useOrgWarehouses(organisationId, {
            page: 0,
            pageSize: 999,
            search: "",
        });

    const { mutate: updateRole, isLoading: roleUpdating } =
        useUpdateRoleMutation(onSubmitFinish);

    const { mutate: updateUser, isLoading: updating } =
        useUpdateUserMutation(onSubmitFinish);

    const { mutate: disableUser, isLoading: disabling } =
        useDisableUserMutation();
    const { mutate: enableUser, isLoading: enabling } = useEnableUserMutation();
    const { mutate: removeBlacklist, isLoading: blacklist } =
        useRemoveUserMutation();
    const { mutate: deleteUser, isLoading: deleting } = useDeleteUserMutation();

    const { data: zones, isLoading: isLoadingZones } = useProductionZones({
        page: 0,
        pageSize: 999,
        search: "",
    });

    const onSubmit = (user: typeof defaultValues) => {
        let contacts: Contact[] = [];
        if (user.phone) {
            contacts.push({
                isPrimary: true,
                type: "mobile",
                value: user.phone,
            });
        }
        if (user.email) {
            contacts.push({
                isPrimary: true,
                type: "email",
                value: user.email,
            });
        }
        let payload: UpdateUser & {
            productionZoneIds: number[];
            warehouseId: number;
        } = {
            firstName: user.firstName,
            lastName: user.lastName,
            countryCode: user.countryCode,
            city: user.city,
            identificationDocumentId: user.identificationDocumentId,
            identificationType: user.identificationType,
            identificationMedia: {
                contentType: "application/json",
                fileName: "Any",
                url: IDImage,
            },
            contacts,
            productionZoneIds: selectedRegions.map((x) => Number(x.id)),
            warehouseId: user.warehouseId,
        };

        if (data && payload) {
            updateUser({
                variables: {
                    data: payload,
                    id: Number(id),
                },
            });
        }
    };

    const handleUserStatus = (id: number, action: string) => {
        if (action === "enable") {
            enableUser({
                variables: {
                    id,
                },
            });
        }
        if (action === "disable") {
            disableUser({
                variables: {
                    id,
                },
            });
        }
    };

    useEffect(() => {
        const subscription = watch((value) => {
            if (value.identificationMedia) {
                toBase64(value.identificationMedia[0])
                    .then((image: unknown) => {
                        console.log({ image });
                        setIDImage(image as string);
                    })
                    .catch(console.log);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (data) {
            // @ts-ignore
            if (
                data[fetchValidRoleName(data.role.role)]["identificationMedia"]
            ) {
                // @ts-ignore
                setIDImage(
                    data[fetchValidRoleName(data.role.role)][
                        "identificationMedia"
                    ]["url"]
                );
            }

            reset({
                firstName: data.firstName,
                lastName: data.lastName,
                countryCode: data.country?.code || "",
                // @ts-ignore
                identificationDocumentId:
                    data[fetchValidRoleName(data.role.role)][
                        "identificationDocumentId"
                    ] || "",
                // @ts-ignore
                identificationType: data[fetchValidRoleName(data.role.role)][
                    "identificationType"
                ]
                    ? data[fetchValidRoleName(data.role.role)][
                          "identificationType"
                      ]["label"]
                    : "",
                warehouseId:
                    data[fetchValidRoleName(data.role.role)]?.warehouse?.id ||
                    null,
                role: data.role.supportedUserRole,
                phone:
                    data.contacts.find((e: any) => e.type === "mobile")
                        ?.value || "",
                email:
                    data.contacts.find((e: any) => e.type !== "mobile")
                        ?.value || "",
                city: data.city,
                isBlacklisted: data.isBlacklisted || false,
                identificationMedia: "",
                isEnabled: data.isEnabled || false,
            });

            const zones = (data[fetchValidRoleName(data.role.role)]
                ?.productionZones || []) as { id: string; name: string }[];
            const warehouse =
                data[fetchValidRoleName(data.role.role)]?.warehouse;

            data.warehouseId = warehouse?.id || null;
            data.productionZones = zones;
            data.warehouseName = warehouse?.name;
            setSelectedRegions(zones);
        }
        return () => {};
    }, [data]);

    const genders = [
        { label: "Male", value: "M" },
        { label: "Female", value: "F" },
    ];

    return (
        <div className={cn(s.root, "px-3 lg:px-3")}>
            {/*@ts-ignore*/}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box
                    className={cn(
                        "section no-border flex lg:flex-row flex-col"
                    )}
                >
                    {/* @ts-ignore */}
                    <Image
                        src={data?.profileImage || ""}
                        fallback={<Shimmer height={50} width={50} />}
                        errorFallback={(props) => (
                            <Avatar
                                className={cn("", s.avatar)}
                                initials={generateInitials(
                                    `${
                                        data
                                            ? `${data.firstName} ${data.lastName}`
                                            : error && !isLoading
                                            ? "Error"
                                            : "..."
                                    }`
                                )}
                            />
                        )}
                        NativeImgProps={{
                            className: "img-sm img-avatar w-[100px] h-[100px]",
                            alt: data
                                ? `${data.firstName} ${data.lastName}`
                                : error && !isLoading
                                ? "Error"
                                : "...",
                        }}
                    />

                    <Box form className={cn("flex flex-col w-full")}>
                        <Box
                            className={
                                "items-center flex lg:flex-row flex-col gap-4 lg:gap-0"
                            }
                        >
                            <Box col className={"w-full lg:w-auto"}>
                                <Heading size="lg100" className={"text-left"}>
                                    {data
                                        ? `${data.firstName} ${data.lastName}`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Heading>
                                <Text
                                    Component={"label"}
                                    className={"gi-subtitle"}
                                >
                                    Added on{" "}
                                    {data
                                        ? `${formatDate(
                                              new Date(data.createdOn)
                                          )}`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Text>
                            </Box>

                            <Box
                                className={
                                    "flex flex-row ml-auto w-full lg:w-auto"
                                }
                            >
                                {edit ? (
                                    <>
                                        <a
                                            className="btn btn-md mt-5"
                                            onClick={() => setEdit(false)}
                                            type={"button"}
                                            role={"button"}
                                        >
                                            Cancel
                                        </a>
                                        <Button
                                            variant="fill"
                                            pill
                                            disabled={!isValid || updating}
                                            type={"submit"}
                                            className="btn-md"
                                            label={
                                                updating ? "Saving..." : "Save"
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="fill"
                                            pill
                                            className="btn-md"
                                            onClick={() =>
                                                setEdit((current) => !current)
                                            }
                                            label={"Edit User"}
                                            type={"button"}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box row className={"section !py-0"}>
                    <Box
                        className={cn(
                            "lg:mr-[13px] !hidden lg:!flex",
                            s.avatar
                        )}
                    />
                    <Box col className={cn("w-full section no-border !py-0")}>
                        <Box row className={"gi-field w-full"}>
                            <Text Component={"label"} className={s.label}>
                                ID Type
                            </Text>
                            {edit ? (
                                <SelectWrapper
                                    name={"identificationType"}
                                    className={"mb-4 input "}
                                    placeholder={"Select ID Type"}
                                    options={idTypes}
                                    value={idTypes.find(
                                        (i) =>
                                            i.value ===
                                            methods.getValues(
                                                "identificationType"
                                            )
                                    )}
                                    onChange={(
                                        newValue: unknown,
                                        actionMeta
                                    ) => {
                                        let value = newValue as any;
                                        if (data) {
                                            setValue(
                                                "identificationType",
                                                value.value
                                            );
                                        }
                                    }}
                                />
                            ) : (
                                //  @ts-ignore
                                <Text className={"gi-subtitle"}>
                                    {(data &&
                                        data[
                                            fetchValidRoleName(data.role.role)
                                        ]["identificationType"]?.label) ||
                                        "Not provided"}
                                </Text>
                            )}
                        </Box>
                        <Box row className={"gi-field w-full"}>
                            <Text Component={"label"} className={s.label}>
                                ID Number
                            </Text>
                            {edit ? (
                                <TextWrapper
                                    name={"identificationDocumentId"}
                                    color="primary"
                                    placeholder="10605488"
                                    className={s.input}
                                />
                            ) : (
                                //  @ts-ignore
                                <Text className={"gi-subtitle"}>
                                    {(data &&
                                        data[
                                            fetchValidRoleName(data.role.role)
                                        ]["identificationDocumentId"]) ||
                                        "Not provided"}
                                </Text>
                            )}
                        </Box>

                        <Box row className={"gi-field"}>
                            <Text
                                Component={"label"}
                                className={cn(s.label, "mb-auto")}
                            >
                                Photo of ID
                            </Text>
                            <Box col className={"input"}>
                                {edit ? (
                                    <Box gap={"1rem"} alignItems={"baseline"}>
                                        <DropZoneWrapper
                                            name={"identificationMedia"}
                                            render={() => (
                                                <img
                                                    alt={"holding"}
                                                    src={IDImage}
                                                />
                                            )}
                                        />
                                    </Box>
                                ) : (
                                    <>
                                        <Box
                                            row
                                            gap={"1rem"}
                                            alignItems={"baseline"}
                                        >
                                            <Text
                                                className={cn(
                                                    "gi-subtitle",
                                                    s.id,
                                                    "w-full"
                                                )}
                                            >
                                                {/*  @ts-ignore*/}
                                                {(data &&
                                                    data[
                                                        fetchValidRoleName(
                                                            data.role.role
                                                        )
                                                    ]["identificationMedia"]
                                                        ?.filename) ||
                                                    "Not provided"}
                                            </Text>
                                            <AiOutlineDelete
                                                className={"gi-subtitle"}
                                            />
                                        </Box>
                                        <Box row className={"!h-[40px]"}>
                                            <Button
                                                variant="icon"
                                                className={cn(
                                                    "ml-auto mr-3",
                                                    s.link
                                                )}
                                            >
                                                View
                                            </Button>
                                            <Button
                                                variant="icon"
                                                className={cn(s.link, "")}
                                            >
                                                Edit
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box row className={"section"}>
                    <Box
                        className={cn(
                            "lg:mr-[13px] !hidden lg:!flex",
                            s.avatar
                        )}
                    />
                    <Box col className={cn("w-full section no-border")}>
                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                Gender
                            </Text>
                            {edit ? (
                                <SelectWrapper
                                    name={"gender"}
                                    className={"mb-4 input "}
                                    placeholder={
                                        genders.find(
                                            (x) => x.value === data.gender
                                        )?.label
                                    }
                                    options={genders}
                                    isLoading={isLoading}
                                    value={genders.find(
                                        (i) =>
                                            i.value ===
                                            methods.getValues("gender")
                                    )}
                                    onChange={(
                                        newValue: unknown,
                                        actionMeta
                                    ) => {
                                        let value = newValue as any;
                                        if (data) {
                                            setValue("gender", value.value);
                                        }
                                    }}
                                />
                            ) : (
                                <Text className={"gi-subtitle"}>
                                    {data
                                        ? `${
                                              genders.find(
                                                  (x) => x.value === data.gender
                                              )?.label || "Unspecified gender"
                                          }`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Text>
                            )}
                        </Box>
                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                Phone Number
                            </Text>
                            {edit ? (
                                <TextWrapper
                                    name={"phone"}
                                    color="primary"
                                    placeholder="024xxxxxxxx"
                                    className={"mb-4"}
                                />
                            ) : (
                                <Text
                                    Component={"label"}
                                    className={"gi-subtitle"}
                                >
                                    {data
                                        ? `${
                                              data.contacts.find(
                                                  (e: any) =>
                                                      e.type === "mobile"
                                              )?.value || "No Phone Number"
                                          }`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Text>
                            )}
                        </Box>
                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                Email
                            </Text>
                            {edit ? (
                                <TextWrapper
                                    name={"email"}
                                    type={"email"}
                                    color="primary"
                                    placeholder="chelseaarday@gmail.com"
                                    className={"mb-4"}
                                />
                            ) : (
                                <Text
                                    Component={"label"}
                                    className={"gi-subtitle"}
                                >
                                    {data
                                        ? `${
                                              data.contacts.find(
                                                  (e: any) =>
                                                      e.type !== "mobile"
                                              )?.value || "No Email Address"
                                          }`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Text>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box row className={"section no-border flex flex-col"}>
                    <Box
                        className={cn(
                            "lg:mr-[13px] !hidden lg:!flex",
                            s.avatar
                        )}
                    />
                    <Box
                        col
                        className={cn(
                            "w-full section no-border flex flex-col",
                            s.textBox
                        )}
                    >
                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                Station
                            </Text>
                            {edit ? (
                                <SelectWrapper
                                    name={"warehouseId"}
                                    className={"mb-4 input "}
                                    placeholder={"Select Warehouse"}
                                    isLoading={isLoading}
                                    options={warehouses.map((x) => ({
                                        ...x,
                                        label: x.name,
                                        value: x.id,
                                    }))}
                                    value={warehouses
                                        .map((x) => ({
                                            ...x,
                                            label: x.name,
                                            value: x.id,
                                        }))
                                        .find(
                                            (i) =>
                                                i.value ===
                                                methods.getValues("warehouseId")
                                        )}
                                    onChange={(
                                        newValue: unknown,
                                        actionMeta
                                    ) => {
                                        let value = newValue as any;
                                        if (value) {
                                            setValue(
                                                "warehouseId",
                                                value.value
                                            );
                                        }
                                    }}
                                />
                            ) : (
                                <Text className={"gi-subtitle"}>
                                    {data
                                        ? `${
                                              data.warehouseName ||
                                              "No warehouse"
                                          }`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Text>
                            )}
                        </Box>

                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                Production Zones
                            </Text>
                            {edit ? (
                                <SelectWrapper
                                    className={"mb-4 input "}
                                    name={"productionZones"}
                                    required
                                    placeholder={"Select zones"}
                                    options={zones}
                                    isLoading={isLoadingZones}
                                    loadingMessage={() =>
                                        "Fetching supported production zones..."
                                    }
                                    getOptionValue={(option: any) => option.id}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    value={zones.find(
                                        (role: any) =>
                                            role.name ===
                                            methods.getValues("productionZones")
                                    )}
                                    onChange={(
                                        newValue: unknown,
                                        actionMeta
                                    ) => {
                                        const value = newValue as {
                                            id: string;
                                            name: string;
                                        };

                                        if (value) {
                                            setValue(
                                                "productionZones",
                                                value.id
                                            );
                                            add(value);
                                        }
                                    }}
                                />
                            ) : (
                                <Text className={"gi-subtitle"}>
                                    {data
                                        ? `${
                                              data?.productionZones
                                                  ? data.productionZones
                                                        .map(
                                                            (x: {
                                                                id: number;
                                                                name: string;
                                                            }) => x.name
                                                        )
                                                        .join(",")
                                                  : null ||
                                                    "No production zones"
                                          }`
                                        : error && !isLoading
                                        ? "Error"
                                        : "..."}
                                </Text>
                            )}
                        </Box>

                        {edit ? (
                            <Box
                                ref={ref as any}
                                className={
                                    "flex flex-row mt-[0.5rem] gap-[0.625rem] flex-wrap"
                                }
                            >
                                {selectedRegions.map((region, index) => (
                                    <Box
                                        display={"flex"}
                                        key={index}
                                        className={
                                            " flex-row !h-[1.1875rem] gap-[0.7937rem] px-[0.625rem] rounded-[0.1875rem] items-center bg-[#FFDBCA]"
                                        }
                                    >
                                        <span className="text-[#ED7136] text-[0.625rem]">
                                            {region.name}
                                        </span>
                                        <AiOutlineClose
                                            className="text-[10px]"
                                            onClick={() => remove(region.id)}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        ) : null}

                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                Role
                            </Text>

                            <SelectWrapper
                                name={"role"}
                                className={"mb-4 input "}
                                placeholder={"Select user role"}
                                options={roles.filter(
                                    (x) => x.name !== "systemAdministrator"
                                )}
                                isLoading={isLoading}
                                loadingMessage={() =>
                                    "Fetching supported roles..."
                                }
                                getOptionValue={(option: any) => option.name}
                                getOptionLabel={(option: any) =>
                                    option.humanizedName
                                }
                                value={roles.find(
                                    (role: any) =>
                                        role.name === methods.getValues("role")
                                )}
                                onChange={(newValue: unknown, actionMeta) => {
                                    let value = newValue as SupportedRole;
                                    if (data) {
                                        updateRole({
                                            variables: {
                                                role: value.name,
                                                id: data.id,
                                            },
                                        });
                                        setValue("role", value.name);
                                    }
                                }}
                            />
                        </Box>
                        <Box row className={"gi-field"}>
                            <Text Component={"label"} className={s.label}>
                                User Access
                            </Text>
                            <SelectWrapper
                                name={"isEnabled"}
                                className={"mb-4 input "}
                                placeholder={"Select User Access"}
                                options={statusOptions}
                                isLoading={isLoading}
                                value={statusOptions.find(
                                    (i) =>
                                        i.value ===
                                        methods.getValues("isEnabled")
                                )}
                                onChange={(newValue: unknown, actionMeta) => {
                                    let value = newValue as any;
                                    if (data) {
                                        if (value.value === false) {
                                            handleUserStatus(
                                                data.id,
                                                "disable"
                                            );
                                        }
                                        if (value.value === true) {
                                            handleUserStatus(data.id, "enable");
                                        }
                                        setValue("isEnabled", value.value);
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </FormProvider>
        </div>
    );
};

export default Information;
