/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useState} from "react";

import cn from "classnames";
import {Box, Text} from "@components/common";
import Heading from "@components/heading";
import {WizardChildRef} from "@components/common/wizard";

import "@styles/forms.css";
import {useUsers} from "services/system/users";
import {ROLES} from "models/query/system";
import {TableColumn, TablePagination} from "@models/store/table";
import auth from "@models/query/auth";
import Avatar from "@components/avatar";
import {Link} from "react-router-dom";
import Table from "@components/table/Table";

type Props = {
  id: string;
} & WizardChildRef;

const Farmers: React.FC<Props> = (props) => {
  const { id } = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const {
    users,
    paginatorInfo,
    isLoading,
    error,
    loadNext,
    loadPrevious
  } = useUsers({
    page,
    pageSize,
    role: ROLES.FARMER,
    educationLevel: id
  });

  const Pagination: TablePagination | false  = paginatorInfo ? {
    count: paginatorInfo.totalCount,
    pageSize: paginatorInfo.pageSize,
    page: paginatorInfo.page,

  } : false;

  const Columns: Array<TableColumn> = [
    {
      key: "firstName",
      title: "Name",
      render: (text: string, record: auth.RootObject) => (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {record.profileImage ? (
                  <img
                      className="h-10 w-10 rounded-full"
                      src={record.profileImage}
                      alt=""
                  />
              ) : (
                  <Avatar
                      initials={record.firstName[0] + record.lastName[0]}
                      color={"green"}
                  />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                <Link to={record.id.toString()} className={'nav-link font-bold'}>
                  {record.firstName} {record.lastName}
                </Link>
              </div>
            </div>
          </div>
      ),
    },
    {
      key: "contacts",
      title: "Contact",
      render: (text: auth.Contact3[], record: auth.RootObject) => (
          <div className="text-sm text-gray-500">
            {record.contacts.map((contact, index) => (
                <div key={index}>
                  {contact.value}
                  {contact.isPrimary && (
                      <span className="text-xs text-gray-400"> (Primary)</span>
                  )}
                </div>
            ))}
          </div>
      ),
    },
  ];
  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full")}>
        <Box col className={"mb-[4.0625rem]"}>
          <Heading size="lg100" className={"text-left"}>
            Farmers
          </Heading>
          <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
            You can view the farmers under this education Level
          </Text>
        </Box>

        <Table
            tableClassName={'table-hover'}
            loading={isLoading}
            columns={Columns}
            dataSource={users}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={'No farmers with this education level are available'}
        />
      </Box>
    </div>
  );
};

export default Farmers;
