import cn from "classnames";
import React, {
  HTMLAttributes,
  useRef,
  useEffect,
  ReactNode,
  FunctionComponent,
  useState,
} from "react";

import s from "./index.module.css";
import Button from "../button";
import { AiOutlineClose } from "react-icons/ai";
import ClickOutside from "../click-outside";
type Props = {
  className?: string;
  variant?: "dropdown" | "search" | "default";
  size?: "lg" | "md" | "sm";
  show?: boolean;
  toggle?: Function;
  children?: ReactNode;
};
type ToggleProps = {
  className?: string;
  // className?: string;
  // variant?: "dropdown" | "search" | "default";
  // size?: "lg" | "md" | "sm";
  show?: boolean;
  toggle?: Function;
  // x?: "left" | "left-half" | "right" | "right-half" | "center";
  children?: ReactNode;
};
type MenuProps = {
  className?: string;
  variant?: "dropdown" | "search" | "default";
  size?: "lg" | "md" | "sm";
  show?: boolean;
  toggle?: Function;
  x?: "left" | "left-half" | "right" | "right-half" | "center";
  children?: ReactNode;
};
type ItemProps = {
  className?: string;
  toggle?: Function;
  show?: boolean;
} & HTMLAttributes<HTMLLIElement>;

type Child = React.ReactElement & {
  show: boolean;
  toggle: Function;
};

const Dropdown: FunctionComponent<Props> & {
  Toggle: FunctionComponent<ToggleProps>;
  Menu: FunctionComponent<MenuProps>;
  Item: FunctionComponent<ItemProps>;
} = ({ children, className }) => {
  // const  = props;
  const [showDropdown, toggleDropdown] = useState(false);
  const rootClassName = cn(s.root, className);
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child as Child, {
        toggle: toggleDropdown,
        show: showDropdown,
        ...child.props,
      });
    }
    return child;
  });
  return (
    <ClickOutside
      active={showDropdown || false}
      onClick={() => {
        if (toggleDropdown) {
          toggleDropdown(false);
        }
      }}
    >
      <div className={rootClassName}>{childrenWithProps}</div>
    </ClickOutside>
  );
};
const Toggle: FunctionComponent<ToggleProps> = ({ children, toggle, show }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as { onClick: Function } & React.ReactElement,
        {
          onClick: () => toggle && toggle(!show),
          show,
          ...child.props,
        }
      );
    }
    return child;
  });
  return <>{childrenWithProps}</>;
};
const Menu: FunctionComponent<MenuProps> = ({
  children,
  className,
  variant,
  size,
  toggle,
  show,
  x = "center",
}) => {
  const ref = useRef(null);
  useEffect(() => {}, [ref]);
  const rootClassName = cn(
    s.menu,
    {
      [s.lg]: size === "lg",
      [s.md]: size === "md",
      [s.sm]: size === "sm",
      [s.dropdown]: variant === "dropdown",
      [s.search]: variant === "search",
      [s.default]: variant === "default",
      [s.open]: show,
      [s.center]: x == "center",
      [s.left]: x == "left",
      [s.right]: x == "right",
      [s.leftHalf]: x == "left-half",
      [s.rightHalf]: x == "right-half",
    },
    className
  );
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child as Child, {
        toggle,
        show,
        ...child.props,
      });
    }
    return child;
  });

  return (
    <div ref={ref} className={rootClassName}>
      <Button
        className={s.closeButton}
        color="primary"
        variant="icon"
        onClick={() => toggle && toggle(false)}
      >
        <AiOutlineClose />
      </Button>
      <ul className={s.menuList}>{childrenWithProps}</ul>
    </div>
  );
};
function Item({ children, className, toggle, show, ...rest }: ItemProps) {
  const rootClassName = cn(s.item, className);
  const handleClick = (event: any) => {

    // onClick && onClick(event);
    toggle && toggle(false);
  };
  return (
    <li className={rootClassName} onClick={handleClick} {...rest}>
      {children}
    </li>
  );
}
Dropdown.Toggle = Toggle;
Dropdown.Menu = Menu;
Dropdown.Item = Item;
export default Dropdown;
