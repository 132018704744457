import React, { useEffect, useState } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { ClickOutside, Collapse } from "@components/common";
import AccordionCard from "../accordion-card";
import { BsChevronDown } from "react-icons/bs";
import { boolean } from "yup";

type AccordionProps = {
  className?: string;
  expand?: boolean;
  // Body?: React.ReactNode;
  // Header?: React.ReactNode;
  children?: React.ReactNode;
} & { Body: AccordionBodyProps; Header: AccordionHeaderProps };

type AccordionHeaderProps = {
  className?: string;
  show?: boolean;
  toggle?: Function;
  children?: React.ReactNode;
};
type AccordionBodyProps = {
  className?: string;
  show?: boolean;
  toggle?: Function;
  children?: React.ReactNode;
};
type Child = React.ReactElement & {
  show: boolean;
  toggle: Function;
};

const Accordion = (props: AccordionProps) => {
  const { children, className, expand = false, ...rest } = props;
  const [show, toggleAccordion] = useState(expand);
  // useEffect(() => {
  //   toggleAccordion(expand);
  //   // console.log("notebook.context", expand);
  // }, [expand]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // const childClone: Child = child;
      return React.cloneElement(child as Child, {
        toggle: toggleAccordion,
        show: show,
      });
    }
    return child;
  });
  return (
    <ClickOutside active={show} onClick={() => toggleAccordion(false)}>
      <div className={cn(s.root, className)} {...rest}>
        {childrenWithProps}
      </div>
    </ClickOutside>
  );
};
const Header: React.FC<AccordionHeaderProps> = (props) => {
  const { children, toggle, show, className, ...rest } = props;
  return (
    <div
      {...rest}
      data-expand={`${show}`}
      onClick={() => {
        // console.log("dashboard.announcement.accordion", props);
        toggle && toggle(!show);
      }}
      className={cn(s.header, className)}
    >
      {children}
      <BsChevronDown data-expand={`${show}`} className={s.caret} />
    </div>
  );
};
const Body: React.FC<AccordionBodyProps> = (props) => {
  const { show, className, toggle, children } = props;
  return (
    <Collapse isOpen={show}>
      <div data-expand={`${show}`} className={cn(s.body, className)}>
        {children}
      </div>
    </Collapse>
  );
};
Accordion.Body = Body;
Accordion.Header = Header;
export default Accordion;
