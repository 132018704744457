import React, { useEffect, useState } from "react";
import TopBar from "@layout/app/partials/top-bar";
import SetProductionRegion from "@forms/buyer/SetProductionRegion";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import { Box, Button } from "@components/common";
import cn from "classnames";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import { AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import Table from "@components/table/Table";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "@layout/app/context";
import { FieldValues, useForm } from "react-hook-form";
import {
  useDeleteRegionMutation,
  useOrganizationProductionRegion,
  useProductionRegions,
} from "services/buyer/goods";
import { TableColumn, TablePagination } from "@models/store/table";
import { ProductionRegion } from "@models/query/buyer";
import u from "@features/tables/system/users-table/index.module.css";
import { DeleteDialog } from "@features/Utils";
import { useAppSelector } from "hooks/appHooks";

type Props = {};

const Default: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const { generateModal } = useModalContext();

  const defaultValues: FieldValues = {
    search: "",
  };
  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;

  const { user } = useAppSelector((state) => state.auth);
  const organizationId = user?.organisation?.id;

    const {
      data: regions,
      paginatorInfo,
      isLoading,
      error,
      loadNext,
      loadPrevious
  } = useOrganizationProductionRegion(organizationId, {
      page,
      pageSize,
      search: searchTerm,
  });

  const { mutate: deleteRegion, isLoading: deleting } =
    useDeleteRegionMutation();

  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  const handleClick = (id: number) => {
    // navigate(`${id}`);
    console.log(id)
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      handleSearch(value.search);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const Pagination: TablePagination | false = paginatorInfo
    ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,
      }
    : false;

  const Columns: Array<TableColumn> = [
    {
      key: "name",
      title: "Production Region",
      onClick: (reg: ProductionRegion) => handleClick(reg.id),
      render: (text: string, record: ProductionRegion) => (
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{text}</div>
          </div>
        </div>
      ),
    },
    {
      key: "id",
      title: "",
      className: "text-end",
      render: (text: string, record: ProductionRegion) => (
        <Box row className={cn(u.box, u.actions)}>
          <Button
            variant="icon"
            className="!hidden lg:!flex"
            onClick={() =>
              generateModal(
                "Delete Production Region",
                <DeleteDialog
                  prompt={
                    "Are you sure you want to delete this Production Region?"
                  }
                  callback={() =>
                    deleteRegion({
                      variables: {
                        productionRegionId: record.id,
                        orgId: organizationId
                      },
                    })
                  }
                  loading={deleting}
                />,
                "center"
              )
            }
          >
            <AiOutlineDelete />
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <AppGuard accessibleRoles={["corporateAdministrator"]}>
      <div className={""}>
        <TopBar
          title="Production Region"
          modalTitle={"Set Production Region"}
          renderForm={<SetProductionRegion />}
          addButtonLabel={"Set Production Region"}
        />
        <FormProvider methods={methods} onSubmit={() => {}}>
          <Box row alignItems={"center"} className={cn(s.search, "mb-4")}>
            <TextWrapper
              name={"search"}
              className={s.input}
              variant="borderless"
              placeholder={"Search"}
            />
            <AiOutlineSearch />
          </Box>
        </FormProvider>
        <Table
          tableClassName={"table-hover"}
          loading={isLoading}
          columns={Columns}
          dataSource={regions}
          pagination={Pagination}
          pageSize={pageSize}
          loadNext={loadNext}
          loadPrevious={loadPrevious}
          setPage={setPage}
          page={page}
          error={error}
          emptyText={"No production region available"}
        />
      </div>
    </AppGuard>
  );
};

export default Default;
