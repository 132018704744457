import React, { useState } from "react";
import cn from "classnames";
import { Box, Textfield, Text, Button, TextArea, Checkbox } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
import { Warehouse } from "@models/query/system";
import {
  useDeleteWarehouseMutation,
  useUpdateWarehouseMutation,
} from "services/buyer/warehouse";
type Props = { data: Warehouse } & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [data, setData] = useState<{ default: Warehouse; editable: Warehouse & { location?: string } }>(
    { default: props.data, editable: props.data }
  );
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();

  const { mutate: deleteWarehouse, isLoading: deleting } =
    useDeleteWarehouseMutation(() => props.goToStep && props.goToStep(1));


  const { mutate: updateWarehouse, isLoading: updating } =
    useUpdateWarehouseMutation({ setEdit });

  const handleChange = (evnt: any) => {
    
    if (evnt) {
      const name = evnt.target.name;
      const value = evnt.target.value;

      if (name && value) {
        if(name === 'location'){
          //@ts-ignore
          setData((prev) => ({ ...prev, editable: { ...prev.editable, address: {...prev.editable.address, streetAddress: value} },}));
          return
        }
        setData((prev) => ({
          ...prev,
          editable: { ...prev.editable, [name]: value },
        }));
      }

      if (!name && typeof(value) == 'string') {
        setData((prev) => ({
          ...prev,
          editable: { ...prev.editable, description: value },
        }));
      }

    }
  };

  const handleSubmit = () => {

    const organisationId = data.editable.operatedBy.id;

    const address = {
      ...data.editable.address,
      streetAddress: data.editable.address?.streetAddress,
      cityOrTown: "cityOrTown",
      regionOrState: "regionOrState",
      country: "Ghana",
    };

    const type = { id: 38 };

    const operatedBy = {
      id: Number(organisationId),
    };

    const ownedBy = {
      id: Number(organisationId),
    };

    const contacts = data.editable?.contacts || [];

    const payload = {
      id: data.default.id,
      name: data.editable.name,
      description: data.editable.description,
      address,
      isOperational: true,
      isFactoryWarehouse: data.editable.isFactoryWarehouse,
      type,
      contacts,
      operatedBy,
      ownedBy
    };

    console.log({ payload });

    if (data.default.id) {
      updateWarehouse({
        variables: {
          input: payload as any,
          warehouseId: data.default.id,
          organisationId: String(organisationId)
        }
      })
    }

  };

  return (
    <div className={"root px-4 lg:px-4"}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between header"
          }
        >
          <Box col>
            <Heading size="lg100" bold="bold600" className={"text-left"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle max-w-[29.0625rem] text-left")}>
              You can change the details of the basic commodity
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => {
                    setData((prev) => ({
                      ...prev,
                      editable: { ...prev.default },
                    }));
                    setEdit(false);
                  }}
                  label="Cancel"
                />

                <Button
                  onClick={handleSubmit}
                  variant="fill"
                  pill
                  disabled={updating}
                  className="btn-md"
                  label={"Save"}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  label={"Edit"}
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                />

                <Button
                  variant="fill"
                  pill
                  label={"Delete Warehouse"}
                  className="lg:btn-lg btn-md"
                  onClick={() =>
                    generateModal(
                      "Delete Warehouse",
                      <DeleteDialog
                        prompt={
                          "Are you sure you want to delete this warehouse?"
                        }
                        callback={() =>
                          deleteWarehouse({
                            variables: {
                              warehouseId: data.editable.id,
                              organisationId: String(
                                data.editable.operatedBy.id
                              ),
                            },
                          })
                        }
                        loading={deleting}
                      />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section no-border"}>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Name of Warehouse
            </Text>
            {edit ? (
              <Textfield
                color="primary"
                name="name"
                placeholder={data.editable.name}
                value={data.editable.name}
                onChange={handleChange}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.name}</Text>
            )}
          </Box>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Location
            </Text>
            {edit ? (
              <Textfield
                color="primary"
                name="location"
                placeholder={data.editable.address?.streetAddress}
                value={data.editable.address?.streetAddress}
                onChange={handleChange}
              />
            ) : (
              <Text className={"gi-subtitle"}>
                {data.editable.address?.streetAddress}
              </Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Warehouse Description
            </Text>
            {edit ? (
              <TextArea
                color="primary"
                name="description"
                rows={5}
                placeholder={data.editable.description}
                value={data.editable.description}
                onChange={handleChange}
              />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.description}</Text>
            )}
          </Box>

          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Is a factory warehouse?
            </Text>
            {edit ? (
              <Box row gap={"0.5rem"} >
                <input
                  type='checkbox'
                  style={{ width: '20px !important' }}
                  name='isFactoryWarehouse'
                  // checked={isFactoryWarehouse}
                  onChange={(event) => {
                    setData((prev) => ({
                      ...prev,
                      editable: { ...prev.editable, isFactoryWarehouse: event.target.checked },
                    }));
                  }}
                />
                Is a factory warehouse?
              </Box>
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.isFactoryWarehouse ? 'Yes' : 'No'}</Text>
            )}
          </Box>

        </Box>
      </Box>
    </div>
  );
};

export default GeneralInformation;
