/**
 * Project: tracebility-dashboard
 * File: commodities
 * Created by pennycodes on 03/12/2022.
 * Copyright tracebility-dashboard
 */
import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import client from './index';
import {
    QueryOptionsTwo,
    BasicCommoditiesPaginator,
    CreateOrEditBasicCommodity,
    RegionalCommoditiesPaginator,
    CreateOrEditRegionalCommodity, BasicRegionalCommoditiesPaginator
} from "models/query/system";
import ApiConfig from "services/endpoints";
import {toast} from "react-toastify";


//BASIC COMMODITIES
export function useBasicCommodities(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<BasicCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.BASIC.ALL, options],
        ({ queryKey, pageParam }) =>
            client.basicCommodities.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        commodities: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useBasicRegionCommodities(id: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<BasicRegionalCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.BASIC.REGIONAL, options],
        ({ queryKey, pageParam }) =>
            client.basicCommodities.commodities(id,Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        commodities: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useBasicCommodity(id: string) {
    const { data, isLoading, error } = useQuery<BasicCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.BASIC.DETAILS, id],
        () => client.basicCommodities.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        commodity: data?.data[0],
        isLoading,
        error,
    };
}

export interface IBasicCommodityIDVariable {
    variables: {
        id: number;
    }
}

export const useDeleteBasicCommodityMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IBasicCommodityIDVariable) =>
            client.basicCommodities.delete(id),
        {
            onSuccess: () => {
                toast.success('Basic Commodity removed successfully');
                reset && reset();
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured while deleting basic commodity'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.BASIC.ALL);
            },
        }
    );
}

export interface IBasicCommodityUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditBasicCommodity;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateBasicCommodityMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit} = props
    return useMutation(
        ({ variables: { id, input } }: IBasicCommodityUpdateVariables) =>
            client.basicCommodities.update(id, input),
        {
            onSuccess: () => {
                toast.success('Basic Commodity updated successfully');
                setEdit(false)
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.BASIC.ALL);
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.BASIC.DETAILS);
            },
        }
    );
};

export interface IBasicCommodityCreateVariables {
    variables: { input: CreateOrEditBasicCommodity };
}

export const useCreateBasicCommodityMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IBasicCommodityCreateVariables) =>
            client.basicCommodities.create(input),
        {
            onSuccess: () => {
                toast.success('Basic Commodity created successfully');
                reset()
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.BASIC.ALL);
            },
        }
    );
};


// REGIONAL COMMODITIES

export function useRegionalCommodities(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<RegionalCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.REGIONAL.ALL, options],
        ({ queryKey, pageParam }) =>
            client.regionalCommodities.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({  page, pageSize, totalCount }) =>
            {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        commodities: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useRegionalCommodity(id: string) {
    const { data, isLoading, error } = useQuery<RegionalCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.REGIONAL.DETAILS, id],
        () => client.regionalCommodities.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        commodity: data?.data[0],
        isLoading,
        error,
    };
}

export interface IRegionalCommodityIDVariable {
    variables: {
        id: number;
    }
}

export const useDeleteRegionalCommodityMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: IRegionalCommodityIDVariable) =>
            client.regionalCommodities.delete(id),
        {
            onSuccess: () => {
                toast.success('Regional Commodity removed successfully');
                reset && reset();
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.REGIONAL.ALL);
            },
        }
    );
}

export interface IRegionalCommodityUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditRegionalCommodity;
    }
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export const useUpdateRegionalCommodityMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit} = props
    return useMutation(
        ({ variables: { id, input } }: IRegionalCommodityUpdateVariables) =>
            client.regionalCommodities.update(id, input),
        {
            onSuccess: () => {
                toast.success('Regional Commodity updated successfully');
                setEdit(false)
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.REGIONAL.ALL);
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.REGIONAL.DETAILS);
            },
        }
    );
}

export interface IRegionalCommodityCreateVariables {
    variables: { input: CreateOrEditRegionalCommodity };
}

export const useCreateRegionalCommodityMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IRegionalCommodityCreateVariables) =>
            client.regionalCommodities.create(input),
        {
            onSuccess: () => {
                toast.success('Regional Commodity created successfully');
                reset()
            },
            onError: (error: any) => {
                const response = error?.response?.data?.message || 'An error occured'
                toast.error(response);
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.REGIONAL.ALL);
            },
        }
    );
}
