/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import PasswordReset from "./password-reset";
import RecordedGoals from "./recorded-goals";
import Information from "./information";
import DetailsWizard from "@features/detail-wizard";
import { useLocation, useParams } from "react-router-dom";
import { CreateInvitation } from "@models/query/buyer";
import { useUser } from "services/buyer/users";

type Props = {};

const Details: React.FC<Props> = () => {
  //const user = useLocation().state as CreateInvitation;
  const { id } =  useParams();
  const {
    user,
    isLoading,
    error,
  } = useUser(id as string);
  
  const items: Array<{ label: string; name: string }> = [
    {
      label: "Personal Information",
      name: "personal-information",
    },
    // {
    //   label: "Recorded Goals",
    //   name: "recorded-goals",
    // },
    {
      label: "Security",
      name: "reset-password",
    },
  ];

  return (
    <DetailsWizard id={"user-details"} views={items} title={`${user?.firstName} ${user?.lastName}`}>
      <Information name="personal-information"  data={user} error={error}  isLoading={isLoading} />
      {/* <RecordedGoals name="recorded-goals" /> */}
      <PasswordReset name={"reset-password"} />
    </DetailsWizard>
  );
};

export default Details;
