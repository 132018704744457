import React, { useEffect, useState } from "react";
import TopBar from "@layout/app/partials/top-bar";
import SetProductionRegion from "@forms/buyer/SetProductionRegion";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import { Box, Button } from "@components/common";
import cn from "classnames";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import { AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import Table from "@components/table/Table";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "@layout/app/context";
import { FieldValues, useForm } from "react-hook-form";
import {
  useAvailableGoods,
  useDeleteGoodMutation,
  useDeleteRegionMutation,
  useRemoveOrgCommodityMutation,
} from "services/buyer/goods";
import { TableColumn, TablePagination } from "@models/store/table";
import { IAvailableGoods, ProductionRegion } from "@models/query/buyer";
import u from "@features/tables/system/users-table/index.module.css";
import { DeleteDialog } from "@features/Utils";
import AddRecordGoodPurchase from "@components/forms/buyer/AddRecordGoodPurchase";
import { useAppSelector } from "hooks/appHooks";

type Props = {};

const Default: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);
  const { generateModal } = useModalContext();

  const defaultValues: FieldValues = {
    search: "",
  };
  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;

  const { goods, paginatorInfo, isLoading, error, loadNext, loadPrevious } =
    useAvailableGoods({
      page,
      pageSize,
      search: searchTerm,
    });

  const { mutate: deleteGood, isLoading: deleting } =
  useDeleteGoodMutation();

  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  const handleClick = (id: number, data: IAvailableGoods | string = '') => {
    navigate(`${id}`, { state: data });
  };

  useEffect(() => {
    const subscription = watch((value: any) => {
      handleSearch(value.search);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const organisationId = user?.organisation?.id;

  const Pagination: TablePagination | false = paginatorInfo
    ? {
      count: paginatorInfo.totalCount,
      pageSize: paginatorInfo.pageSize,
      page: paginatorInfo.page,
    }
    : false;

  const Columns: Array<TableColumn> = [
    {
      key: "ss",
      title: "Items No",
      onClick: (reg: IAvailableGoods) => handleClick(reg.id, reg),
      render: (text: string, record: IAvailableGoods) => (
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{record.id}</div>
          </div>
        </div>
      ),
    },
    {
      key: "regionalCommodity",
      title: "Items",
      onClick: (reg: IAvailableGoods) => handleClick(reg.id, reg),
      render: (text: {id: number, name: string}, record: IAvailableGoods) => {
        return (
          <div className="flex items-center">
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">{text?.name}</div>
            </div>
          </div>
        )
      },
    },
    {
      key: "provider",
      title: "Seller",
      onClick: (reg: IAvailableGoods) => handleClick(reg.id, reg),
      render: (text: string, record: IAvailableGoods) => (
        <div className="text-sm text-gray-500">{text}</div>
      ),
    },
    {
      key: "location",
      title: "Location",
      onClick: (reg: IAvailableGoods) => handleClick(reg.id, reg),
      render: (text: string, record: IAvailableGoods) => (
        <div className="text-sm text-gray-500">{text}</div>
      ),
    },
    {
      key: "id",
      title: "",
      className: "text-end",
      render: (text: string, record: IAvailableGoods) => (
        <Box row className={cn(u.box, u.actions)}>
          <Button
            variant="icon"
            className="!hidden lg:!flex"
            onClick={() =>
              generateModal(
                "Delete Goods",
                <DeleteDialog
                  prompt={
                    "Are you sure you want to delete this goods?"
                  }
                  callback={() =>
                    deleteGood({
                      variables: {
                        id: record.id
                      },
                    })
                  }
                  loading={deleting}
                />,
                "center"
              )
            }
          >
            <AiOutlineDelete />
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <AppGuard accessibleRoles={["corporateAdministrator"]}>
      <div className={""}>
        <TopBar
          // title="Production Region"
          // modalTitle={"Set Production Region"}
          // renderForm={<SetProductionRegion />}
          // addButtonLabel={"Set Production Region"}
          title="Available Goods"
          addButtonLabel={"New Record"}
          modalTitle={"Record Goods for \n Purchase"}
          renderForm={<AddRecordGoodPurchase />}
        />
        <FormProvider methods={methods} onSubmit={() => { }}>
          <Box row alignItems={"center"} className={cn(s.search, "mb-4")}>
            <TextWrapper
              name={"search"}
              className={s.input}
              variant="borderless"
              placeholder={"Search"}
            />
            <AiOutlineSearch />
          </Box>
        </FormProvider>
        <Table
          tableClassName={"table-hover"}
          loading={isLoading}
          columns={Columns}
          dataSource={goods}
          pagination={Pagination}
          pageSize={pageSize}
          loadNext={loadNext}
          loadPrevious={loadPrevious}
          setPage={setPage}
          page={page}
          error={error}
          emptyText={"No goods available"}
        />
      </div>
    </AppGuard>
  );
};

export default Default;
