/**
 * Project: tracebility-dashboard
 * File: SelectWrapper
 * Created by pennycodes on 08/11/2022.
 * Copyright tracebility-dashboard
 */

import React from "react";


import { useFormContext, Controller } from 'react-hook-form';
import ErrorWrapper from "./ErrorWrapper";
import Select, {SelectProps} from "components/select";


interface SelectWrapperProps extends SelectProps {
    name: string;
}
const SelectWrapper: React.FC<SelectWrapperProps> = (props) => {
    const { control } = useFormContext();
    const { name, ...rest } = props


    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <>
                    <Select {...field}  {...rest}  />
                    <ErrorWrapper state={!!error} message={error?.message}  />
                </>
            )}
        />
    );
}

export default SelectWrapper
