/**
 *Project:Traceability Platform
 *File:avatar
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";

type Props = {
  color?: "purple" | "red" | "green" | "yellow" | "blue" | "none";
  initials?: string;
};

const Avatar: React.FC<Props> = (props) => {
  const { initials, color = "green" } = props;
  return (
    <div className={s.root} data-color={`${color}`}>
      {initials}
    </div>
  );
};

export default Avatar;
