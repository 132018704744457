/**
 * Project: tracebility-dashboard
 * File: CustomRouter
 * Created by pennycodes on 11/01/2023.
 * Copyright tracebility-dashboard
 */
import React, { FC, PropsWithChildren } from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import {logOut} from "store/actions/authActions";
import {store} from "store";

const history = createBrowserHistory({ window });

const CustomRouter: FC<PropsWithChildren> = ({ children, ...props }) => {
    return (
        <HistoryRouter history={history as any} {...props}>
            {children}
        </HistoryRouter>
    );
};

export const appNavigate = (to: string) => {
    history.push(to);
};

export const signOut = () => {
    store.dispatch(logOut());
    history.push('/auth/login');
};

export default CustomRouter;
