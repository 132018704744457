/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import PasswordReset from "./password-reset";
import Organization from "./organization";
import Information from "./information";
import DetailsWizard from "@features/detail-wizard";
import {useParams} from "react-router-dom";
import {useUser} from "services/system/users";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = () => {

 const { id } =  useParams();


  const {
    user,
    isLoading,
    error,
  } = useUser(id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "Personal Information",
      name: "personal-information",
    },
    {
      label: "Organization",
      name: "organization",
    },
    {
      label: "Security",
      name: "reset-password",
    },
  ];

  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard id={"user-details"} views={items} title={`${user?.firstName || '...'} ${user?.lastName || '...'}`}>
      <Information
          name="personal-information"
          data={user}
          error={error}
          isLoading={isLoading}
      />
      <Organization
          name="organization"
      />
      <PasswordReset
          name={"reset-password"}
      />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
