/**
 *Project:Traceability Platform
 *File:add-organization
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React from "react";
import {FieldValues, useForm} from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import {useCreateOrganisationMutation} from "services/system/organizations";

import s from "./index.module.css";
import {CreateOrEditOrganization} from "@models/query/system";
import TextAreaWrapper from "@forms/TextAreaWrapper";

type Props = {};

const AddOrganization: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();



    const organisationValidationSchema = Yup.object().shape({
        name: Yup.string().required('Organisation name is required'),
        contact: Yup.string().required('Organisation contact email/phone is required'),
        description: Yup.string()
    });

    const defaultValues: FieldValues = {
        name: "",
        contact: "",
        description: ""
    };

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(organisationValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, formState: { isValid } } = methods;

    const { mutate: createOrganisation, isLoading: creating } =
        useCreateOrganisationMutation(onSubmitFinish);

    const onSubmit = (organization: CreateOrEditOrganization) => {


        createOrganisation({
            variables: {
                input: organization
            }
        });
    }

    return (
    <div className="flex flex-col w-full pb-5">
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <TextWrapper
            name={'name'}
          required
          label={"Name of organization"}
          className={cn(s.input, s.text, 'mb-4')}
          placeholder="Enter organization name"
        />
        <TextWrapper
            name={'contact'}
          required
          label={"Contact"}
          className={cn(s.input, s.text, 'mb-4')}
          placeholder="Enter email address or phone number"
        />
            <TextAreaWrapper
                name={'description'}
                label={"Description"}
                rows={3}
                className={cn(s.input, s.text)}
                placeholder="Enter description" />

            <Box
                row
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={"1.875rem"}
                className="mt-[6.25rem]"
            >
                <Button
                    variant="icon"
                    style={{ color: "#20201F" }}
                    onClick={() => closeModal()}
                    type={"button"}
                >
                    Cancel
                </Button>
                <Button
                  disabled={!isValid || creating}
                    color="primary"
                    pill
                    style={{ width: "10.625rem" }}
                >
                    {creating ? "Creating..." : "Add Organization"}
                </Button>
            </Box>
        </FormProvider>

    </div>
  );
};

export default AddOrganization;
