/**
 *Project:Traceability Platform
 *File:receive-batch
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Select, Text, Textfield } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { KgNoBatches } from "@components/Icons";
import AddReceivedBatch from "@forms/buyer/AddRecieveBatch";

import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";

type Props = {} & WizardChildRef;

const ReceiveBatch: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();
  const [isEmpty, setIsEmpty] = useState(true);
  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full h-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              Receive Batch
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can view the batch of goods you have received
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setEdit(false)}
                  variant="fill"
                  pill
                  className="btn-md"
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  onClick={() => {
                    setIsEmpty(false);
                    generateModal(
                      "Receive Batch",
                      <>
                        <AddReceivedBatch />
                      </>,
                      "side"
                    );
                  }}
                >
                  Receive Batch
                </Button>
              </>
            )}
          </Box>
        </Box>
        {isEmpty ? (
          <>
            <Box col className={"h-fit w-fit m-auto items-center"}>
              <KgNoBatches />
              <span>You have received no batch yet</span>
            </Box>
          </>
        ) : (
          <>
            <Box col className={cn("section")}>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Quantity of Goods</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Coastal Shea Produce"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Shea</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Units of Quantity</Text>
                {edit ? (
                  <Textfield
                    color="primary"
                    placeholder="GN 8654 -24"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>GN 8654 -24</Text>
                )}
              </Box>

              <Box row className={"gi-field"}>
                <Text Component={"label"}>Warehouse</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Awodome Center"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Awodome Center</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Delivery Vehicle Type</Text>
                {edit ? (
                  <Textfield
                    color="primary"
                    placeholder="Box Truck"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Box Truck</Text>
                )}
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>
                  Delivery Vehicle Registration <br /> Number
                </Text>
                {edit ? (
                  <Textfield
                    color="primary"
                    placeholder="Box Truck"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>GN 8654 -24</Text>
                )}
              </Box>
            </Box>
            <Box col className={"section no-border"}>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Moved Batch</Text>
                <Button
                  variant="icon"
                  className="!text-primary"
                  onClick={() => {
                    props.goToNamedStep && props.goToNamedStep("move-batch");
                  }}
                >
                  View &nbsp;
                  <BsChevronRight />
                </Button>
              </Box>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Disposed Batch</Text>
                <Button
                  variant="icon"
                  className="!text-primary"
                  onClick={() => {
                    props.goToNamedStep && props.goToNamedStep("dispose-batch");
                  }}
                >
                  View &nbsp;
                  <BsChevronRight />
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default ReceiveBatch;
