/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
// import Commodities from "./commodity";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import ReceiveBatch from "./RecieveBatch";
import MoveBatch from "./MoveBatch";
import DisposeBatch from "./DisposeBatch";
import { useLocation } from "react-router-dom";
import { IBatch } from "@models/query/buyer";

type Props = {};

const Details: React.FC<Props> = (props) => {

  const location = useLocation();
  const data = location.state as IBatch;
  console.log({ data })

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    // {
    //   label: "Receive Batch",
    //   name: "receive-batch",
    // },
  ];
  return (
    <DetailsWizard
      id={"basic-commodities-details"}
      views={items}
      title={"#" + data.id}
    >
      <GeneralInformation data={data} name="general-information" />
      <ReceiveBatch name="receive-batch" />
      <MoveBatch name="move-batch" />
      <DisposeBatch name="dispose-batch" />
      {/* <Commodities name="basic-commodities" /> */}
    </DetailsWizard>
  );
};

export default Details;
