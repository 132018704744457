import "@styles/colors.css";
import "@styles/global.css";
import "@styles/modal.css";
import "@styles/typography.css";
import "@styles/tables.css";

import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import  env from 'dotenv'
// env.config();
import { getAppRoutes, getAuthRoutes } from "config";
import AppPage from "./pages/app";
import Auth from "./pages/auth";
import Landing from "pages/index";
import Loader from "components/loader";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { AuthProvider } from "contexts/AuthProvider";
import configureStore from "store/index";
import NotificationProvider from "components/NotiStack";
import { useAppSelector } from "hooks/appHooks";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import InvitationResponse from "./pages/InvitationResponse";
import CustomRouter from "@utils/CustomRouter";

function App() {
  const { store, persistor } = configureStore();

  const [queryClient] = useState(() => new QueryClient());
  const BrowserRoutes = () => {

    const { role } = useAppSelector((state) => state.auth);

    return (
      <Routes>
        <Route path="*" element={<Loader message="Error Page Not Found" />} />
        <Route
          path="/invitation-response/:id"
          element={<InvitationResponse />}
        />
        <Route path={`/`} element={<Landing />}>
          {getAuthRoutes().map((route) => {
            const Component: React.ElementType = route.component;
            const subRoutes = route.subRoutes;
            return (
              <Route key={route.path} path={`${route.path}`} element={<Component />}>
                {subRoutes &&
                  subRoutes.map((r) => {
                    const SubComponent: React.ElementType = r.component;
                    return <Route path={r.path} element={<SubComponent />} />;
                  })}
              </Route>
            );
          })}
        </Route>
        <Route path={`/auth`} element={<Auth />}>
          <Route path="" element={<Navigate to="login" replace />} />
          {getAuthRoutes().map((route) => {
            const Component: React.ElementType = route.component;
            const subRoutes = route.subRoutes;
            return (
              <Route  key={route.path} path={`${route.path}`} element={<Component />}>
                {subRoutes &&
                  subRoutes.map((r) => {
                    const SubComponent: React.ElementType = r.component;
                    return <Route path={r.path} element={<SubComponent />} />;
                  })}
              </Route>
            );
          })}
        </Route>
        {role && role !== "" && (
          <Route path={`/app`} element={<AppPage />}>
            <Route path="" element={<Navigate to="dashboard" replace />} />
            {getAppRoutes(role).map((route) => {
              const Component: React.ElementType = route.component;
              const subRoutes = route.subRoutes;
              return (
                <Route  key={route.path} path={`${route.path}`} element={<Component />}>
                  {subRoutes &&
                    subRoutes.map((r) => {
                      const SubComponent: React.ElementType = r.component;
                      return <Route path={r.path} element={<SubComponent />} />;
                    })}
                </Route>
              );
            })}
          </Route>
        )}
      </Routes>
    );
  };

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Suspense fallback={<Loader />}>
              <div className="App">
                <CustomRouter>
                  <BrowserRoutes />
                </CustomRouter>
              </div>
            </Suspense>
            <NotificationProvider />
          </AuthProvider>
        </QueryClientProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
