/**
 *Project:Traceability Platform
 *File:warehouse
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useEffect, useState} from "react";
import TopBar from "@layout/app/partials/top-bar";
import AddNewWarehouse from "@forms/system/add-new-warehouse";
import {useNavigate} from "react-router-dom";
import {useModalContext} from "@layout/app/context";
import {FieldValues, useForm} from "react-hook-form";
import {useDeleteWarehouseMutation, useWarehouses} from "services/system/warehouses";
import {useAppSelector} from "hooks/appHooks";
import {TableColumn, TablePagination} from "@models/store/table";
import {
    Contact,
    Organisation,
    StorageCapacity,
    Warehouse,
    WarehouseContact,
    WarehouseType
} from "@models/query/system";
import {Box, Button} from "@components/common";
import cn from "classnames";
import u from "@features/tables/system/users-table/index.module.css";
import {DeleteDialog} from "@features/Utils";
import {AiOutlineDelete, AiOutlineSearch} from "react-icons/ai";
import auth from "@models/query/auth";
import Badge from "@features/tables/system/users-table/badge";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import Table from "@components/table/Table";

type Props = {};

const Default: React.FC<Props> = (props) => {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();


    const { generateModal } = useModalContext();




    const defaultValues: FieldValues = {
        search: '',
    };
    const methods = useForm({
        defaultValues,
    });

    const { watch } = methods;

    const { user } = useAppSelector((state) => state.auth);

    const {
        warehouses,
        paginatorInfo,
        isLoading,
        error,
        loadNext,
        loadPrevious
    } = useWarehouses(user.organisation.id,{
        page,
        size: pageSize,
        query: searchTerm,
    });
    const { mutate: deleteWarehouse, isLoading: deleting } =
        useDeleteWarehouseMutation();
    function handleSearch(searchText: string) {
        setSearchTerm(searchText);
        setPage(0);
    }

    const handleClick = (id: number) => {
        navigate(`${id}`);

    }

    useEffect(() => {
        const subscription = watch((value:any) => {
            handleSearch(value.search);
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const Pagination: TablePagination | false  = paginatorInfo ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,

    } : false;

    const Columns: Array<TableColumn> = [
        {
            key: 'name',
            title: 'Name',
            onClick: (house: Warehouse) => handleClick(house.id),
            render: (text: string) => (
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {text}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'type',
            title: 'Type',
            onClick: (house: Warehouse) => handleClick(house.id),
            render: (text: WarehouseType) => (
                <div className="text-sm text-gray-500">
                    {text.label}
                </div>
            )
        },
        {
            key: 'contacts',
            title: 'Contact',
            onClick: (house: Organisation) => handleClick(house.id),
            render: (text: WarehouseContact[]) => (
                <div className="text-sm text-gray-500">
                    {text.map((contact, index) => (
                        <div key={index}>{contact.value}
                            {contact.isPrimary && <span className="text-xs text-gray-400"> (Primary)</span>}
                        </div>
                    ))}
                </div>
            )
        },
        {
            key: 'storageCapacity',
            title: 'Storage Capacity',
            onClick: (house: Warehouse) => handleClick(house.id),
            render: (text: StorageCapacity) => (
                <div className="text-sm text-gray-500">
                    {text.value + " " + text.unit}
                </div>
            )
        },
        {
            key: "isOperational",
            title: "Operational",
            render: (text: boolean, record: auth.RootObject) => (
                <Badge status={text ? 'active' : 'pending'} label={text ? 'Yes' : 'No'} />
            ),
        },

        {
            key: 'id',
            title: '',
            className: 'text-end',
            render: (text: number) => (
                <Box row className={cn(u.box, u.actions)}>
                    <Button
                        variant="icon"
                        className="!hidden lg:!flex"
                        onClick={() =>
                            generateModal(
                                "Delete Warehouse",
                                <DeleteDialog
                                    prompt={"Are you sure you want to delete this Warehouse?"}
                                    callback={() => deleteWarehouse({
                                        variables: {
                                            id: text,
                                            org: user.organisation.id
                                        }
                                    })}
                                    loading={deleting}
                                />,
                                "center"
                            )
                        }
                    >
                        <AiOutlineDelete />
                    </Button>
                </Box>
            )
        }
    ]
    return (
        <AppGuard  accessibleRoles={["systemAdministrator"]}>
      <div className={""}>
        <TopBar
          title="Warehouse"

        />
          <FormProvider methods={methods} onSubmit={()=>{}} >

              <Box row alignItems={"center"} className={cn(s.search, 'mb-4')}>
                  <TextWrapper
                      name={'search'}
                      className={s.input}
                      variant="borderless"
                      placeholder={"Search"}
                  />
                  <AiOutlineSearch />

              </Box>
          </FormProvider>
          <Table
              tableClassName={'table-hover'}
              loading={isLoading}
              columns={Columns}
              dataSource={warehouses}
              pagination={Pagination}
              pageSize={pageSize}
              loadNext={loadNext}
              loadPrevious={loadPrevious}
              setPage={setPage}
              page={page}
              error={error}
              emptyText={'No warehouse available'}
          />
      </div>
        </AppGuard>
  );
};

export default Default;
