/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import {useLocation} from "react-router-dom";
import {useCountry} from "services/system/country";
import {useAppSelector} from "hooks/appHooks";
import AppGuard from "guards/AppGuard";

type Props = {};

const Details: React.FC<Props> = () => {

  const { pathname } = useLocation();

  const { user } = useAppSelector((state) => state.auth);

  const id = pathname.split("/").pop();

  const {
    country,
    isLoading,
    error,
  } = useCountry(user.organisation.id,id as string);


  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard id={"country-details"}
                   title={country?.supportedCountry.name} views={items}
    >
      <GeneralInformation
          name="general-information"
          data={country}
          error={error}
          isLoading={isLoading}
      />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
