/**
 * Project: tracebility-dashboard
 * File: index
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */
import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
// import homepageSaga from './homepageSaga';

export default function* watch() {
    yield all([
        authSaga(),
        // homepageSaga()
    ]);
}
