/**
 *Project:Traceability Platform
 *File:table
 *Created by KojoGyaase
 *Copyright Bentil
 **/

import s from "./index.module.css";
import cn from "classnames";

import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";

type Props = {
  className?: string;
  expand?: boolean;
  children?: React.ReactNode;
};

type HeadProps = {
  className?: string;
  show?: boolean;
  toggle?: Function;
  children?: React.ReactNode;
};
type HeaderProps = {
  className?: string;
  show?: boolean;
  toggle?: Function;
  children?: React.ReactNode;
};
type RowProps = {
  className?: string;
  show?: boolean;
  toggle?: Function;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
};
type CellProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLTableCellElement>;
};

const Table: FunctionComponent<Props> & {
  Cell: FunctionComponent<CellProps>;
  Row: FunctionComponent<RowProps>;
  Head: FunctionComponent<HeadProps>;
  Header: FunctionComponent<HeaderProps>;
} = (props) => {
  const { children, className } = props;
  return (
    <table className={cn(className, s.root)}>
      <tbody>{children}</tbody>
    </table>
  );
};
const Row: React.FC<RowProps> = (props) => {
  const { children, className, onClick } = props;
  return (
    <tr
      onClick={(e) => onClick && onClick(e)}
      className={cn(s.root, className)}
    >
      {children}
    </tr>
  );
};
const Head: React.FC<HeadProps> = (props) => {
  const { children, className } = props;
  return <th className={cn(s.root, className)}>{children}</th>;
};
const Header: React.FC<HeaderProps> = (props) => {
  const { children } = props;
  return <tr className={s.header}>{children}</tr>;
};
const Cell: React.FC<CellProps> = (props) => {
  const { children, className, onClick } = props;
  return (
    <td onClick={onClick} className={cn(s.cell, className)}>
      {children}
    </td>
  );
};

Table.Row = Row;
Table.Head = Head;
Table.Header = Header;
Table.Cell = Cell;
export default Table;
