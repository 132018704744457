/**
 *Project:Traceability Platform
 *File:text
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { CSSProperties, JSXElementConstructor, ReactNode } from "react";
import s from "./index.module.css";
import cn from "classnames";

type Props = {
  Component?: string | JSXElementConstructor<any>;
  className?: String;
  children?: ReactNode;
} & CSSProperties;

const Text: React.FC<Props> = (props) => {
  const { Component = "p", className, children, ...rest } = props;
  return (
    <Component style={rest} className={cn(s.root, className as string)}>
      {children}
    </Component>
  );
};

export default Text;
