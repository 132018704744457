/**
 * Project: tracebility-dashboard
 * File: createReducer
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
export default function createReducer(initialState: any, handlers: any) {
    return function reducer(state = initialState, action: any) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}
