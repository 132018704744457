/**
 * Project: tracebility-dashboard
 * File: regions
 * Created by pennycodes on 22/11/2022.
 * Copyright tracebility-dashboard
 */
import { useQuery, useInfiniteQuery, useQueryClient, useMutation } from 'react-query';
import client from './index';
import {
    ProductionRegionPaginator,
    CreateOrEditProductionRegion,
    ProductionRegionOrganisationPaginator,
    QueryOptionsTwo,
    ProductionRegionCommoditiesPaginator,
    ISetProductionRegion,
    ProviderTypePaginator,
    ProviderPaginator,
    ICreateAvailableGoods,
    ICountryPaginator,
    IAvailableGoodsPaginator,
    ProductionZonePaginator,
    ICooperativePaginator,
    QueryOptions
} from "models/query/buyer";
import ApiConfig from "services/endpoints";
import { toast } from "react-toastify";


export interface IRegionIDVariable {
    variables: {
        orgId: number;
        productionRegionId: number
    }
}

export interface IOrgCommodity {
    variables: {
        orgId: number;
        coomodityId: number
    }
}

export interface IRegionUpdateVariables {
    variables: {
        id: number;
        input: CreateOrEditProductionRegion;
    };
}

export interface updateProps {
    setEdit: (edit: boolean) => void;
}

export interface IRegionCreateVariables {
    variables: { input: CreateOrEditProductionRegion };
}

export interface ISetProductionRegionVariables {
    variables: { input: ISetProductionRegion };
}

export interface ICreateAvailableGoodsVariables {
    variables: { input: ICreateAvailableGoods };
}

export function useProductionRegions(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.FETCH_SET_REGION, options],
        ({ queryKey, pageParam }) =>
            client.productionRegions.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        regions: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useProductionRegion(id: string) {
    const { data, isLoading, error } = useQuery<ProductionRegionPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.DETAILS, id],
        () => client.productionRegions.details(id),
        {
            enabled: !!id,
        }
    );
    return {
        region: data?.data[0],
        isLoading,
        error,
    };
}

export const useDeleteRegionMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { orgId, productionRegionId } }: IRegionIDVariable) =>
            client.productionRegions.deleteOrgProductionRegions(orgId.toString(), productionRegionId.toString()),
        {
            onSuccess: () => {
                toast.success('Production Region removed successfully');
                reset && reset();
            },
            // Always refetch after error or success:
            onSettled: (data, error, param) => {
                const orgId = param.variables.orgId.toString();
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ORGAN.ALL.replace('{id}', orgId));
            },
        }
    );
}

export const useUpdateRegionMutation = (props: updateProps) => {
    const queryClient = useQueryClient();
    const { setEdit } = props
    return useMutation(
        ({ variables: { id, input } }: IRegionUpdateVariables) =>
            client.productionRegions.update(id, input),
        {
            onSuccess: () => {
                toast.success('Production Region updated successfully');
                setEdit(false)
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ALL);
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.DETAILS);
            },
        }
    );
};

export const useCreateRegionMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: IRegionCreateVariables) =>
            client.productionRegions.create(input),
        {
            onSuccess: () => {
                toast.success('Production Region created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ALL);
            },
        }
    );
};

export function useRegionOrganisations(id: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionOrganisationPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.ORGANISATIONS, options],
        ({ queryKey, pageParam }) =>
            client.productionRegions.organisations(id, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        organisations: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export const useSetProductionRegionMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: ISetProductionRegionVariables) =>
            client.productionRegions.setProductionRegions(input),
        {
            onSuccess: () => {
                //   toast.success('User Invitation created successfully');
                toast.success('Production Regions set successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: (data, error, params) => {
                const id = params.variables.input.organisation;
                console.log({id});
                queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ORGAN.ALL.replace('{id}', id.toString()));
            },
        }
    );
};

export function useAvailableGoods(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<IAvailableGoodsPaginator, Error>(
        [ApiConfig.GOODS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.availableGoods.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        goods: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export const useCreateAvailableGoodMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: ICreateAvailableGoodsVariables) =>
            client.availableGoods.create(input),
        {
            onSuccess: () => {
                toast.success('Goods created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: (data, error, vari) => {
                console.log({ error })
                //toast.error('Error occured whiles creating goods');
                queryClient.invalidateQueries(ApiConfig.GOODS.ALL);
            },
        }
    );
};

export const useUpdateAvailableGoodMutation = (id: number, reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { input } }: ICreateAvailableGoodsVariables) =>
            client.availableGoods.update(id, input),
        {
            onSuccess: () => {
                toast.success('Goods updated successfully');
                reset()
            },
            onError: (error) => {
                toast.error('An error occured whiles updating');
            },
            // Always refetch after error or success:
            onSettled: (data, error, vari) => {
                console.log({ error })
               // toast.error('Error occured whiles updating goods');
                queryClient.invalidateQueries(ApiConfig.GOODS.ALL);
            },
        }
    );
};

export function useProviderType(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProviderTypePaginator, Error>(
        [ApiConfig.GOODS.GET_PROVIDER_TYPES, options],
        ({ queryKey, pageParam }) =>
            client.availableGoods.providerTypes(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useProvider(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProviderPaginator, Error>(
        [ApiConfig.GOODS.GET_PROVIDERS, options],
        ({ queryKey, pageParam }) =>
            client.availableGoods.providers(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useCountry(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ICountryPaginator, Error>(
        [ApiConfig.GOODS.GET_COUNTRY, options],
        ({ queryKey, pageParam }) =>
            client.availableGoods.countries(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useOrgCountry(orgId: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ICountryPaginator, Error>(
        [ApiConfig.COUNTRIES.ALL.replace('{organisationId}', orgId), options],
        ({ queryKey, pageParam }) =>
            client.country.all(orgId, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useGetGoodsById(id: string, options?: QueryOptionsTwo) {
    const { data, isLoading, error } = useQuery<IAvailableGoodsPaginator, Error>(
        [ApiConfig.GOODS.DETAILS, id],
        () => client.availableGoods.details(id),
        {
            enabled: !!id,
        }
    );

    console.log({ data })
    return {
        client: data?.data[0],
        isLoading,
        error,
    };
}

export function useOrganizationProductionRegion(id: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionOrganisationPaginator, Error>(
        [ApiConfig.PRODUCTION_REGIONS.ORGAN.ALL.replace('{id}', id), options],
        ({ queryKey, pageParam }) =>
            client.productionRegions.getOrgProductionRegions(id, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useOrganizationRegionCommodities(orgId: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.BASIC.ORGANIZATION.replace('{id}', orgId), options],
        ({ queryKey, pageParam }) =>
            client.commodities.allInOrg(orgId, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        commodities: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useRegionCommodities(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionRegionCommoditiesPaginator, Error>(
        [ApiConfig.COMMODITIES.REGIONAL.ALL, options],
        ({ queryKey, pageParam }) =>
            client.commodities.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        commodities: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export const useSetOrgCommodityMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { orgId, coomodityId } }: IOrgCommodity) =>
            client.commodities.createForOrg(String(coomodityId), String(orgId)),
        {
            onSuccess: () => {
                //   toast.success('User Invitation created successfully');
                toast.success('Regional commodity assigned successfully');
                reset()
            },
            onError: () => {
                toast.error('An error occured while assigning regional commodity');
            },
            // Always refetch after error or success:
            onSettled: (data, err, params) => {
                const id = params.variables.orgId;
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.BASIC.ORGANIZATION.replace('{id}', id.toString()));

            },
        }
    );
};

export const useRemoveOrgCommodityMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { orgId, coomodityId } }: IOrgCommodity) =>
            client.commodities.removeForOrg(String(coomodityId), String(orgId)),
        {
            onSuccess: () => {
                //   toast.success('User Invitation created successfully');
                toast.success('Regional commodity removed successfully');
                reset && reset();
            },
            onError: () => {
                toast.error('An error occured while removing regional commodity');
            },
            // Always refetch after error or success:
            onSettled: (data, err, params) => {
                console.log(err)
                const id = params.variables.orgId;
                queryClient.invalidateQueries(ApiConfig.COMMODITIES.BASIC.ORGANIZATION.replace('{id}', id.toString()));

            },
        }
    );
};

export function useProductionZones(options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ProductionZonePaginator, Error>(
        [ApiConfig.PRODUCTION_ZONES.ALL, options],
        ({ queryKey, pageParam }) =>
            client.productionZones.all(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export const useDeleteGoodMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id } }: { variables: { id: number } }) =>
            client.availableGoods.delete(id),
        {
            onSuccess: () => {
                toast.success('Good deleted successfully');
                reset && reset();
            },
            onError: () => {
                toast.error('An error occured while deleting good');
            },
            // Always refetch after error or success:
            onSettled: () => {
                queryClient.invalidateQueries(ApiConfig.GOODS.ALL);
            },
        }
    );
}

export function useGetCooperatives(options?: QueryOptions) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<ICooperativePaginator, Error>(
        [ApiConfig.ORGANISATIONS.ALL, options],
        ({ queryKey, pageParam }) =>
            client.availableGoods.cooperatives(Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}