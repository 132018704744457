/**
 *Project:Traceability Platform
 *File:add-basic-commoditty
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CreateOrEditBasicCommodity} from "@models/query/system";
import {useCreateBasicCommodityMutation} from "services/system/commodities";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";

type Props = {};

const AddBasicCommodity: React.FC<Props> = (props) => {

    const { closeModal } = useModalContext();

    const commodityValidationSchema = Yup.object().shape({
        name: Yup.string().required('Commodity name is required'),
    });

    const defaultValues: FieldValues = {
        name: ""
    };
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(commodityValidationSchema),
        defaultValues,
    });


    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, formState: { isValid } } = methods;

    const { mutate: createCommodity, isLoading: creating } =
        useCreateBasicCommodityMutation(onSubmitFinish);

    const onSubmit = (commodity: CreateOrEditBasicCommodity) => {


        createCommodity({
            variables: {
                input: commodity
            }
        });
    }
  return (
    <div className={s.root}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box >
          <TextWrapper
              name={'name'}
              required
              label={"Name of Basic Commodity"}
              className={cn(s.input, s.text)}
              placeholder="Enter  name"
          />
      </Box>
      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || creating}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
            {creating ? "Creating..." : "Add Basic Commodity"}

        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddBasicCommodity;
