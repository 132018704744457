/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

import Default from "./Default";
import Details from "./Details";

type Props = {};
export const OrganizationDetails = Details;
const Organizations: FunctionComponent<Props> & {
  Details: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
Organizations.Details = Details;
Organizations.Default = Default;
export default Organizations;
