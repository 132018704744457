/**
 *Project:Traceability Platform
 *File:auth
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { ReactNode } from "react";
import s from "./index.module.css";
import { Box, Text } from "components/common";
import { KgFrame } from "components/Icons";
import cn from "classnames";
interface Props {
  children?: ReactNode;
}

const Auth: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <div className={s.root}>
      <div className={s.banner}>
        <Box
          row
          gap={"1rem"}
          maxWidth={231}
          maxHeight={227}
          alignItems="center"
        >
          <KgFrame className={s.icon} />
        </Box>
        <Text className={s.label}>Powered by theSOFTtribe</Text>
      </div>
      <div className={cn(s.content, "debug-css")}>
        {children}
        <Text className={s.label}>Powered by theSOFTtribe</Text>
      </div>
    </div>
  );
};

export default Auth;
