/**
 *Project:Traceability Platform
 *File:activity-stepper
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import { Box, Text } from "@components/common";
import { uuid } from "@utils/helper";
import { KgEmptyActivity } from "@components/Icons";
import { IBatchTrace } from "@models/query/buyer";
type Props = { activity: boolean, trace: IBatchTrace[] };

const ActivityStepper: React.FC<Props> = (props) => {
  const { activity, trace} = props;

  return (
    <div className={s.root}>
      {activity ? (
        <>
          <Text className={"text-black2A text-[0.625rem] h-fit lg:pl-[2.2rem]"}>
            Start
          </Text>
          <div className="flex flex-row w-full">
            <div className={s.activities}>
              {trace
                .map((batch) => (
                  <div className={s.activity} key={uuid()}>
                    <div className={s.dot} key={uuid()} />
                    <Box col>
                    <Text>{batch.description}</Text>
                    <Text>{new Date(batch.timestamp).toUTCString()}</Text> 
                      {/* <Text>Farmer ”Kofi Amoah ” added new batch 1001</Text>
                      <Text>June 4 2021, 10:30 AM</Text> */}
                    </Box>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <div className="flex  items-center justify-center h-full pb-[2.375rem]">
          <KgEmptyActivity />
        </div>
      )}
    </div>
  );
};

export default ActivityStepper;
