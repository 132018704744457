/**
 *Project:Traceability Platform
 *File:dispose-batch
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Select, Text, Textfield } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { KgNoBatches } from "@components/Icons";
import AddDisposedBatch from "@forms/buyer/AddDisposedBatch";

import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";

type Props = {} & WizardChildRef;

const DisposeBatch: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();
  const [isEmpty, setIsEmpty] = useState(true);
  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full h-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box row alignItems={"center"}>
            <Button
              onClick={() => props.goToStep && props.goToStep(1)}
              variant="icon"
              pill
              className="!text-2xl mr-5"
            >
              <AiOutlineArrowLeft />
            </Button>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              Dispose Batch
            </Heading>
          </Box>
          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setEdit(false)}
                  variant="fill"
                  pill
                  className="btn-md"
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  onClick={() => {
                    setIsEmpty(false);
                    generateModal(
                      "Dispose Batch",
                      <>
                        <AddDisposedBatch />
                      </>,
                      "side"
                    );
                  }}
                >
                  Dispose Batch
                </Button>
              </>
            )}
          </Box>
        </Box>
        {isEmpty ? (
          <>
            <Box col className={"h-fit w-fit m-auto items-center"}>
              <KgNoBatches />
              <span>You have not disposed batch yet</span>
            </Box>
          </>
        ) : (
          <>
            <Box col className={cn("section no-border")}>
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Origin Warehouse</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Coastal Shea Produce"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>30</Text>
                )}
              </Box>
              {edit && (
                <Box row className={"gi-field"}>
                  <Text Component={"label"}>Waybill</Text>
                  {edit ? (
                    <Textfield
                      color="primary"
                      placeholder="89893SD"
                      className="input"
                    />
                  ) : (
                    <Text className={"gi-subtitle"}>0908</Text>
                  )}
                </Box>
              )}
              <Box row className={"gi-field"}>
                <Text Component={"label"}>Date of Disposal</Text>
                {edit ? (
                  <input
                    type={"date"}
                    placeholder="Enter date of disposal"
                    className={cn(
                      "rounded-[3px] w-full h-[2.6875rem]  border-solid border-[#5C6970] border-[0.0625rem] pl-[1.875rem] input"
                    )}
                  />
                ) : (
                  <Text className={"gi-subtitle"}>7 /14 / 2022</Text>
                )}
              </Box>

              <Box row className={"gi-field"}>
                <Text Component={"label"}>Recipient</Text>
                {edit ? (
                  <Select
                    color="primary"
                    placeholder="Kofi Amoah"
                    className="input"
                  />
                ) : (
                  <Text className={"gi-subtitle"}>Kofi Amoah</Text>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};
export default DisposeBatch;
