
/**
 * Project: tracebility-dashboard
 * File: index
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import { Button, Select } from "@components/common";
import Dropzone from "@components/common/dropzone";
import TextField from "@components/common/textfield";
import * as Yup from "yup";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";

import { KgMasterCardInvitation } from "@components/Icons";
import { fData, toBase64, validateEmail, validatePhoneNumber } from "@utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { useAcceptInvitationMutation, useInvitation } from "services/system/users";
import React, { useEffect, useState } from "react";
import { AcceptInvitation, Contact } from "models/query/system";
import SelectWrapper from "@forms/SelectWrapper";
import DropZoneWrapper from "@forms/DropZoneWrapper";

const idTypes = [
    { label: "Voter's ID", value: "Voters" },
    { label: "National ID", value: "National" },
    { label: "Passport", value: "Passport" },
    { label: "NHIS", value: "NHIS" },
    { label: "Drivers License", value: "Drivers" },
];

const MAX_FILE_SIZE = 3 * 1000 * 1000; // 3 Mb
const FILE_FORMATS = ["image/jpg", "image/jpeg", "image/webp", "image/png"];

export default function InvitationResponse() {

    let { id } = useParams();

    const { invitation, error, isLoading: loading } = useInvitation(id as string)

    const [IDImage, setIDImage] = useState("/image/image-2.png");
    const [emailStatus, setEmailStatus] = useState(false);
    const [phoneStatus, setPhoneStatus] = useState(false);
    const navigate = useNavigate();

    const invitationValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        role: Yup.string().required('Role is required'),
        password: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string()
            .required("Password Confirmation is required")
            .oneOf([Yup.ref("password"), null], "Passwords do not match"),
        email: Yup.string()
            .required("Email Address is Required")
            .test("validate-email", "Enter a valid email address", (value: any) => {
                if (value) {
                    return validateEmail(value);
                }
                return true;
            }),
        phone: Yup.string()
            .required("Phone Number is Required")
            .test("validate-phone", "Enter a valid phone number", (value: any) => {
                if (value) {
                    return validatePhoneNumber(value);
                }
                return true;
            }),
        identificationDocumentId: Yup.string().required("ID Number is Required"),
        identificationType: Yup.string().required("ID Type is Required"),
        identificationMedia: Yup.mixed()
            .notRequired()
            .test("fileFormat", "Unsupported Format", (value) => {
                if (value && value.length > 0) {
                    return value && FILE_FORMATS.includes(value[0].type);
                }
                return true;
            })
            .test(
                "fileSize",
                `File must be less than or equal to ${fData(MAX_FILE_SIZE)}`,
                (value) => {
                    if (value && value.length > 0) {
                        return value && value[0].size <= MAX_FILE_SIZE;
                    }
                    return true;
                }
            ),
    });

    const defaultValues: FieldValues = {
        firstName: '',
        lastName: '',
        role: '',
        password: '',
        confirmPassword: '',
        email: '',
        phone: '',
        identificationDocumentId: '',
        identificationType: '',
        identificationMedia: '',
    };

    const methods = useForm({
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(invitationValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);

        if (localStorage) {
            localStorage.clear();
        }
        
        navigate("/auth/login")
    };

    const { mutate: acceptInvitation, isLoading } =
        useAcceptInvitationMutation(onSubmitFinish);
    const {
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { isValid },
    } = methods;

    useEffect(() => {
        if (invitation) {
            let email = '', phone = '';
            if (validateEmail(invitation.username)) {
                email = invitation.username
                setEmailStatus(true);
            }
            else if (validatePhoneNumber(invitation.username)) {
                phone = invitation.username
                setPhoneStatus(true)
            }
            reset({
                role: invitation.role,
                email,
                phone,
                firstName: '',
                lastName: '',
                password: '',
                confirmPassword: '',
                identificationDocumentId: '',
                identificationType: '',
                identificationMedia: '',
            })
        }
        return () => { };
    }, [invitation]);

    const onSubmit = (user: typeof defaultValues) => {
        let contacts: Contact[] = [];
        if (user.phone) {
            contacts.push({
                isPrimary: true,
                type: "mobile",
                value: user.phone,
            });
        }
        if (user.email) {
            contacts.push({
                isPrimary: true,
                type: "email",
                value: user.email,
            });
        }

        let payload: AcceptInvitation = {
            firstName: user.firstName,
            lastName: user.lastName,
            identificationDocumentId: user.identificationDocumentId,
            identificationType: user.identificationType,
            identificationMedia: {
                contentType: "application/json",
                fileName: "Any",
                url: IDImage,
            },
            // @ts-ignore
            contacts,
            password: user.password
        };

        if (id) {
            acceptInvitation({
                variables: {
                    data: payload,
                    id: id as string,
                },
            });
        }
    };

    useEffect(() => {
        const subscription = watch((value) => {
            if (value.identificationMedia) {
                toBase64(value.identificationMedia[0])
                    .then((image: unknown) => setIDImage(image as string))
                    .catch(console.log);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className="relative py-[20px] px-[36px] w-full h-screen flex flex-col">
            <KgMasterCardInvitation />
            <img
                loading="lazy"
                alt={"banner"}
                src={"/image/image.png"}
                className={"object-fit mt-4 mx-auto max-w-[593px] mb-[40px]"}
            />
            {/*@ts-ignore*/}
            {invitation && !error && <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div className=" max-h-[70vh] overflow-y-auto">
                    <div className="mx-auto max-w-[593px]">
                        <div className="grid grid-cols-1 gap-[40px]">
                            <TextWrapper
                                name={"firstName"}
                                color="primary"
                                placeholder="Enter first name"
                                className={"mb-4"}
                            />
                            <TextWrapper
                                name={"lastName"}
                                color="primary"
                                placeholder="Enter last name"
                                className={"mb-4"}
                            />
                            <TextWrapper
                                name={"role"}
                                color="primary"
                                placeholder="superAdmin"
                                className={'hidden'}
                            />

                            <TextWrapper
                                name={"email"}
                                type={"email"}
                                color="primary"
                                placeholder="chelseaarday@gmail.com"
                                readOnly={emailStatus}
                                className={"mb-4"}
                            />
                            <TextWrapper
                                name={"phone"}
                                color="primary"
                                readOnly={phoneStatus}
                                placeholder="024xxxxxxxx"
                                className={"mb-4"}
                            />
                            <TextWrapper
                                name={"identificationDocumentId"}
                                color="primary"
                                placeholder={"Enter your ID Number"}
                                className={"mb-4"}
                            />
                            <SelectWrapper
                                name={"identificationType"}
                                className={"mb-4 input "}
                                placeholder={"Select ID Type"}
                                options={idTypes}
                                value={idTypes.find(
                                    (i) => i.value === methods.getValues("identificationType")
                                )}
                                onChange={(newValue: unknown, actionMeta) => {
                                    let value = newValue as any;
                                    if (id) {
                                        setValue("identificationType", value.value);
                                    }
                                }}
                            />
                            <div className="flex flex-col gap-[8px]">
                                <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
                                    Photo of ID
                                </label>
                                <DropZoneWrapper
                                    name={'identificationMedia'}
                                    render={() => (
                                        <img alt={"holding"} src={IDImage} />
                                    )}
                                />
                            </div>
                            <TextWrapper
                                name={'password'}
                                label={"New Password"}
                                placeholder={"Enter New Password"}
                                type={'password'}
                                className={'mb-4'}
                            />
                            <TextWrapper
                                name={'confirmPassword'}
                                label={"Confirm New Password"}
                                placeholder={"Confirm New Password"}
                                type={'password'}
                                className={'mb-4'}
                            />

                            <Button
                                type={'submit'}
                                label={isLoading ? 'Submitting...' : 'Continue'}
                                disabled={isLoading || !isValid}
                            />
                        </div>
                    </div>
                </div>
            </FormProvider>}
            {!invitation && loading &&
                <div
                    className="justify-center align-middle self-center items-center relative max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white opacity-20">Fetching Invitation details</h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400 opacity-20">Please be patient.</p>
                    <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                        <svg aria-hidden="true"
                            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#ED7136" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>}
            {error && !loading &&
                <div
                    className="justify-center align-middle self-center items-center relative max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white opacity-20">An error occurred</h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400 opacity-20">Your invitation ID is invalid or has already been accepted.</p>
                </div>}
            <p className="text-[#495057] text-[12px] leading-[14px] mt-auto w-fit mr-auto">
                Powered by theSOFTtribe
            </p>
        </div>
    );
}
