/**
 *Project:Traceability Platform
 *File:add-production-region
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import {FieldValues, useForm} from "react-hook-form";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import {useCreateRegionMutation} from "services/system/regions";
import {CreateOrEditProductionRegion} from "@models/query/system";


type Props = {};

const AddProductionRegion: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();

    const regionValidationSchema = Yup.object().shape({
        name: Yup.string().required('Production region name is required'),
    });

    const defaultValues: FieldValues = {
        name: ""
    };

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(regionValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, formState: { isValid } } = methods;

    const { mutate: createRegion, isLoading: creating } =
        useCreateRegionMutation(onSubmitFinish);

    const onSubmit = (region: CreateOrEditProductionRegion) => {


        createRegion({
            variables: {
                input: region
            }
        });
    }


    return (
    <div className={s.root}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <TextWrapper
            name={'name'}
          required
          label={"Name of Production Region"}
          className={cn(s.input, s.text)}
          placeholder="Enter  name"
        />
      </Box>
      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || creating}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
            {creating ? "Creating..." : "Add Production Region"}

        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddProductionRegion;
