/**
 *Project:Traceability Platform
 *File:warehouse
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import Default from "./Default";

import Details from "./Details";

type Props = {};
const Warehouse: FunctionComponent<Props> & {
  Details: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
Warehouse.Default = Default;
Warehouse.Details = Details;
export default Warehouse;
