/**
 *Project:Traceability Platform
 *File:add-new-country
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useEffect} from "react";
import s from "./index.module.css";
import { Box, Button } from "@components/common";
import { useModalContext } from "@layout/app/context";
import {useAppSelector} from "hooks/appHooks";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateCountryMutation} from "services/system/country";
import {AddOrEditCountry} from "@models/query/system";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import {AxiosError} from "axios";
import {toast} from "react-toastify";

type Props = {};

const AddNewCountry: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();

    const { user } = useAppSelector((state) => state.auth);

    const countryValidationSchema = Yup.object().shape({
        name: Yup.string().required('Country name is required'),
        code: Yup.string().required('Country code is required'),
    });

    const defaultValues: FieldValues = {
        name: "",
        code: ""
    };

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(countryValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, formState: { isValid } } = methods;

    const { mutate: createCountry, isLoading: creating, error, isError } =
        useCreateCountryMutation(onSubmitFinish);

    const onSubmit = (country: AddOrEditCountry) => {


        createCountry({
            variables: {
                input: country,
                org: user.organisation.id
            }
        });
    }

    useEffect(() => {
        if (isError  && error) {
            let errorInstance = error as AxiosError
            if (errorInstance.response?.data) {

                toast.error('Invalid country code supplied');
            }
        }
        return () => { };
    }, [isError, error]);


    return (
    <div className={s.root}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box >
        <TextWrapper
            name={'name'}
          required
          label={"Name of Country"}
          className={'mb-4'}
          placeholder="Enter name"
        />
        <TextWrapper
            name={'code'}
          required
          label={"International Code / Abbreviation"}
          placeholder="Enter name"
        />
      </Box>

      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={"button"}
        >
          Cancel
        </Button>
        <Button
            disabled={!isValid || creating}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
            {creating ? "Creating..." : " Add Country"}
        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddNewCountry;
