/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import "@styles/forms.css";

import { Box, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import cn from "classnames";
import React from "react";
import {EducationLevel} from "@models/query/system";

type Props = {
  data: EducationLevel | undefined;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
    const { data } = props;
  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full")}>
        <Box row className={"items-center mb-5 justify-between"}>
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
          </Box>
        </Box>
        <Text className={"gi-subtitle"}>
          {data ? data.description : 'Fetching...'}
        </Text>
      </Box>
    </div>
  );
};

export default GeneralInformation;
