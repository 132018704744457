/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import DetailsWizard from "@features/detail-wizard";
import { FC } from "react";

import Commodities from "./commodity";
import GeneralInformation from "./GeneralInformation";
import {useLocation} from "react-router-dom";
import {useRegionalCommodity} from "services/system/commodities";
import AppGuard from "guards/AppGuard";
type Props = {};
const Details: FC<Props> = (props) => {


  const { pathname } = useLocation();

  const id = pathname.split("/").pop();

  const {
    commodity,
    isLoading,
    error,
  } = useRegionalCommodity(id as string);


  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
    // {
    //   label: "Basic Commodities",
    //   name: "regional-commodities",
    // },
  ];
  return (
      <AppGuard accessibleRoles={['systemAdministrator']}>
    <DetailsWizard
      id={"regional-commodities-details"}
      views={items}
      title={commodity?.name}
    >
      <GeneralInformation
          data={commodity}
          isLoading={isLoading}
          error={error}
          name="general-information" />
      <Commodities
          id={id as string}
          name="regional-commodities" />
    </DetailsWizard>
      </AppGuard>
  );
};

export default Details;
