/**
 *Project:Traceability Platform
 *File:add-new-warehouse
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Checkbox, TextArea, Textfield } from "@components/common";
import { useModalContext } from "@layout/app/context";
import { AiOutlineClose } from "react-icons/ai";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import SelectWrapper from "@forms/SelectWrapper";
import FormProvider from "@forms/FormProvider";
import { useAppSelector } from "hooks/appHooks";
import React from "react";
import { useCreateOrgWarehouseMutation } from "services/buyer/warehouse";
import { Warehouse } from "models/query/system";
import TextWrapper from "../TextWrapper";
import TextAreaWrapper from "../TextAreaWrapper";

type Props = {};

const AddNewWarehouse: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();

  const defaultValues: FieldValues = {
    name: "",
    location: "",
    description: "",
    isFactoryWarehouse: false,
  };

  const validationSchema = Yup.object().shape({
    isFactoryWarehouse: Yup.boolean(),
    name: Yup.string().required("Name is required"),
    location: Yup.string().required("Location is required"),
    description: Yup.string().required("Description is required"),
    
  });

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { isValid },
  } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    closeModal();
  };

  const { user } = useAppSelector((state) => state.auth);

  const { mutate: createWarehouse, isLoading: creating } =
    useCreateOrgWarehouseMutation(onSubmitFinish);

  const onSubmit = (data: {
    name: string;
    description: string;
    location: string;
    isFactoryWarehouse: boolean;
  }) => {
    const organisationId = user?.organisation?.id;

    const address = {
      streetAddress: data.location,
      cityOrTown: "cityOrTown",
      regionOrState: "regionOrState",
      country: "Ghana",
    };

    const type = { id: 38 };

    const operatedBy = {
      id: Number(organisationId)
    }

    const ownedBy = {
      id: Number(organisationId)
    }

    const contacts = user?.organisation?.contacts || [];

    const payload = {
      name: data.name,
      description: data.description,
      address,
      isOperational: true,
      isFactoryWarehouse: data.isFactoryWarehouse,
      type,
      contacts,
      operatedBy,
      ownedBy
    };

    createWarehouse({
      variables: { organisationId, input: payload as any },
    });
  };

  return (
    <div className={"w-full pb-5"}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit as any)}>
        <Box form className={"w-full"}>
          <TextWrapper
            name={"name"}
            required
            label={"Name of Warehouse"}
            placeholder="Enter warehouse name"
          />

          <TextWrapper
            name={"location"}
            required
            label={"Location"}
            placeholder="Enter Location"
          />

          <TextAreaWrapper
            required
            label={"Warehouse Description"}
            name="description"
            // className={cn(s.input, s.text)}
            placeholder="Enter Description"
          />

          <div style={{ marginTop: 20 }}>
            <Box row gap={"0.5rem"} >
              <Checkbox
                name='isFactoryWarehouse'
                // checked={isFactoryWarehouse}
                onChange={(event) => {
                  setValue("isFactoryWarehouse", event.target.checked);
                }}
              />
              Is a factory warehouse?
            </Box>
          </div>

        </Box>
        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
          className="mt-[5rem]"
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          {/* <Button
          onClick={(_) => {}}
          color="primary"
          pill
          style={{ width: "13.75rem" }}
        >
          Add Warehouse
        </Button> */}
          <Button
            color="primary"
            disabled={!isValid || creating}
            // type='button'
            // onClick={()=> {handleSubmit(onSubmit as any)}}
            pill
            style={{ width: "13.75rem" }}
          >
            {creating ? "Processing..." : "Add Warehouse"}
          </Button>
        </Box>
      </FormProvider>
    </div>
  );
};

export default AddNewWarehouse;
