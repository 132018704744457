/**
 *Project:Traceability Platform
 *File:dashboard
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { FunctionComponent, useState } from "react";
import { Outlet } from "react-router-dom";
import Default from "./partials/Default";

import MoveGoods from "./partials/MoveGoods";
import DisposeGoods from "./partials/DisposeGoods";
import Batch from "./partials/Batch";

interface Props {}

const Dashboard: FunctionComponent<Props> & {
  MoveGoods: FunctionComponent<Props>;
  DisposeGoods: FunctionComponent<Props>;
  Batch: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return <Outlet />;
};
Dashboard.MoveGoods = MoveGoods;
Dashboard.DisposeGoods = DisposeGoods;
Dashboard.Batch = Batch;
Dashboard.Default = Default;
export default Dashboard;
