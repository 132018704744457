/**
 * Project: damirifa
 * File: TextField
 * Created by Pennycodes on 4/27/2022.
 * Copyright damirifa
 */

// import { useFormContext, Controller } from "react-hook-form";
// import styled, { css } from 'styled-components'
import cn from "classnames";
import React, {
  JSXElementConstructor,
    TextareaHTMLAttributes,
  useEffect,
} from "react";
// import { AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";
import s from "./index.module.css";
// import LoadingDots from '../loading-dots'

export interface TextFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  color?: "primary" | "secondary";
  variant?: "outline";
  Component?: string | JSXElementConstructor<any>;
  disabled?: boolean;
  name?: string;
  disableCopyAndPaste?: boolean;
  type?: "email" | "text" | "tel" | "currency" | "password";
  label?: String;
  required?: boolean;
  ref?: any;
}

const TextArea: React.FC<TextFieldProps> = (props) => {
  const {
    className,
    variant = "outline",
    color = "primary",
    disabled = false,
    style = {},
    label,
    Component = "textarea",
    name = "default",
    disableCopyAndPaste = false,
    required = false,
    type,
    ...rest
  } = props;

  const rootClassName = cn(s.root, {
    [s.secondary]: color === "secondary",
    [s.primary]: color === "primary",
    [s.outline]: variant === "outline",
    [s.disabled]: disabled,
    [s.tel]: type === "tel",
  });

  let otherProps = { ...rest };
  //  update props with null copy and paste functions
  if (disableCopyAndPaste) {
    otherProps = {
      onCopy: (e: any) => {
        e.preventDefault();
        return false;
      },
      onPaste: (e: any) => {
        e.preventDefault();
        return false;
      },
      ...rest,
    };
  }

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <fieldset className={rootClassName}>
        {label && (
          <label className={cn(s.label)}>
            {label}&nbsp;{required ? <span>*</span> : <></>}
          </label>
        )}
        <Component
            ref={props.ref}
          data-variant={variant}
          disabled={disabled}
          type={type}
          placeholder={" "}
          className={className}
          style={{
            ...style,
          }}
          pattern="/^$|\s+/"
          {...otherProps}
        />
      </fieldset>
    </>

    //   {!!error && <p className={'help text-danger'}>{error?.message}</p>}
    // </HTMLDivElement>
  );
};

export default TextArea;
