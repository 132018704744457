import React, { useCallback, useRef } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { useDropzone } from "react-dropzone";

export interface Props {
  className?: string;
  variant?: "icon" | "default";
  children?: React.ReactNode;
  render?: Function;
  onChange?: Function;
  name?: string;
  value?: string;
  ref?: any;
}

const Dropzone: React.FC<Props> = (props) => {
  const inputFile = useRef(null) 
  const { className, children, variant = "icon", render,value , ref , onChange } = props;

  const onDrop = useCallback((acceptedFiles: File[]) => {
   if (onChange)  onChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    //@ts-ignore
    <div className={cn(s.root, className)} onClick={() => inputFile.current.click() }>
      <div {...getRootProps()}>
        <input {...getInputProps()} ref={inputFile} value={value} />
        {render && !isDragActive ? (
          render()
        ) : (
          <p className={s.paragraph}>
            Drag the banner image here or&nbsp;
            <i className={s.alternate}>browse</i>&nbsp;for the image to upload
          </p>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
