/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import "@styles/forms.css";

import { Badge, Box, Button, Checkbox, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { DeleteDialog } from "@features/Utils";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useState } from "react";
import { IBatch } from "@models/query/buyer";
import { useAppSelector } from "hooks/appHooks";
import { useOrganizationRegionCommodities } from "services/buyer/goods";
import { useVendors } from "services/buyer/vendor";
import { useDeleteBatchMutation, useUpdateBatchMutation } from "services/buyer/batch";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";

type Props = { data: IBatch } & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();
  const [data, setData] = useState<{ default: IBatch, editable: IBatch }>({ default: props.data, editable: props.data });

  const { user } = useAppSelector((state) => state.auth);
  const organizationId = user?.organisation?.id

  const { commodities, isLoading: isRegionalCommoditiesLoading } =
    useOrganizationRegionCommodities(organizationId, {
      page: 0,
      pageSize: 999,
      search: '',
    });

  const { data: vendors, isLoading: isVendorsLoading } = useVendors({
    page: 0,
    pageSize: 999,
    search: '',
  });

  const { mutate: deleteBatch, isLoading: deleting } =
  useDeleteBatchMutation(() => navigate && navigate(-1));

  const { mutate: updateBatch, isLoading: updating } =
  useUpdateBatchMutation({setEdit});

  const onSubmit = () => {

    const payload = {
      id: data.editable.id,
      referenceCode: null,
      vendor: {
        id: data.editable?.vendor?.id || 0
      },
      quantity: data.editable?.quantity,
      regionalCommodity: {
        id: data.editable?.regionalCommodity?.id 
      },
      commodities: null,
      isExpired: false,
      isAdHoc: data.editable.isAdHoc,
      createdBy: null,
      updatedBy: null,
      createdOn: null,
      updatedOn: null
    }

    updateBatch({ variables: { id: String(data.editable.id), input: payload as any} })
  }

  return (
    <div className={"root px-3 lg:px-0"}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can change the details of the goods available for purchase.
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => {
                    setEdit(false);
                    setData((prev) => ({ ...prev, editable: { ...prev.default } }))
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onSubmit}
                  variant="fill"
                  disabled={updating}
                  pill
                  className="btn-md"
                >
                  {updating ? 'Updating...' : 'Save'}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  onClick={() =>
                    generateModal(
                      "Delete Batch",
                      <DeleteDialog
                        prompt={"Are you sure you want to delete batch "}
                        boldText={`${data.editable.id}`}
                        callback={() => deleteBatch({
                          variables: {
                            id: data.default.id.toString()
                          }
                        })}
                        loading={deleting}
                      />,
                      "center"
                    )
                  }
                >
                  Delete Record
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box col className={cn("section", { "no-border": !data.editable.isAdHoc })}>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>Regional Commodity</Text>
            {edit ? (
              // <Select color="primary" placeholder="Shea" className="input" />
              <Select
                name='regionalCommodity'
                placeholder={data.editable.regionalCommodity.name}
                className="input"
                onChange={(evnt) => {
                  if(evnt?.id){
                    setData(x => ({...x, editable: {...x.editable, regionalCommodity: {...x.editable.regionalCommodity, id: evnt.id}}}));
                  }
                  
                }}
                options={commodities.map(x => ({ label: x.name, value: x.id, ...x }))} />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.regionalCommodity.name}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"}>ad - hoc</Text>
            {edit ? (
              <Box row gap={"0.5rem"} >
              <Checkbox
              
                checked={data.editable.isAdHoc}
                onChange={(event) => {
                  setData(x => ({...x, editable: {...x.editable, isAdHoc: event.target.checked}}));
                }}
              />
              Is this an ad - hoc batch?
            </Box>
            ) : (
              <>{ data.editable.isAdHoc ?
               <Badge className="!bg-[#EEFFFA]  !text-[#73CAB1] !w-[4.75rem] !h-[1.1875rem]">
              Ad-hoc
            </Badge> : '__'}</>
            )}
          </Box>
        </Box>
        {data.editable.isAdHoc && (
          <Box row className={"gi-field mt-4"}>
            <Text Component={"label"}>Vendor</Text>
            {edit ? (
              <Select
              name='vendor'
              placeholder={data.editable.vendor ? `${data.editable.vendor?.firstName} ${data.editable.vendor?.lastName}` : 'No vendor'}
              className="input"
              onChange={(evnt) => {
                if(evnt?.id){
                  setData(x => ({...x, editable: {...x.editable, vendor: {...x.editable.vendor, id: evnt.id}}}));
                }
                
              }}
              options={vendors.map(x => ({ label: `${x.firstName} ${x.lastName}`, value: x.id, ...x }))} />
            ) : (
              <Text className={"gi-subtitle"}>{data.editable.vendor ? `${data.editable.vendor?.firstName} ${data.editable.vendor?.lastName}` : 'No vendor'}</Text>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default GeneralInformation;
