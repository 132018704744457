/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useEffect, useState} from "react";
import {Box, Button, Dropdown} from "@components/common";
import Wizard from "@components/common/wizard";
import Details from "./Details";
import TopBar from "@layout/app/partials/top-bar";
import UsersOverviewCards from "@features/cards/users-overview-cards";
import AddNewUser from "@forms/system/add-user/add-new-user";
import InvitationSuccess from "@forms/system/add-user/invitation-success";
import cn from "classnames";
import {KgExchange, KgFilter, KgInvitationPending, KgInvitationRejected, KgInvitationSent,} from "@components/Icons";
import {AiOutlineDelete, AiOutlineSearch} from "react-icons/ai";
import {BsChevronDown, BsThreeDotsVertical} from "react-icons/bs";
import AppGuard from "guards/AppGuard";
import {
  useDeleteInvitationMutation,
  useInvitations,
  useResendInvitationMutation,
  useUsersInvitationCount
} from "services/system/users";
import {Invitation, INVITATION_STATUS, ROLES} from "models/query/system";
import {useModalContext} from "@layout/app/context";
import {DeleteDialog} from "@features/Utils";
import {FieldValues, useForm} from "react-hook-form";
import {TableColumn, TablePagination} from "@models/store/table";
import auth from "@models/query/auth";
import Badge from "@features/tables/system/users-table/badge";
import s from "@features/tables/system/users-table/index.module.css";
import client from "services/system";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import SelectWrapper from "@forms/SelectWrapper";
import Table from "@components/table/Table";
import {uuid} from "@utils/helper";

type Props = {};
export const UserDetails = Details;


const roles = [
  {label: "All", value: ROLES.ALL},
  {label: "System Administrator", value: ROLES.SYSTEM},
  {label: "Corporate Administrator", value: ROLES.ADMIN},
  {label: "Buyer Operations", value: ROLES.BUYER},
  {label: "Buyer Warehouse", value: ROLES.WAREHOUSE},
  {label: "Corporate Warehouse Manager", value: ROLES.MANAGER},
  {label: "Farmer", value: ROLES.FARMER},
  {label: "Field Agent", value: ROLES.FIELDAGENT}
];
const statusOptions = [
  {label: "All", value: INVITATION_STATUS.ALL},
  {label: "Active", value: INVITATION_STATUS.ACTIVE},
  {label: "Pending", value: INVITATION_STATUS.PENDING},
  {label: "Rejected", value: INVITATION_STATUS.REJECTED}

];


const Default: React.FC<Props> = () => {
  const [collapse, toggleCollapse] = useState<boolean>(true);
  const { accepted, pending, sent } = useUsersInvitationCount();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [exporting, setExporting] = useState("Export CSV");
  const [role, setRole] = useState<ROLES>(ROLES.ALL);
  const [status, setStatus] = useState<INVITATION_STATUS>(INVITATION_STATUS.ALL);

  const { generateModal } = useModalContext();




  const defaultValues: FieldValues = {
    role: ROLES.ALL,
    status: INVITATION_STATUS.ALL,
    search: '',
  };
  const methods = useForm({
    defaultValues,
  });

  const { reset, setValue, watch } = methods;

const {
  invitations,
    paginatorInfo,
    error,
    loadNext,
    loadPrevious,
    isLoading
} = useInvitations({
    page,
    pageSize,
    username: searchTerm,
    role,
    status
});
  const { mutate: deleteInvitation, isLoading: deleting } =
      useDeleteInvitationMutation();

  const { mutate: resendInvitation, isLoading: resending } =
      useResendInvitationMutation();

  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  const handleResetFilter = () => {
    reset(defaultValues);
  };

  const onRoleFilter = (value: ROLES) => {
    setRole(value);
    setPage(0)

  }

  const onStatusFilter = (value: INVITATION_STATUS) => {
    setStatus(value);
    setPage(0)

  }

  useEffect(() => {
    const subscription = watch((value:any) => {
      handleSearch(value.search);
      onRoleFilter(value.role);
      onStatusFilter(value.status);

    });
    return () => subscription.unsubscribe();
  }, [watch]);


  const deleteModal = (id: string) => {
    generateModal(
        "Delete Invitation",
        <DeleteDialog
            prompt={"Are you sure you want to delete this invitation?"}
            callback={() => deleteInvitation({
              variables: {
                id
              }
            })}
            loading={deleting}
        />,
        "center"
    )
  }
  const resendModal = (id: string) => {
    generateModal(
        "Resend Invitation",
        <DeleteDialog
            prompt={"An email will be sent to the user with a new invitation link. Are you sure you want to resend this invitation?"}
            callback={() => resendInvitation({
              variables: {
                id
              }
            })}
            loading={resending}
            text={"Resend"}
        />,
        "center"
    )
  }

  const Pagination: TablePagination | false  = paginatorInfo ? {
    count: paginatorInfo.totalCount,
    pageSize: paginatorInfo.pageSize,
    page: paginatorInfo.page,

  } : false;

  const Columns: Array<TableColumn> = [
    {
      key: 'username',
      title: 'Contact',
      render: (text: string, record: Invitation) => (
          <div className="text-sm text-gray-500">
            {text}
          </div>
      )
    },
    {
      key: 'role',
      title: 'Role',
      render: (text: string, record:Invitation) => (
          <div className="text-sm text-gray-500">
            {roles.find((role) => role.value === text)?.label}
          </div>
      )
    },
    {
      key: 'organisation',
      title: 'Organization',
      render: (text: auth.Organization, record: Invitation) => (
          <div className="text-sm text-gray-500">
            {text?.name}
          </div>
      )
    },
    {
      key: 'status',
      title: 'Status',
      render: (text: string , record: auth.RootObject) => (
          <Badge status={text.toLowerCase()} />
      )
    },

    {
      key: 'id',
      title: '',
      className: 'text-end',
      render: (text: string, record: Invitation) => (
          <Box row className={cn(s.box, s.actions)}>
            {record.status !== INVITATION_STATUS.ACTIVE &&  <>
              <Dropdown >
                <Dropdown.Toggle>
                  <Button variant="icon">
                    <BsThreeDotsVertical />
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu x="left" variant="default" className={s.dropdown}>
                  {actions.map((i) => (
                      <Dropdown.Item className={s.item} key={uuid()}>
                    <span className={s.item} onClick={() => i.onClick(record.id)}>
                      {i.label}
                    </span>
                      </Dropdown.Item>
                  ))}
                  <Dropdown.Item className={cn("lg:hidden", s.item)} key={uuid()}>
                  <span
                      className={s.item}
                      onClick={deleteModal.bind(null, record.id)}
                  >
                    Delete User
                  </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                  variant="icon"
                  className="!hidden lg:!flex"
                  onClick={deleteModal.bind(null, record.id)}
              >
                <AiOutlineDelete />
              </Button>
            </>}

          </Box>
      )
    }
  ]

  const actions: Array<{ label: string; onClick: Function }> = [
    { label: "Resend Invite", onClick: (id: string) => resendModal(id) },
    {
      label: "Delete Pending Invite",
      onClick: (id: string) =>deleteModal(id),
    }
  ];
  const exportACSV = () => {
    setExporting('Exporting invitations...');

    client.invitations.all({
      size: 9999,
      exportAsCsv: true
    })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response as unknown as BlobPart]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'invitations.csv');
          document.body.appendChild(link);
          link.click();
          setExporting('Export Complete');
        })
        .catch((error) => {
          console.log(error);
          setExporting('Export Error. Retry');
        })
        .finally(() => {
          setTimeout(() => {
            setExporting('Export CSV');
          }, 2000);
        });
  }

  const AddUserModal = () => (
    <Wizard id={"user-add-side-modal"}>
      <AddNewUser />
      <InvitationSuccess />
    </Wizard>
  );
  return (
    <AppGuard accessibleRoles={["systemAdministrator"]}>
      <main className={""}>
        <TopBar
          title="Invitations"
          modalTitle={"Add New User"}
          renderForm={<AddUserModal />}
          addButtonLabel={"Add New User"}
          optionalButtonLabel={exporting}
          optionalAction={exportACSV}
        />
        <Box
          className={
            "gap-[0.6456rem] xl:gap-[3.125rem] grid md:grid-cols-2 lg:grid-cols-3"
          }
        >
          <UsersOverviewCards
            Icon={KgInvitationSent}
            label={"Invitation sent"}
            value={sent ?? "..."}
            fillColor={"blue"}
          />
          <UsersOverviewCards
            Icon={KgInvitationPending}
            label={"Pending Invitations "}
            value={pending ?? "..."}
            fillColor={"pink"}
          />
          <UsersOverviewCards
            Icon={KgInvitationRejected}
            label={"Rejected Invitations"}
            value={accepted ?? "..."}
            fillColor={"red"}
          />
        </Box>
        <Button
          variant="icon"
          className="mt-[1.75rem] mb-[0.75rem] px-5 gap-4 text-primary"
          onClick={(_) => toggleCollapse((r) => !r)}
        >
          <KgFilter />
          <span>Filter</span>
          <BsChevronDown
            style={{ transform: `rotateZ(${collapse ? "0deg" : "180deg"})` }}
          />
        </Button>
        {/* <Collapse isOpen={true}> */}
        {!collapse ? (
            <FormProvider methods={methods} onSubmit={()=>{}} >
          <Box
            className={cn(
              "mb-4 gap-4 flex flex-col lg:flex-row lg:items-center",
              {}
            )}
          >
            <Box
              row
              alignItems={"center"}
              className={
                "lg:w-[16.9375rem] border-solid border-[0.0625rem] !h-[2.6875rem]  border-[#DADADA] rounded pr-4"
              }
            >
              <TextWrapper
                  name={'search'}
                  className="!border-none !h-[100%] "
                  fill
                  placeholder={"Search"}
              />
              <AiOutlineSearch className="text-[#878787] text-lg" />
            </Box>
            <SelectWrapper
                name={'role'}
                className={"lg:w-[16.9375rem]"}
                options={roles}
                placeholder={"Select Role"}
                value={roles.find((i) => i.value === role)}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as any;
                  setValue('role', value.value)
                }
                }
            />
            <SelectWrapper
                name={'status'}
                className={"lg:w-[16.9375rem]"}
                options={statusOptions}
                placeholder={"Select Status"}
                value={statusOptions.find((i) => i.value === status)}
                onChange={(newValue: unknown, actionMeta) => {
                  let value = newValue as any;
                  setValue('status', value.value)
                }
                }
            />
            <Button
                variant="outline"
                className="px-4 rounded-[5px] !max-w-[3.375rem] mx-auto lg:mx-0 !h-[54px] aspect-square"
                color="secondary"
                onClick={handleResetFilter}
                type={"button"}
                pill={false}
            >
              <KgExchange />
            </Button>
          </Box>
            </FormProvider>
        ) : (
          <></>
        )}
        <Table
            tableClassName={'table-hover'}
            loading={isLoading}
            columns={Columns}
            dataSource={invitations}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={'No invitations available'}
        />
      </main>
    </AppGuard>
  );
};

export default Default;
