/**
 *Project:Traceability Platform
 *File:organization
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import Table from "@components/table";
import cn from "classnames";
import React from "react";

import s from "./index.module.css";

type Props = {
  id: string;
} & WizardChildRef;
const TableItem = (props: { status?: "active" | "pending" | "disabled" }) => {
  return (
    <>
      <Table.Row className={cn(s.row, s.rowLayout)}>
        <Table.Cell className={s.cell}>Clean Nuts Ltd</Table.Cell>
      </Table.Row>
    </>
  );
};
const Commodities: React.FC<Props> = (props) => {
  return (
    <>
      <div className={cn(s.root, "px-3 lg:px-3")}>
        <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
          Regional Commodity
        </Heading>
        <Box row className={" w-full justify-between mb-[2rem]"}>
          <Text
            className={
              "text-[#99A1A3] text-base text-left max-w-[29.0625rem] mb-[1.25rem]"
            }
          >
            You can change the details of the regional commodity
          </Text>
        </Box>
        <div className={s.tableContainer}>
          <Table className={s.table}>
            <Table.Header className={s.rowLayout}>
              <Table.Head className={s.heading}>Name</Table.Head>
              <Table.Head></Table.Head>
            </Table.Header>
            <TableItem />
            <TableItem status="disabled" />
            <TableItem status="pending" />
            <TableItem status="disabled" />
            <TableItem status="pending" />
          </Table>
        </div>
      </div>
    </>
  );
};

export default Commodities;
