/**
 *Project:Traceability Platform
 *File:password-reset
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import Heading from "@components/heading";
import { Button, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import cn from "classnames";
import { useRelativePath } from "@utils/helper";
import { useNavigate } from "react-router-dom";
import { mode } from "config";
type Props = {} & WizardChildRef;

const PasswordReset: React.FC<Props> = (props) => {
  const base = useRelativePath();
  const navigate = useNavigate();
  return (
    <div className={cn(s.root, "px-3 lg:px-0")}>
      <Heading size="lg100" className={"text-left"}>
        Password Reset
      </Heading>
      <Text
        className={
          "text-[#99A1A3] text-base text-left max-w-[29.0625rem] mb-[1.25rem]"
        }
      >
        You can initiate password reset if the user has forgotten password to
        their account
      </Text>
      <Button
        variant="fill"
        color="primary"
        className="px-4"
        pill
        onClick={() => navigate(`/auth/reset-password`)}
      >
        Initiate Reset Password
      </Button>
    </div>
  );
};

export default PasswordReset;
