import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import Default from "./Default";
import NewPassword from "./NewPassword";
import Verification from "./Verification";

type Props = {};

const ResetPassword: FunctionComponent<Props> & {
  Verification: FunctionComponent<Props>;
  NewPassword: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
ResetPassword.Default = Default;
ResetPassword.Verification = Verification;
ResetPassword.NewPassword = NewPassword;

export default ResetPassword;
