/**
 * Project: tracebility-dashboard
 * File: index
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loadingReducer from "./loadingReducer";
import commonReducer from "./commonReducer";


const rootReducers = combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    common: commonReducer
});

export default rootReducers;
