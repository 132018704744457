/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import Default from "./Default";

import Details from "./Details";

type Props = {};
export const UserDetails = Details;
const Users: FunctionComponent<Props> & {
  Details: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
Users.Details = Details;
Users.Default = Default;
export default Users;
