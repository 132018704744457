import { createContext, ReactNode, useContext } from "react";
export enum MenuPaths {
  DASHBOARD = "dashboard",
  USERS = "users",
  ACTIVE_USERS = "active-users",
  INVITED_USERS = "invited-users",
  ORGANIZATIONS = "organization",
  PRODUCTION_REGION = "production-region",
  PRODUCTION_ZONE = "production-zone",
  COMMODITIES = "commodities",
  BASIC_COMMODITIES = "basic-commodities",
  REGIONAL_COMMODITIES = "regional-commodities",
  WAREHOUSE = "warehouse",
  EDUCATION_LEVEL = "education-level",
  REPORT_ANALYTICS = "analytics",
  ANALYTICS = "report-analytics",
  TRACE_ANALYTICS = "trace-analytics",
  SETTINGS = "settings",
  COUNTRY = "country",
  BLACKLIST_CATEGORY = "blacklist-category",
  GOODS = "goods",
  BATCH = "batch",
  VEHICLES = "vehicle",
  CLIENTS = "client",
}

type ContextType = {
  showHamburger: boolean;
  toggleHamburger: Function;
  activePage: MenuPaths;
  setActivePage: Function;
};
export const LayoutContext = createContext<ContextType>({
  showHamburger: false,
  toggleHamburger: () => {},
  activePage: MenuPaths.DASHBOARD,
  setActivePage: () => {},
});
export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

type ModalContextType = {
  generateModal: Function;
  closeModal: Function;
};
export const ModalContext = createContext<ModalContextType>({
  generateModal: (
    title: string,
    children: ReactNode,
    modalType: "center" | "side",
    subtitle?: string
  ) => {},
  closeModal: () => {},
});
export const useModalContext = () => {
  return useContext(ModalContext);
};
