/**
 * Project: tracebility-dashboard
 * File: Icon
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import React from 'react';
import { Icon, IconifyIcon, IconProps } from '@iconify/react';

// ----------------------------------------------------------------------

interface Props extends IconProps{
    icon: string | IconifyIcon;


}

export default function Iconify({ icon, ...other }: Props) {
    return <div >
        <Icon icon={icon} {...other}  />
    </div>;
}
