/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { useState } from "react";
import cn from "classnames";
import { Box, Textfield, Text, Button, TextArea } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
import { ICreateVehicle, IVehicle } from "models/query/buyer";
import { useLocation, useNavigate } from "react-router-dom";
import { useDeleteVehicleMutation, useUpdateVehicleMutation } from "services/buyer/vehicle";
import { useAppSelector } from "hooks/appHooks";

type Props = {vehicle: IVehicle} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const { generateModal } = useModalContext();
  const location = useLocation();
  const navigate = useNavigate()
  const [data, setData] = useState({editable: location.state as IVehicle, default: location.state as IVehicle});
  const [edit, setEdit] = useState(false);

  const { mutate: deleteVehicle, isLoading: deleting } = useDeleteVehicleMutation(() => navigate && navigate(-1));

  const { user } = useAppSelector((state) => state.auth);
  const organisationId = user?.organisation?.id;

  const handleOnChange = (evnt: any) => {
    const name = evnt?.target?.name;
    const value = evnt?.target?.value;

    if(name === 'capacity'){
      setData((x) => ({...x, editable: {...x.editable, capacity: {...x.editable.capacity, value}}}));
      return
    }

    setData((x) => ({...x, editable: {...x.editable, [name]: value}}));
  }

  const { mutate: updateVehicle, isLoading: updating } = useUpdateVehicleMutation({setEdit});

  const handleUpdate = () => {

    const ownedBy = {
      id: null,
      name: user?.organisation?.name,
      contacts: [{ id: null, value: "+23324410010000", type: "mobile" }],
      isOwnerRegisteredOrganisation: true,
      isIndependentOwner: false,
      organisation: null
    };

    const size = {
      length: 0,
      width: 0,
      height: 0,
      unit: "ft",
      vehicle: null
    }

    const payload = {
      id: data.editable.id,
      make: data.editable.make,
      model: data.editable.model,
      registrationNumber: data.editable.registrationNumber,
      color: "",
      isActive: true,
      axleCount: data.editable.axleCount,
      capacity: {
        id: data.editable.capacity.id,
        value: data.editable.capacity.value,
        unit: "ton"
      },
      size: data.editable?.size ? data.editable.size : size,
      ownedBy,
      transporterFor: { id: user?.organisation?.id }
    }

    updateVehicle({
      variables: {
        id: String(data.editable.id),
        org: user?.organisation?.id,
        input: payload as unknown as ICreateVehicle
      }
    });
  }

  return (
    <div className={"root px-3 lg:px-3"}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between header"
          }
        >
          <Box col>
            <Heading size="lg100" bold="bold600" className={"text-left"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle max-w-[29.0625rem] text-left")}>
              You can change the details of the vehicle.
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => {setEdit(false); setData((x) => ({...x, editable: {...x.default}})); }}
                  label="Cancel"
                />

                <Button
                  onClick={handleUpdate}
                  variant="fill"
                  pill
                  className="btn-md"
                  disabled={updating}
                  label={updating ? 'Updating...' : "Save"}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  label={"Edit"}
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                />

                <Button
                  variant="fill"
                  pill
                  label={"Delete Vehicle"}
                  className="lg:btn-lg btn-md"
                  onClick={() =>
                    generateModal(
                      "Delete Vehicle",
                      <DeleteDialog
                  prompt={"Are you sure you want to delete vehicle "}
                  boldText={data.editable.registrationNumber}
                  callback={() => deleteVehicle({
                    variables: {
                      id: data.editable.id.toString(),
                      org: organisationId
                    }
                  })}
                  loading={deleting}
                />,
                      "center"
                    )
                  }
                />
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section no-border"}>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Registration Number
            </Text>
            {edit ? (
              <Textfield color="primary" name='registrationNumber' value={data?.editable?.registrationNumber} onChange={handleOnChange} />
            ) : (
              <Text className={"gi-subtitle"}>{data?.editable?.registrationNumber}</Text>
            )}
          </Box>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Make
            </Text>
            {edit ? (
              <Textfield color="primary" name='make' value={data?.editable?.make} onChange={handleOnChange} />
            ) : (
              <Text className={"gi-subtitle"}>{data?.editable?.make}</Text>
            )}
          </Box>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Model
            </Text>
            {edit ? (
              <Textfield color="primary" name='model' value={data?.editable?.model} onChange={handleOnChange} />
            ) : (
              <Text className={"gi-subtitle"}>{data?.editable?.model}</Text>
            )}
          </Box>
          <Box className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Capacity (Tonnes)
            </Text>
            {edit ? (
              <Textfield color="primary" name={'capacity'} value={data?.editable?.capacity?.value as any || 0} onChange={handleOnChange} />
            ) : (
              <Text className={"gi-subtitle"}>{data?.editable?.capacity?.value as any || 0} Ton</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Number of axles
            </Text>
            {edit ? (
              <Textfield color="primary" name='axleCount' value={data?.editable?.axleCount} onChange={handleOnChange} />
            ) : (
              <Text className={"gi-subtitle"}>{data?.editable?.axleCount}</Text>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default GeneralInformation;
