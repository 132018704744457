import { Box, Dropdown, Button } from "@components/common";
import Table from "@components/table";
import { useModalContext } from "@layout/app/context";
import { generateInitials, uuid } from "@utils/helper";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, createSearchParams } from "react-router-dom";
import Avatar from "./avatar";
import Badge from "./badge";
import cn from "classnames";
import s from "./index.module.css";
import { BlacklistDialog, DeleteDialog } from "@features/Utils";

export const TableItem = (props: {
  status?: "active" | "pending" | "disabled";
}) => {
  const { generateModal } = useModalContext();
  const { status = "active" } = props;
  const navigate = useNavigate();
  const [showDropdown, toggleDropdown] = useState(false);

  const actions: Array<{ label: string; onClick: Function }> = [
    { label: "Edit User", onClick: () => navigate("hello") },
    { label: "Change User Role", onClick: () => navigate("hello") },
    {
      label: "Reset Password",
      onClick: () =>
        navigate({
          pathname: "listing",
          search: createSearchParams({
            action: "reset-password",
          }).toString(),
        }),
    },
    {
      label: "Blacklist User",
      onClick: () => {
        generateModal("Blacklist User", BlacklistDialog, "center");
      },
    },
    { label: "Disable User", onClick: () => navigate("hello") },
  ];
  return (
    <>
      <Table.Row className={cn(s.row, s.rowLayout)}>
        <Table.Cell className={s.cell} onClick={() => navigate("hello")}>
          <Box className={s.box}>
            <Avatar initials={generateInitials("Chelsea Arday")} />
            &nbsp;&nbsp;Chelsea Arday
          </Box>
        </Table.Cell>
        <Table.Cell className={s.cell} onClick={() => navigate("hello")}>
          hello
        </Table.Cell>
        <Table.Cell className={s.cell} onClick={() => navigate("hello")}>
          hello
        </Table.Cell>
        <Table.Cell className={s.cell} onClick={() => navigate("hello")}>
          hello
        </Table.Cell>
        <Table.Cell className={s.cell} onClick={() => navigate("hello")}>
          <Badge status={status} />
        </Table.Cell>
        <Table.Cell className={s.cell}>
          <Box row className={cn(s.box, s.actions)}>
            <Dropdown show={showDropdown} toggle={toggleDropdown}>
              <Dropdown.Toggle>
                <Button variant="icon">
                  <BsThreeDotsVertical />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu x="left" variant="default" className={s.dropdown}>
                {actions.map((i) => (
                  <Dropdown.Item
                    onClick={(event: any) => i.onClick()}
                    key={uuid()}
                  >
                    <span className={s.item}>{i.label}</span>
                  </Dropdown.Item>
                ))}
                <Dropdown.Item className={cn("lg:hidden")} key={uuid()}>
                  <span
                    className={s.item}
                    onClick={() =>
                      generateModal(
                        "Delete User",
                        <DeleteDialog
                          prompt={"Are you sure you want to delete User"}
                        />,
                        "center"
                      )
                    }
                  >
                    Delete User
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              variant="icon"
              className="!hidden lg:!flex"
              onClick={() =>
                generateModal(
                  "Delete User",
                  <DeleteDialog
                    prompt={"Are you sure you want to delete User"}
                  />,
                  "center"
                )
              }
            >
              <AiOutlineDelete />
            </Button>
          </Box>
        </Table.Cell>
      </Table.Row>
    </>
  );
};
