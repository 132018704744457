/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import { useParams } from "react-router-dom";
import { useVehicle } from "services/buyer/vehicle";
import { useAppSelector } from "hooks/appHooks";
import { IVehicle } from "@models/query/buyer";

type Props = {};

const Details: React.FC<Props> = (_props) => {
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.auth);

  const { data } = useVehicle(user?.organisation?.id, id as string);

  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    }
  ];


  return (
    <DetailsWizard id={"vehicle-details"} views={items} title={data?.registrationNumber || 'Unavailable'}>
      <GeneralInformation name="general-information" vehicle={data as unknown as IVehicle} />
      {/* <InventoryNotification name="inventory-notification" /> */}
    </DetailsWizard>
  );
};

export default Details;
