/**
 *Project:Traceability Platform
 *File:add-new-warehouse
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button } from '@components/common';
import { useModalContext } from '@layout/app/context';
import React from 'react';
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useCreateClientMutation} from "services/buyer/clients";
import {CreateOrEditClient} from "models/query/buyer";
import {useAppSelector} from "hooks/appHooks";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";

type Props = {};

const AddNewClient: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();
    const { user } = useAppSelector((state) => state.auth);

    const clientValidationSchema = Yup.object().shape({
        name: Yup.string().required('Client\'s full name is required'),
        contactEmail: Yup.string().required('Contact email is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
    });

    const defaultValues: FieldValues = {
        name: "",
        contactEmail: "",
        phoneNumber: "",
    };


    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(clientValidationSchema),
        defaultValues,
    });

    const onSubmitFinish = () => {
        reset(defaultValues);
        closeModal();

    }

    const { handleSubmit, reset, formState: { isValid } } = methods;

    const { mutate: createClient, isLoading: creating } =
        useCreateClientMutation(onSubmitFinish);

    const onSubmit = (client: CreateOrEditClient) => {


        createClient({
            variables: {
                input: client,
                org: user.organisation.id
            }
        });
    }


  return (
    <div className={"pb-5 w-full"}>
        {/*@ts-ignore*/}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box>
                <TextWrapper
                    name={'name'}
                  required
                  label={"Client's Name"}
                  placeholder="Enter fullname of client"
                    className={'mb-3'}
                />
                <TextWrapper
                    name={'contactEmail'}
                    required
                    label={"Email"}
                    placeholder="Enter email"
                    className={'mb-3'}
                />
                <TextWrapper
                    name={'phoneNumber'}
                    required
                    label={"Phone Number"}
                    placeholder="Enter phone number"
                />
            </Box>
      <Box
        row
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={"1.875rem"}
        className="mt-[5rem]"
      >
        <Button
          variant="icon"
          style={{ color: "#20201F" }}
          onClick={() => closeModal()}
          type={'button'}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          pill
          style={{ width: "13.75rem" }}
          disabled={!isValid || creating}
        >
            {creating ? "Creating..." : "Add Client"}

        </Button>
      </Box>
        </FormProvider>
    </div>
  );
};

export default AddNewClient;
