/**
 * Project: tracebility-dashboard
 * File: Table
 * Created by pennycodes on 08/11/2022.
 * Copyright tracebility-dashboard
 */
import classnames from 'classnames';
import {Shimmer} from "react-shimmer";

interface Props {
    className?: string;
    columns: number;
    rows: number;
}

const TableShimmer = (props: Props) => {
    const {className, columns, rows} = props;
    return (
        <div className={classnames('overflow-x-auto relative', className)}>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className={'text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'}>
                <tr>
                    {Array.from(Array(columns).keys()).map((column: number) => (
                        <th  scope="col" className="py-3 px-6" key={column}><Shimmer width={100} height={10} /></th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {Array.from(Array(rows).keys()).map((row: number) => (
                    <tr key={row} className={'bg-white border-b dark:bg-gray-800 dark:border-gray-700'}>
                        {Array.from(Array(columns).keys()).map((column: number) => (
                            <td className={'py-4 px-6'} key={column}><Shimmer width={100} height={10} /></td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

}

export default TableShimmer;
