/**
 *Project:Traceability Platform
 *File:organizations
 *Created by KojoGyaase
 *Copyright Bentil
 **/

import AddBlacklistCategory from "@forms/system/add-blacklist-category";
import TopBar from "@layout/app/partials/top-bar";
import React, { useState } from "react";
import { useModalContext } from "@layout/app/context";
import {
  useBlacklistCategories,
  useDeleteBlacklistMutation,
} from "services/system/blacklist";
import { TableColumn, TablePagination } from "@models/store/table";
import { Box, Button } from "@components/common";
import cn from "classnames";
import u from "@features/tables/system/users-table/index.module.css";
import { DeleteDialog } from "@features/Utils";
import { AiOutlineDelete } from "react-icons/ai";
import Table from "@components/table/Table";
// //
type Props = {};

const Default: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);

  const { generateModal } = useModalContext();

  const {
    categories,
    paginatorInfo,
    isLoading,
    error,
    loadNext,
    loadPrevious,
  } = useBlacklistCategories({
    page,
    size: pageSize,
  });
  const { mutate: deleteCategory, isLoading: deleting } =
    useDeleteBlacklistMutation();

  const Pagination: TablePagination | false = paginatorInfo
    ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,
      }
    : false;

  const Columns: Array<TableColumn> = [
    {
      key: "name",
      title: "Name",
      render: (text: string) => (
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-bold text-black2A">{text}</div>
          </div>
        </div>
      ),
    },
    {
      key: "description",
      title: "Description",
      render: (text: string) => (
        <div className="flex items-center">
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{text}</div>
          </div>
        </div>
      ),
    },
    {
      key: "timesAssigned",
      title: "Times Assigned",
      render: (text: number | null) => (
        <div className="text-sm text-gray-500">
          {text ? text.toString() : "0"}
        </div>
      ),
    },

    {
      key: "id",
      title: "",
      className: "text-end",
      render: (text: number) => (
        <Box row className={cn(u.box, u.actions)}>
          <Button
            variant="icon"
            className="!hidden lg:!flex"
            onClick={() =>
              generateModal(
                "Delete Blacklist Category",
                <DeleteDialog
                  prompt={
                    "Are you sure you want to delete this Blacklist Category?"
                  }
                  callback={() =>
                    deleteCategory({
                      variables: {
                        id: text,
                      },
                    })
                  }
                  loading={deleting}
                />,
                "center"
              )
            }
          >
            <AiOutlineDelete />
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      <div>
        <TopBar
          title="Blacklist Categories"
          modalTitle={"Add New Blacklist \n Category"}
          renderForm={<AddBlacklistCategory />}
          addButtonLabel={"New Blacklist Category"}
        />
        <Table
          tableClassName={"table-hover"}
          loading={isLoading}
          columns={Columns}
          dataSource={categories}
          pagination={Pagination}
          pageSize={pageSize}
          loadNext={loadNext}
          loadPrevious={loadPrevious}
          setPage={setPage}
          page={page}
          error={error}
          emptyText={"No Blacklist Categories"}
        />
      </div>
    </>
  );
};

export default Default;
