import { uuid } from "@utils/helper";
import React, { startTransition, useState } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { options } from "../../../layout/app/partials/sidebar/constants";
import { BsCaretDownFill, BsChevronDown } from "react-icons/bs";

interface _Props {
  className?: string;
  color?: "primary" | "secondary";
  options?: Array<string>;
  placeholder?: string;
  onChange?: Function;
  pill?: boolean;
  variant?: "borderless" | "outline";
  x?: "left" | "left-half" | "right" | "right-half" | "center";
  required?: boolean;
  error?: boolean;
  label?: string;
  icon?: "caret" | "chevron";
}

const Select: React.FC<_Props> = (props) => {
  const {
    className,
    options = [
      "test",
      "New Port Beach",
      "Fountain",
      "Metal Tower",
      "Bugatti Chiron Grand Vitesse",
    ],
    label,
    placeholder,
    color = "primary",
    icon = "chevron",
    variant = "outline",
    onChange,
    required,
    error = false,
    x = "center",
    ...rest
  } = props;
  const [value, setValue] = useState<string>(options[0]);
  const updateValue = (v: any) => {
    startTransition(() => {
      setValue(v);
      onChange && onChange(v);
      setOpen(false);
    });
  };
  const [open, setOpen] = useState(false);
  return (
    <fieldset
      className={cn("flex flex-col relative w-auto", className, s.root)}
    >
      {label && (
        <label>
          {label}
          &nbsp;
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className="flex flex-row w-auto relative ">
        <select
          data-expand={`${open}`}
          {...rest}
          className={cn(s.button, className, {
            [s.error]: error,
            [s.outline]: variant === "outline",
            [s.borderless]: variant === "borderless",
            [s.secondary]: color === "secondary",
            [s.primary]: color === "primary",
          })}
        >
          <option className="text-[#99A1A3]" selected>
            {placeholder ? placeholder : value}
          </option>
          {options.map((r) => (
            <option key={uuid()} value={r}>
              {r}
            </option>
          ))}
        </select>
        <div className={s.icon}>
          {icon === "chevron" && <BsChevronDown data-rotate={`${open}`} />}
          {icon === "caret" && <BsCaretDownFill data-rotate={`${open}`} />}
        </div>
      </div>
    </fieldset>
  );
};
export default Select;
