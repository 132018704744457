// /**
//  *Project:Traceability Platform
//  *File:organizations
//  *Created by KojoGyaase
//  *Copyright Bentil
//  **/
// // import BatchesTable from "@features/tables/buyer/batches-table";
// import BatchesTable from "@features/tables/buyer/Batches";
// import AddBatch from "@forms/buyer/AddBatch";

// import TopBar from "@layout/app/partials/top-bar";
// import React from "react";
// type Props = {};

// const Default: React.FC<Props> = (props) => {
//   return (
//     <>
//       <div className={"flex flex-col gap-[1rem]"}>
//         <TopBar
//           title="Available Goods"
//           onSearch={() => {}}
//           addButtonLabel={"New Batch"}
//           modalTitle={"Add New Batch"}
//           renderForm={<AddBatch />}
//         />
//         <BatchesTable />
//       </div>
//     </>
//   );
// };

// export default Default;


/**
 *Project:Traceability Platform
 *File:organizations
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import AddNewClient from "@forms/buyer/AddNewClient";
import TopBar from "@layout/app/partials/top-bar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "@layout/app/context";
import { FieldValues, useForm } from "react-hook-form";
import { useAppSelector } from "hooks/appHooks";
import { TableColumn, TablePagination } from "@models/store/table";
import { Badge, Box, Button } from "@components/common";
import cn from "classnames";
import u from "@features/tables/system/users-table/index.module.css";
import { DeleteDialog } from "@features/Utils";
import { AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import { Client, IBatch } from "@models/query/buyer";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import Table from "@components/table/Table";
import { useBatches, useDeleteBatchMutation } from "services/buyer/batch";
import AddBatch from "@forms/buyer/AddBatch";

type Props = {};

const Default: React.FC<Props> = (props) => {

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const { generateModal } = useModalContext();

  const defaultValues: FieldValues = {
    search: '',
  };
  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;
  const { user } = useAppSelector((state) => state.auth);
  const {
    data: batches,
    paginatorInfo,
    isLoading,
    error,
    loadNext,
    loadPrevious
  } = useBatches({
    page,
    pageSize,
    search: searchTerm,
  });

  const { mutate: deleteBatch, isLoading: deleting } =
    useDeleteBatchMutation();


  function handleSearch(searchText: string) {
    setSearchTerm(searchText);
    setPage(0);
  }

  const handleClick = (id: number, data: any = null) => {
    navigate(`${id}`, {state: data});
  }

  useEffect(() => {
    const subscription = watch((value: any) => {
      handleSearch(value.search);
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  const Pagination: TablePagination | false = paginatorInfo ? {
    count: paginatorInfo.totalCount,
    pageSize: paginatorInfo.pageSize,
    page: paginatorInfo.page,
  } : false;

  const Columns: Array<TableColumn> = [
    {
      key: '1',
      title: 'Batch No',
      onClick: (reg: IBatch) => handleClick(reg.id, reg),
      render: (text: string, record: IBatch) => (
        <Box row className={"box"}>
          {record.id}
          {record.isAdHoc ? (
            <Badge className="!bg-[#EEFFFA]  !text-[#73CAB1] !w-[4.75rem] !h-[1.1875rem]">
              Ad-hoc
            </Badge>
          ) : (
            <></>
          )}
        </Box>
      )
    },
    {
      key: 'regionalCommodity',
      title: 'Regional Commodity',
      onClick: (reg: IBatch) => handleClick(reg.id, reg),
      render: (text: string, record: IBatch) => (
        <div className="text-sm text-gray-500">
          {record.regionalCommodity.name}
        </div>
      )
    },
    {
      key: 'id',
      title: '',
      className: 'text-end',
      render: (text: string, record: IBatch) => (
        <Box row className={cn(u.box, u.actions)}>
          <Button
            variant="icon"
            className="!hidden lg:!flex"
            onClick={() =>
              generateModal(
                "Delete Batch",
                <DeleteDialog
                  prompt={"Are you sure you want to delete batch "}
                  boldText={`${record.id}`}
                  callback={() => deleteBatch({
                    variables: {
                      id: record.id.toString()
                    }
                  })}
                  loading={deleting}
                />,
                "center"
              )
            }
          >
            <AiOutlineDelete />
          </Button>
        </Box>
      )
    }
  ]
  return (
    <AppGuard accessibleRoles={["buyerWarehouse", 'corporateAdministrator']}>
      <div className={""}>
        <TopBar
          title="Batch"
          modalTitle={"Add New Batch"}
          renderForm={<AddBatch />}
         // addButtonLabel={"New Batch"}
        />
        <FormProvider methods={methods} onSubmit={() => { }} >

          <Box row alignItems={"center"} className={cn(s.search, 'mb-4')}>
            <TextWrapper
              name={'search'}
              className={s.input}
              variant="borderless"
              placeholder={"Search"}
            />
            <AiOutlineSearch />

          </Box>
        </FormProvider>
        <Table
          tableClassName={'table-hover'}
          loading={isLoading}
          columns={Columns}
          dataSource={batches}
          pagination={Pagination}
          pageSize={pageSize}
          loadNext={loadNext}
          loadPrevious={loadPrevious}
          setPage={setPage}
          page={page}
          error={error}
          emptyText={'No batches available'}
        />
      </div>
    </AppGuard>
  );
};

export default Default;

