/**
 * Project: tracebility-dashboard
 * File: commonActions
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import * as types from './types';
import auth from 'models/query/auth';

export function commonResponse(response: { status: number, data: any }) {
    return {
        type: types.COMMON_RESPONSE,
        ...response,
    };
}

export function resetStatus() {
    return {
        type: types.RESET_STATUS,
    };
}

export function commonFailure(response: { status: number, data: any }) {
    return {
        type: types.COMMON_FAILED,
        ...response,
    };
}

export function inActiveAccount(response: auth.RootObject) {
    return {
        type: types.INACTIVE_ACCOUNT,
        ...response,
    };
}

export function enableLoader() {
    return {
        type: types.ENABLE_LOADER,
    };
}

export function disableLoader() {
    return {
        type: types.DISABLE_LOADER,
    };
}
