/**
 *Project:Traceability Platform
 *File:organization
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useState} from "react";
import s from "./index.module.css";
import cn from "classnames";
import Heading from "@components/heading";
import {Box, Button, Text} from "@components/common";
import Wizard, { WizardChildRef } from "@components/common/wizard";
import Avatar from "@components/avatar";
import Badge from "./partials/badge";
import { KgBin } from "@components/Icons";
import AddNewUser from "@forms/system/add-user/add-new-user";
import InvitationSuccess from "@forms/system/add-user/invitation-success";
import {useDeleteUserMutation, useUsers} from "services/system/users";
import {TableColumn, TablePagination} from "@models/store/table";
import auth from "@models/query/auth";
import {DeleteDialog} from "@features/Utils";
import {useModalContext} from "@layout/app/context";
import Table from "@components/table/Table";

type Props = {
    id: string;
} & WizardChildRef;

const AddUserModal = (props: { id?: string }) => (
    <Wizard id={"user-add-side-modal"}>
      <AddNewUser id={props.id} />
      <InvitationSuccess />
    </Wizard>
);

const Users: React.FC<Props> = (props: Props) => {
    const { id } = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);

  const { generateModal } = useModalContext();
  const { users,
    paginatorInfo,
    isLoading,
    error,
    loadNext,
    loadPrevious
  } = useUsers({
    page,
    size: pageSize,
    organisationIds: id
  });
  const { mutate: deleteUser, isLoading: deleting } =
      useDeleteUserMutation();

  const Pagination: TablePagination | false  = paginatorInfo ? {
    count: paginatorInfo.totalCount,
    pageSize: paginatorInfo.pageSize,
    page: paginatorInfo.page,

  } : false;

  const Columns: Array<TableColumn> = [
    {
      key: 'firstName',
      title: 'Name',
      render: (text: string, record: auth.RootObject) => (
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {record.profileImage ? (
                  <img className="h-10 w-10 rounded-full" src={record.profileImage} alt="" />) : (
                  <Avatar initials={record.firstName[0] + record.lastName[0]} color={'green'} />
              )}
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {record.firstName} {record.lastName}
              </div>
            </div>
          </div>
      )
    },

    {
      key: 'role',
      title: 'Role',
      render: (text: auth.Role, record: auth.RootObject) => (
          <div className="text-sm text-gray-500">
            {text.role}
          </div>
      )
    },
    {
      key: 'status',
      title: 'Status',
      render: (text: string , record: auth.RootObject) => (
          <Badge status={text.toLowerCase()} />
      )
    },

    {
      key: 'id',
      title: '',
      className: 'text-end',
      render: (text: string, record: auth.RootObject) => (
          <Button
              variant="icon"
              className="!hidden lg:!flex"
              type={'button'}
              onClick={() =>
                  generateModal(
                      "Delete User",
                      <DeleteDialog
                          prompt={"Are you sure you want to delete User"}
                          callback={() => deleteUser({
                            variables: {
                              id: record.id
                            }
                          })}
                          loading={deleting}
                      />,
                      "center"
                  )
              }
          >
            <KgBin />
          </Button>
      )
    }
  ]

  return (
    <>
      <div className={cn(s.root, "px-3 lg:px-0")}>
        <Heading size="lg100" className={"text-left"}>
          Users
        </Heading>
        <Box
          className={
            "lg:flex-row flex flex-col w-full justify-between mb-[2rem]"
          }
        >
          <Text
            className={
              "text-[#99A1A3] text-base text-left max-w-[29.0625rem] mb-[1.25rem]"
            }
          >
            You can view the organization this user is a part of, or add them to
            organization groups from this section.
          </Text>
          <Button
            variant="fill"
            color="primary"
            pill
            className="w-[9rem] h-[3rem]"
            onClick={() =>
          generateModal('Add User', <AddUserModal id={id} />)
          }
          >
            Add
          </Button>
        </Box>
        <Table
            tableClassName={'table-hover'}
            loading={isLoading}
            columns={Columns}
            dataSource={users}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={'No users available'}
        />
      </div>
    </>
  );
};

export default Users;
