import React, { ButtonHTMLAttributes, JSXElementConstructor } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { BsSquare } from "react-icons/bs";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "secondary";
  variant?: "outline" | "fill" | "icon" | "text";
  pill?: Boolean;
  label?: String;
  Component?: string | JSXElementConstructor<any>;
  className?: string;
  size?: "md" | "lg";
  disabled?: boolean;
}

const Button: React.FC<Props> = (props) => {
  const {
    className,
    variant = "fill",
    children,
    pill = true,
    label,
    size = "md",
    color,
    Component = "button",
      disabled,
    ...rest
  } = props;
  return (
    <Component
      {...rest}
      className={cn(
        s.root,
        {
          [s.primary]: color === "primary" || !color,
          [s.secondary]:
            color === "secondary" || (variant === "icon" && !color),
          [s.icon]: variant === "icon",
          [s.fill]: variant === "fill",
          [s.text]: variant === "text",
          [s.outline]: variant === "outline",
          [s.pill]: pill,
          [s.mdHeight]: size === "md",
          [s.lgHeight]: size === "lg",
            [s.disabled]: disabled,
        },
        className
      )}
    >
      {children}
      {children && label && <div className="mx-2" />}
      {label}
    </Component>
  );
};

export default Button;
