/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useEffect, useState} from "react";
import cn from "classnames";
import { Box, Text, Button } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";
import "@styles/forms.css";
import { useModalContext } from "@layout/app/context";
import { DeleteDialog } from "@features/Utils";
import {BasicCommodity, CreateOrEditBasicCommodity} from "@models/query/system";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useDeleteBasicCommodityMutation, useUpdateBasicCommodityMutation} from "services/system/commodities";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import TextWrapper from "@forms/TextWrapper";
import s from "@pages/app/system/organizations/general-information/index.module.css";
import FormProvider from "@forms/FormProvider";

type Props = {
  data: BasicCommodity | undefined;
  error: any;
  isLoading: boolean;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);
  const { generateModal } = useModalContext();


  const navigate = useNavigate();


  const { data, isLoading } = props;

  const commodityValidationSchema = Yup.object().shape({
    name: Yup.string().required('Commodity name is required'),
  });

  const defaultValues: FieldValues = {
    name: '',
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(commodityValidationSchema),
    defaultValues,
  });

  const onSubmitFinish = () => {
    reset(defaultValues);

  }

  const { handleSubmit, reset, formState: { isValid } } = methods;

  const { mutate: updateCommodity, isLoading: updating } =
      useUpdateBasicCommodityMutation({
        setEdit: onSubmitFinish
      });

  const goBack = () => {
    navigate(-1);

  }

  const { mutate: deleteCommodity, isLoading: deleting, error: DeleteError } =
      useDeleteBasicCommodityMutation(goBack);


  const onSubmit = (commodity: CreateOrEditBasicCommodity) => {


    if (data) {
      updateCommodity({
        variables: {
          input: commodity,
          id: data.id
        }
      });
      setEdit(false);
    }
  }

  useEffect(() => {
    if (data) reset(data)
    return () =>{};
  }, [data]);


  useEffect(() => {
    if (DeleteError) {
      let handler = DeleteError as AxiosError
      console.log('handler', handler)
      if (handler.response?.data) {
        // @ts-ignore
        toast.error(handler.response?.data.message);

      }
    }
    return () =>{};
  }, [DeleteError]);

  return (
    <div className={"root px-3 lg:px-0"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can change the details of the basic commodity
            </Text>
          </Box>

          <Box row>
            {edit ? (
              <>
                <a
                    className="btn btn-md mt-5"
                    onClick={() => setEdit(false)}
                    type={'button'}
                    role={'button'}
                >Cancel</a>
                <Button
                    disabled={!isValid || updating}
                  variant="fill"
                  pill
                  className="btn-md"
                >
                  {updating ? 'Saving Changes...' : 'Save'}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                  type={'button'}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="lg:btn-lg btn-md"
                  type={'button'}
                  onClick={() =>
                    generateModal(
                      "Delete Basic Commodity",
                      <DeleteDialog
                          callback={() => data ? deleteCommodity({
                            variables: {
                              id: data.id
                            }
                          }) : null}
                          loading={deleting}
                          prompt=" Are you sure you want to delete this Basic Commodity ?" />,
                      "center"
                    )
                  }
                >
                  Delete Commodity
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Box row className={"gi-field"}>
          <Text Component={"label"}>
            Name of Basic <br /> Commodity
          </Text>
          {edit ? (
              <TextWrapper
                  name={"name"}
                  color="primary"
                  placeholder="Shea"
                  className={s.input}
              />
          ) : (
              <Text className={"gi-subtitle"}>{isLoading ? 'Fetching' : data?.name}</Text>
          )}
        </Box>
      </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
