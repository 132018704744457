/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button, Select, Textfield } from "@components/common";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useModalContext } from "@layout/app/context";
import React, { useState } from "react";

type Props = {};

const AddReceivedBatch: React.FC<Props> = (props) => {
    const { closeModal } = useModalContext();
    // const ref = useAutoAnimate({
    //     duration: 0.3,
    // });
    // useref instead of useAutoAnimate
    const ref = React.useRef(null);

    return (
        <div
            className={"flex flex-col gap-[2.1875rem] pb-5 w-full mt-[-1rem]"}
            ref={ref as any}
        >
            <Textfield label={"Batch Number"} required placeholder="847401" />
            <Textfield
                label={"Quantity of Goods Received"}
                required
                placeholder="847401"
            />
            <Select
                required
                label={"Units of Quantity"}
                placeholder="Select unit of quantity"
            />
            <Select
                required
                label={"Warehouse"}
                placeholder="Select a warehouse"
            />
            <Select
                required
                label={"Delivery Vehicle Type"}
                placeholder="Select vehicle type"
            />
            <Textfield
                label={"Delivery Vehicle Registration Number"}
                required
                placeholder="Enter delivery Vehicle registration no."
            />
            <Box
                row
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={"1.875rem"}
                className="mt-[rem]"
            >
                <Button
                    variant="icon"
                    style={{ color: "#20201F" }}
                    onClick={() => closeModal()}
                >
                    Cancel
                </Button>
                <Button
                    onClick={(_) => {}}
                    color="primary"
                    pill
                    style={{ width: "13.75rem" }}
                >
                    Add Batch
                </Button>
            </Box>
        </div>
    );
};

export default AddReceivedBatch;
