import {
  Box,
  Button,
  Checkbox,
  Select,
  Text,
  Textfield,
} from "@components/common";
import Radio from "@components/common/radio";
import Heading from "@components/heading";
import autoAnimate from "@formkit/auto-animate";
import cn from "classnames";
import React, { Ref, useEffect, useRef, useState } from "react";
import RefObject from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { useRelativePath } from "@utils/helper";

interface Props {}

const MoveGoods = (props: Props) => {
  const [view, setView] = useState(0);
  const parent = useRef(null);
  const base = useRelativePath();
  const navigate = useNavigate();
  useEffect(() => {
    parent.current && autoAnimate(parent.current, { duration: 250000 });
  }, [parent]);
  return (
    <Box col ref={parent as any}>
      <Box
        className={
          "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
        }
      >
        <Box row>
          <Button
            className={"!text-[1.2rem] mr-4 text-[#99a1a3]"}
            variant={"icon"}
            onClick={() => navigate(-1)}
          >
            <AiOutlineArrowLeft />
          </Button>
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              Generate Waybill
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              Documentation for movement of goods
            </Text>
          </Box>
        </Box>
        <Button
          label={"Start Goods Movement"}
          className="px-[1.9063rem]  ml-auto lg:ml-0"
          onClick={() => navigate(`${base}/batch`)}
        />
      </Box>
      <Box
        className={
          "mt-10 lg:mt-32 flex flex-col gap-[2.1875rem] lg:w-[23.9375rem] lg:mx-auto"
        }
      >
        <Box
          className={
            "flex flex-col lg:flex-row  justify-between gap-4 lg:gap-4"
          }
        >
          <Box row gap={"1rem"} alignItems={"start"}>
            <Radio />
            <Text className={"text-dark2A text-[0.875rem] !leading-[100%]"}>
              Generate Waybill
            </Text>
          </Box>
          <Box row gap={"1rem"} alignItems={"start"}>
            <Radio />

            <Text className={"text-dark2A  text-[0.875rem] !leading-[100%]"}>
              Use an existing
              <br className="hidden lg:block" /> waybill number
            </Text>
          </Box>
        </Box>
        <Textfield placeholder="Enter Number" />

        <Box col className={"gap-4"}>
          <Text>Destination Type</Text>
          <Box className={"flex flex-col lg:flex-row gap-4"}>
            <Box row gap={"1rem"}>
              <Checkbox />
              <Text className={"text-dark2A"}>Buyer Warehouse</Text>
            </Box>
            <Box row gap={"1rem"}>
              <Checkbox />
              <Text className={"text-dark2A"}>Client</Text>
            </Box>
          </Box>
        </Box>
        <Select
          label="Destination"
          placeholder="Select Client"
          className="mt-2"
        />
        <Textfield label={"Fullname"} required placeholder="Enter Fullname" />
      </Box>
    </Box>
  );
};

export default MoveGoods;
