/**
 *Project:Traceability Platform
 *File:details
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
// import Commodities from "./commodity";
import GeneralInformation from "./GeneralInformation";
import DetailsWizard from "@features/detail-wizard";
import { useLocation } from "react-router-dom";
import { IAvailableGoods } from "@models/query/buyer";

type Props = {};

const Details: React.FC<Props> = (props) => {

  const location = useLocation();
  const items: Array<{ label: string; name: string }> = [
    {
      label: "General Information",
      name: "general-information",
    },
  ];

  const data = location.state as IAvailableGoods;

  return (
    <DetailsWizard
      id={"basic-commodities-details"}
      views={items}
      title={data?.regionalCommodity?.name}
    >

      <GeneralInformation
        name="general-information"
        //@ts-ignore
        defaultData={location.state} />
        
    </DetailsWizard>
  );
};

export default Details;
