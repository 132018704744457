/**
 *Project:Traceability Platform
 *File:organization
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, {useState} from "react";
import s from "./index.module.css";
import cn from "classnames";
import Heading from "@components/heading";
import { Box, Button, Text } from "@components/common";
import Table from "@components/table/Table";
import  { WizardChildRef } from "@components/common/wizard";
import {useParams} from "react-router-dom";
import {useModalContext} from "@layout/app/context";
import {useDeleteUserOrganisationMutation, useUserOrganisations} from "services/system/users";
import {TableColumn, TablePagination} from "@models/store/table";
import {DeleteDialog} from "@features/Utils";
import {KgBin} from "@components/Icons";
import Badge from "@pages/app/system/organizations/users/partials/badge";
import { AddUserToOrganisation } from "@forms/system/add-user/add-new-user";
type Props = {} & WizardChildRef;

const AddOrganisationModal = (props: { id?: string }) =>  <AddUserToOrganisation id={props.id} />;

const Organization: React.FC<Props> = (props) => {
    const { id } =  useParams();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);

    const { generateModal } = useModalContext();
    const {
        organisations,
        paginatorInfo,
        isLoading,
        error,
        loadNext,
        loadPrevious
    } = useUserOrganisations(Number(id),{
        page,
        size: pageSize
    });

    const { mutate: deleteUserOrganisation, isLoading: deleting } =
        useDeleteUserOrganisationMutation();

    const Pagination: TablePagination | false  = paginatorInfo ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,

    } : false;
    const Columns: Array<TableColumn> = [
        {
            key: 'name',
            title: 'Name',
            render: (text: string) => (
                <div className="text-sm font-medium text-gray-900">
                    {text}
                </div>
            )
        },
        {
            key: 'numberOfUsers',
            title: 'Number of Users',
            render: (text: number) => (
                <div className="text-sm font-normal text-gray-900">
                    {text}
                </div>
            )
        },
        {
            key: 'isEnabled',
            title: 'Status',
            render: (text: boolean) => (
                <Badge status={text ? 'active' : 'pending'} />
            )
        },

        {
            key: 'id',
            title: '',
            className: 'text-end',
            render: (text: number) => (
                <Button
                    variant="icon"
                    className="!hidden lg:!flex"
                    type={'button'}
                    onClick={() =>
                        generateModal(
                            "Remove Organisation",
                            <DeleteDialog
                                prompt={"Are you sure you want to remove user from Organisation Group?"}
                                callback={() => deleteUserOrganisation({
                                    variables: {
                                        id: text
                                    }
                                })}
                                loading={deleting}
                            />,
                            "center"
                        )
                    }
                >
                    <KgBin />
                </Button>
            )
        }
    ]
    return (
    <div className={cn(s.root, "px-3 lg:px-0")}>
      <Heading size="lg100" className={"text-left"}>
        Organization
      </Heading>
      <Box
        className={
          " w-full justify-between mb-[2rem] flex lg:flex-row flex-col"
        }
      >
        <Text
          className={
            "text-[#99A1A3] text-base text-left max-w-[29.0625rem] mb-[1.25rem]"
          }
        >
          You can view the organization this user is a part of, or add them to
          organization groups from this section.
        </Text>
        <Button
          variant="fill"
          color="primary"
          pill
          className="w-[9rem] h-[3rem]"
          onClick={() =>
              generateModal('Add To Organisation', <AddOrganisationModal id={id} />)
          }
        >
          Add
        </Button>
      </Box>
        <Table
            tableClassName={'table-hover'}
            loading={isLoading}
            columns={Columns}
            dataSource={organisations}
            pagination={Pagination}
            pageSize={pageSize}
            loadNext={loadNext}
            loadPrevious={loadPrevious}
            setPage={setPage}
            page={page}
            error={error}
            emptyText={'No user organisations available'}
        />
    </div>
  );
};

export default Organization;
