/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import "@styles/forms.css";
import { Box, Text, Button } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import TransactionsTable from "@features/tables/buyer/Transaction";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { Client, IBatchTrace, IClientTransaction } from "@models/query/buyer";
import { useClientTransaction } from "services/buyer/clients";
import { TableColumn, TablePagination } from "@models/store/table";
import u from "@features/tables/system/users-table/index.module.css";
import Table from "@components/table/Table";
import TopBar from "@layout/app/partials/top-bar";
import AddNewClient from "@components/forms/buyer/AddNewClient";
import { useAppSelector } from "hooks/appHooks";
import { useTaceBatch } from "services/buyer/reporting";
import {useWarehouseDispatchedGoods} from "services/buyer/warehouse";
import ActivityStepper from "pages/app/buyer/clients/activity-stepper";
type Props = { payload: any | undefined } & WizardChildRef;

const transactions = [
    { "batchMovementId": 2887, "tagId": "000000000000000000000020", "regionalCommodity": "Northern Savannah Shea", "date": "2023-03-21T08:06:16.359" },
    { "batchMovementId": 2961, "tagId": "000000000000000000000023", "regionalCommodity": "Northern Savannah Shea", "date": "2023-03-21T11:43:06.553" },
    { "batchMovementId": 2776, "tagId": "000000000000000000000018", "regionalCommodity": "Middle Belt Shea", "date": "2023-03-20T23:57:09.535" }
];
const paginatorInfo = { totalCount: 100, pageSize: 12, page: 0 };
const isLoading = false
const error = null
const loadNext = () => { };
const loadPrevious = () => { };

const DispatchedGoods: React.FC<Props> = (props) => {
    const { generateModal } = useModalContext();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [first, setFirst] = useState(false);
    const [data, setData] = useState<IBatchTrace[]>([]);
    const [tagId, setTagId] = useState('');

    const { user } = useAppSelector((state) => state.auth);
    const organisationId = user?.organisation?.id;

    const { mutate: searchBatchTag, isLoading: isLoadingTrace, isError } = useTaceBatch({ setFirst, setData });

    const { payload: client } = props;

    const { data: transactions,
        paginatorInfo,
        isLoading,
        error,
        loadNext,
        loadPrevious } = useWarehouseDispatchedGoods(String(client?.id), {
        page,
        pageSize,
        range: 'custom',
    });

    const Pagination: TablePagination | false = paginatorInfo ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,
    } : false;

    const handleOnClick = (reg: { tagId: string, timestamp: string }) => {
        searchBatchTag({ variables: { organisationId, tagId: reg.tagId } });

        generateModal(
            'Trace Report',
            <div>
                <span style={{fontSize: 13}}>TagID: <span style={{fontWeight: 'bolder'}}>{reg.tagId}</span></span>
                <ActivityStepper activity={first} trace={data} />
            </div>
        );
    }

    const Columns: Array<TableColumn> = [
        {
            key: 'tagId',
            title: 'Item ID',
            onClick: (reg: { tagId: string, timestamp: string }) => handleOnClick(reg),
            render: (text: string) => (
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900" style={{ color: 'blueviolet' }}>
                            {text}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'regionalCommodity',
            title: 'Regional Commodity',
            onClick: (reg: { tagId: string, timestamp: string }) => () => { },
            render: (data: {id: number, name: string}) => (
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {data?.name}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'timestamp',
            title: 'Date',
            onClick: (reg: { tagId: string, timestamp: string }) => () => { },
            render: (text: string) => (
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {text ? new Date(text).toUTCString() : 'n/a'}
                        </div>
                    </div>
                </div>
            )
        },
    ]

    return (
        <div className={"root px-3 lg:px-3"}>
            <Box col className={cn("w-full")}>
                <Box
                    className={
                        "flex lg:flex-row flex-col gap-4 mb-[2rem] lg:gap-0 items-center justify-between header"
                    }
                >
                    <Box col>
                        <Heading size="lg100" bold="bold600" className={"text-left"}>
                            Dispatched Goods
                        </Heading>
                        <Text className={cn("gi-subtitle max-w-[29.0625rem] text-left")}>
                            You can view all dispatched goods for the warehouse
                        </Text>
                    </Box>
                </Box>
                {/* <TransactionsTable /> */}
                <Table
                    tableClassName={'table-hover'}
                    loading={isLoading}
                    columns={Columns}
                    dataSource={transactions}
                    pagination={Pagination}
                    pageSize={pageSize}
                    loadNext={loadNext}
                    loadPrevious={loadPrevious}
                    setPage={setPage}
                    page={page}
                    error={error}
                    emptyText={'No dispatched goods available'}
                />
            </Box>
        </div>
    );
};

export default DispatchedGoods;
