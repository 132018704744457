/**
 * Project: tracebility-dashboard
 * File: AppGuard
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */
import { useAppSelector } from 'hooks/appHooks';

// ----------------------------------------------------------------------

interface AppGuardProps {
    accessibleRoles:string[];
    children: React.ReactNode;
    showMessage?: boolean;
}

export default function AppGuard({ accessibleRoles, children, showMessage = true }: AppGuardProps) {
    const { role, accountActive } = useAppSelector((state) => state.auth);

    if (!accessibleRoles.includes(role)) {
     if (showMessage) {
         return (
             <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                  role="alert">
                 <span className="font-medium">Permission Denied!</span>  You do not have permission to access this page
             </div>
         );
     }
     else return null;
    }

    if (!accountActive) {
        if (showMessage) {
            return (
                <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                     role="alert">
                    <span className="font-medium">Account Disabled!</span>    Your account is inactive. Contact your administrator
                </div>
            );
        }
        else return null;
    }

    return <>{children}</>;
}
