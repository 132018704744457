/**
 *Project:Traceability Platform
 *File:activity-stepper
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import { Box, Text } from "@components/common";
import { uuid } from "@utils/helper";
import { KgEmptyActivity } from "@components/Icons";
import { IBatchTrace } from "@models/query/buyer";
type Props = { activity: boolean, trace: { data: any[], params: any } };

const ActivityStepper: React.FC<Props> = (props) => {
  const { activity, trace } = props;

  return (
    <div className={s.root}>
      {activity ? (
        <>
          {/* <Text className={"text-black2A text-[0.625rem] h-fit lg:pl-[2.2rem]"}>
            Start
          </Text> */}
          <div className="flex flex-row w-full">
            <div className={s.activities}>
              {trace.data
                .map((batch) => (
                  <div className={s.activity} key={uuid()}>
                    <table>
                      <tr>
                        <th>
                          <span style={{ fontSize: 13, color: '#ED7136', fontWeight: 'bold' }}>Production Zone/Region: </span>
                          <span style={{ fontSize: 12 }}>{trace.params?.prodRegion?.name || trace.params?.prodZone?.name}</span>
                        </th>
                        <th>
                          <span style={{ fontSize: 13, color: '#ED7136', fontWeight: 'bold' }}>Start: </span>
                          <span style={{ fontSize: 12 }}>{new Date(batch.rangeStart).toLocaleDateString()}</span>
                        </th>
                        <th>
                          <span style={{ fontSize: 13, color: '#ED7136', fontWeight: 'bold' }}>End: </span>
                          <span style={{ fontSize: 12 }}>{new Date(batch.rangeEnd).toLocaleDateString()}</span>
                        </th>
                      </tr>
                      <tr>
                        <td><span style={{ fontSize: 13, fontWeight: 'bold' }}>Name</span></td>
                        <td><span style={{ fontSize: 13, fontWeight: 'bold' }}>Quantity</span></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><span style={{ fontSize: 12 }}>{trace.params?.report?.label}</span></td>
                        <td><span style={{ fontSize: 12 }}>{batch.count}</span></td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                ))}
            </div>
          </div>
        </>
      ) : (
        <div className="flex  items-center justify-center h-full pb-[2.375rem]">
          <KgEmptyActivity />
        </div>
      )}
    </div>
  );
};

export default ActivityStepper;
