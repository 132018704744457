/**
 * Project: tracebility-dashboard
 * File: AuthProvider
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */

import { useEffect } from 'react';
import { isValidToken, setSession } from 'utils/jwt';
import { get } from 'utils/ls';
import { useAppDispatch, useAppSelector } from 'hooks/appHooks';
import { initializeApp } from 'store/actions/authActions';


function AuthProvider({ children }: { children: React.ReactNode }) {
    const dispatch = useAppDispatch();
    // const { role, user } = useAppSelector((state: { auth: any; }) => state.auth);
    useEffect(() => {
        const initialize = async () => {
            try {
              //  const accessToken = get('accessToken');
              //   if (true) {
              //     //  setSession(accessToken);
              //
              //       // switch (role) {
              //       //     case "systemAdministrator":
              //       //         // dispatch(retrieveAdminDetails(user.id));
              //       //         break;
              //       //     case "systemBuyer":
              //       //         // dispatch(retrieveBuyerDetails(user.id));
              //       //         break;
              //       //     default:
              //       //         dispatch(initializeApp(false, null));
              //       // }
              //   } else {
              //       dispatch(initializeApp(false, null));
              //   }
            } catch (err) {
                console.error(err);
                dispatch(initializeApp(false, null));
            }
        };

        initialize().then((_r) => '');
    }, []);

    return <>{children}</>;
}

export { AuthProvider };
