/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import SelectWrapper from "@forms/SelectWrapper";
import FormProvider from "@forms/FormProvider";
import {
  ICreateAvailableGoods,
  ISetProductionRegion,
} from "@models/query/buyer";
import { useAppSelector } from "hooks/appHooks";
import {
  Box,
  Button,
  Checkbox,
  Select,
  Text,
  Textfield,
} from "@components/common";
import { useModalContext } from "@layout/app/context";
import React from "react";
import {
  useOrgCountry,
  useProviderType,
  useCountry,
  useCreateAvailableGoodMutation,
  useGetCooperatives,
  useOrganizationRegionCommodities,
} from "services/buyer/goods";
import s from "./index.module.css";
import TextWrapper from "../TextWrapper";
import { useUsers } from "services/buyer/users";
import auth from "@models/query/auth";
type Props = {};

export const UnitsOfQuantity = [
  { id: 'NONE', name: 'NONE' },
  { id: 'TEN_KG_SACKS', name: 'TEN_KG_SACKS' },
  { id: 'TWENTY_KG_SACKS', name: 'TWENTY_KG_SACKS' }
];

const AddRecordGoodPurchase: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();

  const { user } = useAppSelector((state) => state.auth);
  
  const platformOrgId = user?.authPayload?.platformOrganisationId;
  const organisationId = user?.organisation?.id;

  const { commodities, isLoading } = useOrganizationRegionCommodities(String(organisationId), {
    page: 0,
    pageSize: 100,
    search: "",
  });

  const { data: providerTypes, isLoading: isProviderTypesLoading } =
    useProviderType({
      page: 0,
      pageSize: 100,
      search: "",
    });

  // const { data: countries, isLoading: isCountriesLoading } =
  //   useCountry({
  //     page: 0,
  //     pageSize: 999,
  //     search: "",
  //   });

    const { data: countries, isLoading: isCountriesLoading } =
    useOrgCountry(platformOrgId, {
        page: 0,
        pageSize: 999,
        search: "",
      });

  const defaultValues: FieldValues = {
    askingPrice: "", //int
    countryCode: "", //str
    digitalAddress: "", //str
    location: "", //str
    providerId: "", //int
    providerType: "", //str
    quantity: "", //int
    quantityUnit: "", //str
    regionalCommodityId: "", //int
  };

  const validationSchema = Yup.object().shape({
    // regionId: Yup.string().required("Production region is required"),
    askingPrice: Yup.string().required("Asking Price is required"),
    countryCode: Yup.string().required("Country is required"),
    digitalAddress: Yup.string().required("Digital Address is required"),
    location: Yup.string().required("Location is required"),
    providerId: Yup.string().required("Provider is required"),
    providerType: Yup.string().required("ProviderType is required"),
    quantity: Yup.string().required("Quantity is required"),
    quantityUnit: Yup.string().required("Quantity Unit is required"),
    regionalCommodityId: Yup.string().required("Regional Commodity is required"),
  });

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = methods;

  //NONE, TEN_KG_SACKS, TWENTY_KG_SACKS

  const [providers, setProviders] = React.useState<{ mode: string, data: auth.RootObject[] }>({ mode: 'FARMER', data: [] });

  const { users: farmers, isLoading: isLoadingFarmers } = useUsers({
    page: 0,
    size: 999,
    query: '',
    //@ts-ignore
    role: 'FARMER'
  });

  const { data: cooperatives, isLoading: isLoadingCooperatives } = useGetCooperatives({
    page: 0,
    size: 999,
    query: '',
    type: 'COOPERATIVE'
  });

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (value.providerType) {
        switch (value.providerType) {
          case 'FARMER':
            setProviders({ mode: value.providerType, data: farmers });
            break;
          case 'COOPERATIVE':
            setProviders({ mode: value.providerType, data: cooperatives });
            value.provider = ''
            break;
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  const onSubmitFinish = () => {
    reset(defaultValues);
    closeModal();
  };

  const { mutate: createGoods, isLoading: creating } =
    useCreateAvailableGoodMutation(onSubmitFinish);

  const onSubmit = (data: ICreateAvailableGoods) => {
    console.log(data);
    createGoods({
      variables: { input: { ...data } },
    });
  };

  const isNameEqual = (role: any, emthod: string) => {
    return role.name === emthod;
  }

  const arrangeCountries = (countries: any[]) => {
    return countries && countries.length ?
    countries.map((x) => ({...x?.supportedCountry})) : [];
  }

  return (
    <div className={"flex flex-col gap-[2.1875rem] pb-5 w-full"}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit as any)}>
        <Box form className={"pb-[min(5rem,10vh)]"}>

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Regional Commodity <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              // aria-label="Regional Commodity"
              name={"regionalCommodityId"}
              required
              placeholder={"Select Regional Commodity"}
              options={commodities}
              isLoading={isLoading}
              loadingMessage={() =>
                "Fetching supported regional commodities..."
              }
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) => option.name}
              value={commodities.find(
                (role: any) =>
                  role.name === methods.getValues("regionalCommodityId")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                const value = newValue as { id: string; name: string };

                if (value) {
                  setValue("regionalCommodityId", value.id);
                }
              }}
            />
          </div>

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Provider Type <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              name={"providerType"}
              required
              placeholder={"Select Provider Type"}
              options={providerTypes}
              isLoading={isProviderTypesLoading}
              loadingMessage={() => "Fetching supported provider types..."}
              getOptionValue={(option: any) => option.name}
              getOptionLabel={(option: any) => option.name}
              value={providerTypes.find(
                (role: any) => role.name === methods.getValues("providerType")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                const value = newValue as { name: string };
                console.log(value)
                if (value) {
                  setValue("providerType", value.name);
                  setValue("providerId", '');

                }
              }}
            />
          </div>

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Provider <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              name={"providerId"}
              required
              placeholder={`Select Provider`}
              options={providers.mode === 'FARMER' ? farmers : cooperatives}
              isLoading={isLoadingFarmers}
              loadingMessage={() => "Fetching supported providers..."}
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) => option.name}
              value={providers.mode === 'FARMER' ? farmers.find((role: any) => isNameEqual(role, methods.getValues("providerId"))) :
                cooperatives.find((role: any) => isNameEqual(role, methods.getValues("providerId")))}
              onChange={(newValue: unknown, actionMeta) => {
                const value = newValue as { name: string, id: string };
                if (value) {
                  setValue("providerId", value.id);
                }
              }}
            />
          </div>

          <TextWrapper
            key={"quantity"}
            name={"quantity"}
            required
            label={"Quantity"}
            placeholder="Enter quantity"
            //@ts-ignore
            type="number"
          />

          {/* <TextWrapper
            key={"quantityUnit"}
            name={"quantityUnit"}
            required
            label={"Units Of Quantity"}
            placeholder="Enter units of quantity"
          /> */}

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Units Of Quantity <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              name={"quantityUnit"}
              required
              placeholder={`Select Units Of Quantity`}
              options={UnitsOfQuantity}
              getOptionValue={(option: any) => option.id}
              getOptionLabel={(option: any) => option.name}
              value={UnitsOfQuantity.find((role: any) => methods.getValues("quantityUnit") === role.id)}
              onChange={(newValue: unknown, actionMeta) => {
                const value = newValue as { name: string, id: string };
                if (value) {
                  setValue("quantityUnit", value.id);
                }
              }}
            />
          </div>

          <TextWrapper
            key={"askingPrice"}
            name={"askingPrice"}
            required
            label={"Asking Price"}
            //@ts-ignore
            type="number"
            placeholder="Enter asking price"
          />

          <div className="flex flex-col gap-[8px]">
            <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
              Country <span style={{ color: "red" }}>*</span>
            </label>
            <SelectWrapper
              // aria-label="Regional Commodity"
              name={"countryCode"}
              required
              placeholder={"Select Country"}
              options={arrangeCountries(countries)}
              isLoading={isCountriesLoading}
              loadingMessage={() => "Fetching supported countries..."}
              getOptionValue={(option: any) => option.code}
              getOptionLabel={(option: any) => option.name}
              value={arrangeCountries(countries).find(
                (role: any) => role.code === methods.getValues("countryCode")
              )}
              onChange={(newValue: unknown, actionMeta) => {
                const value = newValue as { id: string; code: string };

                if (value) {
                  setValue("countryCode", value.code);
                }

                // setValue("role", value.name);
              }}
            />
          </div>

          <TextWrapper
            name={"location"}
            required
            label={"Location"}
            placeholder="Enter location"
          />

          <TextWrapper
            name={"digitalAddress"}
            required
            label={"Digital Address  (GPS Coordinates)"}
            placeholder="Enter digital address"
          />

        </Box>
        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
          className="mt-[4rem]"
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid || creating}
            pill
            style={{ width: "9rem" }}
          >
            {/* {creating ? "Processing..." : "Add Record"} */}
            Add Record
          </Button>
          {/* <Button
            onClick={(_) => {}}
            color="primary"
            pill
            style={{ width: "13.75rem" }}
          >
            Add Record
          </Button> */}
        </Box>
      </FormProvider>
    </div>
  );
};

export default AddRecordGoodPurchase;
