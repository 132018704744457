/**
 * Project: tracebility-dashboard
 * File: system
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */
import auth from "./auth";

export interface QueryOptions {
    query?: string;
    page?: number;
    size?: number;
}
export interface QueryOptionsTwo {
    search?: string;
    page?: number;
    pageSize?: number;
}

export interface SearchParamOptions {
    [key: string]: unknown;
}

export interface QueryOptionsWithSearch extends QueryOptions {
    search?: SearchParamOptions;
}

export interface ResetPassword {
    confirmPassword?: string;
    oldPassword?: string;
    password: string;
}

interface PaginatorInfo<T> {
    page: number;
    data: T[];
    errors: any
    pageSize: number;
    message: string;
    requestId: string;
    status: number;
    totalCount: number;
}

interface UserOrgCount {
    users: number;
    organisations: number;
}

interface UserInvitationCount {
    accepted: number;
    pending: number;
    sent: number;
}

interface WarehouseCount {
    count: number;
    rangeStart: string;
    rangeEnd: string;
    queryRange: {
        description: string
        range: string
    }
}

interface CommodityCount {
    count: number;
    rangeStart: string;
    rangeEnd: string;
    queryRange: {
        description: string
        range: string
    }
}

interface VehicleCount {
    count: number;
    rangeStart: string;
    rangeEnd: string;
    queryRange: {
        description: string
        range: string
    }
}

export interface CountQuery {
    to?: string
    from?: string
    range: 'daily' | 'weekly' | 'monthly' | 'custom'
}


export interface DashboardCount extends PaginatorInfo<UserOrgCount> {}
export interface CommoditiesCount extends PaginatorInfo<CommodityCount> {}
export interface WarehousesCount extends PaginatorInfo<WarehouseCount> {}
export interface VehiclesCount extends PaginatorInfo<VehicleCount> {}


export interface InvitationCount extends PaginatorInfo<UserInvitationCount> {}

export interface UserSearchQuery extends QueryOptions, SearchParamOptions {
    authenticated?: boolean;
    contact?: string;
    exportAsCsv?: boolean;
    organisationIds?: string;
    role?: ROLES;
    status?: USER_STATUS;
    educationLevel?: string;
}

export interface InvitationSearchQuery extends QueryOptions, SearchParamOptions {
    exportAsCsv?: boolean;
    username?: string;
    role?: ROLES;
    status?: INVITATION_STATUS;
}

export enum ROLES {
    ALL = "",
    SYSTEM = 'systemAdministrator',
    ADMIN = 'corporateAdministrator',
    BUYER = 'buyerOperations',
    WAREHOUSE = 'buyerWarehouse',
    MANAGER = 'cooperativeWarehouseManager',
    FARMER = 'farmer',
    FIELDAGENT = 'fieldAgent',
}

export enum USER_STATUS {
    ALL = "",
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    LITERATE = 'functionallyLiterate',
    JHS = 'juniorSecondary',
    NO_EDUCATION = 'noEducation',
    PRIMARY = 'primary',
    SHS = 'seniorSecondary',
    TERTIARY = 'tertiary',
}
export enum INVITATION_STATUS {
    ALL = "",
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED'
}

export interface Invitation {
    id: string;
    role: ROLES;
    status: INVITATION_STATUS;
    username: string;
    organisation: Organisation;


}
export interface AcceptInvitation {
    contacts: Contact;
    role: ROLES;
    status: INVITATION_STATUS;
    firstName: string;
    lastName: string;
    identificationDocumentId: string;
    identificationMedia: DocumentMedia;
    identificationType: string;
    password: string


}

export interface InvitationPaginator extends PaginatorInfo<Invitation> {}

export interface UserPaginator extends PaginatorInfo<auth.RootObject> {}

export interface DocumentMedia {
    contentType: string;
    fileName: string;
    url: string;
}

export interface UpdateUser {
    address?: string;
    city: string;
    contacts: Contact[];
    countryCode: string;
    countryOrDistrict?: string;
    digitalAddress?: string;
    firstName: string;
    identificationDocumentId?: string;
    identificationMedia?: DocumentMedia;
    identificationType?: string;
    lastName: string;
    productionRegionId?: string;
    productionZoneId?: string;
    profileImage?: string
}

export interface AddBlacklist {
    categoryId: number;
    reason: string;
}

export interface BlackListUser {
    authPayload: AuthPayload
    blacklist: Blacklist
    buyerOperator: BuyerOperator
    buyerWarehouseOperator: BuyerWarehouseOperator
    city: string
    contacts: Contact3[]
    corporateAdministrator: CorporateAdministrator
    country: Country9
    createdOn: string
    farmer: Farmer
    firstName: string
    gender: string
    id: number
    isBlacklisted: boolean
    isBuyerOperator: boolean
    isBuyerWarehouseOperator: boolean
    isCooperativeWarehouseManager: boolean
    isCorporateAdministrator: boolean
    isEnabled: boolean
    isFarmer: boolean
    isSystemAdministrator: boolean
    isVerified: boolean
    lastName: string
    organisation: Organisation5
    profileImage: string
    role: Role
    status: string
    username: string
}

export interface AuthPayload {
    expiration: number
    expiresIn: number
    token: string
    id: number
    tokenType: string
    username: string
}


export interface Blacklist {
    description: string
    id: number
    name: string
    timesAssigned: number
}

export interface BuyerOperator {
    address: Address
    createdOn: string
    id: number
    identificationDocumentId: string
    identificationMedia: IdentificationMedia
    identificationType: IdentificationType2
    organisation: Organisation
    updatedOn: string
}

export interface Address {
    cityOrTime: string
    country: Country
    countyOrDistrict: string
    createdOn: string
    digitalAddress: string
    gpsCoordinates: GpsCoordinates
    id: number
    mailAddress: string
    regionOrState: string
    streetAddress: string
    updatedOn: string
    zipCoderPostCode: string
}

export interface Country {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface GpsCoordinates {
    createdOn: string
    id: number
    latitude: string
    longitude: string
    updatedOn: string
}

export interface IdentificationMedia {
    contentType: string
    createdOn: string
    filename: string
    id: number
    identificationType: IdentificationType
    updatedOn: string
    url: string
}

export interface IdentificationType {
    country: Country2
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country2 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface IdentificationType2 {
    country: Country3
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country3 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface Organisation {
    contacts: Contact[]
    description: string
    id: number
    isEnabled: boolean
    name: string
    numberOfUsers: number
    type: string
    isBlacklisted: boolean
    createdOn: number
    updatedOn: number
}

export interface Contact {
    isPrimary: boolean
    type: string
    value: string
}

export interface BuyerWarehouseOperator {
    address: Address2
    createdOn: string
    id: number
    identificationDocumentId: string
    identificationMedia: IdentificationMedia2
    identificationType: IdentificationType4
    organisation: Organisation2
    updatedOn: string
}

export interface Address2 {
    cityOrTime: string
    country: Country4
    countyOrDistrict: string
    createdOn: string
    digitalAddress: string
    gpsCoordinates: GpsCoordinates2
    id: number
    mailAddress: string
    regionOrState: string
    streetAddress: string
    updatedOn: string
    zipCoderPostCode: string
}

export interface Country4 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface GpsCoordinates2 {
    createdOn: string
    id: number
    latitude: string
    longitude: string
    updatedOn: string
}

export interface IdentificationMedia2 {
    contentType: string
    createdOn: string
    filename: string
    id: number
    identificationType: IdentificationType3
    updatedOn: string
    url: string
}

export interface IdentificationType3 {
    country: Country5
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country5 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface IdentificationType4 {
    country: Country6
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country6 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface Organisation2 {
    contacts: Contact2[]
    description: string
    id: number
    isEnabled: boolean
    name: string
    numberOfUsers: number
    type: string
}

export interface Contact2 {
    isPrimary: boolean
    type: string
    value: string
}

export interface Contact3 {
    isPrimary: boolean
    type: string
    value: string
}

export interface CorporateAdministrator {
    createdOn: string
    id: number
    identificationDocumentId: string
    identificationMedia: IdentificationMedia3
    identificationType: IdentificationType6
    organisation: Organisation3
    updatedOn: string
}

export interface IdentificationMedia3 {
    contentType: string
    createdOn: string
    filename: string
    id: number
    identificationType: IdentificationType5
    updatedOn: string
    url: string
}

export interface IdentificationType5 {
    country: Country7
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country7 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface IdentificationType6 {
    country: Country8
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country8 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface Organisation3 {
    contacts: Contact4[]
    description: string
    id: number
    isEnabled: boolean
    name: string
    numberOfUsers: number
    type: string
}

export interface Contact4 {
    isPrimary: boolean
    type: string
    value: string
}

export interface Country9 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface Farmer {
    address: Address3
    cooperativeRegistrationNo: string
    createdOn: string
    id: number
    identificationDocumentId: string
    identificationMedia: IdentificationMedia4
    identificationType: IdentificationType8
    organisation: Organisation4
    updatedOn: string
}

export interface Address3 {
    cityOrTime: string
    country: Country10
    countyOrDistrict: string
    createdOn: string
    digitalAddress: string
    gpsCoordinates: GpsCoordinates3
    id: number
    mailAddress: string
    regionOrState: string
    streetAddress: string
    updatedOn: string
    zipCoderPostCode: string
}

export interface Country10 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface GpsCoordinates3 {
    createdOn: string
    id: number
    latitude: string
    longitude: string
    updatedOn: string
}

export interface IdentificationMedia4 {
    contentType: string
    createdOn: string
    filename: string
    id: number
    identificationType: IdentificationType7
    updatedOn: string
    url: string
}

export interface IdentificationType7 {
    country: Country11
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country11 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface IdentificationType8 {
    country: Country12
    createdOn: string
    description: string
    id: number
    isRequired: boolean
    label: string
    updatedOn: string
}

export interface Country12 {
    code: string
    createdOn: string
    currency: string
    flagImage: string
    id: number
    name: string
    updatedOn: string
}

export interface Organisation4 {
    contacts: Contact5[]
    description: string
    id: number
    isEnabled: boolean
    name: string
    numberOfUsers: number
    type: string
}

export interface Contact5 {
    isPrimary: boolean
    type: string
    value: string
}

export interface Organisation5 {
    contacts: Contact6[]
    description: string
    id: number
    isEnabled: boolean
    name: string
    numberOfUsers: number
    type: string
}

export interface Contact6 {
    isPrimary: boolean
    type: string
    value: string
}

export interface Role {
    description: string
    role: string
    supportedUserRole: string
}

export interface BlackListPaginator extends PaginatorInfo<BlackListUser> {}

//ORGANIZATION

export interface OrganisationsPaginator extends PaginatorInfo<Organisation> {}

export interface OrganisationType {
    name: string
    humanizedName: string
    description: string

}

export interface OrganisationTypePaginator extends PaginatorInfo<OrganisationType> {}

export interface CreateOrEditOrganization {
    name: string
    contact: string
    description?: string
}

//INVITATION

export interface CreateInvitation {
    organisationId: number
    username: string
    role: string
}

// SUPPORTED ROLES
export interface SupportedRole {
    name: string
    humanizedName: string
    description: string

}

export interface SupportedRolePaginator extends PaginatorInfo<SupportedRole> {}


//PRODUCTION REGIONS

export interface ProductionRegion {
    id: number
    name: string
    organisationCount: number
    createdOn: string
    updatedOn: string
}

export interface ProductionRegionPaginator extends PaginatorInfo<ProductionRegion> {}

export interface CreateOrEditProductionRegion {
    name: string
}

export interface BasicCommodity {
    id: number
    name: string
    createdOn: string
    updatedOn: string
}

export interface RegionalCommodity {
    basicCommodity: BasicCommodity
    id: number
    name: string
    productionRegion: ProductionRegion
    createdOn: string
    updatedOn: string

}

export interface ProductionRegionOrganisation {
    id: number
    domain: string
    isBlackListed: boolean
    isEnabled: boolean
    name: string
    productionRegions: ProductionRegion[]
    regionalCommodities: RegionalCommodity[]
    type: string
    createdOn: string
    updatedOn: string
}


export interface ProductionRegionOrganisationPaginator extends PaginatorInfo<ProductionRegionOrganisation> {}

export interface ProductionRegionCommodity {
    id: number
    name: string
    basicCommodity: BasicCommodity
    productionRegion: ProductionRegion
    createdOn: string
    updatedOn: string

}

export interface ProductionRegionCommoditiesPaginator extends PaginatorInfo<ProductionRegionCommodity> {}

//BLACKLIST CATEGORIES

export interface BlackListCategory {
    id: number
    name: string
    timesAssigned: number
    description: string
}

export interface CreatBlackListCategory {
    name: string
    description: string
}

export interface BlackListCategoryPaginator extends PaginatorInfo<BlackListCategory> {}

// PRODUCTION ZONES

export interface ProductionZone {
    id: number
    name: string
    description: string
    productionRegion: ProductionRegion
    createdOn: string
    updatedOn: string
}

export interface ProductionZonePaginator extends PaginatorInfo<ProductionZone> {}

export interface CreateOrEditProductionZone {
    name: string
    description: string
    productionRegionId: number
}




//BASIC COMMODITIES

export interface BasicCommodity {
    id: number
    name: string
    createdOn: string
    updatedOn: string
}

export interface CreateOrEditBasicCommodity {
    name: string
}


export interface BasicCommoditiesPaginator extends PaginatorInfo<BasicCommodity> {}

export interface BasicRegionalCommodity {
    id: number
    name: string
    basicCommodity: BasicCommodity
    productionRegion: ProductionRegion
    createdOn: string
    updatedOn: string

}

export interface BasicRegionalCommoditiesPaginator extends PaginatorInfo<BasicRegionalCommodity> {}

//REGIONAL COMMODITIES

export interface RegionalCommodity {
    id: number
    name: string
    basicCommodity: BasicCommodity
    productionRegion: ProductionRegion
    createdOn: string
    updatedOn: string
}

export interface CreateOrEditRegionalCommodity {
    name: string
    basicCommodity: number
    productionRegion: number
}

export interface RegionalCommoditiesPaginator extends PaginatorInfo<RegionalCommodity> {}

// WAREHOUSES

export interface WarehouseContact {
    id: number
    isContactable: boolean
    isPrimary: boolean
    organisation?: Organisation
    type: string
    value: string
    createdOn: string
    updatedOn: string
}

export interface OperatedBy {
    id: number
    name: string
}

export interface OwnedBy {
    id: number
    name: string
}

export interface StorageCapacity {
    id: number
    unit: string
    value: number
    createdOn: string
    updatedOn: string
}

export interface WarehouseType {
    id: number
    label: string
    isEnabled: boolean
    description: string
    createdOn: string
}

export interface Warehouse {
    id: number
    name: string
    description: string
    address: Address
    buyerWarehouseOperator?: BuyerWarehouseOperator
    contacts: WarehouseContact[]
    isFactoryWarehouse: boolean
    isOperational: boolean
    operatedBy: OperatedBy
    ownedBy: OwnedBy
    storageCapacity: StorageCapacity
    type: WarehouseType
    organisation: Organisation
    createdOn: string
    updatedOn: string
}

export interface WarehousePaginator extends PaginatorInfo<Warehouse> {}

export interface CreateOrEditWarehouse {
    name: string
    description: string
}

//EDUCATION LEVELS

export interface EducationLevel {
    name: string
    humanizedName: string
    description: string
}

export interface EducationLevelPaginator extends PaginatorInfo<EducationLevel> {}


// COUNTRIES

export interface SupportedCountry {
    id: number
    name: string
    code: string
    officialName: string
    currency: string
    flag: string
    callingCode: string
    tld: string
    region: string
    subRegion: string
    language: string
    createdOn: string
    updatedOn: string

}
export interface CountryPage {
    id: number
    organisation: {
        id: number
        name: string
    }
    supportedCountry: SupportedCountry
    createdOn: string
    updatedOn: string
}

export interface AddOrEditCountry {
    name: string
    code: string
}

export interface CountriesPaginator extends PaginatorInfo<CountryPage> {}


// USER ORGANISATIONS

export interface UserOrganisation {
    address: Address
    contacts: Contact[]
    description: string
    id: number
    isBlacklisted: boolean|null
    isEnabled: boolean
    name: string
    numberOfUsers: number
    productionRegions: ProductionRegion[]
    productionZones: ProductionZone[]
    representative: auth.RootObject

}

export interface AddUserOrganisation {
    organisationId: number
}

export interface UserOrganisationsPaginator extends PaginatorInfo<UserOrganisation> {}
