/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import cn from "classnames";
import { Box, Textfield, Text, Button } from "@components/common";
import Heading from "@components/heading";
import { WizardChildRef } from "@components/common/wizard";

type Props = {} & WizardChildRef;

const Security: React.FC<Props> = (props) => {
  const { goToStep } = props;
  return (
    <div className={"root px-3 lg:px-0 h-fit"}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            " flex lg:flex-row flex-col gap-4 lg:gap-0 items-center justify-between mb-[2.5rem]"
          }
        >
          <Box col className={"w-full"}>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              Security
            </Heading>
            <Text className={cn(s.subtitle, "max-w-[29.0625rem] text-left")}>
              You can change your password settings
            </Text>
          </Box>
          <Box row>
            <Button
              variant="icon"
              className="lg:btn-md mr-6"
              onClick={() => {
                goToStep && goToStep(0);
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => {}} variant="fill" pill className="btn-md">
              Save
            </Button>
          </Box>
        </Box>
        {/* personal Information */}
        <Box form className={"section no-border !pb-0 gi-inputs"}>
          <Textfield
            color="primary"
            placeholder="Enter old password"
            label={"Old Password"}
          />
          <Textfield
            color="primary"
            placeholder="Enter new password"
            label={"New Password"}
          />
          <Textfield
            color="primary"
            placeholder="Enter new password"
            label={"Re-enter Password"}
          />
        </Box>
      </Box>
    </div>
  );
};
export default Security;
