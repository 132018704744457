/**
 *Project:Traceability Platform
 *File:blacklist
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React from "react";
import s from "./index.module.css";
import { Button } from "@components/common";
import Heading from "@components/heading";
import { AiOutlineArrowLeft } from "react-icons/ai";
import BlacklistTable from "@features/tables/system/blacklist-table";
import { useNavigate } from "react-router-dom";

type Props = {};

const Blacklist: React.FC<Props> = () => {
  const navigate = useNavigate();
  return (
    <div className={s.root}>
      <div className="flex flex-row items-center gap-7 pb-14">
        <Button
          className={s.backButton}
          variant={"icon"}
          onClick={() => navigate(-1)}
        >
          <AiOutlineArrowLeft />
        </Button>
        <Heading className={""}>Blacklist</Heading>
      </div>
      <BlacklistTable />
    </div>
  );
};

export default Blacklist;
