/**
 *Project:Traceability Platform
 *File:information
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import "@styles/forms.css";

import { Box, Button, Text } from "@components/common";
import { WizardChildRef } from "@components/common/wizard";
import Heading from "@components/heading";
import { DeleteDialog } from "@features/Utils";
import { useModalContext } from "@layout/app/context";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {CreateOrEditProductionZone, ProductionRegion, ProductionZone} from "@models/query/system";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {FieldValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDeleteZoneMutation, useUpdateZoneMutation} from "services/system/zones";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import FormProvider from "@forms/FormProvider";
import TextWrapper from "@forms/TextWrapper";
import s from "@pages/app/system/organizations/general-information/index.module.css";
import SelectWrapper from "@forms/SelectWrapper";
import {useProductionRegions} from "services/system/regions";
import TextAreaWrapper from "@forms/TextAreaWrapper";

type Props = {
  data: ProductionZone | undefined;
  error: any;
  isLoading: boolean;
} & WizardChildRef;

const GeneralInformation: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState(false);

  const { generateModal } = useModalContext();

  const navigate = useNavigate();

  const { regions } = useProductionRegions({
    pageSize: 999,
  });

  const { data, isLoading, error } = props;

  const zoneValidationSchema = Yup.object().shape({
    name: Yup.string().required('Production zone name is required'),
    description: Yup.string().required('Description is required'),
    productionRegionId: Yup.number().required('Production zone is required'),
  });
  const defaultValues: FieldValues = {
    name: "",
    description: "",
    productionRegionId: 0,
  };

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(zoneValidationSchema),
    defaultValues,
  });

  const { handleSubmit, reset, setValue, formState: { isValid } } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    setEdit(false);

  }

  const { mutate: updateZone, isLoading: updating, error: updateError } =
      useUpdateZoneMutation({
        setEdit: onSubmitFinish
      });

  const goBack = () => {
    navigate(-1);

  }

  const { mutate: deleteZone, isLoading: deleting, error: DeleteError } =
      useDeleteZoneMutation(goBack);

  const onSubmit = (zone: CreateOrEditProductionZone) => {


    if (data) {
      updateZone({
        variables: {
          input: zone,
          id: data.id
        }
      });
      setEdit(false);
    }
  }

  useEffect(() => {
    if (data) reset({
        name: data.name,
        description: data.description,
        productionRegionId: data.productionRegion.id
    })
    return () =>{};
  }, [data]);


  useEffect(() => {
    if (DeleteError) {
      let handler = DeleteError as AxiosError
      console.log('handler', handler)
      if (handler.response?.data) {
        // @ts-ignore
        toast.error(handler.response?.data.message);

      }
    }
    return () =>{};
  }, [DeleteError]);

  useEffect(() => {
    if (updateError) {
      let handler = updateError as AxiosError
      if (handler.response?.data) {
       // @ts-ignore
        if ('errors' in handler.response?.data) {
          // @ts-ignore
          toast.error(handler.response?.data.errors[0].message);
        }

      }
    }
    return () =>{};
  }, [updateError]);

  return (
    <div className={"root px-3 lg:px-0"}>
      {/*@ts-ignore*/}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box col className={cn("w-full")}>
        <Box
          className={
            "flex lg:flex-row flex-col gap-4 lg:gap-0 items-center header justify-between"
          }
        >
          <Box col>
            <Heading size="lg100" className={"text-left fs-30 lufga-semi-bold"}>
              General Information
            </Heading>
            <Text className={cn("gi-subtitle", "max-w-[29.0625rem] text-left")}>
              You can change the details of the production Zone.
            </Text>
          </Box>
          <Box row>
            {edit ? (
              <>
                <a
                    className="btn btn-md mt-5"
                    onClick={() => setEdit(false)}
                    type={'button'}
                    role={'button'}
                >Cancel</a>
                <Button
                    disabled={!isValid || updating}
                  variant="fill"
                  pill
                  className="btn-md"
                    label={updating ? 'Saving Changes...' : 'Save'}
                />
              </>
            ) : (
              <>
                <Button
                  variant="icon"
                  className="btn-md"
                  onClick={() => setEdit((current) => !current)}
                  type={'button'}
                >
                  Edit
                </Button>
                <Button
                  variant="fill"
                  pill
                  className="px-6"
                  type={'button'}
                  onClick={() =>
                    generateModal(
                      "Delete Production Zone",
                      <DeleteDialog
                          prompt=" Are you sure you want to delete this Production Zone ?"
                          callback={() => data ? deleteZone({
                            variables: {
                              id: data.id
                            }
                          }) : null}
                          loading={deleting}
                      />,
                      "center"
                    )
                  }
                >
                  Delete Production Zone
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box col className={"section no-border"}>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Production Region
            </Text>
            {edit ? (
                <SelectWrapper
                    name={"productionRegionId"}
                    className={'mb-4'}
                    required
                    placeholder={"Select production region"}
                    options={regions}
                    isLoading={isLoading}
                    loadingMessage={() => "Fetching production regions..."}
                    getOptionValue={(option: any) => option.id}
                    getOptionLabel={(option: any) => option.name}
                    value={regions.find(
                        (region: any) => region.id === methods.getValues("productionRegionId")
                    )}
                    onChange={(newValue: unknown, actionMeta) => {
                      let value = newValue as ProductionRegion;

                      setValue("productionRegionId", value.id);
                    }}
                />
            ) : (
                <Text className={"subtitle"}>{isLoading ? 'Fetching' : data?.productionRegion.name}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Name of Production <br /> Zone
            </Text>
            {edit ? (
                <TextWrapper
                    name={"name"}
                    color="primary"
                    placeholder="Northern Savannah Belt"
                    className={s.input}
                />
            ) : (
                <Text className={"subtitle"}>{isLoading ? 'Fetching' : data?.name}</Text>
            )}
          </Box>
          <Box row className={"gi-field"}>
            <Text Component={"label"} className={"label"}>
              Description
            </Text>
            {edit ? (
                <TextAreaWrapper
                    name={'description'}
                    required
                    label={"Production Zone Description"}
                    placeholder="Enter Description"
                    rows={3}
                />
            ) : (
                <Text className={"subtitle"}>{isLoading ? 'Fetching' : data?.description}</Text>
            )}
          </Box>
        </Box>
      </Box>
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
