/**
 * Project: tracebility-dashboard
 * File: authReducer
 * Created by Pennycodes on 9/27/2022.
 * Copyright tracebility-dashboard
 */
import createReducer from 'hooks/createReducer';
import * as types from '../actions/types';
import { IAuthState } from 'models/store/auth';
import auth from 'models/query/auth';


const initialState: IAuthState = {
    isAuthenticated: false,
    accountActive: false,
    user: null,
    status: 100,
    isInitialized: false,
    role: '',
}

const authReducer = createReducer(initialState, {
    [types.INITIALIZE](state: IAuthState, action: { isAuthenticated: boolean, user: any }) {
        const { isAuthenticated, user } = action;
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    [types.LOGIN_REQUEST](state: IAuthState, action: { role: string }) {
        return {
            ...state,
            status: 100,
            role: action.role,
        };
    },
    [types.LOGIN_LOADING_ENDED](state: IAuthState) {
        return {
            ...state,
            isAuthenticated: true,
            status: 100,
        };
    },
    [types.INACTIVE_ACCOUNT](state: IAuthState, action:auth.RootObject) {
        return { ...state, accountActive: false, user: action };
    },
    [types.LOGIN_RESPONSE](state: IAuthState, action: auth.RootObject) {
        const accountActive = action.status === 'ACTIVE';
        return {
            ...state,
            status: 200,
            user: action,
            role: action.role.role,
            accountActive,
        };
    },
    [types.LOGIN_FAILED](state: IAuthState, action: { status: number }) {
        return {
            ...state,
            status: action.status,
            isAuthenticated: false,
        };
    },
    [types.LOG_OUT](state: IAuthState) {
        return {
            ...state,
            isAuthenticated: false,
            isInitialized: false,
            user: null,
            token: '',
            status: 0,
            role: '',
        };
    },
});
export default authReducer;
