/**
 *Project:Traceability Platform
 *File:organizations
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import AddProductionZone from "@forms/system/add-production-zone";
import TopBar from "@layout/app/partials/top-bar";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useModalContext} from "@layout/app/context";
import {FieldValues, useForm} from "react-hook-form";
import {useDeleteZoneMutation, useProductionZones} from "services/system/zones";
import {TableColumn, TablePagination} from "@models/store/table";
import {ProductionRegion, ProductionZone} from "@models/query/system";
import {Box, Button} from "@components/common";
import cn from "classnames";
import u from "@features/tables/system/users-table/index.module.css";
import {DeleteDialog} from "@features/Utils";
import {AiOutlineDelete, AiOutlineSearch} from "react-icons/ai";
import AppGuard from "guards/AppGuard";
import FormProvider from "@forms/FormProvider";
import s from "@layout/app/partials/top-bar/index.module.css";
import TextWrapper from "@forms/TextWrapper";
import Table from "@components/table/Table";

type Props = {};

const Default: React.FC<Props> = (props) => {


    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const { generateModal } = useModalContext();

    const defaultValues: FieldValues = {
        search: '',
    };
    const methods = useForm({
        defaultValues,
    });

    const { watch } = methods;

    const {
        zones,
        paginatorInfo,
        isLoading,
        error,
        loadNext,
        loadPrevious
    } = useProductionZones({
        page,
        pageSize,
        search: searchTerm,
    });
    
    const { mutate: deleteZone, isLoading: deleting } =
        useDeleteZoneMutation()


    function handleSearch(searchText: string) {
        setSearchTerm(searchText);
        setPage(0);
    }

    const handleClick = (id: number) => {
        navigate(`${id}`);

    }

    useEffect(() => {
        const subscription = watch((value:any) => {
            handleSearch(value.search);
        });
        return () => subscription.unsubscribe();
    }, [watch]);


    const Pagination: TablePagination | false  = paginatorInfo ? {
        count: paginatorInfo.totalCount,
        pageSize: paginatorInfo.pageSize,
        page: paginatorInfo.page,

    } : false;
    const Columns: Array<TableColumn> = [
        {
            key: 'name',
            title: 'Production Zone',
            onClick: (zone: ProductionZone) => handleClick(zone.id),
            render: (text: string) => (
                <div className="flex items-center">
                    <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                            {text}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'productionRegion',
            title: 'Production Region',
            onClick: (zone: ProductionZone) => handleClick(zone.id),
            render: (text: ProductionRegion) => (
                <div className="text-sm text-gray-500">
                    {text.name}
                </div>
            )
        },
        {
            key: 'description',
            title: 'Description',
            onClick: (zone: ProductionZone) => handleClick(zone.id),
        },

        {
            key: 'id',
            title: '',
            className: 'text-end',
            render: (text: number) => (
                <Box row className={cn(u.box, u.actions)}>
                    <Button
                        variant="icon"
                        className="!hidden lg:!flex"
                        onClick={() =>
                            generateModal(
                                "Delete Production Zone",
                                <DeleteDialog
                                    prompt={"Are you sure you want to delete this Production Zone?"}
                                    callback={() => deleteZone({
                                        variables: {
                                            id: text
                                        }
                                    })}
                                    loading={deleting}
                                />,
                                "center"
                            )
                        }
                    >
                        <AiOutlineDelete />
                    </Button>
                </Box>
            )
        }
    ]

    return (
        <AppGuard  accessibleRoles={["systemAdministrator"]}>
      <div className={" "}>
        <TopBar
          title="Production Zone"
          modalTitle={"Add New \n Production Zone"}
          renderForm={<AddProductionZone />}
          addButtonLabel={"New Production Zone"}
        />
          <FormProvider methods={methods} onSubmit={()=>{}} >

              <Box row alignItems={"center"} className={cn(s.search, 'mb-4')}>
                  <TextWrapper
                      name={'search'}
                      className={s.input}
                      variant="borderless"
                      placeholder={"Search"}
                  />
                  <AiOutlineSearch />

              </Box>
          </FormProvider>
          <Table
              tableClassName={'table-hover'}
              loading={isLoading}
              columns={Columns}
              dataSource={zones}
              pagination={Pagination}
              pageSize={pageSize}
              loadNext={loadNext}
              loadPrevious={loadPrevious}
              setPage={setPage}
              page={page}
              error={error}
              emptyText={'No production zone available'}
          />
      </div>
        </AppGuard>
  );
};

export default Default;
