import {
    ClientTransactionPaginator,
    QueryOptionsTwo,
    WarehouseDispatchedGoodsPaginator,
    WarehouseReceivedGoodsPaginator
} from "models/query/buyer";
import ApiConfig from "services/endpoints";
import client from './index';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { CreateOrEditWarehouse, Warehouse, WarehousePaginator } from "models/query/system";

export function useOrgWarehouses(id: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<WarehousePaginator, Error>(
        [ApiConfig.WAREHOUSES.ALL.replace('{organisationId}', id), options],
        ({ queryKey, pageParam }) =>
            client.warehouses.all(id, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    }
    return {
        data: data?.pages.flatMap((page) => page.data) ?? [],
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useOrgWarehouse(orgId:string, id: string) {
    const { data, isLoading, error } = useQuery<WarehousePaginator, Error>([ApiConfig.PRODUCTION_REGIONS.DETAILS, id],
        () => client.warehouses.details(orgId, id),
        {
            enabled: !!id,
        }
    );

    return {
        data: data?.data[0],
        isLoading,
        error,
    };
}

export const useCreateOrgWarehouseMutation = (reset: (data?: any) => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ variables: { organisationId, input } }: {variables: {input: Warehouse, organisationId: string}}) =>
            client.warehouses.create(organisationId, input),
        {
            onSuccess: () => {
                toast.success('Warehouse created successfully');
                reset()
            },
            // Always refetch after error or success:
            onSettled: (data, error, param) => {
                const organisationId = param.variables.organisationId.toString();
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL.replace('{organisationId}', organisationId));
            },
        }
    );
};

export const useDeleteWarehouseMutation = (reset?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { organisationId, warehouseId } }: {variables: {organisationId: string, warehouseId: number}}) =>
            client.warehouses.delete(organisationId, warehouseId),
        {
            onSuccess: () => {
                toast.success('Warehouse removed successfully');
                reset && reset();
            },
            onError: (error) => {
                toast.error('An error occured');
            },
            // Always refetch after error or success:
            onSettled: (data, error, param) => {
                const organisationId = param.variables.organisationId.toString();
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL.replace('{organisationId}', organisationId));
            },
        }
    );
}

export const useUpdateWarehouseMutation = (props: any) => {
    const queryClient = useQueryClient();
    const { setEdit } = props
    return useMutation(
        ({ variables: { organisationId, warehouseId, input } }: {variables: {organisationId: string, warehouseId: number, input: CreateOrEditWarehouse}}) =>
            client.warehouses.update(organisationId, warehouseId, input),
        {
            onSuccess: () => {
                toast.success('Warehouse updated successfully');
                setEdit(false)
            },
            onError: (error) => {
                console.log({error})
                toast.error('An error occured whiles updating ware house');
            },
            // Always refetch after error or success:
            onSettled: (data, error, param) => {
                const organisationId = param.variables.organisationId.toString();
                queryClient.invalidateQueries(ApiConfig.WAREHOUSES.ALL.replace('{organisationId}', organisationId));
                // queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.ALL);
                // queryClient.invalidateQueries(ApiConfig.PRODUCTION_REGIONS.DETAILS);
            },
        }
    );
};


export function useWarehouseReceivedGoods(warehouseId: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<WarehouseReceivedGoodsPaginator, Error>(
        [ApiConfig.WAREHOUSES.RECEIVED_GOODS.replace('{warehouseId}', warehouseId), options],
        ({ queryKey, pageParam }) =>
            client.warehouses.receivedGoods(warehouseId, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    };

    const response = data?.pages.flatMap((page) => page.data) ?? [];

    return {
        data: response,
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

export function useWarehouseDispatchedGoods(warehouseId: string, options?: QueryOptionsTwo) {
    const {
        data,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        hasPreviousPage,
        fetchPreviousPage,
    } = useInfiniteQuery<WarehouseDispatchedGoodsPaginator, Error>(
        [ApiConfig.WAREHOUSES.DISPATCHED_GOODS.replace('{warehouseId}', warehouseId), options],
        ({ queryKey, pageParam }) =>
            client.warehouses.dispatchedGoods(warehouseId, Object.assign({}, queryKey[1], pageParam)),
        {
            getNextPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page + 1, size: pageSize };
                }
                return false;
            },
            getPreviousPageParam: ({ page, pageSize, totalCount }) => {
                if (page * pageSize < totalCount) {
                    return { page: page - 1, size: pageSize };
                }
                return false;
            },
        }
    );
    function handleLoadNext(page?: number) {
        if (hasNextPage) {
            if (page) {
                fetchNextPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchNextPage();
            }
        }
    }

    function handleLoadPrevious(page?: number) {
        if (hasPreviousPage) {
            if (page) {
                fetchPreviousPage({
                    pageParam: {
                        page
                    }
                });
            } else {
                fetchPreviousPage();
            }
        }
    };

    const response = data?.pages.flatMap((page) => page.data) ?? [];

    return {
        data: response,
        paginatorInfo: Array.isArray(data?.pages)
            ? data?.pages[data.pages.length - 1]
            : null,
        hasNextPage,
        isLoadingMore: isFetchingNextPage,
        isLoading,
        error,
        loadNext: handleLoadNext,
        loadPrevious: handleLoadPrevious,
    };
}

