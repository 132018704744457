/**
 * Project: tracebility-dashboard
 * File: NotiStack
 * Created by pennycodes on 18/10/2022.
 * Copyright tracebility-dashboard
 */

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from './Icon';

export default function NotificationProvider() {


    return (

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            closeButton={(key) => (
                <Iconify icon={'eva:close-fill'} />
            )}

        />
    );
}

