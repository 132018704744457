/**
 * Project: tracebility-dashboard
 * File: index
 * Created by pennycodes on 08/11/2022.
 * Copyright tracebility-dashboard
 */
import React from "react";
import ReactSelect, { Props } from "react-select";
import { selectStyles } from "./style";

export type Ref = any;

export interface SelectProps extends Props {}

export const Select = React.forwardRef<Ref, Props>((props, ref) => (
    <ReactSelect styles={selectStyles} {...props} ref={ref} />
));
Select.displayName = "Select";
export default Select;
