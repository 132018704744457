/**
 *Project:Traceability Platform
 *File:add-regional-commodity
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { Box, Button } from '@components/common';
import { useModalContext } from '@layout/app/context';
import { useRegionCommodities, useSetOrgCommodityMutation } from 'services/buyer/goods';
import React from 'react';
import Select from 'react-select';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import SelectWrapper from "@forms/SelectWrapper";
import FormProvider from "@forms/FormProvider";
import { useAppSelector } from 'hooks/appHooks';

type Props = {};

const AddRegionalCommodity: React.FC<Props> = (props) => {
  const { closeModal } = useModalContext();


  const defaultValues: FieldValues = {
    regionId: "",
    // organizationId: 0,
  };

  const regionValidationSchema = Yup.object().shape({
    regionId: Yup.string().required("Regional commodity is required"),
  });

  const methods = useForm({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(regionValidationSchema),
    defaultValues,
  });


  const { handleSubmit, reset, setValue, formState: { isValid } } = methods;

  const onSubmitFinish = () => {
    reset(defaultValues);
    closeModal();
  };

  const { commodities, isLoading: isCommoditiesLoading } = useRegionCommodities({ page: 0, pageSize: 999, search: "" });
  const { mutate: setCommodity, isLoading: creating } = useSetOrgCommodityMutation(onSubmitFinish);
  const { user } = useAppSelector((state) => state.auth);

  const onSubmit = (data: any) => {
    const organisationId = user?.organisation?.id;
    console.log({data, organisationId});
    
    setCommodity({
      variables: {
        orgId: organisationId,
        coomodityId: data.regionId
      }
    })
  }

  return (
    <div className={"flex flex-col gap-[2.1875rem] pb-5 w-full"}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

        {/* <div className="flex flex-col gap-[8px]">
          <label className="text-start w-fit mr-auto text-[ #131313] text-sm font-bold">
            Select Regional Commodity <span style={{ color: "red" }}>*</span>
          </label>
          <Select placeholder={'Select regional commodity'} className="input" 
          options={commodities.map(x => ({ label: x.name, value: x.id, ...x }))} />
        </div> */}

        <SelectWrapper
          name={"regionId"}
          required
          placeholder={"Select regional commodity"}
          options={commodities}
          isLoading={isCommoditiesLoading}
          loadingMessage={() => "Fetching supported regions..."}
          getOptionValue={(option: any) => option.id}
          getOptionLabel={(option: any) => option.name}
          value={commodities.find(
            (role: any) => role.name === methods.getValues("regionId")
          )}
          onChange={(newValue: unknown, actionMeta) => {
            const value = newValue as { id: string; name: string };

            if (value) {
              setValue("regionId", value.id);
            }
          }}
        />


        <Box
          row
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={"1.875rem"}
          className="mt-[4rem]"
        >
          <Button
            variant="icon"
            style={{ color: "#20201F" }}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!isValid || creating}
            pill
            style={{ width: "13.75rem" }}
          >
            {creating ? "Processing..." : "Add Regional Commodity"}
          </Button>
        </Box>
      </FormProvider>
    </div>
  );
};

export default AddRegionalCommodity;
