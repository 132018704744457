import { buyerAuthRoutes } from "utils/BuyerRoutes";
import { systemAppRoutes, systemAuthRoutes } from "utils/SystemRoutes";
import { buyerAppRoutes } from "utils/BuyerRoutes";

export const getAuthRoutes = (mode = "buyer") => {
  // return mode === "systemAdministrator" ? systemAuthRoutes : buyerAuthRoutes;
  return buyerAuthRoutes;
};

export const getAppRoutes = (mode: string) => {
  return mode === "systemAdministrator" ? systemAppRoutes : buyerAppRoutes;
};
export const mode = "buyer";
