/**
 * Project: tracebility-dashboard
 * File: index
 * Created by pennycodes on 22/01/2023.
 * Copyright tracebility-dashboard
 */

import { HttpClient } from "services/requests";
import {
    QueryOptions,
    QueryOptionsTwo,
    ClientsPaginator,
    CreateOrEditClient,
    ProductionZonePaginator,
    CreateOrEditProductionZone,
    ProductionRegionPaginator,
    CreateOrEditProductionRegion,
    ProductionRegionOrganisationPaginator,
    ProductionRegionCommoditiesPaginator,
    ISetProductionRegion,
    ICreateAvailableGoods,
    IAvailableGoodsPaginator,
    CreateInvitation,
    ICreateVehicle,
    IVehiclePaginator,
    IBatchPaginator,
    IBatch,
    ClientTransactionPaginator,
    WarehouseReceivedGoodsPaginator, WarehouseDispatchedGoodsPaginator
} from "@models/query/buyer";
import ApiConfig from "services/endpoints";
import { AcceptInvitation, AddBlacklist, AddUserOrganisation, BlackListPaginator, CountQuery, CreateOrEditWarehouse, DashboardCount, InvitationCount, InvitationPaginator, InvitationSearchQuery, ResetPassword, SupportedRolePaginator, UpdateUser, UserOrganisationsPaginator, UserPaginator, UserSearchQuery, WarehousePaginator, WarehousesCount } from "@models/query/system";

class Client {
    users = {
        details: (id: string) => HttpClient.get<UserPaginator>(ApiConfig.USERS.DETAILS.replace('{id}', id)),
        count: () => HttpClient.get<DashboardCount>(ApiConfig.USERS.REPORTS, {}),
        invitationCount: () => HttpClient.get<InvitationCount>(ApiConfig.INVITATIONS.REPORTS, {}),
        all: (query?: UserSearchQuery) =>
            HttpClient.get<UserPaginator>(ApiConfig.USERS.ALL, query),
        delete: (id: number) => HttpClient.delete(ApiConfig.USERS.DELETE.replace('{id}', String(id))),
        blacklist: () => HttpClient.get<BlackListPaginator>(ApiConfig.USERS.BLACKLIST, {}),
        supportedRoles: (query?: QueryOptions) => HttpClient.get<SupportedRolePaginator>(ApiConfig.USERS.SUPPORTED_ROLES, query),
        updateRole: (id: number, role: string) => HttpClient.put(ApiConfig.USERS.ROLE.replace('{id}', String(id)), { role }),
        updatePassword: (id: number, data: ResetPassword) => HttpClient.put(ApiConfig.USERS.PASSWORD.replace('{id}', String(id)), data),
        enable: (id: number) => HttpClient.put(ApiConfig.USERS.ENABLE.replace('{id}', String(id)), {}),
        disable: (id: number) => HttpClient.put(ApiConfig.USERS.DISABLE.replace('{id}', String(id)), {}),
        update: (id: number, data: UpdateUser) => HttpClient.put(ApiConfig.USERS.UPDATE.replace('{id}', String(id)), data),
        add_blacklist: (id: number, data: AddBlacklist) => HttpClient.put(ApiConfig.USERS.ADD_BLACKLIST.replace('{id}', String(id)), data),
        remove_blacklist: (id: number) => HttpClient.delete(ApiConfig.USERS.REMOVE_BLACKLIST.replace('{id}', String(id))),
        organisations: (id: number, query?: QueryOptions) => HttpClient.get<UserOrganisationsPaginator>(ApiConfig.USERS.ORGANISATIONS.ALL.replace('{id}', String(id)), query),
        addOrganisation: (id: number, data: AddUserOrganisation) => HttpClient.put(ApiConfig.USERS.ORGANISATIONS.CREATE.replace('{id}', String(id)), data),
        removeOrganisation: (id: number) => HttpClient.delete(ApiConfig.USERS.ORGANISATIONS.DELETE.replace('{id}', String(id))),
        create:  (data: CreateInvitation) => HttpClient.post(ApiConfig.USERS.CREATE, data),
    };
    invitations = {
        create: (data: CreateInvitation) => HttpClient.post(ApiConfig.INVITATIONS.CREATE, data),
        all: (query?: InvitationSearchQuery) => HttpClient.get<InvitationPaginator>(ApiConfig.INVITATIONS.ALL, query),
        details: (id: string) => HttpClient.get<InvitationPaginator>(ApiConfig.INVITATIONS.DETAILS.replace('{id}', id)),
        delete: (id: string) => HttpClient.delete(ApiConfig.INVITATIONS.DELETE.replace('{id}', id)),
        resend: (id: string) => HttpClient.get(ApiConfig.INVITATIONS.RESEND.replace('{id}', id)),
        accept: (id: string, data: AcceptInvitation) => HttpClient.post(ApiConfig.INVITATIONS.ACCEPT.replace('{id}', id), data),
    };
    clients = {
        all: (organisationId: string, query?: QueryOptionsTwo) => HttpClient.get<ClientsPaginator>(ApiConfig.CLIENTS.ALL.replace('{organisationId}', organisationId), query),
        create: (organisationId: string, data: CreateOrEditClient) => HttpClient.post<ClientsPaginator>(ApiConfig.CLIENTS.CREATE.replace('{organisationId}', organisationId), data),
        update: (organisationId: string, id: string, data: CreateOrEditClient) => HttpClient.put(ApiConfig.CLIENTS.UPDATE.replace('{organisationId}', organisationId).replace('{id}', id), data),
        delete: (organisationId: string, id: string) => HttpClient.delete(ApiConfig.CLIENTS.DELETE.replace('{organisationId}', organisationId).replace('{id}', id)),
        details: (organisationId: string, id: string) => HttpClient.get<ClientsPaginator>(ApiConfig.CLIENTS.DETAILS.replace('{organisationId}', organisationId).replace('{id}', id)),
        transaction: (clientId: string, query?: QueryOptionsTwo) => HttpClient.get<ClientTransactionPaginator>(ApiConfig.TRANSACTION.CLIENT.ALL.replace('{clientId}', clientId), query),
    };
    warehouses = {
        all: (organisationId: string, query?: QueryOptions) => HttpClient.get<WarehousePaginator>(ApiConfig.WAREHOUSES.ALL.replace('{organisationId}', organisationId), query),
        create: (organisationId: string, data: CreateOrEditWarehouse) => HttpClient.post<WarehousePaginator>(ApiConfig.WAREHOUSES.CREATE.replace('{organisationId}', organisationId), data),
        update: (organisationId: string, id: number, data: CreateOrEditWarehouse) => HttpClient.put(ApiConfig.WAREHOUSES.UPDATE.replace('{organisationId}', organisationId).replace('{id}', String(id)), data),
        delete: (organisationId: string, id: number) => HttpClient.delete(ApiConfig.WAREHOUSES.DELETE.replace('{organisationId}', organisationId).replace('{id}', String(id))),
        details: (organisationId: string, id: string) => HttpClient.get<WarehousePaginator>(ApiConfig.WAREHOUSES.DETAILS.replace('{organisationId}', organisationId).replace('{id}', id)),
        status: (organisationId: string, id: string, enabled: boolean) => HttpClient.patch(ApiConfig.WAREHOUSES.STATUS.replace('{organisationId}', organisationId).replace('{id}', id).replace('{enabled}', String(enabled)), {}),
        count: (query?: CountQuery) => HttpClient.get<WarehousesCount>(ApiConfig.WAREHOUSES.COUNT, query),
        receivedGoods: (warehouseId: string, query?: QueryOptionsTwo) => HttpClient.get<WarehouseReceivedGoodsPaginator>(ApiConfig.WAREHOUSES.RECEIVED_GOODS.replace('{warehouseId}', warehouseId), query),
        dispatchedGoods: (warehouseId: string, query?: QueryOptionsTwo) => HttpClient.get<WarehouseDispatchedGoodsPaginator>(ApiConfig.WAREHOUSES.DISPATCHED_GOODS.replace('{warehouseId}', warehouseId), query),
    };
    productionZones = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<ProductionZonePaginator>(ApiConfig.PRODUCTION_ZONES.ALL, query),
        create: (data: CreateOrEditProductionZone) => HttpClient.post<ProductionRegionPaginator>(ApiConfig.PRODUCTION_ZONES.CREATE, data),
        update: (id: number, data: CreateOrEditProductionZone) => HttpClient.put(ApiConfig.PRODUCTION_ZONES.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.PRODUCTION_ZONES.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<ProductionZonePaginator>(ApiConfig.PRODUCTION_ZONES.DETAILS.replace('{id}', id))
    };
    productionRegions = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.ALL, query),
        create: (data: CreateOrEditProductionRegion) => HttpClient.post<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.CREATE, data),
        update: (id: number, data: CreateOrEditProductionRegion) => HttpClient.put(ApiConfig.PRODUCTION_REGIONS.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.PRODUCTION_REGIONS.DELETE_SET_REGION.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.DETAILS.replace('{id}', id)),
        organisations: (id: string, query?: QueryOptions) => HttpClient.get<ProductionRegionOrganisationPaginator>(ApiConfig.PRODUCTION_REGIONS.ORGANISATIONS.replace('{id}', id), query),
        commodities: (id: string, query?: QueryOptionsTwo) => HttpClient.get<ProductionRegionCommoditiesPaginator>(ApiConfig.PRODUCTION_REGIONS.COMMODITIES.replace('{id}', id), query),
        setProductionRegions: (data: ISetProductionRegion) => HttpClient.put<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.SET_REGION, data),
        getOrgProductionRegions: (id: string, query?: QueryOptions) => HttpClient.get<ProductionRegionOrganisationPaginator>(ApiConfig.PRODUCTION_REGIONS.ORGAN.ALL.replace('{id}', id), query),
        deleteOrgProductionRegions: (orgId: string, productionRegionId: string) => HttpClient.put(ApiConfig.PRODUCTION_REGIONS.ORGAN.DELETE.replace('{organisationId}', orgId).replace('{productionRegionId}', productionRegionId), null),
    };
    commodities = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<ProductionRegionCommoditiesPaginator>(ApiConfig.COMMODITIES.REGIONAL.ALL, query),
        allInOrg: (orgId: string, query?: QueryOptionsTwo) => HttpClient.get<ProductionRegionCommoditiesPaginator>(ApiConfig.COMMODITIES.BASIC.ORGANIZATION.replace('{id}', orgId), query),
        createForOrg: (id: string, orgId: string) => HttpClient.put(ApiConfig.COMMODITIES.BASIC.SET.replace('{id}', id).replace('{organisationId}', orgId), null),
        removeForOrg: (id: string, orgId: string) => HttpClient.put(ApiConfig.COMMODITIES.BASIC.REMOVE_ORG.replace('{id}', id).replace('{organisationId}', orgId), null),
    }
    availableGoods = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.GOODS.ALL, query),
        create: (data: any) => HttpClient.post<ICreateAvailableGoods>(ApiConfig.GOODS.CREATE, data),
        update: (id: number, data: any) => HttpClient.put(ApiConfig.GOODS.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.GOODS.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<IAvailableGoodsPaginator>(ApiConfig.GOODS.DETAILS.replace('{id}', id)),
        providerTypes: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.GOODS.GET_PROVIDER_TYPES, query),
        providers: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.GOODS.GET_PROVIDERS, query),
        countries: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.GOODS.GET_COUNTRY, query),
        cooperatives: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.ORGANISATIONS.COOPERATIVES, query),
        //COOPERATIVES
    };
    vehicle = {
        all: (organisationId: string, query?: QueryOptionsTwo) => HttpClient.get<ClientsPaginator>(ApiConfig.VEHICLE.ALL.replace('{organisationId}', organisationId), query),
        create: (organisationId: string, data: ICreateVehicle) => HttpClient.post<ClientsPaginator>(ApiConfig.VEHICLE.CREATE.replace('{organisationId}', organisationId), data),
        update: (organisationId: string, id: string, data: ICreateVehicle) => HttpClient.put(ApiConfig.VEHICLE.UPDATE.replace('{organisationId}', organisationId).replace('{id}', id), data),
        delete: (organisationId: string, id: string) => HttpClient.delete(ApiConfig.VEHICLE.DELETE.replace('{organisationId}', organisationId).replace('{id}', id)),
        details: (organisationId: string, id: string) => HttpClient.get<IVehiclePaginator>(ApiConfig.VEHICLE.DETAILS.replace('{organisationId}', organisationId).replace('{id}', id)),
    };
    batch = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.BATCH.ALL, query),
        create: (data: any) => HttpClient.post<IBatch>(ApiConfig.BATCH.CREATE, data),
        update: (id: number, data: any) => HttpClient.put(ApiConfig.BATCH.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.BATCH.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<IBatchPaginator>(ApiConfig.BATCH.DETAILS.replace('{id}', id)),
    };
    vendor = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.VENDOR.ALL, query),
    }
    country = {
        all: (orgId: string, query?: QueryOptionsTwo) => HttpClient.get<any>(ApiConfig.COUNTRIES.ALL.replace('{organisationId}', orgId), query),
    }

    reporting = {
        search: (orgId: string, tagId: string) => HttpClient.get<any>(ApiConfig.REPORTING.TRACE.SEARCH.replace('{organisationId}', orgId).replace('{tagId}', tagId),),
        queryReport: (param: string) => HttpClient.get<any>(ApiConfig.REPORTING.QUERY + param),
    }
}

export default new Client();