/**
 * Project: tracebility-dashboard
 * File: Table
 * Created by pennycodes on 08/11/2022.
 * Copyright tracebility-dashboard
 */
import classnames from 'classnames';
import { v4 as uuid } from "uuid";
import {TableColumn, TableProps} from "@models/store/table";
import TableShimmer from "@components/shimmers/Table";
import s from "@features/tables/system/users-table/index.module.css";
import TableComponent from "@components/table";


interface Props extends  TableProps {
    className?: string;
    tableClassName?: string;
    tableHeaderClassName?: string;
    tableBodyClassName?: string;
    hasNextPage?: boolean
    loadNext: (page?: number) => void
    hasPreviousPage?: boolean
    loadPrevious: (page?: number) => void
    setPage: (value: any) => void
    page: number,
    emptyText?: string
    error: Error | null
}

const Table = (props: Props) => {

    const {
        dataSource,
        columns,
        loading,
        pagination,
        tableClassName,
        tableBodyClassName,
        tableHeaderClassName,
        pageSize,
        setPage,
        loadNext,
        loadPrevious,
        page,
        emptyText,
        error
    } = props;


    const handlePage = (page: number , callback: (page?: number) =>void ) => {
        setPage(page);
        callback(page);
    }
    // @ts-ignore
    return (
        loading ? (
            <TableShimmer columns={columns.length} rows={7} /> ) : (
            <div className={s.root}>
                <div className={s.tableContainer}>
                    <TableComponent className={classnames(s.table, tableClassName)}>
                        <TableComponent.Header className={classnames(s.rowLayout, tableHeaderClassName)}>
                            {columns.map((column: TableColumn) => (
                                column.show !== false && <TableComponent.Head className={classnames(s.heading, column.className)} key={column.key}>{column.title}</TableComponent.Head>
                            ))}

                        </TableComponent.Header>
                        {/*<TableComponent.Row className={classnames(s.row, s.rowLayout, tableBodyClassName)}>*/}
                        {dataSource.map((data: any) => (
                            <TableComponent.Row className={classnames(s.row, s.rowLayout, tableBodyClassName)} onClick={() => {}} key={uuid()}>
                                {columns.map((column: TableColumn) => (
                                    column.show !== false && <TableComponent.Cell className={classnames(s.cell, column.className)} onClick={ (_e) => column.onClick && column.onClick(data)}  key={column.key + uuid()}>{column.render ? column.render(data[column.key], data) : data[column.key]}</TableComponent.Cell>
                                ))}
                            </TableComponent.Row>
                        ))}
                        {dataSource.length === 0 && (
                            <TableComponent.Row className={classnames(s.row, s.rowLayout, tableBodyClassName)}>
                                <td colSpan={columns.length} className={classnames('text-center')}>{emptyText ?? 'No data found'}</td>
                            </TableComponent.Row>
                        )}

                        {error && !loading && (
                            <tr>
                                <td colSpan={columns.length} className={'text-center'}>{error.message}</td>
                            </tr>
                        )}
                        {/*</TableComponent.Row>*/}

                    </TableComponent>
                </div>
                {pagination && pagination.count > 0 ? (
                    <div className="mt-3">
                        <nav aria-label="Page navigation">
                            <ul className="inline-flex -space-x-px">
                                {page > 0 &&  <li ><a
                                    onClick={()=> handlePage(page-1, loadPrevious)}
                                    className="py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    href="#!">
                                   Previous
                                </a></li>}
                                {page > 2 && (
                                    <>
                                        <li ><a
                                            onClick={()=> handlePage(0, loadPrevious)}
                                            className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            href="#!">1</a></li>
                                        <li ><a className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dot" href="#!">...</a></li>
                                    </>
                                )}
                                {page - 2  > 0 &&   <li ><a  onClick={()=> handlePage(page-2, loadPrevious)} className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" href="#!">{page-1}</a></li>}
                                {page - 1  > 0 &&   <li ><a  onClick={()=> handlePage(page-1, loadPrevious)} className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" href="#!">{page}</a></li>}
                                <li ><a  aria-current="page" className="py-2 px-3 text-green-600 bg-green-50 border border-gray-300 hover:bg-green-100 hover:text-green-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white" >{page+1}</a></li>
                                {page + 2  < (pagination.count/pageSize) + 1 &&   <li ><a  onClick={()=> handlePage(page+1, loadNext)} className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" href="#!">{page+2}</a></li>}
                                {page + 3  < (pagination.count/pageSize) + 1 &&   <li ><a  onClick={()=> handlePage(page+2, loadNext)} className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" href="#!">{page+3}</a></li>}
                                {page+1  < Math.ceil(pagination.count/pageSize) - 2 &&   (
                                    <>
                                        <li ><a className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dot" href="#!">...</a></li>
                                        <li ><a  onClick={()=> handlePage(Math.floor(pagination.count/pageSize), loadNext)} className="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" href="#!">{Math.ceil(pagination.count/pageSize)}</a></li>
                                    </>
                                )}

                                {page+1 < (pagination.count/pageSize) &&    <li ><a onClick={()=> handlePage(page+1, loadNext)} className="py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" href="#!">Next</a></li> }

                            </ul>
                        </nav>
                    </div>
                ): null}

            </div>

        )
    );
}

export default Table;

