/**
 *Project:Traceability Platform
 *File:badge
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import React, { ReactNode } from "react";
import s from "./index.module.css";
import cn from "classnames";
import { Badge as BadgeRoot } from "@components/common";
type Props = {
  status?: string;
  label?: string;
};

const Badge: React.FC<Props> = (props) => {
  const { status, label } = props;
  const setColor = () => {
    switch (status) {
      case "active":
        return "green" as const;
      case "pending":
        return "yellow" as const;
      case "disabled":
        return "red" as const;
      case "rejected":
        return "red" as const;
    }
  };
  return (
    <BadgeRoot color={setColor()} className={s.root}>
      {label || status}
    </BadgeRoot>
  );
};

export default Badge;
