/**
 *Project:Traceability Platform
 *File:users
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

import Default from "./Default";
import Details from "./Details";

type Props = {};

const ProductionZone: FunctionComponent<Props> & {
  Details: FunctionComponent<Props>;
  Default: FunctionComponent<Props>;
} = (props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
ProductionZone.Default = Default;
ProductionZone.Details = Details;
export default ProductionZone;
