/**
 *Project:Traceability Platform
 *File:users-table
 *Created by KojoGyaase
 *Copyright Bentil
 **/
import Table from "@components/table";
import React, { useState } from "react";

import s from "./index.module.css";
import { TableItem } from "./TableItem";

type Props = {};

const UsersTable: React.FC<Props> = (props) => {
  const [collapse, toggleCollapse] = useState<boolean>(true);
  return (
    <div className={s.root}>
      {/* </Box> */}
      <div className={s.tableContainer}>
        <Table className={s.table}>
          <Table.Header className={s.rowLayout}>
            <Table.Head className={s.heading}>Name</Table.Head>
            <Table.Head className={s.heading}>Contact</Table.Head>
            <Table.Head className={s.heading}>Role</Table.Head>
            <Table.Head className={s.heading}>Organization</Table.Head>
            <Table.Head className={s.heading}>Status</Table.Head>
            <Table.Head></Table.Head>
          </Table.Header>
          <TableItem />
          <TableItem status="disabled" />
          <TableItem status="pending" />
          <TableItem status="disabled" />
          <TableItem status="pending" />
        </Table>
      </div>
    </div>
  );
};

export default UsersTable;
