/**
 * Project: tracebility-dashboard
 * File: index
 * Created by pennycodes on 19/10/2022.
 * Copyright tracebility-dashboard
 */
import { HttpClient } from 'services/requests';
import ApiConfig from 'services/endpoints';
import auth from "models/query/auth";
import {
    BlackListPaginator,
    DashboardCount,
    InvitationCount,
    UserPaginator,
    UserSearchQuery,
    OrganisationsPaginator,
    QueryOptions,
    OrganisationTypePaginator,
    CreateOrEditOrganization,
    CreateOrEditProductionRegion,
    ProductionRegionPaginator,
    ProductionRegionOrganisationPaginator,
    SupportedRolePaginator,
    CreateInvitation,
    InvitationSearchQuery,
    InvitationPaginator,
    BlackListCategoryPaginator,
    CreatBlackListCategory,
    ResetPassword,
    UpdateUser,
    AddBlacklist,
    QueryOptionsTwo,
    ProductionZonePaginator,
    CreateOrEditProductionZone,
    BasicCommoditiesPaginator,
    CreateOrEditBasicCommodity,
    RegionalCommoditiesPaginator,
    CreateOrEditRegionalCommodity,
    WarehousePaginator,
    CreateOrEditWarehouse,
    EducationLevelPaginator,
    CountriesPaginator,
    AddOrEditCountry,
    ProductionRegionCommoditiesPaginator,
    BasicRegionalCommoditiesPaginator,
    UserOrganisationsPaginator,
    AddUserOrganisation,
    CountQuery,
    WarehousesCount,
    CommoditiesCount, AcceptInvitation, VehiclesCount
} from "models/query/system";

class Client {
    users = {
        details: (id: string) => HttpClient.get<UserPaginator>(ApiConfig.USERS.DETAILS.replace('{id}', id)),
        count: () => HttpClient.get<DashboardCount>(ApiConfig.USERS.REPORTS, {}),
        invitationCount: () => HttpClient.get<InvitationCount>(ApiConfig.INVITATIONS.REPORTS, {}),
        all: (query?: UserSearchQuery) =>
            HttpClient.get<UserPaginator>(ApiConfig.USERS.ALL, query),
        delete: (id: number) => HttpClient.delete(ApiConfig.USERS.DELETE.replace('{id}', String(id))),
        blacklist: () => HttpClient.get<BlackListPaginator>( ApiConfig.USERS.BLACKLIST, {}),
        supportedRoles: (query?: QueryOptions) => HttpClient.get<SupportedRolePaginator>(ApiConfig.USERS.SUPPORTED_ROLES, query),
        updateRole: (id: number, role: string) => HttpClient.put(ApiConfig.USERS.ROLE.replace('{id}', String(id)), { role }),
        updatePassword: (id: number, data: ResetPassword) => HttpClient.put(ApiConfig.USERS.PASSWORD.replace('{id}', String(id)), data),
        enable: (id: number) => HttpClient.put(ApiConfig.USERS.ENABLE.replace('{id}', String(id)), {}),
        disable: (id: number) => HttpClient.put(ApiConfig.USERS.DISABLE.replace('{id}', String(id)), {}),
        update: (id: number, data: UpdateUser) => HttpClient.put(ApiConfig.USERS.UPDATE.replace('{id}', String(id)), data),
        add_blacklist: (id: number, data:AddBlacklist) => HttpClient.put(ApiConfig.USERS.ADD_BLACKLIST.replace('{id}', String(id)), data),
        remove_blacklist: (id: number) => HttpClient.delete(ApiConfig.USERS.REMOVE_BLACKLIST.replace('{id}', String(id))),
        organisations: (id: number, query?: QueryOptions) => HttpClient.get<UserOrganisationsPaginator>(ApiConfig.USERS.ORGANISATIONS.ALL.replace('{id}', String(id)), query),
        addOrganisation: (id: number, data: AddUserOrganisation) => HttpClient.put(ApiConfig.USERS.ORGANISATIONS.CREATE.replace('{id}', String(id)), data),
        removeOrganisation: (id: number) => HttpClient.delete(ApiConfig.USERS.ORGANISATIONS.DELETE.replace('{id}', String(id))),
    };
    invitations =  {
        create: (data: CreateInvitation) => HttpClient.post(ApiConfig.INVITATIONS.CREATE, data),
        all: (query?: InvitationSearchQuery) => HttpClient.get<InvitationPaginator>(ApiConfig.INVITATIONS.ALL, query),
        details: (id: string) => HttpClient.get<InvitationPaginator>(ApiConfig.INVITATIONS.DETAILS.replace('{id}', id)),
        delete: (id: string) => HttpClient.delete(ApiConfig.INVITATIONS.DELETE.replace('{id}',id)),
        resend: (id: string) => HttpClient.get(ApiConfig.INVITATIONS.RESEND.replace('{id}', id)),
        accept: (id: string , data: AcceptInvitation) => HttpClient.post(ApiConfig.INVITATIONS.ACCEPT.replace('{id}', id), data),
    };
    organisations = {
        details: (id: string) => HttpClient.get<OrganisationsPaginator>(ApiConfig.ORGANISATIONS.DETAILS.replace('{id}', id)),
        all: (query?: QueryOptions) => HttpClient.get<OrganisationsPaginator>(ApiConfig.ORGANISATIONS.ALL, query),
        supported: () => HttpClient.get<OrganisationTypePaginator>(ApiConfig.ORGANISATIONS.SUPPORTED, {}),
        delete: (id: number) => HttpClient.delete(ApiConfig.ORGANISATIONS.DELETE.replace('{id}', String(id))),
        update: (id: number, data: CreateOrEditOrganization) => HttpClient.patch(ApiConfig.ORGANISATIONS.UPDATE.replace('{id}', String(id)), data),
        create: (data: CreateOrEditOrganization) => HttpClient.post(ApiConfig.ORGANISATIONS.CREATE, data),
        disable: (id: number) => HttpClient.put(ApiConfig.ORGANISATIONS.DISABLE.replace('{id}', String(id)), {}),
        enable: (id: number) => HttpClient.put(ApiConfig.ORGANISATIONS.ENABLE.replace('{id}', String(id)), {}),
    };
    productionRegions = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.ALL, query),
        create: (data: CreateOrEditProductionRegion) => HttpClient.post<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.CREATE, data),
        update: (id: number, data: CreateOrEditProductionRegion) => HttpClient.put(ApiConfig.PRODUCTION_REGIONS.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.PRODUCTION_REGIONS.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<ProductionRegionPaginator>(ApiConfig.PRODUCTION_REGIONS.DETAILS.replace('{id}', id)),
        organisations: (id: string, query?:QueryOptions) => HttpClient.get<ProductionRegionOrganisationPaginator>(ApiConfig.PRODUCTION_REGIONS.ORGANISATIONS.replace('{id}', id), query),
        commodities: (id: string, query?:QueryOptionsTwo) => HttpClient.get<ProductionRegionCommoditiesPaginator>(ApiConfig.PRODUCTION_REGIONS.COMMODITIES.replace('{id}', id), query),
        getZonesByRegion: (regionId: string) => HttpClient.get<any>(ApiConfig.PRODUCTION_ZONES.GET_BY_REGION.replace('{regionId}', regionId))
    };
    blacklistCategories = {
        all: (query?: QueryOptions) => HttpClient.get<BlackListCategoryPaginator>(ApiConfig.BLACKLIST.CATEGORIES.ALL, query),
        create: (data: CreatBlackListCategory) => HttpClient.post<BlackListCategoryPaginator>(ApiConfig.BLACKLIST.CATEGORIES.CREATE, data),
        delete: (id: number) => HttpClient.delete(ApiConfig.BLACKLIST.CATEGORIES.DELETE.replace('{id}', String(id))),
    };
    productionZones = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<ProductionZonePaginator>(ApiConfig.PRODUCTION_ZONES.ALL, query),
        create: (data: CreateOrEditProductionZone) => HttpClient.post<ProductionRegionPaginator>(ApiConfig.PRODUCTION_ZONES.CREATE, data),
        update: (id: number, data: CreateOrEditProductionZone) => HttpClient.put(ApiConfig.PRODUCTION_ZONES.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.PRODUCTION_ZONES.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<ProductionZonePaginator>(ApiConfig.PRODUCTION_ZONES.DETAILS.replace('{id}', id)),
    };
    basicCommodities = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<BasicCommoditiesPaginator>(ApiConfig.COMMODITIES.BASIC.ALL, query),
        create: (data: CreateOrEditBasicCommodity) => HttpClient.post<BasicCommoditiesPaginator>(ApiConfig.COMMODITIES.BASIC.CREATE, data),
        update: (id: number, data: CreateOrEditBasicCommodity) => HttpClient.put(ApiConfig.COMMODITIES.BASIC.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.COMMODITIES.BASIC.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<BasicCommoditiesPaginator>(ApiConfig.COMMODITIES.BASIC.DETAILS.replace('{id}', id)),
        commodities: (id: string, query?:QueryOptionsTwo) => HttpClient.get<BasicRegionalCommoditiesPaginator>(ApiConfig.COMMODITIES.BASIC.REGIONAL.replace('{id}', id), query),
    };
    regionalCommodities = {
        all: (query?: QueryOptionsTwo) => HttpClient.get<RegionalCommoditiesPaginator>(ApiConfig.COMMODITIES.REGIONAL.ALL, query),
        create: (data: CreateOrEditRegionalCommodity) => HttpClient.post<RegionalCommoditiesPaginator>(ApiConfig.COMMODITIES.REGIONAL.CREATE, data),
        update: (id: number, data: CreateOrEditRegionalCommodity) => HttpClient.put(ApiConfig.COMMODITIES.REGIONAL.UPDATE.replace('{id}', String(id)), data),
        delete: (id: number) => HttpClient.delete(ApiConfig.COMMODITIES.REGIONAL.DELETE.replace('{id}', String(id))),
        details: (id: string) => HttpClient.get<RegionalCommoditiesPaginator>(ApiConfig.COMMODITIES.REGIONAL.DETAILS.replace('{id}', id)),
        count: (query?: CountQuery) => HttpClient.get<CommoditiesCount>(ApiConfig.COMMODITIES.REGIONAL.COUNT, query),
    };
    warehouses = {
        all: (organisationId: string, query?: QueryOptions) => HttpClient.get<WarehousePaginator>(ApiConfig.WAREHOUSES.ALL.replace('{organisationId}', organisationId), query),
        create: (organisationId: string, data: CreateOrEditWarehouse) => HttpClient.post<WarehousePaginator>(ApiConfig.WAREHOUSES.CREATE.replace('{organisationId}', organisationId), data),
        update: (organisationId: string, id: number, data: CreateOrEditWarehouse) => HttpClient.put(ApiConfig.WAREHOUSES.UPDATE.replace('{organisationId}', organisationId).replace('{id}', String(id)), data),
        delete: (organisationId: string, id: number) => HttpClient.delete(ApiConfig.WAREHOUSES.DELETE.replace('{organisationId}', organisationId).replace('{id}', String(id))),
        details: (organisationId: string, id: string) => HttpClient.get<WarehousePaginator>(ApiConfig.WAREHOUSES.DETAILS.replace('{organisationId}', organisationId).replace('{id}', id)),
        status: (organisationId: string, id: string, enabled: boolean) => HttpClient.patch(ApiConfig.WAREHOUSES.STATUS.replace('{organisationId}', organisationId).replace('{id}', id).replace('{enabled}', String(enabled)), {}),
        count: (query?: CountQuery) => HttpClient.get<WarehousesCount>(ApiConfig.WAREHOUSES.COUNT, query),
        countByOrgId: (organisationId: string, query?: CountQuery) => HttpClient.get<WarehousesCount>(ApiConfig.WAREHOUSES.COUNT_BY_ORG.replace('{organisationId}', organisationId), query), //
    };
    educationLevels = {
        all: (query?: QueryOptions) => HttpClient.get<EducationLevelPaginator>(ApiConfig.EDUCATION_LEVELS, query),
    }
    countries = {
        all: (organisationId: string, query?: QueryOptions) => HttpClient.get<CountriesPaginator>(ApiConfig.COUNTRIES.ALL.replace('{organisationId}', organisationId), query),
        create: (organisationId: string, data: AddOrEditCountry) => HttpClient.post<CountriesPaginator>(ApiConfig.COUNTRIES.CREATE.replace('{organisationId}', organisationId), data),
        update: (organisationId: string, id: number, data: AddOrEditCountry) => HttpClient.put(ApiConfig.COUNTRIES.UPDATE.replace('{organisationId}', organisationId).replace('{id}', String(id)), data),
        delete: (organisationId: string, id: number) => HttpClient.delete(ApiConfig.COUNTRIES.DELETE.replace('{organisationId}', organisationId).replace('{id}', String(id))),
        details: (organisationId: string, id: string) => HttpClient.get<CountriesPaginator>(ApiConfig.COUNTRIES.DETAILS.replace('{organisationId}', organisationId).replace('{id}', id))
    };

    dashboard = {
        vehicleCount: (query?: CountQuery) => HttpClient.get<VehiclesCount>(ApiConfig.VEHICLE.COUNT, query),
        movedGoodsCount: (query?: CountQuery) => HttpClient.get<VehiclesCount>(ApiConfig.REPORTING.MOVED_GOODS_COUNT, query),
        receivedGoodsCount: (query?: CountQuery) => HttpClient.get<VehiclesCount>(ApiConfig.REPORTING.RECEIVED_GOODS_COUNT, query),
        availableGoodsCount: (query?: CountQuery) => HttpClient.get<VehiclesCount>(ApiConfig.REPORTING.AVAILABLE_GOODS_COUNT, query),
    }

    reporting = {
        search: (tagId: string) => HttpClient.get<any>(ApiConfig.REPORTING.TRACE.SEARCH_NO_ORG.replace('{tagId}', tagId),),
        queryReport: (param: string) => HttpClient.get<any>(ApiConfig.REPORTING.QUERY + param),
    }

}

export default new Client();
